import { Edit, SearchOutlined } from '@mui/icons-material';
import { Pill } from 'common/components/Pill/Pill';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { asDateTime } from 'common/helpers/dateHelpers';
import { PageNavTitles } from 'features/admin/modules/components/PageNavTitles';
import { InviteUserModal } from 'features/admin/organizations/components/invite-user-modal/InviteUserModal';
import useCancelInvitation from 'features/admin/organizations/hooks/useCanceInvitation';
import useGetInvitations from 'features/admin/organizations/hooks/useGetInvitations';
import { useState } from 'react';
import { Button, Form, InputGroup, Stack, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export const InvitedUsers = () => {
  const [filter, setFilter] = useState<string>('');

  const { organizationId } = useParams();

  const [showModal, setShowModal] = useState<boolean>(false);

  const [isLoading, , data, fetch] = useGetInvitations(organizationId ?? undefined);

  const [isCancellationLoading, , , , cancel] = useCancelInvitation();

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const handleInvitationCreated = () => {
    fetch();
  };

  const { t } = useTranslation('admin', { keyPrefix: 'organizations.userInvitations' });

  return (
    <>
      <PageNavTitles
        titles={[
          { title: 'Aktive brukere', link: `/admin/organizations/${organizationId}/users/active` },
          { title: 'Inviterte brukere', link: `/admin/organizations/${organizationId}/users/invited`, active: true },
        ]}
        backTo={`/admin/organizations/${organizationId}`}
      ></PageNavTitles>
      <br></br>

      <TableContainer>
        <Stack className="table-filter" direction="horizontal" gap={3}>
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder={'Søk på inviterte brukere'}
              aria-describedby={'Søk på inviterte brukere'}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
          <Button variant="tertiary" className="text-nowrap" onClick={() => setShowModal(true)}>
            Legg til bruker
          </Button>
        </Stack>
        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">{t('tableHeaders.name')}</th>
              <th scope="col">{t('tableHeaders.sentOn')}</th>
              <th scope="col">{t('tableHeaders.expiresOn')}</th>
              <th scope="col">{t('tableHeaders.status')}</th>
              <th scope="col">{t('tableHeaders.organizationRole')}</th>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((row, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <span>{row.userEmail}</span>
                    </td>
                    <td>
                      <span>{asDateTime(row.createdOn as string)}</span>
                    </td>
                    <td>
                      <span>{asDateTime(row.expiresOn as string)}</span>
                    </td>
                    <td>
                      <span>Venter på svar</span>
                    </td>
                    <td>
                      <span>{row.assignOrgAdmin ? 'Organisasjonsadministrator' : 'Organisasjonsbruker'}</span>
                    </td>
                    <td>
                      <Button size="sm" variant="danger" onClick={() => cancel(row.userInvitationId || '')}>
                        {t('btnCancel')}
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </TableContainer>

      <InviteUserModal
        show={showModal}
        organizationId={organizationId + ''}
        handleClose={handleModalClosed}
        handleInvitationCreated={handleInvitationCreated}
      />
    </>
  );
};

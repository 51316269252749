import {
  ObjectPropertyResponse,
  ObjectTypeLevelResponse,
  WorkspaceDetailLevelPropertyResponse,
  WorkspaceDetailLevelResponse,
} from 'common/api/multimap';
import { UserConfirmationModal } from 'common/modals/UserConfirmationModal';
import { Guid } from 'common/types/guid.type';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Stack } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFieldError } from '../../helpers/FormFieldError';
import { ObjectModalProperties } from './ObjectModalProperties';

export interface IFormInput {
  parentId?: Guid;
  systemName: string;
  systemDescription: string;
  area: number;
  properties: WorkspaceDetailLevelPropertyResponse[];
}

export interface IProps {
  show: boolean;
  mode: 'read' | 'edit' | 'create';
  object: WorkspaceDetailLevelResponse;
  objectType: ObjectTypeLevelResponse;
  objects: WorkspaceDetailLevelResponse[];
  properties: ObjectPropertyResponse[];
  showDeleted: boolean;
  onConfirm?: (object: WorkspaceDetailLevelResponse) => void;
  onDelete?: () => void;
  onHide: () => void;
}

const levelOptions = (
  objects: WorkspaceDetailLevelResponse[],
  objLevel: number,
  level: number,
  parentId: Guid | null,
  deactivated: boolean,
): JSX.Element[] => {
  const options = objects
    .filter((x) => !x.deactivatedOn || deactivated === true)
    .filter((x) => x.level === level && (x.parentId === parentId || parentId === null) && x.level < objLevel)
    .sort((v1, v2) => v1.systemName.localeCompare(v2.systemName))
    .map((obj) => {
      const opts = (
        <option key={obj.objectId} value={obj.objectId} disabled={obj.level !== objLevel - 1}>
          {[...Array((obj.level - 1) * 2)].map(() => '\u00a0')}
          {obj.systemName}
        </option>
      );

      const children = levelOptions(objects, objLevel, obj.level + 1, obj.objectId, deactivated);
      return [opts].concat(children);
    })
    .reduce((prev, curr) => prev.concat(curr), []);
  return options;
};

const numChildrenFor = (objects: WorkspaceDetailLevelResponse[], objectId: Guid): number => {
  const children = objects.filter((x) => x.parentId === objectId && !x.deactivatedOn);
  let count = children.length;
  for (let i = 0; i < children.length; i++) {
    count += numChildrenFor(objects, children[i].objectId);
  }
  return count;
};

export const ObjectModal: React.FC<IProps> = ({
  show,
  mode,
  object,
  objectType,
  objects,
  properties,
  showDeleted,
  onConfirm,
  onDelete,
  onHide,
}) => {
  const { t: ts } = useTranslation('shared');
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.detail.object' });

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    register,
    control,
    trigger,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInput>({
    defaultValues: {
      parentId: object?.parentId ?? undefined,
      systemName: object?.systemName,
      systemDescription: object?.systemDescription,
      properties:
        objectType.properties
          .sort((id1, id2) => {
            const v1 = properties.find((x) => x.objectPropertyId === id1)?.systemName ?? '';
            const v2 = properties.find((x) => x.objectPropertyId === id2)?.systemName ?? '';
            return v1.localeCompare(v2);
          })
          .map(
            (otpId) =>
              object.properties.find((op) => op.objectPropertyId === otpId) ?? {
                objectPropertyId: otpId,
              },
          )
          .map((x) => {
            return {
              objectPropertyId: x.objectPropertyId ?? null,
              valueDecimal: x.valueDecimal ?? null,
              valueInteger: x.valueInteger ?? null,
              valueOptionReferenceId: x.valueOptionReferenceId ?? null,
              valueString: x.valueString ?? null,
            };
          }) ?? [],
    },
    mode: 'onBlur',
  });

  const onSubmit = (data: IFormInput) => {
    if (!onConfirm) {
      return;
    }
    const result: WorkspaceDetailLevelResponse = {
      ...data,
      level: object?.level,
      objectId: object?.objectId ?? '',
    };
    onConfirm(result);
  };

  const onHideHandler = () => {
    reset();
    onHide();
  };

  if (!object || !objectType) {
    return <></>;
  }

  const title =
    mode === 'create'
      ? t('title-create', { type: objectType?.systemName })
      : t('title-edit', { type: objectType?.systemName });

  return (
    <>
      <Modal show={show} onHide={onHideHandler} centered={true} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Container fluid className="p-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={mode === 'read'}>
              <Row className="mb-3">
                {object.level > 1 && (
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label id="parent">* Parent</Form.Label>

                      <Controller
                        control={control}
                        name="parentId"
                        rules={{
                          validate: (value) => !(object.level <= 1) || value !== '',
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Form.Select
                            aria-labelledby="parent"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value ?? undefined}
                            ref={ref}
                            isInvalid={!!errors?.parentId}
                            isValid={!errors?.parentId}
                          >
                            {levelOptions(objects, object.level, 1, null, showDeleted)}
                          </Form.Select>
                        )}
                      />
                      <FormFieldError fieldError={errors?.parentId} />
                    </Form.Group>
                  </Col>
                )}

                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label id="name">* Navn</Form.Label>
                    <Controller
                      control={control}
                      name="systemName"
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          aria-labelledby="name"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          isInvalid={!!errors.systemName}
                          isValid={!errors.systemName}
                        ></Form.Control>
                      )}
                    />
                    <FormFieldError fieldError={errors?.systemName} />
                  </Form.Group>
                </Col>

                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label id="description">Beskrivelse</Form.Label>
                    <Controller
                      control={control}
                      name="systemDescription"
                      defaultValue={object.systemDescription}
                      rules={{ required: false }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          as={'textarea'}
                          aria-labelledby="description"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          isInvalid={!!errors.systemDescription}
                          isValid={!errors.systemDescription}
                        ></Form.Control>
                      )}
                    />
                    <FormFieldError fieldError={errors?.systemDescription} />
                  </Form.Group>
                </Col>
              </Row>

              {objectType.properties.length > 0 && (
                <>
                  <hr />
                  <Row>
                    <h6>Objektegenskaper</h6>
                    <ObjectModalProperties
                      disabled={mode === 'read'}
                      control={control}
                      errors={errors}
                      object={object}
                      properties={properties}
                    />
                  </Row>
                </>
              )}

              {(mode === 'edit' || mode === 'create') && (
                <Row>
                  <Col>
                    <Stack direction="horizontal">
                      <div className="ms-auto">
                        {onDelete && !object.deactivatedOn && (
                          <>
                            <Button size="sm" variant="secondary" onClick={() => setShowDeleteModal(true)}>
                              Slett Object
                            </Button>
                            <UserConfirmationModal
                              show={showDeleteModal}
                              title="Slett Object"
                              requireExtraConfirmation={true}
                              onCancel={setShowDeleteModal}
                              onConfirm={onDelete}
                            >
                              <span>
                                Du er ferd med å slette et object, dette kan medføre store endringer i kundeporteføljer.
                                Dette objektet innholder {numChildrenFor(objects, object.objectId)} underobjekter. Er du
                                sikker på at du ønsker å slette dette objektet?
                              </span>
                            </UserConfirmationModal>
                          </>
                        )}

                        <Button type="submit" variant="primary" size="sm" className="ms-2">
                          {ts('actionConfirm')}
                        </Button>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              )}
            </fieldset>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

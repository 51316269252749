import {
  ModuleConditionDegreeDescriptionResponse,
  ModuleMappingGroupDescriptionResponse,
  ModuleParameterDescriptionResponse,
} from 'common/api/multimap';
import { Infobox } from 'common/components/Infobox/Infobox';
import { Guid } from 'common/types/guid.type';
import { useEffect, useState } from 'react';
import { Accordion, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';

import { PageNavTitles } from '../components/PageNavTitles';
import useAddModuleConditionDescriptionsShell from '../hooks/useAddModuleConditionDescriptionsShell';
import useGetAllModuleConditionDescriptions from '../hooks/useGetAllModuleConditionDescriptions';
import useGetModule from '../hooks/useGetModule';
import useUpdateModuleConditionDescriptions from '../hooks/useUpdateModuleConditionDescription';
import styles from '../modules.module.scss';

export interface TotalWeighting {
  groupId: Guid;
  totalWeighting: number;
}

export const ConditionDescriptions: React.FC = () => {
  const { id } = useParams();

  const moduleId = id ? id : '';

  const [moduleLoading, , moduleData] = useGetModule(moduleId);

  const [, , data] = useGetAllModuleConditionDescriptions(moduleId);

  const [, , , , add] = useAddModuleConditionDescriptionsShell();

  const [, , , , update] = useUpdateModuleConditionDescriptions();

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const { fields: groups } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'groups', // unique name for your Field Array
  });

  const [disableGenerate, setDisableGenerate] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      reset({
        groups: data,
      });
    }
  }, [data, reset]);

  return (
    <>
      <PageNavTitles
        titles={[
          { title: 'Modul Oppsett', link: `/admin/modules/${id}/detail` },
          { title: 'Vekting', link: `/admin/modules/${id}/detail/weighting` },
          { title: 'Hjelpematrise', link: `/admin/modules/${id}/detail/condition-descriptions`, active: true },
        ]}
        backTo={`/admin/modules/${id}/detail`}
      >
        {data && data.length > 0 ? (
          <>
            <Button
              variant="tertiary"
              size="sm"
              onClick={() => {
                reset();
                setChanged(false);
              }}
              disabled={!changed}
            >
              Avbryt
            </Button>

            <Button type="submit" variant="primary" size="sm" className="mx-2" form="form" disabled={!changed}>
              Lagre
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="tertiary"
              size="sm"
              onClick={() => {
                setDisableGenerate(true);
                add({ id: moduleId });
              }}
              disabled={disableGenerate}
            >
              Generer hjelpematrise
            </Button>
          </>
        )}
      </PageNavTitles>

      <Form
        onSubmit={handleSubmit((d) => {
          update({ id: moduleId, request: d.groups });
          setChanged(false);
        })}
        id="form"
      >
        {data && data.length > 0 ? (
          <Container fluid className="mt-3 pt-3 bg-white">
            <div className="d-flex justify-content-between px-3">
              <span>Hovedkomponent</span>
            </div>
            {getValues('groups')
              .sort((a: ModuleMappingGroupDescriptionResponse, b: ModuleMappingGroupDescriptionResponse) => {
                return a.sortOrder - b.sortOrder;
              })
              .map((row: ModuleMappingGroupDescriptionResponse, rowIndex: number) => {
                return (
                  <div className="mb-5">
                    <h4 key={rowIndex} className="px-3">
                      {row.mappingGroupName}
                    </h4>
                    <hr></hr>
                    <Accordion alwaysOpen>
                      {row.parameters
                        .sort((a: ModuleParameterDescriptionResponse, b: ModuleParameterDescriptionResponse) => {
                          return a.sortOrder - b.sortOrder;
                        })
                        .map((parameter: ModuleParameterDescriptionResponse, pIndex: number) => {
                          const onEditorStateChange = (editorState: string) => {
                            setValue(`groups[${rowIndex}].parameters.${pIndex}.description`, editorState);
                          };
                          const editorContent = watch(`groups[${rowIndex}].parameters.${pIndex}.description`);
                          return (
                            <Accordion.Item key={pIndex} eventKey={`${pIndex}`} className="border-0 bg-white">
                              <Accordion.Header className={`${styles['accordion-header']}`}>
                                {parameter.parameterName}
                              </Accordion.Header>
                              <Accordion.Body className="px-0 py-3 bg-white">
                                <Accordion alwaysOpen>
                                  <Accordion.Item key={0} eventKey={'0'} className="border-0 rounded">
                                    <Accordion.Header className={`${styles['accordion-header-inner']}`}>
                                      <h5 className="p-0 m-0">Generell beskrivelse for {parameter.parameterName}</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="mx-3 pb-3">
                                        <ReactQuill
                                          theme="snow"
                                          value={editorContent}
                                          onChange={(e) => {
                                            onEditorStateChange(e);
                                            setChanged(true);
                                          }}
                                          className="bg-white"
                                        />
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  {parameter.conditionDegrees &&
                                    parameter.conditionDegrees
                                      .sort(
                                        (
                                          a: ModuleConditionDegreeDescriptionResponse,
                                          b: ModuleConditionDegreeDescriptionResponse,
                                        ) => {
                                          return a.conditionDegree - b.conditionDegree;
                                        },
                                      )
                                      .map((cd: ModuleConditionDegreeDescriptionResponse, i: number) => {
                                        const onEditorStateChangeCD = (editorState: string) => {
                                          setValue(
                                            `groups[${rowIndex}].parameters[${pIndex}].conditionDegrees.${i}.description`,
                                            editorState,
                                          );
                                        };
                                        const editorContentCD = watch(
                                          `groups[${rowIndex}].parameters[${pIndex}].conditionDegrees.${i}.description`,
                                        );
                                        return (
                                          <Accordion.Item
                                            key={i + 1}
                                            eventKey={`${i + 1}`}
                                            className="border-0 rounded my-1"
                                          >
                                            <Accordion.Header className={styles['accordion-header-inner']}>
                                              <h5 className="p-0 m-0">Tilstandsgrad: {cd.conditionDegree}</h5>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="mx-3 pb-3">
                                                <ReactQuill
                                                  theme="snow"
                                                  value={editorContentCD}
                                                  onChange={(e) => {
                                                    onEditorStateChangeCD(e);
                                                    setChanged(true);
                                                  }}
                                                  className="bg-white"
                                                />
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        );
                                      })}
                                </Accordion>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}
                    </Accordion>
                  </div>
                );
              })}
          </Container>
        ) : (
          <div className="mt-3">
            <Infobox
              show={true}
              variant="light"
              title={''}
              text={
                'Det er ingen hjelpematrise som er lagd ennå for denne modulen. Vennligst trykk på generer hjelpematrise, så skal vi hente opprette hjelpematrise skall basert på modul oppsettet.'
              }
              actionText={''}
              onClick={() => console.log('clicked')}
            ></Infobox>
          </div>
        )}
      </Form>
    </>
  );
};

// import { Input, InputType } from 'common/components/form/input/Input';
import { Input } from 'common/components/form/input/Input';
import { Form as GenericForm } from 'common/form/hooks/useGenericForm';
// TODO: Find a workaround for failing export check
// eslint-disable-next-line import/named
import { Controller, Path } from 'react-hook-form';

type FormInputProps<T> = {
  name: Path<T>;
  height?: string;
  labelText?: string;
  placeholder?: string;
  readonly?: boolean;
  hidden?: boolean;
  form: GenericForm<T>;
};

function FormInput<T>({ name, labelText, placeholder, readonly, form, hidden }: FormInputProps<T>) {
  const { isLoading, methods, formProps } = form;
  const val: string | number | readonly string[] | undefined = (formProps.model &&
    formProps.model[name.toString() as keyof typeof formProps.model]) as
    | string
    | number
    | readonly string[]
    | undefined;
  const validationErrors = formProps.errorDetails?.errors?.[name.toString()] ?? [];
  const isInvalid = validationErrors?.some((x: string) => x) ?? false;

  return (
    <Controller
      control={methods.control}
      name={name}
      render={({ field: { onChange, onBlur, ref } }) => (
        <Input
          onChange={onChange}
          labelText={labelText}
          ref={ref}
          onBlur={onBlur}
          isInvalid={isInvalid}
          placeholder={placeholder}
          defaultValue={val}
          readOnly={readonly || isLoading}
          name={name}
          hidden={hidden}
        />
      )}
    />
  );
}

export default FormInput;

import React from 'react';
// eslint-disable-next-line import/named
import { Form, FormControlProps } from 'react-bootstrap';

export interface InputProps extends FormControlProps {
  name: string;
  labelText?: string;
  invalidMessages?: string[];
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ name, labelText, invalidMessages, ...rest }: InputProps, ref) => {
    return (
      <Form.Group className="mb-2" controlId={rest.id} hidden={rest.hidden}>
        <Form.Label>{labelText}</Form.Label>
        <Form.Control name={name} {...rest} ref={ref} />
        {invalidMessages?.some((x) => x) && (
          <Form.Control.Feedback type="invalid">{invalidMessages[0]}</Form.Control.Feedback>
        )}
      </Form.Group>
    );
  },
);

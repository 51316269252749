import ArrowIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { CreateCustomerAccessRequest, OrganizationType } from 'common/api/multimap';
import { useCallback, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import StepWizard, { StepWizardChildProps, StepWizardProps } from 'react-step-wizard';

import { OnboardingSplash } from '../onboarding-splash';
import { OrganizationAgreement } from '../organization-agreement';
import { OrganizationCreated } from '../organization-created';
import { OrganizationRegistration } from '../organization-registration';
import { RequestCustomerAccess } from '../request-customer-access/RequestCustomerAccess';
import { ResponsiblePersonDialogue } from '../responsible-person-dialogue';
import style from './wizardShell.module.scss';

export interface IProps {
  show: boolean;
  onHide: () => void;
}

type WizardState = {
  currentStep: number;
  initialStep: number;
  totalSteps: number;
  isResponsiblePerson?: boolean;
  organizationFound: boolean;
  customerAccessRequest?: CreateCustomerAccessRequest;
  hasError: boolean;
  acceptAgreement?: boolean;
};

const defaultState: WizardState = {
  initialStep: 1,
  currentStep: 1,
  totalSteps: 6,
  organizationFound: false,
  hasError: false,
};

export const WizardShell: React.FC<IProps> = ({ show, onHide }) => {
  const [wizardState, setWizardState] = useState<Partial<StepWizardChildProps>>({});
  const [flowState, setFlowState] = useState<WizardState>(defaultState);

  const setInstance = (i: StepWizardProps) => setWizardState({ ...i });

  const isLastStep = () => flowState.currentStep === flowState.totalSteps;

  const onStepChange: ((stepChange: { previousStep: number; activeStep: number }) => void) | undefined = ({
    activeStep,
  }) => {
    setFlowState((x) => ({ ...x, currentStep: activeStep }));
  };

  const handleOnHide = () => {
    setFlowState(defaultState);
    setWizardState({});
    onHide();
  };

  const handleNextStep = () => {
    if (!isLastStep() && wizardState?.nextStep) wizardState?.nextStep();
    else handleOnHide();
  };
  const handleResponsibleChange = useCallback(
    (isResponsible: boolean | undefined) => {
      setFlowState((s) => ({ ...s, isResponsiblePerson: isResponsible }));
    },
    [setFlowState],
  );

  const handleAgreementChange = useCallback(
    (agreementAccepted: boolean | undefined) => {
      setFlowState((s) => ({ ...s, acceptAgreement: agreementAccepted }));
    },
    [setFlowState],
  );
  const handleSearchResultChange = useCallback(
    (orgFound: boolean, customerAccessRequest?: CreateCustomerAccessRequest) => {
      setFlowState((s) => ({ ...s, organizationFound: orgFound, customerAccessRequest }));
    },
    [setFlowState],
  );

  const handleError = useCallback(() => {
    setFlowState((s) => ({ ...s, hasError: true }));
  }, [setFlowState]);

  const isProgressionEnabled = () => {
    switch (flowState.currentStep) {
      case 3:
        return flowState.isResponsiblePerson;
      case 4:
        return (
          flowState.organizationFound && flowState.customerAccessRequest?.organizationType !== OrganizationType.Unknown
        );
      case 5:
        return !flowState.hasError;
      default:
        return true;
    }
  };
  const isProgressionBtnVisible = () => {
    switch (flowState.currentStep) {
      case 1:
        return false;
      case 2:
        return flowState.isResponsiblePerson;
      case 3:
        return flowState.acceptAgreement;
      case 4:
        return flowState.isResponsiblePerson && flowState.organizationFound;
      case 5:
        return !flowState.hasError;
      default: {
        return true;
      }
    }
  };

  return (
    <Modal show={show} fullscreen={true} onHide={handleOnHide} animation={false} className="overflow-hidden">
      <Container fluid className={`vh-100 ${style.wsBody}`}>
        <div className="h-100 d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-end p-3">
            <div
              role="button"
              tabIndex={0}
              onClick={handleOnHide}
              onKeyDown={handleOnHide}
              className={`${style.wsClose} text-center`}
            >
              <CloseIcon />
              <div className="d-none d-md-block">ESC</div>
            </div>
          </div>
          <div className="w-100">
            <StepWizard
              instance={setInstance}
              onStepChange={onStepChange}
              initialStep={flowState.initialStep}
              className={`${style.wsContainer} w-100 h-100 align-items-center`}
            >
              <OnboardingSplash nextStep={handleNextStep} />
              <ResponsiblePersonDialogue onConfirmationChange={handleResponsibleChange} />
              <OrganizationAgreement onAcceptChange={handleAgreementChange} />
              <OrganizationRegistration onSearchResultChange={handleSearchResultChange} />
              <RequestCustomerAccess
                request={flowState.customerAccessRequest}
                onSuccess={handleNextStep}
                onError={handleError}
                onCloseRequest={handleOnHide}
                isActive={flowState.currentStep === 3}
              />
              <OrganizationCreated currentStep={flowState.currentStep} />
            </StepWizard>
          </div>
          <div className="d-flex align-items-end justify-content-end p-3">
            <Button
              className={`${!isProgressionBtnVisible() ? 'invisible' : ''}`}
              disabled={!isProgressionEnabled()}
              onClick={handleNextStep}
            >
              {!isLastStep() ? 'Fortsett' : 'Avslutt'} <ArrowIcon />
            </Button>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

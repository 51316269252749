import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { ValidationErrors } from 'common/components/form/ValidationErrors';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';

import useImportExecuteWorkspace from '../hooks/useImportExecuteWorkspace';

export interface IProps {
  workspaceId: Guid;
  file: File;
  onNext: () => void;
  onCancel: () => void;
}

export const ImportExecuteStep: React.FC<IProps> = ({ workspaceId, file, onNext, onCancel }) => {
  const [isSaving, , , , validationError, importExecuteResponse, saveAction] = useImportExecuteWorkspace();
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    saveAction({ workspaceId, file });
  }, [workspaceId, file]);

  useEffect(() => {
    setIsInvalid(!importExecuteResponse || !!validationError);
  }, [importExecuteResponse, validationError]);

  if (isSaving) {
    return (
      <>
        <LoadingSpinner isLoading={isSaving} />
      </>
    );
  }

  return (
    <>
      {isInvalid && (
        <Row>
          <Col>
            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
              <CancelIcon color="error" fontSize="large" />

              <h4 className="text-danger">En uventet feil oppstod</h4>
            </div>
          </Col>
        </Row>
      )}

      {validationError && <ValidationErrors errors={validationError} />}

      {!isInvalid && (
        <Row>
          <Col>
            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
              <CheckCircleOutlinedIcon color="success" fontSize="large" />

              <h4>Importen er fullført.</h4>
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Stack direction="horizontal" className='justify-content-center'>
            {isInvalid && (
              <Button
                type="submit"
                variant="primary"
                size="sm"
                className="text-nowrap ms-auto"
                onClick={() => onCancel()}
              >
                Start på nytt
              </Button>
            )}
            {!isInvalid && (
              <Button
                size="sm"
                type="submit"
                variant="primary"
                className="text-nowrap mt-4"
                onClick={() => onNext()}
              >
                Se porteføljen
              </Button>
            )}
          </Stack>
        </Col>
      </Row>
    </>
  );
};

import { reportsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { ReportResponse } from 'common/api/multimap';
import { useQuery } from 'react-query';

function useGetAllReports(): [
  boolean,
  boolean,
  ReportResponse[] | undefined,
  () => Promise<ReportResponse[] | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.SpecialReports],
    () => reportsApi.reportsSpecialReportsGet().then((x) => x.data),
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetAllReports;

import { CreditScoreTrendResponse, CustomerAccessRequestStatus, OrganizationType } from 'common/api/multimap';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { asDateTime } from 'common/helpers/dateHelpers';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useEffect } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useGetCustomeAccessRequestDetails from '../hooks/useGetCustomerAccessRequestDetails';
import useSearchOrganizationCreditInfo from '../hooks/useSearchOrganizationCreditInfo';
import useUpdateCustomerAccessRequest from '../hooks/useUpdateCustomerAccessRequest';

const KeyValue = ({
  cols,
  label,
  value,
  tooltip,
}: {
  cols: number;
  label: string;
  value: string;
  tooltip?: string;
}) => {
  return (
    <Col xs={cols}>
      <small>{label}</small>

      {tooltip && <HelpPopover type="help" id={label.replace(' ', '_')} title={label} text={tooltip}></HelpPopover>}

      <h6>{value || '-'}</h6>
    </Col>
  );
};

const History = ({ rows }: { rows: CreditScoreTrendResponse[] | undefined }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'requests.detail.trendData' });
  return rows ? (
    <>
      <TableContainer>
        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">{t('date')}</th>
              <th scope="col">{t('score')}</th>
              <th scope="col">{t('riskClass')}</th>
              <th scope="col">{t('riskClassText')}</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => {
              return (
                <tr key={i}>
                  <td>
                    <span>{asDateTime(row.date || '')}</span>
                  </td>
                  <td>
                    <span>{row.score}</span>
                  </td>
                  <td>
                    <span>{row.riskClass}</span>
                  </td>
                  <td>
                    <span>{row.riskClassText}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <p> {t('noRowsFound')} </p>
  );
};

export const Detail = () => {
  const { id } = useParams();
  const { t } = useTranslation('admin', { keyPrefix: 'requests.detail' });
  const navigate = useNavigate();
  const [isSearchLoading, , isSearchSuccessFul, searchValidationError, , search] = useSearchOrganizationCreditInfo();
  const [isLoadingCustomerRequest, , data] = useGetCustomeAccessRequestDetails(id as string, isSearchSuccessFul);
  const [isLoading, , validationError, updateResult, update] = useUpdateCustomerAccessRequest();

  useEffect(() => {
    if (updateResult) navigate(-1);
  }, [updateResult, navigate]);

  return (
    <>
      <PageTitle title={`${t('title')} fra ${data?.name}`} backTo={`/admin/requests/`}>
        <Button
          variant="secondary"
          size="sm"
          className="mx-2"
          onClick={() => update({ status: CustomerAccessRequestStatus.Denied, id: id + '' })}
        >
          {t('actionDeny')}
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() => update({ status: CustomerAccessRequestStatus.Approved, id: id + '' })}
        >
          {t('actionApprove')}
        </Button>
      </PageTitle>

      {(validationError || searchValidationError) && (
        <div>{validationError?.genericErrors?.[0] || searchValidationError?.genericErrors?.[0]}</div>
      )}

      {isLoading || isSearchLoading || isLoadingCustomerRequest ? (
        <LoadingSpinner isLoading={true} />
      ) : (
        <>
          <Container className="mt-4">
            <Row>
              <KeyValue
                cols={2}
                label={t('data.creationDate')}
                value={(data?.createdOn && asDateTime(data?.createdOn)) || ''}
              ></KeyValue>
              <KeyValue cols={2} label={t('data.createdBy')} value={data?.createdByUserName || ''}></KeyValue>
              <KeyValue cols={2} label={t('data.organizationNumber')} value={data?.organizationNumber + ''}></KeyValue>
              <KeyValue cols={2} label={t('data.organizationName')} value={data?.name || ''}></KeyValue>
              <KeyValue cols={2} label={t('data.address')} value={data?.address1 || ''}></KeyValue>
              <KeyValue cols={2} label={t('data.city')} value={data?.city || ''}></KeyValue>
              <KeyValue cols={2} label={t('data.country')} value={data?.country || ''}></KeyValue>
              <KeyValue cols={2} label={t('data.postalCode')} value={data?.zipCode || ''}></KeyValue>
              <KeyValue
                cols={2}
                label={t('data.organizationType')}
                value={
                  data?.organizationType == OrganizationType.Private
                    ? t('data.organizationTypePrivate')
                    : t('data.organizationTypePublic')
                }
                tooltip={t('data.organizationTypeInfo')}
              ></KeyValue>
            </Row>
            {!data?.creditInfo && (
              <Row className="mt-4">
                <Col sm={12}>
                  <p>
                    <strong>{t('creditCheckDataMissing')}</strong>
                  </p>
                </Col>
                <Col sm={2}>
                  <Button
                    variant="primary"
                    size="sm"
                    className="mx-2"
                    onClick={() => search(data?.organizationNumber ?? undefined)}
                  >
                    {t('btnFetchCreditCheck')}
                  </Button>
                </Col>
              </Row>
            )}
            {data?.creditInfo && (
              <>
                <Row>
                  <Col xs={2}>
                    <h6 className="my-4">{t('data.generalInformation')}</h6>
                    <Row>
                      <KeyValue
                        cols={12}
                        label={t('data.creditScore')}
                        value={data?.creditInfo?.creditScore?.score || ''}
                      ></KeyValue>
                    </Row>
                    <Row>
                      <KeyValue
                        cols={12}
                        label={t('data.riskClass')}
                        value={`${data?.creditInfo?.creditScore?.riskClass ?? ''} - ${data?.creditInfo?.creditScore?.riskClassText ?? ''
                          }`}
                      ></KeyValue>
                    </Row>
                    <Row>
                      <KeyValue
                        cols={12}
                        label={t('data.creditLimit')}
                        value={data?.creditInfo?.creditScore?.creditLimit || ''}
                      ></KeyValue>
                    </Row>
                    <Row>
                      <KeyValue
                        cols={12}
                        label={t('data.interpretationDate')}
                        value={
                          (data?.creditInfo?.creditScore?.creditScoreInterpretationDate &&
                            asDateTime(data?.creditInfo.creditScore.creditScoreInterpretationDate)) ||
                          ''
                        }
                      ></KeyValue>
                    </Row>
                  </Col>
                  <Col xs={10}>
                    <h6 className="my-4">{t('data.history')}</h6>

                    <History rows={data?.creditInfo?.creditScore?.creditScoreTrend ?? undefined}></History>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </>
      )}
    </>
  );
};

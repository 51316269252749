import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ObjectPropertyResponse } from 'common/api/multimap';
import { BoolIcon } from 'common/components/BoolIcon/BoolIcon';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { Guid } from 'common/types/guid.type';
import { useState } from 'react';
import { Col, Form as RBForm, InputGroup, Row, Stack, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { LabelField } from './components/LabelField';

export interface IProps {
  disabled?: boolean;
  excludedIds: Guid[];
  ids: Guid[];
  properties: ObjectPropertyResponse[];
  onChecked: (id: Guid, checked: boolean) => void;
}

export const PropertyEditor: React.FC<IProps> = ({ disabled, excludedIds, ids, properties, onChecked }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes.detail.levelPropertiesEditor' });
  const { t: tTable } = useTranslation('admin', { keyPrefix: 'objectTypes.detail.levelPropertiesEditor.table' });

  const [filter, setFilter] = useState('');

  return (
    <>
      <Row>
        <Col>
          <LabelField label={t('propertiesTitle')} field={t('properties')} tooltip="" />
        </Col>
      </Row>

      <Row>
        <Col>
          <TableContainer>
            <Stack direction="horizontal" className="table-filter">
              <InputGroup>
                <InputGroup.Text>
                  <SearchOutlinedIcon fontSize="small" />
                </InputGroup.Text>
                <RBForm.Control
                  disabled={disabled}
                  type="text"
                  id="filter"
                  placeholder={t('filterPlaceholder')}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </InputGroup>
            </Stack>

            <div style={{ overflow: 'auto', maxHeight: '50vh' }}>
              <Table hover responsive className={`mt-4 ${disabled ? 'disabled' : ''}`}>
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{tTable('name')}</th>
                    <th scope="col" className="text-nowrap">
                      {tTable('inUse')}
                    </th>
                    <th scope="col">{tTable('type')}</th>
                    <th scope="col">{tTable('mandatory')}</th>
                    <th scope="col">{tTable('computation')}</th>
                  </tr>
                </thead>
                <tbody>
                  {properties
                    .filter((x) => x.systemName.toLowerCase().includes(filter.toLowerCase()))
                    .sort((v1, v2) => new Date(v2.createdOn).valueOf() - new Date(v1.createdOn).valueOf())
                    .map((prop, i) => {
                      const isSet = ids.includes(prop.objectPropertyId);
                      return (
                        <tr key={i}>
                          <td>
                            {(isSet || !excludedIds.includes(prop.objectPropertyId)) && (
                              <RBForm.Check
                                type="checkbox"
                                id={prop.objectPropertyId}
                                label=""
                                checked={isSet}
                                onChange={(e) => onChecked(prop.objectPropertyId, e.target.checked)}
                              />
                            )}
                          </td>
                          <td>
                            <span>{prop.systemName}</span>
                          </td>
                          <td className="text-end">
                            <span>{prop.usedBy}</span>
                          </td>
                          <td>
                            <span>{prop.datatypeId}</span>
                          </td>
                          <td className="text-center">
                            <span>
                              <BoolIcon value={prop.required}></BoolIcon>
                            </span>
                          </td>
                          <td className="text-center">
                            <span>
                              {prop.isTotal && tTable('computationTotal')}
                              {prop.isAverage && !prop.weightedAveragePropertyId && tTable('computationAverage')}
                              {prop.weightedAveragePropertyId &&
                                tTable('computationWeightedAverage', {
                                  reference: properties.find((x) => x.objectPropertyId === prop.weightedAveragePropertyId)
                                    ?.systemName,
                                })}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </TableContainer>
        </Col>
      </Row>
    </>
  );
};

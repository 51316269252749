import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router';

export const MaintenancePlan = () => {

  useEffect(() => {
    document.title = "Vedlikeholdsplan"
  }, [])
  return (
    <>
      <Container fluid className="px-sm-4 px-lg-5 mt-4">
        <Outlet />
      </Container>
    </>
  );
};

import { CreateUserInvitationRequest } from 'common/api/multimap';
import { useGenericForm } from 'common/form/hooks/useGenericForm';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, FormGroup, Modal } from 'react-bootstrap';

import useCreateInvitation from '../../hooks/useCreateInvitation';

export type UserInvitation = {
  name: string;
  email: string;
  role: string;
};

export interface IProps {
  show: boolean;
  organizationId: string;
  handleClose: () => void;
  handleInvitationCreated: () => void;
}

export const InviteUserModal: React.FC<IProps> = ({ show, handleClose, handleInvitationCreated, organizationId }) => {
  const [formState, setFormState] = useState<CreateUserInvitationRequest | undefined>();
  const [isLoading, isIdle, , , validationError, data, create, reset] = useCreateInvitation();

  const [role, setRole] = useState('1');
  const { FormContainer, FormInput, form, FormErrors } = useGenericForm<CreateUserInvitationRequest>({
    model: formState,
    errorDetails: validationError,
    onSubmit: async (e) => {
      if (role == '2') {
        e.assignOrgAdmin = true;
      }

      e.urlLink = window.location.origin;

      create({ ...e, organizationId });
    },
  });
  const onClose = useCallback(() => {
    reset();
    handleClose();
    setFormState(undefined);
  }, [reset, handleClose, setFormState]);

  useEffect(() => {
    if (data) {
      handleInvitationCreated();
      onClose();
    }
  }, [isIdle, handleInvitationCreated, data, onClose, reset]);

  return (
    <Modal show={show} onHide={onClose}>
      <FormContainer form={form}>
        <Modal.Header closeButton>
          <Modal.Title> Inviter ny bruker </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-4">
          <FormErrors form={form} />
          <LoadingSpinner isLoading={isLoading} />
          <FormInput form={form} name="userEmail" labelText="E-post" hidden={isLoading} />
          <FormGroup hidden={isLoading}>
            <Form.Label>Rettighet</Form.Label>
            <Form.Select
              aria-label="Velg rolle"
              placeholder="Velg rolle"
              onChange={(e) => setRole(e.currentTarget.value)}
            >
              <option value="1" selected>
                Organisasjonsbruker
              </option>
              <option value="2">Organisasjonsadministrator</option>
            </Form.Select>
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" variant="primary" size="sm">
            Send invitasjon
          </Button>
        </Modal.Footer>
      </FormContainer>
    </Modal>
  );
};

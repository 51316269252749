import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { ObjectTypeResponse } from 'common/api/multimap';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { Guid } from 'common/types/guid.type';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { LoadingSpinner } from '../components/loading-spinner/LoadingSpinner';
import { objectTypeGroups } from './helpers/objectTypeGroups';
import useGetObjectTypes from './hooks/useGetObjectTypes';
import useObjectGroupIcon from './hooks/useObjectGroupIcon';
import useObjectGroupTranslation from './hooks/useObjectGroupTranslation';
import styles from './object-types.module.scss';

export interface IObjectCardProps {
  id: Guid;
  name?: string;
  info: string;
  onClick: () => void;
}

const ObjectCard: React.FC<IObjectCardProps> = ({ id, name, info, onClick }) => {
  return (
    <Card role="button" onClick={onClick}>
      <Card.Body className="px-0 py-2 d-flex flex-column justify-content-between">
        <div className={`d-flex justify-content-end ${info ? '' : 'invisible'} `}>
          <HelpPopover type="info" id={`object-${id}`} title="Info" text={info} />
        </div>
        <div className={`text-center px-3`}>
          <h6 className="text-truncate m-0" title={name}>
            {name}
          </h6>
        </div>
        <div>
          {/* footer */}
          &nbsp;
        </div>
      </Card.Body>
    </Card>
  );
};

export interface IObjectGroupProps {
  icon: React.ReactNode;
  title: string;
  items: ObjectTypeResponse[];
}

export const ObjectGroup: React.FC<IObjectGroupProps> = ({ icon, title, items }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes' });

  const navigate = useNavigate();

  return (
    <>
      <h3 className="d-flex align-items-center">
        {icon} <span className="mx-2"></span>
        {title}
      </h3>

      <Row className="d-flex">
        {items.map((item) => (
          <Col key={item.objectTypeId} sm={6} md={4} lg={2} className="mb-3">
            <ObjectCard
              name={item.systemName || ''}
              info={item.systemDescription || ''}
              onClick={() => navigate(`/admin/object-types/${item.objectTypeId}/detail`)}
              id={item.objectTypeId}
            ></ObjectCard>
          </Col>
        ))}
        {items.length === 0 && <p>{t('noObjectTypes')}</p>}
      </Row>
    </>
  );
};

export const ObjectTypes = () => {
  const navigate = useNavigate();

  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes' });
  const { t: tg } = useObjectGroupTranslation();
  const [iconResolver] = useObjectGroupIcon();

  const [isLoading, , data] = useGetObjectTypes();

  const items = [...(data ?? [])].sort((v1, v2) => v1.systemName.localeCompare(v2.systemName));

  const pageTitle = (
    <PageTitle title={t('title')}>
      <Button variant="tertiary" size="sm" onClick={() => navigate(`/admin/object-types/detail`)}>
        <AddCircleOutlineOutlinedIcon /> {t('actionCreate')}
      </Button>
    </PageTitle>
  );

  return (
    <>
      {pageTitle}

      <LoadingSpinner isLoading={isLoading} />

      {objectTypeGroups.map((group) => (
        <ObjectGroup
          key={group}
          icon={iconResolver(group)}
          title={tg(group)}
          items={items?.filter((x) => x.group === group) ?? []}
        ></ObjectGroup>
      ))}
    </>
  );
};

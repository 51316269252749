import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { TileButton } from 'common/components/TileButton/TileButton';
import React from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export type StartModalChoice = 'manual' | 'upload';

export interface IProps {
  objectType: string;
  onChoice: (value: StartModalChoice) => void;
  onHide: () => void;
}

export const StartModal: React.FC<IProps> = ({ objectType, onChoice, onHide }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.detail.object' });

  return (
    <>
      <Modal show={true} onHide={onHide} centered={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Hvordan ønsker du å opprette {objectType}?</Modal.Title>
        </Modal.Header>

        <Container fluid className="p-3">
          <Form>
            <Row className="mb-3">
              <Col sm={12}>
                <TileButton
                  title="Legg til data manuelt"
                  text="Legg til informasjon om lokasjonen stegvis."
                  onClick={() => onChoice('manual')}
                  leftIcon={<AssignmentTurnedInOutlinedIcon fontSize="large" />}
                  rightIcon={<ArrowForwardIcon />}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <TileButton
                  title="Importer data fra Excel-ark"
                  text="Har du data lagret i et Excel-ark, kan du laste det opp og importere det inn i Multimap."
                  onClick={() => onChoice('upload')}
                  leftIcon={<UploadFileOutlinedIcon fontSize="large" />}
                  rightIcon={<ArrowForwardIcon />}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

import { AxiosError } from 'axios';
import { organizationsApi } from 'common/api/MultimapClients';
import { OrganizationCreditInfoResponse, ValidationProblemDetails } from 'common/api/multimap';
import { useState } from 'react';
import { useMutation } from 'react-query';

import useGetCustomerAccessRequests from './useGetCustomerAccessRequests';

function useSearchOrganizationCreditInfo(): [
  boolean,
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  OrganizationCreditInfoResponse | undefined,
  (organizationNumber: string | undefined) => void,
] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isIdle, isSuccess, isError, data, mutate } = useMutation(
    (organizationNumber: string | undefined) => {
      return organizationsApi.searchCreditCheckInfo({ organizationNumber }).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const search = (organizationNumber: string | undefined) => mutate(organizationNumber);

  return [isLoading, isError, isSuccess, validationError, data, search];
}
export default useSearchOrganizationCreditInfo;

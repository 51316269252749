import { CloudUpload } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { WorkspaceDetailResponse, WorkspaceResponse } from 'common/api/multimap';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetAllObjectProperties from 'features/admin/object-properties/hooks/useGetAllObjectProperties';
import useGetObjectTypeDetail from 'features/admin/object-types/hooks/useGetObjectTypeDetail';
import { Upsert } from 'features/admin/workspaces/detail/Upsert';
import useGetWorkspaceDetail from 'features/admin/workspaces/hooks/useGetWorkspaceDetail';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';

export const ObjectRegistry = () => {

  useEffect(() => {
    document.title = "Portefølje"
  }, [])

  const [workspace] = useLocalStorage<WorkspaceResponse | undefined>('workspace', undefined);

  const [isLoading, , data, fetch] = useGetWorkspaceDetail(workspace ? workspace.workspaceId : '');

  const [isLoading2, , type] = useGetObjectTypeDetail(data ? data.objectType.objectTypeId : '');

  const [isLoading3, , properties] = useGetAllObjectProperties();

  if (isLoading || isLoading2 || isLoading3 || !type) {
    return <LoadingSpinner isLoading={true} />;
  }

  return (
    <Container fluid className="px-sm-4 px-lg-5 mt-4">
      <Upsert
        type={type}
        properties={properties ?? []}
        isLoading={false}
        refresh={fetch}
        workspace={data}
        admin={false}
      />
    </Container>
  );
};

import { AxiosError } from 'axios';
import { modulesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  ModuleResponse,
  ModuleWeightingVersionDetailResponse,
  UpdateModuleRequest,
  UpdateModuleWeightingVersionRequest,
  ValidationProblemDetails,
} from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type UpdateParams = {
  id: string;
  weightingVersionId: string;
  request: UpdateModuleWeightingVersionRequest;
};

function useUpdateModuleWeightingVersion(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  ModuleWeightingVersionDetailResponse | undefined,
  (params: UpdateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    ({ id, weightingVersionId, request }: UpdateParams) => {
      return modulesApi
        .modulesIdWeightingVersionsWeightingVersionIdPut(id, weightingVersionId, request)
        .then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.ModuleWeightingVersions);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const update = (params: UpdateParams) => mutate(params);

  return [isLoading, isError, validationError, data, update];
}

export default useUpdateModuleWeightingVersion;

import { UpdateLearningRequest } from 'common/api/multimap';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { PageNavTitles } from 'features/admin/modules/components/PageNavTitles';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router';

import useAddLearning from '../hooks/useAddLearning';
import useGetLearning from '../hooks/useGetLearning';
import useUpdateLearning from '../hooks/useUpdateLearning';

export const Learning = () => {
  const { id } = useParams();

  //API Hooks
  const [isLoading, , data] = useGetLearning(id ?? undefined);
  const [addLoading, , , newData, add] = useAddLearning();
  const [updateLoading, , , updatedData, update] = useUpdateLearning();

  //React Hooks
  const [changed, setChanged] = useState<boolean>(false);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<UpdateLearningRequest>();

  useEffect(() => {
    if (data) {
      reset({
        learningId: data.learningId,
        mappingSetId: data.mappingSetId,
        active: data.active,
        videoPath: data.videoPath,
        description: data.description,
      });
    }
  }, [data, reset]);

  const onEditorStateChange = (editorState: string) => {
    setValue('description', editorState);
  };

  const editorContent: any = watch('description');

  const updateLearning = async (d: UpdateLearningRequest) => {
    update({ id: id ?? '', request: d });
    setChanged(false);
  };

  return (
    <>
      <PageNavTitles
        titles={[
          { title: 'Rediger parametersett', link: `/admin/mapping-sets/${id}/detail` },
          { title: 'Opplæring', link: `/admin/mapping-sets/${id}/detail/learning`, active: true },
        ]}
        backTo={`/admin/mapping-sets`}
      >
        {isLoading ? (
          <>
            <LoadingSpinner isLoading={isLoading} />
          </>
        ) : (
          <>
            {data ? (
              <>
                {' '}
                <Button type="submit" variant="primary" size="sm" className="mx-2" form="form" disabled={!changed}>
                  Lagre
                </Button>
                <Button variant="secondary" size="sm" disabled={!changed}>
                  Avbryt
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="tertiary"
                  disabled={addLoading}
                  onClick={() => add({ mappingSetId: id, description: '', active: false, videoPath: '' })}
                >
                  Generer
                </Button>
              </>
            )}
          </>
        )}
      </PageNavTitles>
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : (
        <>
          {' '}
          {data ? (
            <>
              <h4 className="mt-4">Oppsett</h4>
              <Form
                className="mt-3"
                onSubmit={handleSubmit((d) => updateLearning(d))}
                onChange={() => {
                  setChanged(true);
                }}
                id="form"
              >
                <Form.Group>
                  <Form.Label>Vil du at dette parametersettet skal vises på opplæringssiden?</Form.Label>
                  <Form.Check label="Ja, vis på opplæringssiden" {...register('active')} />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label>Video lenke</Form.Label>
                  <Form.Control {...register('videoPath')}></Form.Control>
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label>Beskrivelse</Form.Label>
                  <ReactQuill
                    theme="snow"
                    className="bg-white"
                    value={editorContent}
                    onChange={(e) => {
                      onEditorStateChange(e);
                      setChanged(true);
                    }}
                  />
                </Form.Group>
              </Form>
            </>
          ) : (
            <>
              <h4 className="mt-4">
                Du har enda ikke opprettet opplæring på dette parametersettet. Trykk på "Generer" knappen for å starte.
              </h4>
            </>
          )}
        </>
      )}
    </>
  );
};

import { ReportResponse, CreateReportRequest, ReportType } from "common/api/multimap";
import { displayIcon, getListOfIcons } from "features/admin/modules/components/ModuleIcons";
import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useAddReport from "../hooks/useAddReport";
import useUpdateReport from "../hooks/useUpdateReport";
import useGetOrganizationReports from "../hooks/useGetOrganizationReports";
import ReactQuill from "react-quill";
import useGetObjectTypes from "features/admin/object-types/hooks/useGetObjectTypes";

export interface IProps {
  show: boolean;
  onHide: () => void;
  edit: boolean;
  response?: ReportResponse;
}

export const ReportModal: React.FC<IProps> = ({ show, onHide, edit, response }) => {

  const [, , , , add] = useAddReport();
  const [, , , , update] = useUpdateReport();
  const [isLoading, , data] = useGetObjectTypes();

  const [chosenIcon, setChosenIcon] = useState<string>('');

  const [reactIcon, setReactIcon] = useState<JSX.Element>();



  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm<CreateReportRequest>();


  const readMoreOnEditorStateChange = (editorState: string) => {
    setValue('readMore', editorState);
  };

  const orderTextOnEditorStateChange = (editorState: string) => {
    setValue('orderText', editorState);
  }

  const readMoreEditorContent: any = watch('readMore');
  const orderTextEditorContent: any = watch('orderText')

  useEffect(() => {
    if (response && edit) {
      reset(response);
    } else {
      reset({
        systemName: "",
        systemDescription: "",
        readMore: "",
        orderText: "",
        reportTypeId: ReportType.PowerBi,
        reportPrice: 0
      });
    }
  }, [response, edit])

  useEffect(() => {
    if (chosenIcon) {
      setReactIcon(displayIcon(chosenIcon));
    }
  }, [chosenIcon]);

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        {edit ? (<Modal.Title>Rediger rapport</Modal.Title>) : (
          <Modal.Title>Opprett ny rapport</Modal.Title>)}
      </Modal.Header>

      <Form onSubmit={handleSubmit((d) => {
        if (edit) {
          update({ reportId: response?.reportId ?? "", request: d })
        } else {
          add(d);
        }
        onHide();
      })}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Navn</Form.Label>
            <Form.Control {...register('systemName')}></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Beskrivelse</Form.Label>
            <Form.Control as="textarea" {...register('systemDescription')}></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Les mer tekst</Form.Label>
            <ReactQuill
              theme="snow"
              className="bg-white"
              value={readMoreEditorContent}
              onChange={(e) => {
                readMoreOnEditorStateChange(e);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Bestillingstekst</Form.Label>
            <ReactQuill
              theme="snow"
              className="bg-white"
              value={orderTextEditorContent}
              onChange={(e) => {
                orderTextOnEditorStateChange(e);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Rapport type</Form.Label>
            <Form.Select {...register('reportTypeId')}>
              <option value={ReportType.PowerBi}>PowerBI</option>
              <option value={ReportType.Pdf}>PDF</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Objekttype</Form.Label>
            <Form.Select {...register('objectTypeId')}>
              {data && data.map((row, i) => (
                <option value={row.objectTypeId}>{row.systemName}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Pris</Form.Label>
            <Form.Control type="number" {...register('reportPrice')}></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Ikon for modul</Form.Label>
            <Form.Select
              {...register('icon', {
                onChange: (event) => {
                  setChosenIcon(event.currentTarget.value);
                },
              })}
            >
              <option value={''} disabled>
                Velg ikon
              </option>
              {getListOfIcons().map((icon, i) => (
                <option value={icon} key={i}>
                  {icon}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {chosenIcon && (
            <div className="background-blue50 p-3 my-2">
              <h5>Forhåndsvisning av ikon valgt for denne modulen</h5>
              {reactIcon}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={onHide}>Avbryt</Button>
          <Button variant="primary" type="submit">Lagre</Button>
        </Modal.Footer>

      </Form>
    </Modal>
  )
}
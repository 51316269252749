import { useMsal } from '@azure/msal-react';
import { East } from '@mui/icons-material';
import { createResetPasswordRequest } from 'auth/authConfig';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { asDateTime } from 'common/helpers/dateHelpers';
import useGetOrganizationById from 'features/admin/organizations/hooks/useGetOrganizationById';
import { useEffect } from 'react';
import { Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useGetWorkspacesOnOrganizationId from '../../hooks/useGetWorkspacesOnUserId';
import useGetOrganizationModules from '../modules/hooks/useGetOrganizationModules';

export const Overview = () => {
  const { profileInfo } = useAuthenticatedUser();
  const [isLoading, , data] = useGetOrganizationById(profileInfo?.organization?.organizationId ?? undefined);

  const [, , workspaces, fetch] = useGetWorkspacesOnOrganizationId(profileInfo?.organization?.organizationId ?? '');
  const [, , modules] = useGetOrganizationModules(profileInfo?.organization?.organizationId ?? '');
  const { instance, accounts } = useMsal();
  const { t } = useTranslation();

  const handleResetPassword = () => {
    instance.loginRedirect(createResetPasswordRequest());
  };

  console.log(profileInfo);

  const navigate = useNavigate();

  return (
    <>
      <PageTitle title={'Kontoinstillinger'} />
      {data && profileInfo && (
        <>
          {(profileInfo.isOrganizationAdministrator || profileInfo.isSystemAdministrator) && (
            <>
              <h6>Min organisasjon</h6>
              <Card className="mt-2 mb-4">
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <small>Kundenavn</small>
                        <h6>{data.name}</h6>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <small>Organisasjonsnummer</small>
                        <h6>{data.organizationNumber}</h6>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <small>Type</small>
                        <h6>{data.organizationType}</h6>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <small>Abonnement</small>
                        <h6>Aktiv - {modules?.length} moduler</h6>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button variant="link" onClick={() => navigate('/account-settings/modules')}>
                          Se detaljer og moduler <East />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <small>Brukere</small>
                        <h6>{data.userCount} Bruker(e)</h6>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button variant="link" onClick={() => navigate('/account-settings/users/active')}>
                          Se og inviter brukere <East />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <small>Arbeidsområder</small>
                        <h6>{workspaces ? workspaces.length : 0} Arbeidsområder</h6>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button variant="link" onClick={() => navigate('/account-settings/workspaces')}>
                          Se alle arbeidsområder
                          <East />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <small>Tilleggsfunksjonalitet</small>
                        <h6>{data ? data.serviceTypes?.length : 0} Tilleggsfunksjonalitet(er)</h6>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button variant="link" onClick={() => navigate('/account-settings/service-types')}>
                          Se tilleggsfunksjonaliteter
                          <East />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </>
          )}
        </>
      )}
      {profileInfo && (
        <>
          <h6>Min bruker</h6>
          <Card className="mt-2 mb-4">
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Row>
                  <Col>
                    <small>Navn</small>
                    <h6>{profileInfo.displayName}</h6>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <small>Email</small>
                    <h6>{profileInfo.email}</h6>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <small>Rettighetsnivå</small>
                    {profileInfo.isOrganizationAdministrator && <h6>Organisasjonsadministrator</h6>}
                    {profileInfo.isSystemAdministrator && <h6>Systemadministrator</h6>}
                    {!profileInfo.isOrganizationAdministrator && !profileInfo.isSystemAdministrator && (
                      <h6>Organisasjonsbruker</h6>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <small>Dato konto ble opprettet</small>
                    <h6>{asDateTime(profileInfo.createdOn ?? '')}</h6>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>
                    <small>Passord</small>
                    <h6>Sist endret: {asDateTime(profileInfo.createdOn ?? '')}</h6>
                  </Col>

                  <Col className="d-flex justify-content-end align-items-center">
                    <Button variant="link" onClick={() => handleResetPassword()}>
                      Endre passord <East />
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </>
      )}
      <></>
    </>
  );
};

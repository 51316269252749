import { useMsal } from '@azure/msal-react';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { adminRouteGroups } from 'router/RouteConfig';
import { RouteItem } from 'router/RouteItem';

import styles from './sidebar.module.scss';

export const Sidebar = () => {
  const { accounts } = useMsal();
  const { t } = useTranslation('navigation');
  const { pathname } = useLocation();
  const { hasAccess } = useAuthenticatedUser();
  const isVisible = (routeItem: RouteItem) => !routeItem.hidden && hasAccess(routeItem.requiredPermission);

  return (
    <>
      <aside className="ps-2 bg-light h-100">
        <Nav className={`col-md-12 d-none d-md-block pb-4 pt-5 ps-md-1 ps-lg-4`}>
          <Nav.Item className="pb-3">
            <Nav.Link disabled>
              <h3 className="text-break">{t('adminSidebar.title')}</h3>
              <span>{accounts[0].name}</span>
            </Nav.Link>
          </Nav.Item>

          {adminRouteGroups &&
            adminRouteGroups.some((x) => x) &&
            adminRouteGroups
              .filter((gr) => gr.children.some((ri) => isVisible(ri)))
              .map((group, groupIndex) => (
                <Nav.Item key={`admin-nav-group-${groupIndex}`} className="pb-1">
                  <Nav.Link key={`admin-nav-group-header-${groupIndex}`} className="pb-0" disabled>
                    {/* <h4>{t('customers.title')}</h4> */}
                    {t(group.label) as string}
                  </Nav.Link>

                  {group.children
                    .filter((c) => isVisible(c))
                    .map((item, childIndex) => (
                      <Nav.Link
                        key={`admin-nav-item-${childIndex}`}
                        as={Link}
                        className={pathname === `/admin/${item.path}` ? styles.active : ''}
                        to={item.path}
                      >
                        {item.icon} {item.label && (t(item.label) as string)}
                      </Nav.Link>
                    ))}
                </Nav.Item>
              ))}
        </Nav>
      </aside>
    </>
  );
};

import { homeApi, modulesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { ModuleResponse, ObjectsOverviewResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetObjectsOverview(
  id: Guid,
): [boolean, boolean, ObjectsOverviewResponse | undefined, () => Promise<ObjectsOverviewResponse | undefined>] {
  const { isLoading, isFetching, isError, data, refetch, isIdle } = useQuery(
    [QueryKeys.Home, 'Workspace id: ' + id],
    () => homeApi.homeObjectsOverviewWorkspaceIdGet(id).then((x) => x.data),
    {
      enabled: !!id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetObjectsOverview;

import { ErrorMessage } from '@hookform/error-message';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ObjectPropertyDatatype, ObjectPropertyResponse } from 'common/api/multimap';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useRemoveObjectProperty from '../hooks/useRemoveObjectProperty';
import useUpdateObjectProperty from '../hooks/useUpdateObjectProperty';
import styles from '../object-properties.module.scss';

export interface IProps {
  show: boolean;
  onHide: () => void;
  objectProperty?: ObjectPropertyResponse;
  objectProperties?: ObjectPropertyResponse[];
}

type FieldValues = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

export const UpdateObjectPropertyModal: React.FC<IProps> = ({ show, onHide, objectProperty, objectProperties }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectProperties' });
  const [, , , , update] = useUpdateObjectProperty();
  const [, , , , removeObject] = useRemoveObjectProperty();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'options', // unique name for your Field Array
  });

  const [dataType, setDataType] = useState<string>('');

  const [edited, setEdited] = useState<boolean>(false);
  const [editConfirm, setEditConfirm] = useState<boolean>(false);

  const [radioNone, setRadioNone] = useState<boolean>(false);
  const [radioAverage, setRadioAverage] = useState<boolean>(false);
  const [radioTotal, setRadioTotal] = useState<boolean>(false);

  const [checkWeighted, setCheckWeighted] = useState<boolean>(false);

  const [isMinMax, setIsMinMax] = useState<boolean>(false);

  const updateProperty = async (d: FieldValues) => {
    if (objectProperty) {
      if (radioNone || !checkWeighted || radioTotal) {
        d.weightedAveragePropertyId = undefined;
      }

      if (dataType !== ObjectPropertyDatatype.Float && dataType !== ObjectPropertyDatatype.Integer) {
        d.minValue = undefined;
        d.maxValue = undefined;
        d.isAverage = false;
        d.isTotal = false;
        d.suffix = undefined;
      }
      update({ id: objectProperty.objectPropertyId, request: d });
      reset();
      onHide();
    }
  };

  const removeProperty = async () => {
    if (objectProperty) {
      removeObject({ id: objectProperty.objectPropertyId });
      reset();
      onHide();
    } else {
      console.log('Ville ikke slette');
    }
  };

  const resetRadios = (average: boolean, total: boolean) => {
    if (!average && !total) {
      setRadioNone(true);
      setRadioAverage(false);
      setRadioTotal(false);
    }

    if (average) {
      setRadioNone(false);
      setRadioAverage(true);
      setRadioTotal(false);
    }

    if (total) {
      setRadioNone(false);
      setRadioAverage(false);
      setRadioTotal(true);
    }
  };

  const resetMinMax = (max: number | null | undefined, min: number | null | undefined) => {
    if (max !== null || min !== null) {
      setIsMinMax(true);
    } else {
      setIsMinMax(false);
    }
  };

  const resetCheckWeighted = (check: boolean) => {
    setCheckWeighted(check);
  };

  useEffect(() => {
    if (objectProperty) {
      resetMinMax(objectProperty.maxValue, objectProperty.minValue);

      resetRadios(objectProperty.isAverage, objectProperty.isTotal);

      resetCheckWeighted(objectProperty.weightedAveragePropertyId !== null);

      reset({
        systemName: objectProperty.systemName,
        systemDescription: objectProperty.systemDescription,
        required: objectProperty.required,
        dataTypeId: objectProperty.datatypeId,
        minValue: objectProperty.minValue,
        maxValue: objectProperty.maxValue,
        isAverage: objectProperty.isAverage,
        isTotal: objectProperty.isTotal,
        weightedAveragePropertyId: objectProperty.weightedAveragePropertyId,
        suffix: objectProperty.suffix,
        options: objectProperty.options?.sort((a, b) => a.systemName.localeCompare(b.systemName)),
      });
      setEdited(false);
      setEditConfirm(false);
      setDataType(objectProperty.datatypeId);
    }
  }, [objectProperty, reset, onHide]);

  return (
    <>
      {objectProperty && (
        <Modal show={show} onHide={onHide} centered={true}>
          <Modal.Header closeButton>
            <Modal.Title>{t('modal.updateTitle')}</Modal.Title>
          </Modal.Header>

          <Form onSubmit={handleSubmit((d) => updateProperty(d))} onChange={() => setEdited(true)}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="usedBy">
                    <Form.Label>{t('modal.objectAmountProperty')}</Form.Label>
                    <Form.Control type="number" placeholder="Navn på egenskap" value={objectProperty.usedBy} readOnly />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="dataTypeId">
                    <Form.Label>{t('modal.labelDataType')}</Form.Label>
                    <Form.Select
                      {...register('dataTypeId')}
                      onChange={({ currentTarget }) => setDataType(currentTarget.value)}
                    >
                      <option value={ObjectPropertyDatatype.Integer}>{ObjectPropertyDatatype.Integer}</option>
                      <option value={ObjectPropertyDatatype.Float}>{ObjectPropertyDatatype.Float}</option>
                      <option value={ObjectPropertyDatatype.String}>{ObjectPropertyDatatype.String}</option>
                      <option value={ObjectPropertyDatatype.Address}>{ObjectPropertyDatatype.Address}</option>
                      <option value={ObjectPropertyDatatype.OptionSet}>{ObjectPropertyDatatype.OptionSet}</option>
                    </Form.Select>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="systemName">
                        <Form.Label>{t('modal.labelName')}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Navn på egenskap"
                          {...register('systemName', { required: 'Du må gi egenskapen din ett navn' })}
                          isInvalid={!!errors.systemName}
                        />
                        <Form.Text className="text-danger">
                          <ErrorMessage errors={errors} name="systemName" />
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    {(dataType == ObjectPropertyDatatype.Integer || dataType == ObjectPropertyDatatype.Float) && (
                      <Col md={4}>
                        <Form.Group className="mb-3" controlId="suffix">
                          <Form.Label>Suffiks</Form.Label>
                          <Form.Control type="text" placeholder="f.eks m2" {...register('suffix')} />
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                  <Form.Group className="mb-3" controlId="systemDescription">
                    <Form.Label>{t('modal.labelDescription')}</Form.Label>
                    <Form.Control
                      as={'textarea'}
                      type="text"
                      placeholder="Skriv beskrivelse her"
                      {...register('systemDescription')}
                    />
                  </Form.Group>
                  {dataType === ObjectPropertyDatatype.OptionSet && (
                    <Form.Group controlId="options">
                      <Form.Label>OptionSet</Form.Label>

                      <div
                        className="overflow-scroll mb-2 border-top border-bottom py-2"
                        style={{ minHeight: '100px', maxHeight: '200px' }}
                      >
                        {fields.map((field, index) => (
                          <div className="d-flex flex-row align-items-center mb-3" key={index}>
                            <Form.Control type="text" key={field.id} {...register(`options.${index}.systemName`)} />

                            <Button
                              variant="link"
                              size="sm"
                              onClick={() => {
                                remove(index);
                                setEdited(true);
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </Button>
                          </div>
                        ))}
                        <Button variant="secondary" size="sm" onClick={() => append({ systemName: '' })}>
                          <AddCircleOutlineIcon /> {t('modal.addOption')}
                        </Button>
                      </div>
                    </Form.Group>
                  )}
                  <Form.Check
                    type="checkbox"
                    label={t('modal.labelCheck')}
                    {...register('required')}
                    className="mb-3"
                  ></Form.Check>
                  {(dataType == ObjectPropertyDatatype.Integer || dataType == ObjectPropertyDatatype.Float) && (
                    <>
                      <Form.Check
                        type="checkbox"
                        label={'Denne egenskapen skal ha min og maks begrensninger'}
                        className="mb-2"
                        onChange={(e) => setIsMinMax(e.currentTarget.checked)}
                        checked={isMinMax}
                      ></Form.Check>
                      {isMinMax && (
                        <Row>
                          <Col>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                aria-describedby="addon-1"
                                placeholder="Verdi"
                                {...register('minValue')}
                              />
                              <InputGroup.Text id="addon-1">Min #</InputGroup.Text>
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                aria-describedby="addon-1"
                                placeholder="Verdi"
                                {...register('maxValue')}
                              />
                              <InputGroup.Text id="addon-2">Maks #</InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}
                      <hr></hr>
                      <Form.Label>Tilpassede beregninger</Form.Label>
                      <Form.Group className="mb-3">
                        <Form.Check
                          type="radio"
                          inline
                          label="Ingen"
                          checked={radioNone}
                          onChange={() => {
                            setValue('isAverage', false);
                            setValue('isTotal', false);
                            setRadioNone(true);
                            setRadioTotal(false);
                            setRadioAverage(false);
                          }}
                        ></Form.Check>
                        <Form.Check
                          type="radio"
                          inline
                          label="Snitt"
                          onChange={() => {
                            setValue('isAverage', true);
                            setValue('isTotal', false);
                            setRadioNone(false);
                            setRadioTotal(false);
                            setRadioAverage(true);
                          }}
                          checked={radioAverage}
                        ></Form.Check>
                        <Form.Check
                          type="radio"
                          inline
                          label="Total"
                          onChange={() => {
                            setValue('isAverage', false);
                            setValue('isTotal', true);
                            setRadioNone(false);
                            setRadioTotal(true);
                            setRadioAverage(false);
                          }}
                          checked={radioTotal}
                        ></Form.Check>
                      </Form.Group>
                      {radioAverage && (
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            label="Benytt som vekted for"
                            onChange={(e) => {
                              setCheckWeighted(e.currentTarget.checked);
                            }}
                            checked={checkWeighted}
                          />
                          {checkWeighted && (
                            <Form.Select {...register('weightedAveragePropertyId')} defaultValue={''}>
                              <option disabled value={''}>
                                Velg et objekt
                              </option>
                              {objectProperties &&
                                objectProperties
                                  .filter(
                                    (x) =>
                                      (x.datatypeId == ObjectPropertyDatatype.Float ||
                                        x.datatypeId == ObjectPropertyDatatype.Integer) &&
                                      x.objectPropertyId !== objectProperty.objectPropertyId,
                                  )
                                  .map((row, index) => (
                                    <option key={index} value={row.objectPropertyId}>
                                      {row.systemName}
                                    </option>
                                  ))}
                            </Form.Select>
                          )}
                        </Form.Group>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className={edited && !editConfirm ? styles.modalFooter : ''}>
              {edited && !editConfirm ? (
                <Row>
                  <Col className="col-md-2 d-flex justify-content-center">
                    <ErrorOutlineIcon className="size-l" />
                  </Col>
                  <Col className="col-md-10">
                    <h5>{t('modal.alert')}</h5>
                    <p>{t('modal.alertText')}</p>
                    <div className="w-100 d-flex justify-content-end">
                      <Button variant="secondary" size="sm" className="my-3 me-2" onClick={() => setEditConfirm(true)}>
                        {t('modal.alertButtonConfirm')}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        size="sm"
                        className="my-3"
                        onClick={() => {
                          setEdited(false);
                          resetRadios(objectProperty.isAverage, objectProperty.isTotal);
                          resetMinMax(objectProperty.maxValue, objectProperty.minValue);
                          setCheckWeighted(false);
                          reset();
                        }}
                      >
                        {t('modal.alertButtonCancel')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <div className="w-100 d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    size="sm"
                    className="my-3 me-2"
                    onClick={removeProperty}
                    disabled={!editConfirm}
                  >
                    {t('modal.buttonDelete')}
                  </Button>
                  <Button type="submit" variant="primary" size="sm" className="my-3" disabled={!editConfirm}>
                    {t('modal.buttonUpdate')}
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
};

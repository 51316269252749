import { FileDownload, Print, SearchOutlined } from '@mui/icons-material';
import { reportsApi } from 'common/api/MultimapClients';
import { MappingDataReportResponse } from 'common/api/multimap';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { downloadFile } from 'common/helpers/http-header.helper';
import { notify } from 'common/helpers/toast-notification-helper';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetClosedMappingPeriods from 'features/user/mapping/hooks/useGetClosedMappingPeriods';
import useGetWorkspaceModule from 'features/user/mapping/hooks/useGetWorkspaceModule';
import useGetMappingDataReport from 'features/user/report/hooks/useGetMappingDataReport';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Stack, Table } from 'react-bootstrap';
import { useParams } from 'react-router';

export const MappingDataReport = () => {
  const { workspaceModuleId } = useParams();

  const [isLoading, , data] = useGetWorkspaceModule(workspaceModuleId ? workspaceModuleId : '');

  const [periodLoading, , closedPeriods] = useGetClosedMappingPeriods(workspaceModuleId ? workspaceModuleId : '');

  const [reportPeriodId, setReportPeriodId] = useState<string>(closedPeriods ? closedPeriods[0].mappingPeriodId : '');

  const [reportLoading, , reportData, fetchReport] = useGetMappingDataReport(reportPeriodId);

  const [reportedData, setReportedData] = useState(reportData);

  const [isDownloadingExport, setIsDownloadingExport] = useState(false);

  const [filter, setFilter] = useState<string>('');

  const generateReport = () => {
    if (reportPeriodId) {
      fetchReport();
    }
  };

  const downloadMappingDataExport = async (periodId: string) => {
    try {
      setIsDownloadingExport(true);

      const response = await reportsApi.reportsPeriodIdPeriodMappingDataExportGet(periodId, {
        responseType: 'blob',
      });
      downloadFile(response);

      notify('success', 'Eksport fil lagret');
    } finally {
      setIsDownloadingExport(false);
    }
  };

  useEffect(() => {
    if (reportData) {
      setReportedData(reportData);
    }
  }, [reportData]);

  useEffect(() => {
    if (closedPeriods) {
      setReportPeriodId(closedPeriods[0].mappingPeriodId);
    }
  }, [closedPeriods]);

  console.log(reportedData);
  return (
    <>
      {data && (
        <>
          <PageTitle title={`Kartlagt rådata for ${data.systemName}`} backTo="/report" />
          <Row className="my-3">
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Periode</Form.Label>
                <Form.Select onChange={(e) => setReportPeriodId(e.currentTarget.value)}>
                  {closedPeriods &&
                    closedPeriods.map((row, i) => {
                      return (
                        <option key={i} value={row.mappingPeriodId}>
                          {row.mappingPeriodName}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-end justify-content-end">
              <Button variant="tertiary" onClick={generateReport}>
                Generer rapport
              </Button>
            </Col>
          </Row>
          <LoadingSpinner isLoading={reportLoading} />
          <>
            {reportedData && !reportLoading ? (
              <TableContainer>
                <Stack direction="horizontal" gap={3} className="table-filter">
                  <InputGroup>
                    <InputGroup.Text>
                      <SearchOutlined fontSize="small" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      id="filter"
                      placeholder="Søk"
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </InputGroup>
                  <Button variant="tertiary" className="text-nowrap">
                    Skriv ut <Print fontSize="small" />
                  </Button>
                  <Button
                    variant="tertiary"
                    className="text-nowrap"
                    onClick={() => {
                      downloadMappingDataExport(reportedData.periodId);
                    }}
                    disabled={isDownloadingExport}
                  >
                    Last ned <FileDownload />
                  </Button>
                </Stack>
                <Row>
                  <Col></Col>
                </Row>
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span className="text-truncate">Objekt</span>
                      </th>
                      {reportedData.columnTitles &&
                        reportedData.columnTitles.map((title, index) => {
                          return (
                            <th scope="col" key={index}>
                              <div className="d-flex flex-column">
                                <small className="smaller text-truncate">{title.subTitle}</small>{' '}
                                <span className="text-truncate" style={{ width: '150px' }}>
                                  {title.mainTitle}
                                </span>
                              </div>
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {reportedData.reportObjectRows &&
                      reportedData.reportObjectRows
                        .filter((x) => x.objectName.toLowerCase().includes(filter.toLowerCase()))
                        .map((row, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <>
                                  <td>
                                    <Stack direction="vertical">
                                      <small className="text-truncate">{row.objectName}</small>
                                      <small className="smaller">{row.parentObjectName}</small>
                                    </Stack>
                                  </td>
                                  {row.parameterValues &&
                                    row.parameterValues.map((value, valueIndex) => {
                                      return <td key={valueIndex}>{value.value == 99 ? 'N/A' : value.value}</td>;
                                    })}
                                </>
                              </tr>
                              {row.children &&
                                row.children.map((childRow, childIndex) => {
                                  return (
                                    <tr key={childIndex} className="background-blue50">
                                      <>
                                        <td>
                                          <Stack direction="vertical">
                                            <small className="text-truncate">{childRow.objectName}</small>
                                            <small className="smaller">{childRow.parentObjectName}</small>
                                          </Stack>
                                        </td>
                                        {childRow.parameterValues &&
                                          childRow.parameterValues.map((value, valueIndex) => {
                                            return <td key={valueIndex}>{value.value == 99 ? 'N/A' : value.value}</td>;
                                          })}
                                      </>
                                    </tr>
                                  );
                                })}
                            </>
                          );
                        })}
                  </tbody>
                </Table>
              </TableContainer>
            ) : (
              <>
                {reportLoading ? (
                  <Row className="g-0 p-3 mb-3">
                    <Col className="d-flex justify-content-center align-items-center">
                      <h4>Genererer rapport, vennligst vent...</h4>
                    </Col>
                  </Row>
                ) : (
                  <Row className="g-0 rounded p-3 mb-3" style={{ border: '1px dashed #ABBED1' }}>
                    <Col>
                      <h4>Generer rapport</h4>
                      <span>
                        Ingen rapporter er generert. Vennligst definer feltene ovenfor og deretter trykk generer rapport
                        for å få et resultat. Bare en rapport kan vises om gangen.
                      </span>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </>
        </>
      )}
    </>
  );
};

import AccessibleIcon from '@mui/icons-material/Accessible';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import FlagIcon from '@mui/icons-material/Flag';
import GrassIcon from '@mui/icons-material/Grass';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

export const getListOfIcons = () => {
  return [
    'AccessibleIcon',
    'AssignmentTurnedInIcon',
    'CenterFocusStrongIcon',
    'FlagIcon',
    'GrassIcon',
    'LightbulbIcon',
    'LocalFireDepartmentIcon',
  ];
};

export const displayIcon = (icon: string) => {
  switch (icon) {
    case 'AccessibleIcon':
      return <AccessibleIcon fontSize="large" htmlColor="white" />;

    case 'AssignmentTurnedInIcon':
      return <AssignmentTurnedInIcon fontSize="large" htmlColor="white" />;

    case 'CenterFocusStrongIcon':
      return <CenterFocusStrongIcon fontSize="large" htmlColor="white" />;

    case 'FlagIcon':
      return <FlagIcon fontSize="large" htmlColor="white" />;

    case 'GrassIcon':
      return <GrassIcon fontSize="large" htmlColor="white" />;

    case 'LightbulbIcon':
      return <LightbulbIcon fontSize="large" htmlColor="white" />;

    case 'LocalFireDepartmentIcon':
      return <LocalFireDepartmentIcon fontSize="large" htmlColor="white" />;

    default:
      return <AssignmentTurnedInIcon fontSize="large" htmlColor="white" />;
  }
};

import { mappingSetsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { LearningResponse, MappingSetDetailResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetLearning(
  id: Guid | undefined,
): [boolean, boolean, LearningResponse | undefined, () => Promise<LearningResponse | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.Learning, id],
    () => mappingSetsApi.mappingSetsMappingSetIdLearningGet(id ?? '').then((x) => x.data),
    {
      enabled: !!id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetLearning;

import { ErrorMessage } from '@hookform/error-message';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ObjectPropertyDatatype, ObjectPropertyResponse } from 'common/api/multimap';
import { useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAddObjectProperty from '../hooks/useAddObjectProperty';

export interface IProps {
  show: boolean;
  onHide: () => void;
  objectProperties?: ObjectPropertyResponse[];
}

type FieldValues = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

export const AddPropertyModal: React.FC<IProps> = ({ show, onHide, objectProperties }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectProperties' });

  const [, , , , add] = useAddObjectProperty();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'options', // unique name for your Field Array
  });

  const [dataType, setDataType] = useState<string>('');

  const [radioNone, setRadioNone] = useState<boolean>(false);
  const [radioAverage, setRadioAverage] = useState<boolean>(false);
  const [radioTotal, setRadioTotal] = useState<boolean>(false);

  const [checkWeighted, setCheckWeighted] = useState<boolean>(false);

  const [isMinMax, setIsMinMax] = useState<boolean>(false);

  const addProperty = async (d: FieldValues) => {
    if (radioNone || !checkWeighted || radioTotal) {
      d.weightedAveragePropertyId = undefined;
    }

    if (d.DataTypeId !== ObjectPropertyDatatype.Float || d.DataTypeId !== ObjectPropertyDatatype.Integer) {
      d.minValue = undefined;
      d.maxValue = undefined;
      d.isAverage = false;
      d.isTotal = false;
      d.suffix = undefined;
    }

    add(d);
    reset();
    setDataType(ObjectPropertyDatatype.String);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <Modal.Header closeButton>
        <h4>{t('actionCreate')}</h4>
      </Modal.Header>

      <Form onSubmit={handleSubmit((d) => addProperty(d))}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="objectPropertyType">
                <Form.Label>{t('modal.labelDataType')}</Form.Label>
                <Form.Select
                  {...register('dataTypeId')}
                  onChange={({ currentTarget }) => setDataType(currentTarget.value)}
                >
                  <option value={ObjectPropertyDatatype.String}>{ObjectPropertyDatatype.String}</option>
                  <option value={ObjectPropertyDatatype.Integer}>{ObjectPropertyDatatype.Integer}</option>
                  <option value={ObjectPropertyDatatype.Float}>{ObjectPropertyDatatype.Float}</option>
                  <option value={ObjectPropertyDatatype.Address}>{ObjectPropertyDatatype.Address}</option>
                  <option value={ObjectPropertyDatatype.OptionSet}>{ObjectPropertyDatatype.OptionSet}</option>
                </Form.Select>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="systemName">
                    <Form.Label>* {t('modal.labelName')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="f.eks Bygg Id"
                      {...register('systemName', { required: 'Du må gi egenskapen din ett navn' })}
                      isInvalid={!!errors.systemName}
                    />
                    <Form.Text className="text-danger">
                      <ErrorMessage errors={errors} name="systemName" />
                    </Form.Text>
                  </Form.Group>
                </Col>
                {(dataType == ObjectPropertyDatatype.Integer || dataType == ObjectPropertyDatatype.Float) && (
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="suffix">
                      <Form.Label>Suffiks</Form.Label>
                      <Form.Control type="text" placeholder="f.eks m2" {...register('suffix')} />
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Form.Group className="mb-3" controlId="systemDescription">
                <Form.Label>{t('modal.labelDescription')}</Form.Label>
                <Form.Control
                  as={'textarea'}
                  type="text"
                  {...register('systemDescription')}
                  placeholder="Skriv en beskrivelse på egenskapen her..."
                />
              </Form.Group>
              {dataType === ObjectPropertyDatatype.OptionSet && (
                <Form.Group controlId="options">
                  <Form.Label>OptionSet</Form.Label>

                  <div
                    className="overflow-scroll mb-2 border-top border-bottom py-2"
                    style={{ minHeight: '100px', maxHeight: '300px' }}
                  >
                    {fields.map((field, index) => (
                      <div className="d-flex flex-row align-items-center mb-3">
                        <Form.Control type="text" key={field.id} {...register(`options.${index}.systemName`)} />

                        <Button variant="link" size="sm" onClick={() => remove(index)}>
                          <RemoveCircleOutlineIcon />
                        </Button>
                      </div>
                    ))}
                    <Button variant="secondary" size="sm" onClick={() => append({ systemName: '' })}>
                      <AddCircleOutlineIcon /> {t('modal.addOption')}
                    </Button>
                  </div>
                </Form.Group>
              )}
              <Form.Check
                type="checkbox"
                label={t('modal.labelCheck')}
                {...register('required')}
                className="mb-3"
              ></Form.Check>
              {(dataType == ObjectPropertyDatatype.Integer || dataType == ObjectPropertyDatatype.Float) && (
                <>
                  <Form.Check
                    type="checkbox"
                    label={'Denne egenskapen skal ha min og maks begrensninger'}
                    className="mb-2"
                    onChange={(e) => setIsMinMax(e.currentTarget.checked)}
                  ></Form.Check>
                  {isMinMax && (
                    <Row>
                      <Col>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            aria-describedby="addon-1"
                            placeholder="Verdi"
                            {...register('minValue')}
                          />
                          <InputGroup.Text id="addon-1">Min #</InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            aria-describedby="addon-1"
                            placeholder="Verdi"
                            {...register('maxValue')}
                          />
                          <InputGroup.Text id="addon-2">Maks #</InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Row>
                  )}
                  <hr></hr>
                  <Form.Label>Tilpassede beregninger</Form.Label>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="radio"
                      inline
                      label="Ingen"
                      checked={radioNone}
                      onChange={() => {
                        setValue('isAverage', false);
                        setValue('isTotal', false);
                        setRadioNone(true);
                        setRadioTotal(false);
                        setRadioAverage(false);
                      }}
                    ></Form.Check>
                    <Form.Check
                      type="radio"
                      inline
                      label="Snitt"
                      onChange={() => {
                        setValue('isAverage', true);
                        setValue('isTotal', false);
                        setRadioNone(false);
                        setRadioTotal(false);
                        setRadioAverage(true);
                      }}
                      checked={radioAverage}
                    ></Form.Check>
                    <Form.Check
                      type="radio"
                      inline
                      label="Total"
                      onChange={() => {
                        setValue('isAverage', false);
                        setValue('isTotal', true);
                        setRadioNone(false);
                        setRadioTotal(true);
                        setRadioAverage(false);
                      }}
                      checked={radioTotal}
                    ></Form.Check>
                  </Form.Group>
                  {radioAverage && (
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        label="Benytt som vekted for"
                        onChange={(e) => {
                          setCheckWeighted(e.currentTarget.checked);
                        }}
                        checked={checkWeighted}
                      />
                      {checkWeighted && (
                        <Form.Select {...register('weightedAveragePropertyId')} defaultValue={''}>
                          <option disabled value={''}>
                            Velg et objekt
                          </option>
                          {objectProperties &&
                            objectProperties
                              .filter(
                                (x) =>
                                  x.datatypeId == ObjectPropertyDatatype.Float ||
                                  x.datatypeId == ObjectPropertyDatatype.Integer,
                              )
                              .map((row, index) => (
                                <option key={index} value={row.objectPropertyId}>
                                  {row.systemName}
                                </option>
                              ))}
                        </Form.Select>
                      )}
                    </Form.Group>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 d-flex justify-content-end">
            <Button type="submit" variant="primary" className="my-3 btn-sm" data-dismiss="modal">
              {t('modal.buttonAdd')}
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

/*
 * To drill-down @mui SvgIcon props:
 * <BoolIcon value={true} svgProps={{ fontSize: "small" }} ></BoolIcon>
*/

type IProps = {
  value: boolean;
  svgProps?: React.ComponentPropsWithRef<typeof SvgIcon>;
};

export const BoolIcon: React.FC<IProps> = ({ value, svgProps }) => {
  return value ? (
    <CheckBoxIcon {...svgProps}></CheckBoxIcon>
  ) : (
    <CheckBoxOutlineBlankIcon {...svgProps}></CheckBoxOutlineBlankIcon>
  );
};

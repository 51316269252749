import { AxiosError } from 'axios';
import { reportsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { ReportResponse, CreateReportRequest, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

function useAddReport(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  ReportResponse | undefined,
  (createReportRequest: CreateReportRequest) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    (reportRequest: CreateReportRequest) => {
      return reportsApi.reportsSpecialReportsPost(reportRequest).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.SpecialReports);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const add = (request: CreateReportRequest) => mutate(request);

  return [isLoading, isError, validationError, data, add];
}

export default useAddReport;

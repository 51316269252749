import { ValidationProblemDetails } from 'common/api/multimap';
import React from 'react';

interface IProp {
  errors: ValidationProblemDetails;
}

export const ValidationErrors: React.FC<IProp> = ({ errors }) => {
  if (!errors) return <></>;

  const genericErrors = errors.genericErrors;
  const validationErrors = errors.errors
    ? Object.keys(errors.errors).map((e) => {
      return {
        field: e,
        messages: errors.errors![e],
      };
    })
    : [];

  return (
    <>
      {genericErrors
        ?.filter((x) => x)
        .map((error, index) => (
          <p key={index} className="text-danger">
            {error}
          </p>
        ))}
      {validationErrors
        ?.filter((x) => x.field && x.messages.length > 0)
        .map((error, index) => (
          <p key={index} className="text-danger">
            {error.field}:{' '}
            {error.messages.map((msg) => (
              <span>{msg}</span>
            ))}
          </p>
        ))}
    </>
  );
};

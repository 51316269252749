import { organizationsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { OrganizationResponse } from 'common/api/multimap/api';
import { useQuery } from 'react-query';

function useGetOrganizations(): [
  boolean,
  boolean,
  OrganizationResponse[] | undefined,
  () => Promise<OrganizationResponse[] | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.Organizations],
    () => organizationsApi.getAllOrganizations().then((x) => x.data),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetOrganizations;

import { useMsal } from '@azure/msal-react';
import { Input } from 'common/components/form/input/Input';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IProps {
  onConfirmationChange: (isResponsible: boolean | undefined) => void;
}

export const ResponsiblePersonDialogue: React.FC<IProps> = ({ onConfirmationChange }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'features.user.onboarding.components.responsiblePersonDialogue',
  });
  const { accounts } = useMsal();

  const [isResponsiblePerson, setIsResonsiblePerson] = useState<boolean>(false);

  useEffect(() => {
    onConfirmationChange(isResponsiblePerson);
  }, [isResponsiblePerson, onConfirmationChange]);

  return (
    <Container fluid>
      <Row className="d-flex justify-content-center">
        <Col sm={12} md={7} lg={5} xl={4} xxl={3}>
          <Card className={`p-4`}>
            <h3>{t('title')}</h3>
            <p>{t('contentInstruction')}</p>
            <Form id="repsonsiblePersonConsent">
              <Input name="name" labelText={t('form.name')} disabled defaultValue={accounts[0].name} />
              <Input
                name="email"
                labelText={t('form.email')}
                disabled
                defaultValue={(accounts[0]?.idTokenClaims?.email || '') + ''}
              />
              <Form.Check
                className="mt-4"
                type="checkbox"
                id="repsonsiblePersonConsent"
                name="responsiblePersonConsent"
                key="responsiblePersonConsent"
                form="repsonsiblePersonConsent"
                label="Jeg bekrefter at jeg har tilstrekkelige rettigheter i organisasjonen min for å etablere et nytt kundeforhold hos 
                Multiconsult, og jeg tar ansvar for å forvalte brukerkontoer og tilgangene som min organisasjon har i multiMap."
                checked={isResponsiblePerson}
                onChange={(e) => setIsResonsiblePerson(e.target.checked)}
              />
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { workspacesApi } from 'common/api/MultimapClients';
import { ObjectPropertyResponse } from 'common/api/multimap';
import { TileButton } from 'common/components/TileButton/TileButton';
import { downloadFile } from 'common/helpers/http-header.helper';
import { notify } from 'common/helpers/toast-notification-helper';
import { Guid } from 'common/types/guid.type';
import React, { useState } from 'react';
import { Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ImportModal } from './ImportModal';

type WizardStep = 'menu' | 'import';

export interface IProps {
  show: boolean;
  workspaceId: Guid;
  properties: ObjectPropertyResponse[];
  onCompleted?: (refreshRequired: boolean) => void;
  onCancel: () => void;
}

export const ImportExportWizardModal: React.FC<IProps> = ({ show, workspaceId, properties, onCancel, onCompleted }) => {
  const { t: ts } = useTranslation('shared');
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.importExportWizardModal' });

  const [step, setStep] = useState<WizardStep>('menu');
  const [isDownloadingExport, setIsDownloadingExport] = useState(false);
  // const [isImporting, setIsImporting] = useState(false);

  const onCancelHandler = () => {
    onCancel();
  };

  // const downloadExportV1Handler = async () => {
  //   try {
  //     setIsDownloadingExport(true);
  //     const response = await workspacesApi.workspacesWorkspaceIdExportV1Get(workspaceId, {
  //       responseType: 'blob',
  //     });
  //     console.log(response);
  //     downloadFile(response);
  //   } finally {
  //     setIsDownloadingExport(false);
  //   }
  // };

  const downloadExportV2Handler = async () => {
    try {
      setIsDownloadingExport(true);
      const response = await workspacesApi.workspacesWorkspaceIdExportV2Get(workspaceId, {
        responseType: 'blob',
      });
      downloadFile(response);

      notify('success', 'Eksport fil lagret');
    } finally {
      setIsDownloadingExport(false);
    }
  };

  const importCompletedHandler = async () => {
    if (onCompleted) {
      onCompleted(true);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onCancelHandler}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="xl"
        contentClassName="fit-screen"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('title')}</Modal.Title>
        </Modal.Header>

        <Container fluid className="p-3">
          {/* Den knappen kan brukes for å laste ned en fil som er veldig lik til Multimap V1 og kan brukes for å validare dataene (import fra backup f.eks) */}
          {/* <Row className='mb-3'>
            <Col>
              <TileButton
                title="Eksporter portefølje objektene (v1)"
                text="text"
                onClick={downloadExportV1Handler}
                leftIcon={
                  <>
                    {!isDownloadingExport && <DownloadOutlinedIcon />}
                    {isDownloadingExport && (
                      <Spinner
                        className="mx-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </>
                }
              />
            </Col>
          </Row> */}

          {step === 'menu' && (
            <>
              <Row className="mb-3">
                <Col>
                  <TileButton
                    title="Eksporter porteføljeobjektene"
                    text="Du kan eksportere dine data i et Excel-ark."
                    onClick={downloadExportV2Handler}
                    leftIcon={
                      <>
                        {!isDownloadingExport && <DownloadOutlinedIcon />}
                        {isDownloadingExport && (
                          <Spinner
                            className="mx-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </>
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <TileButton
                    title="Importer nye porteføljeobjekter"
                    text="Har du data lagret i et Excel-ark, kan du laste det opp og importere det inn i Multimap."
                    onClick={() => setStep('import')}
                    leftIcon={<UploadOutlinedIcon />}
                    rightIcon={<ArrowForwardIcon />}
                  />
                </Col>
              </Row>
            </>
          )}
          {step === 'import' && (
            <ImportModal workspaceId={workspaceId} properties={properties} onCompleted={importCompletedHandler} />
          )}
        </Container>
      </Modal>
    </>
  );
};

import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { Button, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import useGetActiveMappingPeriod from '../../hooks/useGetActiveMappingPeriod';

export interface IProps {
  workspaceModuleId: string;
}
export const GoToActivePeriodButton: React.FC<IProps> = ({ workspaceModuleId }) => {
  const [loading, , data] = useGetActiveMappingPeriod(workspaceModuleId);

  const navigate = useNavigate();

  return (
    <>
      <LoadingSpinner isLoading={loading} />
      {data && (
        <Button
          variant="tertiary"
          onClick={() => navigate(`/mapping/${data.workspaceModuleId}/periods/${data.mappingPeriodId}/objects`)}
          className="me-2"
        >
          Gå til aktiv kartleggingsperiode
        </Button>
      )}
    </>
  );
};

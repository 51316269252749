import { ValidationProblemDetails } from 'common/api/multimap';
import FormInput from 'common/form/form-controls/FormInput';
import FormSelect from 'common/form/form-controls/FormSelect';
import React, { useEffect, useState } from 'react';
// TODO: Find a workaround for failing export check
// eslint-disable-next-line import/named
import { UseFormReturn, useForm } from 'react-hook-form';

export type FormProps<FormValues> = {
  onSubmit: (model: FormValues) => Promise<void>;
  errorDetails?: ValidationProblemDetails;
  model?: FormValues;
};

export type Form<T> = {
  methods: UseFormReturn<T, unknown>;
  formProps: FormProps<T>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (
    callback?: ((model: T) => void) | undefined,
  ) => (e?: React.BaseSyntheticEvent<object, unknown, unknown> | undefined) => Promise<void>;
};

export type IForm<T> = {
  form: Form<T>;
};

/** Helper function used to find all errors with a key that doesn't correspond to any of the model properties */
const extractNonMatchedErrors = (
  model: Record<string, unknown>,
  fieldErrors: { [key: string]: string[] } | null | undefined,
): string[] => {
  const nonMatchedKeys = fieldErrors
    ? Object.keys(fieldErrors).filter(
      (x) =>
        !Object.keys(model)
          .map((y) => y.toLowerCase())
          .includes(x.toLowerCase()),
    )
    : [];
  return (fieldErrors ? nonMatchedKeys.map((x) => fieldErrors[x]) : []).reduce((x, y) => [...x, ...y], []);
};

function FormContainer<T>(props: React.PropsWithChildren<IForm<T>>) {
  const { form, children } = props;

  return (
    <form
      id="hook"
      autoComplete="off"
      onSubmit={form.methods.handleSubmit(async (e: T) => {
        form.setIsLoading(true);
        try {
          await form.formProps.onSubmit(e);
        } catch (submitError) {
          console.log(submitError);
        }

        form.setIsLoading(false);
      })}
    >
      { }
      {/* Prevent implicit submission of the form */}
      <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true" />
      {children}
    </form>
  );
}

type FormErrorsProps<T> = {
  form: Form<T>;
};

function FormErrors<T>({ form }: FormErrorsProps<T>) {
  const { errorDetails, model } = form.formProps;

  const genericErrors = errorDetails?.genericErrors;
  const formModel = model as Record<string, unknown>;
  const validationErrors = errorDetails?.errors;

  const nonMatchedErrors = extractNonMatchedErrors(formModel, validationErrors);

  return (
    <>
      {genericErrors
        ?.filter((x) => x)
        .map((error, index) => (
          <p key={index} className="text-danger">
            {error}
          </p>
        ))}
      {nonMatchedErrors
        ?.filter((x) => x)
        .map((error, index) => (
          <p key={index} className="text-danger">
            {error}
          </p>
        ))}
    </>
  );
}

export function useGenericForm<T>(formProps: FormProps<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<T>();

  useEffect(() => {
    if (methods) {
      methods.reset(formProps.model);
    }
  }, [formProps.model, methods]);

  const onSubmit = (callback?: (model: T) => void) => {
    return methods.handleSubmit(async (e) => {
      if (callback) {
        callback(e);
      }
      setIsLoading(true);
      try {
        await formProps.onSubmit(e);
      } catch (onSubmitError) {
        console.log(onSubmitError);
      }
      setIsLoading(false);
    });
  };

  const form: Form<T> = {
    methods,
    formProps,
    isLoading,
    setIsLoading,
    onSubmit,
  };

  return {
    form,
    FormContainer,
    FormInput,
    FormSelect,
    FormErrors,
    isLoading,
  };
}

import { useMsal } from '@azure/msal-react';
import { AddCircleOutline, AppRegistration, GroupOutlined, SearchOutlined } from '@mui/icons-material';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { CreateWizard } from 'features/admin/workspaces/create/CreateWizard';
import useGetWorkspacesOnOrganizationId from 'features/user/account-settings/hooks/useGetWorkspacesOnUserId';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Stack, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { RegisterWorkspaceModal } from './components/RegisterWorkspaceModal';

export const OrganizationWorkspaces = () => {
  const { organizationId } = useParams();

  const [show, setShow] = useState<boolean>(false);
  const [registerShow, setRegisterShow] = useState<boolean>(false);

  const [, , workspaces, fetch] = useGetWorkspacesOnOrganizationId(organizationId ?? '');

  return (
    <>
      <PageTitle title={'Arbeidsområder'} backTo={`/admin/organizations/${organizationId}`} />
      <TableContainer>
        <Stack direction="horizontal" className="table-filter" gap={3}>
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control type="text" id="filter" placeholder={'Søk'} aria-describedby={'Søk'} />
          </InputGroup>
          <Button variant="tertiary" className="text-nowrap" onClick={() => setRegisterShow(true)}>
            <AppRegistration /> Registrer arbeidsområde
          </Button>
          <Button variant="tertiary" className="text-nowrap" onClick={() => setShow(true)}>
            <AddCircleOutline /> Opprett nytt arbeidsområde
          </Button>
        </Stack>

        {workspaces && workspaces.length > 0 ? (
          <Table hover responsive>
            <thead>
              <tr>
                <th scope="col">Navn</th>
                <th scope="col">Type</th>
                <th scope="col">Registrere objekter</th>
                <th scope="col">Arbeidsområde eier</th>
              </tr>
            </thead>
            <tbody>
              {workspaces &&
                workspaces.map((row, i) => (
                  <tr>
                    <td>{row.workspaceName}</td>
                    <td>{row.objectTypeName}</td>
                    <td>{row.objectsAmount}</td>
                    <td><GroupOutlined /> {row.ownedBy}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <Row className="table-filter">
            <Col style={{ border: '1px dashed #ABBED1' }}>
              <p>
                Du har ingen arbeidsområde enda. Trykk på "Opprett nytt arbeidsområde" knappen for å opprette
                arbeidsområde
              </p>
            </Col>
          </Row>
        )}
      </TableContainer>
      <RegisterWorkspaceModal
        show={registerShow}
        onHide={() => setRegisterShow(false)}
        organizationId={organizationId ?? ''}
      />
      <CreateWizard admin={false} show={show} onHide={() => setShow(false)} organizationId={organizationId ?? ''} />
    </>
  );
};

import { maintenancePlansApi, mappingSetsApi, objectMappingsApi, reportsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  MaintenancePlanResponse,
  MappingDataReportResponse,
  MappingObjectResponse,
  MappingPeriodResponse,
  MappingSetResponse,
  WorkspaceModuleResponse,
} from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetMaintenancePlan(
  id: Guid,
): [boolean, boolean, MaintenancePlanResponse | undefined, () => Promise<MaintenancePlanResponse | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.MaintenancePlans, 'maintenance-plan: ' + id],
    () => maintenancePlansApi.maintenancePlansMaintenancePlanIdGet(id).then((x) => x.data),
    {
      enabled: !!id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetMaintenancePlan;

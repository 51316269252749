import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { MappingSetResponse } from 'common/api/multimap';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { asDateTime } from 'common/helpers/dateHelpers';
import { useState } from 'react';
import { Button, Form, InputGroup, Stack, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LoadingSpinner } from '../components/loading-spinner/LoadingSpinner';
import useGetAllMappingSets from './hooks/useGetAllMappingSets';

export const MappingSets = () => {
  const { t } = useTranslation('admin', { keyPrefix: 'mappingSets' });

  const [isLoading, , data] = useGetAllMappingSets();

  const [filter, setFilter] = useState<string>('');

  const navigate = useNavigate();

  const sortTableByDate = (a: MappingSetResponse, b: MappingSetResponse) => {
    if (a.createdOn == undefined) {
      return 1;
    }

    if (b.createdOn == undefined) {
      return -1;
    }
    const a1 = new Date(a.createdOn).getTime();
    const b1 = new Date(b.createdOn).getTime();

    return b1 - a1;
  };

  return (
    <>
      <PageTitle title={t('title')}>
        <Button variant="tertiary" size="sm" onClick={() => navigate(`/admin/mapping-sets/detail`)}>
          <AddCircleOutlineOutlinedIcon /> {t('addMappingSetButton')}
        </Button>
      </PageTitle>

      <TableContainer>
        <Stack direction="horizontal" className="table-filter">
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlinedIcon fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder={t('searchForMappingSet')}
              aria-describedby={t('searchForMappingSet')}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </Stack>

        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">{t('mappingSetName')}</th>
              <th scope="col">{t('mappingSetCreatedDate')}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .filter((x) => x.systemName.toLowerCase().includes(filter.toLowerCase()))
                .sort((a, b) => {
                  return sortTableByDate(a, b);
                })
                .map((row, i) => {
                  // const orgnr = organizationNumberFormatter(row.orgnr);
                  return (
                    <tr key={i}>
                      <td>
                        <span>{row.systemName}</span>
                      </td>
                      <td>
                        <span>{(row.createdOn && asDateTime(row.createdOn)) || ''}</span>
                      </td>
                      <td>
                        <div className="d-flex gap-3 flex-wrap justify-content-end align-items-center">
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => navigate(`/admin/mapping-sets/${row.mappingSetId}/detail`)}
                            className="p-0"
                          >
                            <MoreVertIcon />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </TableContainer>

      <LoadingSpinner isLoading={isLoading} />
    </>
  );
};

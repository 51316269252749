import { SearchOutlined } from '@mui/icons-material';
import { CreateOrganizationModuleRequest, ModuleResponse } from 'common/api/multimap';
import { Pill } from 'common/components/Pill/Pill';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { asDateTime } from 'common/helpers/dateHelpers';
import useGetAllModules from 'features/admin/modules/hooks/useGetAllModules';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Modal, Row, Stack, Table } from 'react-bootstrap';

import useAddOrganizationModules from './hooks/useCreateOrganizationModule';
import useGetOrganizationModules from './hooks/useGetOrganizationModules';

export const Modules = () => {
  const { profileInfo } = useAuthenticatedUser();

  const [, , data, fetch] = useGetOrganizationModules(profileInfo?.organization?.organizationId ?? undefined);

  return (
    <>
      {data && (
        <>
          <PageTitle title="Abonnement" backTo="/account-settings"></PageTitle>
          <h4 className="mt-2">Oversikt</h4>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <Stack direction="vertical">
                    <small className="bold">Status</small>
                    <h5>Aktiv</h5>
                  </Stack>
                </Col>
                <Col>
                  <Stack direction="vertical">
                    <small className="bold">Moduler</small>
                    <h5>{data.length}</h5>
                  </Stack>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <h4>Moduler som organisasjonen abonnerer på</h4>
          {data.length > 0 ? (
            data.map((row, i) => (
              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Navn:</small>
                        <span>{row.moduleName}</span>
                      </Stack>
                    </Col>
                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Objekttype:</small>

                        <Stack direction="horizontal" gap={2}>
                          <Pill variant="primary">{row.rootObjectTypeName}</Pill>
                          <Pill variant="secondary">{row.objectTypeName}</Pill>
                        </Stack>
                      </Stack>
                    </Col>
                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Aktivert den:</small>
                        <span>{asDateTime(row.createdOn)}</span>
                      </Stack>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>Ingen abonnement på organisasjon.</p>
          )}
        </>
      )}
    </>
  );
};

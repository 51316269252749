import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface IProps {
  show: boolean;
  title: string;
  requireExtraConfirmation?: boolean;
  onCancel: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  children: JSX.Element | JSX.Element[];
}

export const UserConfirmationModal: React.FC<IProps> = ({
  show,
  title,
  requireExtraConfirmation,
  onCancel,
  onConfirm,
  children,
}) => {
  const { t: ts } = useTranslation('shared');

  const [confirmed, setConfirmed] = useState(!requireExtraConfirmation);

  useEffect(() => {
    if (show) {
      setConfirmed(!requireExtraConfirmation);
    }
  }, [show, requireExtraConfirmation]);

  const onConfirmHandler = () => {
    onCancel(false);
    onConfirm();
  };

  return (
    <>
      <Modal show={show} onHide={() => onCancel(false)} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Container fluid className="p-3">
          {children}

          {requireExtraConfirmation && (
            <Form.Switch
              className="my-4"
              id="enable-editing-tree"
              label={ts('actionConfirmCheck')}
              onChange={(e) => setConfirmed(e.target.checked)}
            />
          )}

          <div className="d-flex justify-content-end">
            <Button type="button" variant="primary" className="btn-sm" disabled={!confirmed} onClick={onConfirmHandler}>
              {ts('actionConfirm')}
            </Button>
          </div>
        </Container>
      </Modal>
    </>
  );
};

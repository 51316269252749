import {
  ObjectTypeGroup,
  ObjectTypeResponse,
  WorkspaceObjectTypeResponse,
  WorkspaceResponse,
} from 'common/api/multimap';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetObjectTypes from 'features/admin/object-types/hooks/useGetObjectTypes';
import useObjectGroupTranslation from 'features/admin/object-types/hooks/useObjectGroupTranslation';
import { Reducer, useEffect, useReducer, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CreateWizardStep1 } from './CreateWizardStep1';
import { CreateWizardStep2 } from './CreateWizardStep2';
import { CreateWizardStep3 } from './CreateWizardStep3';
import { CreateWizardStep4 } from './CreateWizardStep4';

export interface IProps {
  admin: boolean;
  show?: boolean;
  onHide?: () => void;
  organizationId?: Guid;
}

export type NewWorkspaceInfo = {
  step?: Steps;
  group?: ObjectTypeGroup;
  objectType?: ObjectTypeResponse;
  systemName?: string;
  systemDescription?: string;
  workspaceId?: Guid;
  organizationId?: Guid;
};

export type ObjectType = {
  objectTypeId: string;
  systemName: string;
  systemDescription: string;
};
interface Workspace {
  systemName: string;
  workspaceId: string;
  objectType: ObjectType;
}

type Steps = 'group-1' | 'type-2' | 'name-3' | 'done-4';

enum ActionType {
  START_WIZARD,

  SET_GROUP,
  BACKTO_GROUP,

  SET_TYPE,
  BACKTO_TYPE,

  SET_NAME,
}

type Action = {
  type: ActionType;
  payload: NewWorkspaceInfo;
};

const wizardReducer: Reducer<NewWorkspaceInfo, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.START_WIZARD:
      return {
        ...state,
        step: 'group-1',
      };

    case ActionType.SET_GROUP:
      return {
        ...state,
        step: 'type-2',
        group: action.payload.group,
        objectType: undefined,
      };
    case ActionType.BACKTO_GROUP:
      return {
        ...state,
        step: 'group-1',
      };

    case ActionType.SET_TYPE:
      return {
        ...state,
        step: 'name-3',
        objectType: action.payload.objectType,
      };
    case ActionType.BACKTO_TYPE:
      return {
        ...state,
        step: 'type-2',
      };

    case ActionType.SET_NAME:
      return {
        ...state,
        step: 'done-4',
        systemName: action.payload.systemName,
        systemDescription: action.payload.systemDescription,
        workspaceId: action.payload.workspaceId,
        organizationId: action.payload.organizationId,
      };

    default:
      return state;
  }
};

export const CreateWizard: React.FC<IProps> = ({ admin, show, onHide, organizationId }) => {
  const navigate = useNavigate();

  const [workspace, setWorkspace] = useLocalStorage<Workspace | undefined>('workspace', undefined);

  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.create.createWizard' });
  const { t: tg } = useObjectGroupTranslation();

  const [isLoading, , data] = useGetObjectTypes();

  const [state, dispatch] = useReducer(wizardReducer, { step: 'group-1' });

  const [formTitle, setFormTitle] = useState<string>();

  useEffect(() => {
    dispatch({ type: ActionType.START_WIZARD, payload: { step: 'group-1' } });
  }, []);

  useEffect(() => {
    switch (state.step) {
      case 'group-1':
        setFormTitle(t('step1Title'));
        break;

      case 'type-2':
        if (!state.group) {
          throw new Error('workspace not set');
        }
        setFormTitle(t('step2Title', { group: tg(state.group) }));
        break;

      case 'name-3':
        setFormTitle(t('step3Title'));
        break;

      case 'done-4':
        setFormTitle(t('step4Title'));
        break;

      default:
        throw new Error(`Unknown step ${state.step}`);
    }
  }, [state, t, tg]);

  const onHideHandler = () => {
    if (admin) {
      navigate('/admin/workspaces');
    } else {
      if (onHide) {
        onHide();
      }
    }
  };

  const onCompletedHandler = () => {
    // eslint-disable-next-line no-constant-condition
    if (admin) {
      navigate(`/admin/workspaces/${state.workspaceId}/types/${state.objectType?.objectTypeId}/detail`);
    } else {
      if (onHide) {
        navigate('/object-registry');
        location.reload();
      }
    }
  };

  return (
    <>
      <Modal show={admin ? true : show} onHide={onHideHandler} centered={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{formTitle}</Modal.Title>
        </Modal.Header>

        <LoadingSpinner isLoading={isLoading} />

        <Container fluid className="p-4">
          {state.step === 'group-1' && (
            <CreateWizardStep1 onConfirm={(group) => dispatch({ type: ActionType.SET_GROUP, payload: { group } })} />
          )}

          {state.step === 'type-2' && data && state.group && (
            <CreateWizardStep2
              data={data}
              selectedOjectType={state.group}
              onConfirm={(objectType) => dispatch({ type: ActionType.SET_TYPE, payload: { objectType } })}
              onBack={() => dispatch({ type: ActionType.BACKTO_GROUP, payload: {} })}
            />
          )}

          {state.step === 'name-3' && (
            <CreateWizardStep3
              model={state}
              onConfirm={(payload) => {
                setWorkspace({
                  systemName: payload.systemName,
                  workspaceId: payload.workspaceId,
                  objectType: {
                    objectTypeId: state.objectType?.objectTypeId ?? '',
                    systemName: state.objectType?.systemName ?? '',
                    systemDescription: state.objectType?.systemDescription ?? '',
                  },
                });
                dispatch({
                  type: ActionType.SET_NAME,
                  payload: {
                    systemName: payload.systemName,
                    systemDescription: payload.systemDescription,
                    workspaceId: payload.workspaceId,
                    organizationId: organizationId,
                  },
                });
              }}
              onBack={() => dispatch({ type: ActionType.BACKTO_TYPE, payload: {} })}
              organizationId={organizationId}
            />
          )}

          {state.step === 'done-4' && <CreateWizardStep4 onConfirm={onCompletedHandler} />}
        </Container>
      </Modal>
    </>
  );
};

import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';

type IProps = {
  message: string;
};

export const ValidationError: React.FC<IProps> = ({ message }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
      <CancelIcon color="error" fontSize="large" />

      <h4 className="text-danger">{message}</h4>
    </div>
  );
};

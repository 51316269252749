import { CreateOrganizationReportRequest, ReportResponse, WorkspaceResponse } from 'common/api/multimap';
import { Pill } from 'common/components/Pill/Pill';
import { TileButton } from 'common/components/TileButton/TileButton';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetAllWorkspaceModules from 'features/user/mapping/hooks/useGetAllWorkspaceModules';
import { Button, Card, Col, Container, Modal, Row, Form, FormGroup } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router';

import { displayIcon } from '../../../admin/modules/components/ModuleIcons';
import { useState } from 'react';
import { OpenInNew } from '@mui/icons-material';
import useGetAllReports from 'features/admin/reports/hooks/useGetAllReports';
import useGetOrganizationReports from 'features/admin/reports/hooks/useGetOrganizationReports';
import useOrderReport from '../hooks/useOrderReport';
import { ReadMoreModal } from './components/ReadMoreModal';

export const ReportOverview = () => {
  const [workspace] = useLocalStorage<WorkspaceResponse | undefined>('workspace', undefined);

  const [isLoading, , data] = useGetAllWorkspaceModules(workspace ? workspace.workspaceId : '');

  const [showModal, setShowModal] = useState<boolean>(false);

  const [showOrderModal, setShowOrderModal] = useState<boolean>(false);

  const [, , specialReports] = useGetAllReports();

  const [, , organizationReports] = useGetOrganizationReports(workspace?.organizationId ?? '');

  const [, , , , add] = useOrderReport();

  const [orderRequest, setOrderRequest] = useState<CreateOrganizationReportRequest>();

  const [readMoreTitle, setReadMoreTitle] = useState<string>("");

  const [readMoreText, setReadMoreText] = useState<string>("");

  const [orderText, setOrderText] = useState<string>("");

  const [showReadMoreModal, setShowReadMoreModal] = useState<boolean>(false);

  const [finished, setFinished] = useState<boolean>(false);

  const setComment = (comment: string) => {
    const order = orderRequest;

    order!.comment = comment;

    setOrderRequest(order);
  }

  const reportExists = (element: ReportResponse, index: number, array: ReportResponse[]) => {
    return (!organizationReports?.find(x => x.reportId == element.reportId));
  }

  const reportIsCorrectObjectTypeId = (element: ReportResponse, index: number, array: ReportResponse[]) => {
    return element.objectTypeId == workspace?.objectType.objectTypeId;
  }

  const navigate = useNavigate();
  return (
    <>
      <PageTitle title="Rapporter" />
      <h4 className="mb-3">Mine rapporter</h4>
      {data && data.length > 0 ? (
        data.map((row, i) => {
          if (row.hasPreviousMapping) {
            return (
              <Card key={i} className="mb-3">
                <Row className="g-0">
                  <Col xs={1}>
                    <div className="w-100 h-100 bg-primary d-flex justify-content-center align-items-center rounded-start">
                      {displayIcon(row.icon)}
                    </div>
                  </Col>
                  <Col xs={11} className="p-3">
                    <Row>
                      <Col>
                        <h4>{row.systemName}</h4>
                        <span>{row.systemDescription}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex flex-row justify-content-end align-items-end pb-3">
                        <Button
                          variant="secondary"
                          onClick={() => navigate(`/report/${row.workspaceModuleId}/report-types`)}
                        >
                          Se tilgjengelige rapporter
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            );
          } else {
            return (
              <Row className="g-0 rounded pt-3 mb-3" style={{ border: '1px dashed #ABBED1' }}>
                <>
                  <Col xs={8} className="p-3">
                    <h4>{row.systemName}</h4>
                  </Col>
                  <Col xs={4} className="p-3 d-flex justify-content-end align-items-end">
                    <Pill variant="danger">Har ikke vært kartlagt tidligere</Pill>
                  </Col>
                </>
              </Row>
            );
          }
        })
      ) : (
        <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
          <>
            <Col xs={8} className="p-3">
              <h4>Du har ingen moduler</h4>
              <span>Du har ingen kartleggingsmoduler aktive.</span>
            </Col>
          </>
        </Row>
      )}
      {organizationReports && organizationReports.map((row, i) => {
        console.log(row);
        if (row.active) {
          return (
            <Card className="mb-3" key={i}>
              <Row className="g-0">
                <Col xs={1}>
                  <div className="w-100 h-100 bg-primary d-flex justify-content-center align-items-center rounded-start">
                    {displayIcon(row.icon)}
                  </div>
                </Col>
                <Col xs={11} className="p-3">
                  <Row>
                    <Col>
                      <h4>{row.title}</h4>
                      <span>{row.description}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-row justify-content-end align-items-end pb-3">
                      <Button
                        variant="secondary"
                        href={row.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Gå til rapport <OpenInNew />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          )
        } else {
          return (
            <Row className="g-0 rounded pt-3 mb-3" style={{ border: '1px dashed #ABBED1' }}>
              <>
                <Col xs={8} className="p-3">
                  <h4>{row.title}</h4>
                  <p>Denne rapporten er fortsatt under arbeid.</p>
                </Col>
              </>
            </Row>
          )
        }
      })}
      <br></br>
      <br></br>
      <h4>Tilgjengelige rapporter</h4>
      {specialReports && specialReports.filter(reportExists).filter(reportIsCorrectObjectTypeId).map((row, i) => (
        <Card key={i} className="mb-3">
          <Row className="g-0">
            <Col xs={1}>
              <div className="w-100 h-100 bg-primary d-flex justify-content-center align-items-center rounded-start">
                {displayIcon(row.icon)}
              </div>
            </Col>
            <Col xs={11} className="p-3">
              <Row>
                <Col>
                  <h4>{row.systemName}</h4>
                  <span>{row.systemDescription}</span>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col className="d-flex flex-column align-items-start pb-3">
                  <span>Pris: {row.reportPrice},-</span>
                </Col>
                <Col className="d-flex flex-row justify-content-end align-items-end pb-3">
                  <Button
                    variant="tertiary"
                    className="me-2"
                    onClick={() => {
                      setReadMoreTitle(row.systemName);
                      setReadMoreText(row.readMore);
                      setShowReadMoreModal(true);
                    }}
                  >
                    Les mer
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setShowOrderModal(true);
                      setOrderRequest(
                        {
                          reportId: row.reportId,
                          organizationId: workspace?.organizationId,
                          link: "",
                          title: "",
                          description: "",
                          active: false,
                        }
                      );
                      setOrderText(row.orderText);
                    }}
                  >
                    Bestill
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      ))}
      <LoadingSpinner isLoading={isLoading} />

      <ReadMoreModal show={showReadMoreModal} onHide={() => setShowReadMoreModal(false)} title={readMoreTitle} text={readMoreText} />
      <Modal show={showOrderModal} onHide={() => setShowOrderModal(false)} centered size="lg">
        <Modal.Header closeButton>
          {!finished ? (

            <Modal.Title>Vil du bestille denne rapporten?</Modal.Title>
          ) : (

            <Modal.Title>Bestilling mottatt</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {!finished ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: orderText }}></div>
              <Form.Group>
                <Form.Label>Kommentar:</Form.Label>
                <Form.Control as="textarea" onChange={(e) => setComment(e.target.value)}></Form.Control>
              </Form.Group>
            </>
          ) : (
            <>
              <h3>Takk for din bestilling!</h3>
              <p>Din bestilling blir nå behandlet hos oss, du vil få beskjed når rapporten du har bestilt kommer på plass.</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!finished ? (
            <>
              <Button variant="tertiary" onClick={() => setShowOrderModal(false)}>
                Avbryt
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  add({ organizationId: workspace?.organizationId ?? '', request: orderRequest! })
                  setFinished(true);
                }}
              >
                Ja, jeg vil bestille
              </Button>
            </>
          ) : (
            <Button variant="tertiary" onClick={() => {
              setShowOrderModal(false);
              setFinished(false);
            }}>Lukk vinduet</Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

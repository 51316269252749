import { NOTIFICATION_TYPE, Store } from 'react-notifications-component';

export const notify = (type: NOTIFICATION_TYPE, message: string) => {
  Store.addNotification({
    message: message,
    type: type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 4 * 1000,
    },
  });
};

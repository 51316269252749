import { Button, Form, Modal } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, UseFormRegister, UseFormReset, UseFormResetField } from 'react-hook-form';

export interface IProps {
  show: boolean;
  onHide: () => void;
  comment: string;
  objectName: string;
  register: UseFormRegister<FieldValues>;
  reset: UseFormReset<FieldValues>;
}

export const MappingObjectMainCommentModal: React.FC<IProps> = ({
  show,
  onHide,
  comment,
  objectName,
  register,
  reset,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <h4 className="m-0">{objectName}</h4>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Kommentar</Form.Label>
          <Form.Control {...register('comment')}></Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onHide()}>
          Legg til kommentar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

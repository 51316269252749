import { FiberManualRecord } from '@mui/icons-material';

type IProps = {
  conditionDegree: number;
};
export const ConditionDegreeIcon: React.FC<IProps> = ({ conditionDegree }) => {

  if (conditionDegree == null) {
    return <FiberManualRecord fontSize="small" htmlColor="#E7E7E8" />;
  }

  if (conditionDegree < 0.75) {
    return <FiberManualRecord color="success" fontSize="small" />;
  } else if (conditionDegree == 99) {
    return <FiberManualRecord fontSize="small" htmlColor="#E7E7E8" />;
  } else if (conditionDegree < 1.5) {
    return <FiberManualRecord fontSize="small" htmlColor="#B8E926" />;
  } else if (conditionDegree < 2.25) {
    return <FiberManualRecord color="secondary" fontSize="small" />;
  } else {
    return <FiberManualRecord fontSize="small" htmlColor="#FF0000" />;
  }
};

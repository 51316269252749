import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { createLoginRequest } from '../../../auth/authConfig';
import styles from './loginFailed.module.scss';

export const LoginFailed = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const handleLogin = () => {
    const request = createLoginRequest();
    instance.loginRedirect(request).catch((error) => console.log(error));
  };

  const showButton = () => {
    setTimeout(() => {
      setShow(true);
    }, 4000);
  };

  useEffect(() => {
    showButton();
  }, []);

  return (
    <Container fluid className={styles.container}>
      <Spinner animation="border" role="status" className={styles.spinner}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      {show && (
        <Button onClick={handleLogin} variant="primary">
          {t('try-again-login')}
        </Button>
      )}
    </Container>
  );
};

import { AxiosError } from 'axios';
import { modulesApi, objectMappingsApi, organizationsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { OrganizationMessageRequest, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type CreateParams = {
  id: string;
  request: OrganizationMessageRequest;
};

function useAddOrganizationMessage(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  (params: CreateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, mutate } = useMutation(
    async ({ id, request }: CreateParams) => {
      return organizationsApi.organizationsOrganizationIdContactPost(id, request).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.ObjectMappings);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const add = (params: CreateParams) => mutate(params);

  return [isLoading, isError, validationError, add];
}

export default useAddOrganizationMessage;

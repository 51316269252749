import QueryKeys from 'common/api/QueryKeys';
import { useQuery } from 'react-query';

export interface OSMNominatimAddress {
  [key: string]: string;
  house_number: string;
  road: string;
  neighbourhood: string;
  suburb: string;
  city: string;
  municipality: string;
  county: string;
  'ISO3166-2-lvl4': string;
  postcode: string;
  highway: string;
  village: string;
  country: string;
  country_code: string;
}

export interface OSMNominatim {
  address: OSMNominatimAddress;
  boundingbox: string[];
  category: string;
  display_name: string;
  extratags: { [key: string]: string };
  icon: string;
  importance: number;
  lat: string;
  licence: string;
  lon: string;
  namedetails: { [key: string]: string };
  osm_id: number;
  osm_type: string;
  place_id: number;
  place_rank: number;
  type: string;
}

export interface OSMNominatimParameters {
  /** List of  ISO 3166-1alpha2 codes. Ex. `['no', 'se']`. (Default: none). */
  countryCodes?: string[];

  /** Include a breakdown of the address into elements. (Default: false). */
  addressDetails?: boolean;

  /** Include additional information in the result if available, e.g. wikipedia link, opening hours. (Default: false). */
  extraTags?: boolean;

  /** Include a list of alternative names in the results.
   * These may include language variants, references, operator and brand. (Default: false). */
  nameDetails?: boolean;

  /**
   * RFC2616 accept-language string.
   * Preferred language order for showing search results, overrides the value specified in the "Accept-Language" HTTP header.
   * (Default: what the HTTP header has sent) */
  acceptLanguage?: string;

  /** Limit the number of returned results. (Default: 10, Maximum: 50) */
  maxResults?: number;

  /** If you are making large numbers of request include an appropriate email address to identify your requests.
   * See Nominatim's {@link https://operations.osmfoundation.org/policies/nominatim/ Usage Policy} for more details. */
  email?: string;
}

/**
 * Default OSM configuration.
 */
export const OSMDefaultConfig: OSMNominatimParameters = {
  // comma-separated list of "ISO 3166-1alpha2" country codes.
  countryCodes: ['no', 'se'],
  addressDetails: true,
  nameDetails: true,
  extraTags: true,
  acceptLanguage: 'nb;q=1,se;q=0.9',
  email: 'multiMap@multiconsult.no',
};

/**
 * Hook that allows you to look up a location from a textual description or address in `Open street map` `Nominatim` service.
 * Nominatim supports structured and free-form search queries.
 *
 * Read `https://operations.osmfoundation.org/policies/nominatim/` to avoid to get you banned.
 * > [...]
 * > No heavy uses (an absolute maximum of 1 request per second).
 * > [...]
 *
 * > Unacceptable Use
 * > The following uses are strictly forbidden and will get you banned:
 * > - Auto-complete search: This is not yet supported by Nominatim and you must not implement such a service on the client side using the API.
 * > - Systematic queries: This includes reverse queries in a grid, searching for complete lists of postcodes, towns etc. and downloading all POIs in an area.
 *
 * @param query Free-form query string to search for.
 * Free-form queries are processed first left-to-right and then right-to-left if that fails.
 * So you may search for `pilkington avenue, birmingham` as well as for `birmingham, pilkington avenue`.
 * Commas are optional, but improve performance by reducing the complexity of the search.
 * > See: https://nominatim.org/release-docs/develop/api/Search/#parameters
 *
 * @param countryCodes list of "ISO 3166-1alpha2" country codes.
 * > See: https://nominatim.org/release-docs/develop/api/Search/#result-limitation
 *
 * @returns A list of `Json2` results.
 * > See: https://nominatim.org/release-docs/develop/api/Output/#jsonv2
 */
function useOpenStreetMapNominatim(
  query: string,
  parameters: OSMNominatimParameters,
): [boolean, boolean, Partial<OSMNominatim[]> | undefined, () => Promise<OSMNominatim[] | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.OpenStreetMapNominatim, query],
    () => {
      if (!query) {
        return undefined;
      }

      const params = new URLSearchParams({
        q: query,
        format: 'jsonv2',
        addressdetails: parameters.addressDetails ? '1' : '0',
        extratags: parameters.extraTags ? '1' : '0',
        namedetails: parameters.nameDetails ? '1' : '0',
        'accept-language': parameters.acceptLanguage ? '1' : '0',
        email: parameters.email ?? '',
      });

      const url = new URL('https://nominatim.openstreetmap.org/search');
      url.search = params.toString();
      const request = url.toString();
      const result = fetch(request)
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then((json) => {
          return json as OSMNominatim[];
        });
      return result;
    },
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetchFn = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetchFn];
}

export default useOpenStreetMapNominatim;

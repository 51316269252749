import { FieldsetAccordion } from 'common/components/FieldsetAccordion/FieldsetAccordion';
import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

interface IProps {
  title: string;
  eventKey: string;
  children?: JSX.Element | JSX.Element[];
}

export const AccordionProperties: React.FC<IProps> = ({ title, eventKey, children }) => {
  return (
    <Accordion flush className="mb-3">
      <Card>
        <Card.Header>
          <FieldsetAccordion eventKey={eventKey}>
            <span>{title}</span>
          </FieldsetAccordion>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

import { userInfoApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { UserInfoResponse } from 'common/api/multimap/api';
import { useQuery } from 'react-query';

function useGetUserInfo(
  isAuthenticated: boolean,
): [boolean, boolean, UserInfoResponse | undefined, () => Promise<UserInfoResponse | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.UserInfo],
    () => userInfoApi.getUserInfo().then((x) => x.data),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetUserInfo;

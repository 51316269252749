import { AxiosError } from 'axios';
import { objectPropertiesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { CreateObjectPropertyRequest, ObjectPropertyResponse, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

function useAddObjectProperty(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  ObjectPropertyResponse | undefined,
  (createObjectPropertyRequest: CreateObjectPropertyRequest) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    (objectPropertyRequest: CreateObjectPropertyRequest) => {
      return objectPropertiesApi.objectPropertiesPost(objectPropertyRequest).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.ObjectProperties);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const add = (request: CreateObjectPropertyRequest) => mutate(request);

  return [isLoading, isError, validationError, data, add];
}

export default useAddObjectProperty;

import KeyIcon from '@mui/icons-material/Key';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

export interface IProps {
  invitedUserName: string;
  invitedByUserName: string;
  organizationName: string;
  handleClose: () => void;
}

export const AccessAssigned: React.FC<IProps> = ({
  invitedUserName,
  invitedByUserName,
  organizationName,
  handleClose,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'features.user.redeemInvitation.components.accessAssigned',
  });

  return (
    <Container fluid className="h-100 d-flex flex-column">
      <Row className="justify-content-center flex-fill">
        <Col sm={12} md={7} className="text-center align-self-center">
          <KeyIcon className={`mb-4 size-xxl`} />

          <h2 className="m-4">{t('title', { userName: invitedUserName })}</h2>
          <p className="m-4">
            <Trans
              t={t}
              i18nKey={'text'}
              components={[<strong />, <strong />]}
              values={{
                organizationName: organizationName,
                invitedByUserName: invitedByUserName,
              }}
            />
          </p>
        </Col>
      </Row>
      <Row className="align-self-end w-100">
        <Col sm={12} className="text-center">
          <Button variant="primary" className="m-4" onClick={() => handleClose()}>
            {t('btnClose')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

import { planningApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { PlanningProjectResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetPlanningProjects(
  id: Guid,
): [boolean, boolean, PlanningProjectResponse[] | undefined, () => Promise<PlanningProjectResponse[] | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.PlanningProjects, 'workspace: ' + id],
    () => planningApi.planningWorkspaceWorkspaceIdGet(id).then((x) => x.data),
    {
      enabled: !!id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetPlanningProjects;

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import { useDebounce } from 'common/hooks/useDebounce';
import useOpenStreetMapNominatim, {
  OSMDefaultConfig,
  OSMNominatim,
} from 'features/admin/hooks/useOpenStreetMapNominatim';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, InputGroup, Spinner } from 'react-bootstrap';

interface IProps {
  disabled: boolean;
  labelledById: string;
  placeholder?: string;
  value?: OSMNominatim;
  onChange: (value?: OSMNominatim) => void;
  onBlur: (value?: OSMNominatim) => void;
  // ref?: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined;
  isInvalid: boolean;
  isValid: boolean;
}

export const AddressControl = React.forwardRef<HTMLInputElement, IProps>(
  ({ disabled, labelledById, placeholder, value, onChange, onBlur, isInvalid, isValid }, ref) => {
    const [isEditing, setIsEditing] = useState(!value);
    const [address, setAddress] = useState<OSMNominatim | undefined>(value);
    const [addressQuery, setAddressQuery] = useState('');
    const [previousAddress, setPreviousAddress] = useState<OSMNominatim | undefined>(value);

    const [osmQuery, setOsmQuery] = useState<string | undefined>(undefined);
    const [candidates, setCandidates] = useState<OSMNominatim[]>([]);
    const [showResults, setShowResults] = useState(false);

    // OBS: do not reduce to less than 1 second
    // https://operations.osmfoundation.org/policies/nominatim/
    // No heavy uses (an absolute maximum of 1 request per second).
    const debunceMs = 1000 * 2;
    const debouncedSearchAddress = useDebounce<string | undefined>(addressQuery, debunceMs);

    const [isSearching, , results] = useOpenStreetMapNominatim(osmQuery ?? '', OSMDefaultConfig);

    useEffect(() => {
      if (debouncedSearchAddress && debouncedSearchAddress.length > 5) {
        setOsmQuery(debouncedSearchAddress);
      }
    }, [debouncedSearchAddress]);

    useEffect(() => {
      const data = (results ?? [])
        .filter((x) => x)
        .map((x) => x as OSMNominatim)
        .sort((v1, v2) => v1.importance - v2.importance);
      setCandidates(data);
      setShowResults(results !== undefined);
    }, [results]);

    if (disabled) {
      return (
        <>
          <InputGroup>
            <Form.Control
              as={'input'}
              type="text"
              aria-labelledby={labelledById}
              value={address?.display_name ?? ''}
              disabled
              placeholder={placeholder}
            ></Form.Control>
          </InputGroup>
        </>
      );
    }

    if (!isEditing) {
      return (
        <>
          <InputGroup>
            <Form.Control
              as={'input'}
              type="text"
              aria-labelledby={labelledById}
              value={address?.display_name ?? ''}
              disabled
              placeholder={placeholder}
            ></Form.Control>
            <Button
              variant="secondary"
              size="sm"
              aria-label="Edit"
              onClick={() => {
                setAddressQuery(address?.display_name ?? '');
                setIsEditing(true);
              }}
            >
              <EditIcon />
            </Button>
          </InputGroup>
        </>
      );
    }

    return (
      <>
        <InputGroup>
          <Form.Control
            as={'input'}
            type="text"
            aria-labelledby={labelledById}
            placeholder={placeholder}
            onChange={(e) => {
              const v = e.target.value;
              setAddressQuery(v);
            }}
            value={addressQuery ?? ''}
          ></Form.Control>
          {previousAddress && (
            <>
              <Button
                variant="secondary"
                size="sm"
                aria-label="Undo"
                onClick={() => {
                  setAddress(previousAddress);
                  setAddressQuery(previousAddress.display_name);
                  setIsEditing(false);
                }}
              >
                <UndoIcon />
              </Button>
              <Button
                variant="secondary"
                size="sm"
                aria-label="Reset"
                onClick={() => {
                  setAddress(undefined);
                  setAddressQuery('');
                  setIsEditing(false);
                }}
              >
                <DeleteOutlineIcon />
              </Button>
            </>
          )}
          {isSearching && (
            <InputGroup.Text>
              <Spinner className="mx-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            </InputGroup.Text>
          )}
        </InputGroup>

        {showResults && candidates.length === 0 && (
          <Dropdown.Menu show={true}>
            <Dropdown.Item key={0} disabled>
              Ingen resultater
            </Dropdown.Item>
          </Dropdown.Menu>
        )}

        {showResults && candidates.length > 0 && (
          <Dropdown.Menu show={true}>
            {candidates.map((result, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  eventKey={index}
                  onClick={() => {
                    const selected = result;
                    if (selected.display_name === address?.display_name) {
                      setIsEditing(false);
                      return;
                    }

                    setPreviousAddress(address);
                    setAddress(selected);
                    setAddressQuery(selected.display_name);
                    setIsEditing(false);
                    onChange(selected);
                  }}
                >
                  {result?.display_name ?? '-'}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        )}
      </>
    );
  },
);

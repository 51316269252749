// Replace with Lodash if more helpers are needed
// Ref: https://stackoverflow.com/questions/42136098/array-groupby-in-typescript

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((acc, currentItem) => {
    const group = getKey(currentItem);
    const previous = acc;
    if (!previous[group]) {
      previous[group] = [];
    }
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

/**
 * `Update` or `Append` a item based on a find-predicate.
 * If the input list is null or empty, it creates a new array.
 *
 * NB. it mutates the original array.
 */
export const upsert = <T>(
  list: T[],
  item: (value?: T) => T,
  predicate: (value: T, index: number, obj: T[]) => boolean,
) => {
  if (list === null || list === undefined) {
    throw new Error(`Invalid list object: value ${typeof list} is not allowed.`);
  }

  const idx = list.findIndex(predicate);
  if (idx > -1) {
    list[idx] = item(list[idx]);
  } else {
    list.push(item(undefined));
  }
};

/**
 * Merge all the arrays and find duplicates.
 * @param list1 First array
 * @param list2 Second array
 * @returns Elements which are in `list1` AND in `list2` (duplicates).
 */
export const duplicates = <T>(list1: T[], list2: T[] = []): T[] => {
  const arr = list1.concat(list2);
  const result = arr.filter((item, index) => arr.indexOf(item) !== index);
  return result;
};

// TODO: write unit tests
/** Returns the last element if at least one is present (same as `LastOrDefault` in Linq) */
export const lastElementIn = <T>(items: T[]): T | undefined => {
  const length = items?.length || 0;
  if (!items || length === 0) {
    return;
  }

  const item = items[length - 1];
  return item;
};

// TODO: write unit tests
/** Returns the first element if present (same as `FirstOrDefault` in Linq) */
export const firstElementIn = <T>(items: T[] | null | undefined): T | undefined => {
  const length = items?.length || 0;
  if (length < 1) {
    return;
  }

  const item = items![0];
  return item;
};

// TODO: write unit tests
/** Return the first element if only one element is present, otherwise returns `undefined` (same as `Single` in Linq) */
export const singleElementIn = <T>(items: T[] | null | undefined): T | undefined => {
  const length = items?.length || 0;
  if (length !== 1) {
    return;
  }

  const item = items![0];
  return item;
};

/**
 * This is used to optimize a json payload.
 * If an array is null, undefined, or empty, we remove it from the payload via a undefined value.
 * Supports Input <I> and Output <O> types to be used with a mapper function.
 **/
export const optimizeArray = <I, O>(array: I[] | null | undefined, predicate: (array: I[]) => O[]) => {
  if (array === undefined || array === null || array.length === 0) {
    return undefined;
  }
  return predicate(array);
};

export const arrayRange = (start: number, stop: number, step: number) => {
  return Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);
};

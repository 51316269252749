import { ErrorPage } from 'common/components/error-page/ErrorPage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetAllObjectProperties from 'features/admin/object-properties/hooks/useGetAllObjectProperties';
import useGetObjectTypeDetail from 'features/admin/object-types/hooks/useGetObjectTypeDetail';
import { useParams } from 'react-router-dom';

import useGetWorkspaceDetail from '../hooks/useGetWorkspaceDetail';
import { Create } from './Create';
import { Upsert } from './Upsert';

export const Detail = () => {
  const { id, typeid } = useParams();

  const [isLoading, , data, fetch] = useGetWorkspaceDetail(id!);
  const [isLoading2, , type] = useGetObjectTypeDetail(typeid!);
  const [isLoading3, , properties] = useGetAllObjectProperties();

  if (!typeid) {
    return <ErrorPage reason={404} />;
  }

  if (isLoading || isLoading2 || !type) {
    return <LoadingSpinner isLoading={true} />;
  }

  return id ? (
    <Upsert type={type} properties={properties ?? []} isLoading={false} refresh={fetch} workspace={data} admin={true} />
  ) : (
    <Create type={type} properties={properties ?? []} />
  );
};

import { ObjectPropertyResponse, ObjectTypeDetailResponse, WorkspaceDetailResponse } from 'common/api/multimap';
import { ErrorPage } from 'common/components/error-page/ErrorPage';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { unstable_usePrompt } from 'react-router-dom';

import useGetWorkspaceDetail from '../hooks/useGetWorkspaceDetail';
import { UpsertDetail } from './UpsertDetail';

export interface IProps {
  isLoading: boolean;
  refresh: () => Promise<WorkspaceDetailResponse | undefined>;
  workspace: WorkspaceDetailResponse | undefined;
  type: ObjectTypeDetailResponse;
  properties: ObjectPropertyResponse[];
  admin: boolean;
}

export const Upsert: React.FC<IProps> = ({ isLoading, refresh, workspace, type, properties, admin }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes.detail' });

  if (!isLoading && !workspace) {
    return <ErrorPage reason={404} />;
  }

  const refreshHandler = () => {
    refresh();
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />

      {workspace && (
        <UpsertDetail
          mode={'edit'}
          type={type}
          detail={workspace}
          properties={properties}
          onRefresh={refreshHandler}
          admin={admin}
        />
      )}
    </>
  );
};

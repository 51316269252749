import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  left?: JSX.Element | JSX.Element[];
  right?: JSX.Element | JSX.Element[];
};

export const PageActions: React.FC<Props> = ({ left, right }) => {

  return (
    <div className="background-blue50 d-flex">
      <Stack direction="horizontal" className="align-content-center justify-content-start py-3 ps-4 me-auto" gap={3}>
        {left}
      </Stack>
      <Stack direction="horizontal" className="align-content-center justify-content-end py-3 pe-4 ms-auto" gap={3}>
        {right}
      </Stack>
    </div>
  );
};

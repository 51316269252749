import { mappingSetsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { MappingSetResponse } from 'common/api/multimap';
import { useQuery } from 'react-query';

function useGetAllMappingSets(): [
  boolean,
  boolean,
  MappingSetResponse[] | undefined,
  () => Promise<MappingSetResponse[] | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.MappingSets],
    () => mappingSetsApi.mappingSetsGet().then((x) => x.data),
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetAllMappingSets;

import { AxiosError } from 'axios';
import { workspacesApi } from 'common/api/MultimapClients';
import {
  ValidationProblemDetails,
  WorkspaceCreateRequest,
  WorkspaceDetailResponse,
  WorkspaceDetailUpdateRequest,
} from 'common/api/multimap/api';
import { Guid } from 'common/types/guid.type';
import { useState } from 'react';
import { useMutation } from 'react-query';

type CreateRequest = {
  request: WorkspaceCreateRequest;
};

type UpdateRequest = {
  workspaceId: Guid;
  request: WorkspaceDetailUpdateRequest;
};

type UpdateRequestAction = (payload: UpdateRequest) => void;
type CreateRequestAction = (payload: CreateRequest) => void;

function useUpsertWorkspace(
  mode: 'create' | 'edit',
  onSuccess: () => void,
): [
    boolean,
    boolean,
    boolean,
    boolean,
    ValidationProblemDetails | undefined,
    WorkspaceDetailResponse | undefined,
    CreateRequestAction,
    UpdateRequestAction,
    () => void,
  ] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const createMutation = useMutation(
    (payload: CreateRequest) => {
      return workspacesApi.workspacesPost(payload.request).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const updateMutation = useMutation(
    (payload: UpdateRequest) => {
      return workspacesApi.workspacesWorkspaceIdPut(payload.workspaceId, payload.request).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const create = (payload: CreateRequest) => createMutation.mutate(payload);
  const update = (payload: UpdateRequest) => updateMutation.mutate(payload);

  return [
    mode === 'create' ? createMutation.isLoading : updateMutation.isLoading,
    mode === 'create' ? createMutation.isError : updateMutation.isError,
    mode === 'create' ? createMutation.isSuccess : updateMutation.isSuccess,
    mode === 'create' ? createMutation.isIdle : updateMutation.isIdle,
    validationError,
    mode === 'create' ? createMutation.data : updateMutation.data,
    create,
    update,
    mode === 'create' ? createMutation.reset : updateMutation.reset,
  ];
}

export default useUpsertWorkspace;

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ObjectTypeGroup, ObjectTypeResponse } from 'common/api/multimap';
import { Expander, useExpander } from 'common/components/Expander/Expander';
import { TileButton } from 'common/components/TileButton/TileButton';
import { Guid } from 'common/types/guid.type';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type IProps = {
  data: ObjectTypeResponse[];
  selectedOjectType: ObjectTypeGroup;
  onConfirm: (objectType: ObjectTypeResponse) => void;
  onBack: () => void;
};

export const CreateWizardStep2: React.FC<IProps> = ({ data, selectedOjectType, onConfirm, onBack }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.create.createWizard.createWizardStep2' });
  const { t: ts } = useTranslation('shared');
  const [expandedDetailIds, toggleExpandedObjectTypeId, isObjectTypeIdExpanded] = useExpander<Guid>();

  return (
    <>
      <p className="larger">{t('title')}</p>

      {(data || [])
        ?.filter((objectType) => objectType.group === selectedOjectType)
        .sort((v1, v2) => v1.systemName.localeCompare(v2.systemName))
        .map((objectType) => {
          const hasExpander = !!objectType.systemDescription;
          const icon = (
            <Expander
              size="sm"
              className={hasExpander ? '' : 'invisible'}
              variant="link-tertiary"
              ids={expandedDetailIds}
              id={objectType.objectTypeId}
              onToggle={(e) => {
                toggleExpandedObjectTypeId(objectType.objectTypeId);
                e.stopPropagation();
              }}
            />
          );

          return (
            <Row className="mb-3" key={objectType.objectTypeId}>
              <Col sm={12}>
                <TileButton
                  key={objectType.objectTypeId}
                  title={objectType.systemName}
                  text={isObjectTypeIdExpanded(objectType.objectTypeId) ? objectType.systemDescription : ''}
                  onClick={() => onConfirm(objectType)}
                  leftIcon={icon}
                  rightIcon={<ArrowForwardIcon />}
                />
              </Col>
            </Row>
          );
        })}

      <Button variant="tertiary" size="sm" className="d-flex ms-auto" onClick={onBack}>
        {ts('back')}
      </Button>
    </>
  );
};

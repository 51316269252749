import { SearchOutlined } from '@mui/icons-material';
import { maintenancePlansApi } from 'common/api/MultimapClients';
import { MaintenancePlanParameterDetailsResponse } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { downloadFile } from 'common/helpers/http-header.helper';
import { notify } from 'common/helpers/toast-notification-helper';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Stack, Table } from 'react-bootstrap';
import { useParams } from 'react-router';
import Select from 'react-select';

import useGetMaintenancePlansDetails from '../hooks/useGetMaintenancePlansDetails';

interface Option {
  value: string;
  label: string;
}

interface Filters {
  parents: Option[];
  children: Option[];
  maintenancePlanTypes: Option[];
  parameters: Option[];
  conditionDegrees: Option[];
  consequenceDegrees: Option[];
  startYear: Option[];
  budgetedYear: Option[];
  finishedYear: Option[];
}

export const MaintenancePlanDetails = () => {
  const { workspaceModuleId } = useParams();

  //Filters
  const [filters, setFilters] = useState<Filters>({
    parents: [],
    children: [],
    maintenancePlanTypes: [],
    parameters: [],
    conditionDegrees: [],
    consequenceDegrees: [],
    startYear: [],
    budgetedYear: [],
    finishedYear: [],
  });
  const [activeFilters, setActiveFilters] = useState<Filters>({
    parents: [],
    children: [],
    maintenancePlanTypes: [],
    parameters: [],
    conditionDegrees: [],
    consequenceDegrees: [],
    startYear: [],
    budgetedYear: [],
    finishedYear: [],
  });

  const [update, setUpdate] = useState<boolean>(false);

  const [, , data] = useGetMaintenancePlansDetails(workspaceModuleId ?? '');

  const getFilters = (details: MaintenancePlanParameterDetailsResponse[]) => {
    if (details) {
      const tempFilters: Filters = {
        parents: [],
        children: [],
        maintenancePlanTypes: [],
        parameters: [],
        conditionDegrees: [],
        consequenceDegrees: [],
        startYear: [],
        budgetedYear: [],
        finishedYear: [],
      };

      //Parents
      const tempParents: Option[] = [];

      details.map((row, i) => {
        if (!tempParents.some((x) => x.value == row.maintenancePlanParentObjectId)) {
          tempParents.push({ value: row.maintenancePlanParentObjectId, label: row.maintenancePlanParentObjectName });
        }
      });

      tempParents.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      tempFilters.parents = tempParents;

      //Children
      const tempChildren: Option[] = [];
      details.map((row, i) => {
        if (activeFilters.parents.length > 0) {
          if (activeFilters.parents.some((x) => x.value == row.maintenancePlanParentObjectId)) {
            if (!tempChildren.some((x) => x.value == row.maintenancePlanObjectId)) {
              tempChildren.push({ value: row.maintenancePlanObjectId, label: row.maintenancePlanObjectName });
            }
          }
        } else {
          if (!tempChildren.some((x) => x.value == row.maintenancePlanObjectId)) {
            tempChildren.push({ value: row.maintenancePlanObjectId, label: row.maintenancePlanObjectName });
          }
        }
      });
      tempChildren.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      tempFilters.children = tempChildren;

      //Maintenance Plan Types
      const tempTypes: Option[] = [];
      details.map((row, i) => {
        if (!tempTypes.some((x) => x.value == row.maintenancePlanTypeId)) {
          tempTypes.push({ value: row.maintenancePlanTypeId, label: row.maintenancePlanTypeId });
        }
      });
      tempTypes.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      tempFilters.maintenancePlanTypes = tempTypes;

      setFilters(tempFilters);

      //Parameters
      const tempParameters: Option[] = [];
      details.map((row, i) => {
        if (!tempParameters.some((x) => x.value == row.parameterName)) {
          tempParameters.push({ value: row.parameterName, label: row.parameterName });
        }
      });
      tempParameters.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      tempFilters.parameters = tempParameters;

      //Condition degrees
      const tempConditionDegrees: Option[] = [];
      details.map((row, i) => {
        if (row.conditionDegree != null) {
          if (!tempConditionDegrees.some((x) => x.value == row.conditionDegree.toString())) {
            tempConditionDegrees.push({ value: row.conditionDegree.toString(), label: row.conditionDegree.toString() });
          }
        }
      });
      tempConditionDegrees.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      tempFilters.conditionDegrees = tempConditionDegrees;

      //Consequence degree
      const tempConsequenceDegree: Option[] = [];
      details.map((row, i) => {
        if (!tempConsequenceDegree.some((x) => x.value == row.consequenceDegree.toString())) {
          tempConsequenceDegree.push({
            value: row.consequenceDegree.toString(),
            label: row.consequenceDegree.toString(),
          });
        }
      });
      tempConsequenceDegree.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      tempFilters.consequenceDegrees = tempConsequenceDegree;

      //Start year
      const tempStartYear: Option[] = [];
      details.map((row, i) => {
        if (!tempStartYear.some((x) => x.value == row.startYear.toString())) {
          tempStartYear.push({
            value: row.startYear.toString(),
            label: row.startYear.toString(),
          });
        }
      });
      tempStartYear.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      tempFilters.startYear = tempStartYear;

      //Consequence degree
      const tempBudgetYear: Option[] = [];
      details.map((row, i) => {
        if (!tempBudgetYear.some((x) => x.value == row.budgetYear.toString())) {
          tempBudgetYear.push({
            value: row.budgetYear.toString(),
            label: row.budgetYear.toString(),
          });
        }
      });
      tempBudgetYear.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      tempFilters.budgetedYear = tempBudgetYear;

      //Consequence degree
      const tempFinishedYear: Option[] = [];
      details.map((row, i) => {
        let value = 'Ikke avsluttet';
        if (row.finishYear != null) {
          value = row.finishYear.toString();
        }
        if (!tempFinishedYear.some((x) => x.value == value)) {
          tempFinishedYear.push({
            value: value,
            label: value,
          });
        }
      });
      tempFinishedYear.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      tempFilters.finishedYear = tempFinishedYear;

      setFilters(tempFilters);
    }
  };

  useEffect(() => {
    if (data) {
      getFilters(data);
    }
  }, [data, activeFilters.parents, update]);

  return (
    <>
      <PageTitle title="Detaljeoversikt" backTo={`/maintenance-plan/${workspaceModuleId}/overview`}></PageTitle>
      <h4 className="mt-5">Detaljert visning over tiltak og parametere</h4>

      <TableContainer>
        <Row className="table-filter">
          <Col className="mb-3" md={3}>
            <Select
              isMulti
              isSearchable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: '42px',
                  maxWidth: '300px',
                  minWidth: '300px',
                }),
              }}
              placeholder={`Velg ${data && data[0].parentObjectTypeName}`}
              options={filters.parents}
              onChange={(e) => {
                const newFilters = activeFilters;
                newFilters.parents = e as Option[];
                setActiveFilters(newFilters);
                setUpdate(!update);
              }}
              value={activeFilters.parents}
            />
          </Col>
          <Col md={3}>
            <Select
              isMulti
              isSearchable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: '42px',
                  minWidth: '300px',
                  maxWidth: '300px',
                }),
              }}
              placeholder={`Velg ${data && data[0].objectTypeName}`}
              options={filters.children}
              onChange={(e) => {
                const newFilters = activeFilters;
                newFilters.children = e as Option[];
                setActiveFilters(newFilters);
                setUpdate(!update);
              }}
              value={activeFilters.children}
            />
          </Col>
          <Col md={3}>
            <Select
              isMulti
              isSearchable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: '42px',
                  minWidth: '300px',
                  maxWidth: '300px',
                }),
              }}
              placeholder={`Velg tiltakstype`}
              options={filters.maintenancePlanTypes}
              onChange={(e) => {
                const newFilters = activeFilters;
                newFilters.maintenancePlanTypes = e as Option[];
                setActiveFilters(newFilters);
                setUpdate(!update);
              }}
              value={activeFilters.maintenancePlanTypes}
            />
          </Col>
          <Col md={3}>
            <Select
              isMulti
              isSearchable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: '42px',
                  minWidth: '300px',
                  maxWidth: '300px',
                }),
              }}
              placeholder={`Velg parameter`}
              options={filters.parameters}
              onChange={(e) => {
                const newFilters = activeFilters;
                newFilters.parameters = e as Option[];
                setActiveFilters(newFilters);
                setUpdate(!update);
              }}
              value={activeFilters.parameters}
            />
          </Col>
          <Col md={3}>
            <Select
              isMulti
              isSearchable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: '42px',
                  minWidth: '300px',
                  maxWidth: '300px',
                }),
              }}
              placeholder={`Velg tilstandsgrad`}
              options={filters.conditionDegrees}
              onChange={(e) => {
                const newFilters = activeFilters;
                newFilters.conditionDegrees = e as Option[];
                setActiveFilters(newFilters);
                setUpdate(!update);
              }}
              value={activeFilters.conditionDegrees}
            />
          </Col>
          <Col md={3}>
            <Select
              isMulti
              isSearchable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: '42px',
                  minWidth: '300px',
                  maxWidth: '300px',
                }),
              }}
              placeholder={`Velg konsekvensgrad`}
              options={filters.consequenceDegrees}
              onChange={(e) => {
                const newFilters = activeFilters;
                newFilters.consequenceDegrees = e as Option[];
                setActiveFilters(newFilters);
                setUpdate(!update);
              }}
              value={activeFilters.consequenceDegrees}
            />
          </Col>
          <Col md={3}>
            <Select
              isMulti
              isSearchable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: '42px',
                  minWidth: '300px',
                  maxWidth: '300px',
                }),
              }}
              placeholder={`Velg planlagt startår`}
              options={filters.startYear}
              onChange={(e) => {
                const newFilters = activeFilters;
                newFilters.startYear = e as Option[];
                setActiveFilters(newFilters);
                setUpdate(!update);
              }}
              value={activeFilters.startYear}
            />
          </Col>
          <Col md={3} className="mb-3">
            <Select
              isMulti
              isSearchable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: '42px',
                  minWidth: '300px',
                  maxWidth: '300px',
                }),
              }}
              placeholder={`Velg budsjettert år`}
              options={filters.budgetedYear}
              onChange={(e) => {
                const newFilters = activeFilters;
                newFilters.budgetedYear = e as Option[];
                setActiveFilters(newFilters);
                setUpdate(!update);
              }}
              value={activeFilters.budgetedYear}
            />
          </Col>
          <Col md={3}>
            <Select
              isMulti
              isSearchable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: '42px',
                  minWidth: '300px',
                  maxWidth: '300px',
                }),
              }}
              placeholder={`Velg avsluttet år`}
              options={filters.finishedYear}
              onChange={(e) => {
                const newFilters = activeFilters;
                newFilters.finishedYear = e as Option[];
                setActiveFilters(newFilters);
                setUpdate(!update);
              }}
              value={activeFilters.finishedYear}
            />
          </Col>
        </Row>
        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">Prosjekt</th>
              <th scope="col">Objekt</th>
              <th scope="col">Tiltakstype</th>
              <th scope="col">Parameter</th>
              <th scope="col">Tilstandsgrad</th>
              <th scope="col">Konsekvensgrad</th>
              <th scope="col">Risikograd</th>
              <th scope="col">Planlagt startår</th>
              <th scope="col">Budsjettert år</th>
              <th scope="col">Avsluttet år</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .filter((x) => {
                  if (activeFilters.children.length > 0) {
                    return activeFilters.children.some((ac) => ac.value == x.maintenancePlanObjectId);
                  } else {
                    return data;
                  }
                })
                .filter((x) => {
                  if (activeFilters.parents.length > 0) {
                    return activeFilters.parents.some((ac) => ac.value == x.maintenancePlanParentObjectId);
                  } else {
                    return data;
                  }
                })
                .filter((x) => {
                  if (activeFilters.maintenancePlanTypes.length > 0) {
                    return activeFilters.maintenancePlanTypes.some((mpt) => mpt.value == x.maintenancePlanTypeId);
                  } else {
                    return data;
                  }
                })
                .filter((x) => {
                  if (activeFilters.parameters.length > 0) {
                    return activeFilters.parameters.some((mpt) => mpt.value == x.parameterName);
                  } else {
                    return data;
                  }
                })
                .filter((x) => {
                  if (activeFilters.conditionDegrees.length > 0) {
                    return activeFilters.conditionDegrees.some((mpt) => mpt.value == x.conditionDegree.toString());
                  } else {
                    return data;
                  }
                })
                .filter((x) => {
                  if (activeFilters.consequenceDegrees.length > 0) {
                    return activeFilters.consequenceDegrees.some((mpt) => mpt.value == x.consequenceDegree.toString());
                  } else {
                    return data;
                  }
                })
                .filter((x) => {
                  if (activeFilters.startYear.length > 0) {
                    return activeFilters.startYear.some((mpt) => mpt.value == x.startYear.toString());
                  } else {
                    return data;
                  }
                })
                .filter((x) => {
                  if (activeFilters.budgetedYear.length > 0) {
                    return activeFilters.budgetedYear.some((mpt) => mpt.value == x.budgetYear.toString());
                  } else {
                    return data;
                  }
                })
                .filter((x) => {
                  if (activeFilters.finishedYear.length > 0) {
                    let value = 'Ikke avsluttet';
                    if (x.finishYear != null) {
                      value = x.finishYear.toString();
                    }
                    return activeFilters.finishedYear.some((mpt) => mpt.value == value);
                  } else {
                    return data;
                  }
                })
                .map((row, i) => {
                  return (
                    <tr>
                      <td>
                        <Stack direction="vertical">
                          <small>{row.maintenancePlanName}</small>
                          <p className="smaller">{row.maintenancePlanNumber}</p>
                        </Stack>
                      </td>
                      <td>
                        <Stack direction="vertical">
                          <small>{row.maintenancePlanObjectName}</small>
                          <p className="smaller">{row.maintenancePlanParentObjectName}</p>
                        </Stack>
                      </td>
                      <td>{row.maintenancePlanTypeId}</td>
                      <td>{row.parameterName}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <ConditionDegreeIcon conditionDegree={row.conditionDegree} />
                          <span className="ms-2">{row.conditionDegree == 99 ? 'N/A' : row.conditionDegree}</span>
                        </div>
                      </td>
                      <td>{row.consequenceDegree}</td>
                      <td>{row.riskDegree}</td>
                      <td>{row.startYear}</td>
                      <td>{row.budgetYear}</td>
                      <td>{row.finishYear}</td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};

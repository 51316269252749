import { useMsal } from '@azure/msal-react';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';

import { AccessAssigned } from './components/access-assigned/AccessAssigned';
import { AccessFailed } from './components/access-failed/AccessFailed';
import useRedeemInvitation from './hooks/useRedeemInvitation';

export const RedeemInvitation: React.FC = () => {
  const { invitationId } = useParams();
  const [isLoading, isIdle, isSuccess, isError, validationError, data, redeem] = useRedeemInvitation();
  const invitationProcessed = useRef(false);
  const { accounts } = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    if (invitationProcessed.current) return;
    if (isSuccess || isError || !isIdle || !invitationId || accounts.length === 0) return;
    redeem(invitationId);
    invitationProcessed.current = true;
  }, [redeem, isSuccess, isIdle, invitationId, isLoading, isError, accounts.length]);

  return (
    <>
      {isLoading && (
        <Container className="d-flex h-100">
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner isLoading={isLoading} />
          </div>
        </Container>
      )}

      {data && (
        <AccessAssigned
          invitedByUserName={data.createdByUserName || ''}
          invitedUserName={data.redeemedByUserName || ''}
          organizationName={data.invitedByOrganizationName || ''}
          handleClose={() => {
            navigate('/account-settings');
            location.reload();
          }}
        />
      )}

      {validationError && <AccessFailed error={validationError} handleClose={() => navigate('/home')} />}
    </>
  );
};

import { AxiosError } from 'axios';
import { reportsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { CreateOrganizationReportRequest, CreateReportRequest, UpdateModuleMappingGroupDescriptionRequest, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type UpdateParams = {
  reportId: string;
  request: CreateReportRequest;
};

function useUpdateReport(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  CreateReportRequest | undefined,
  (params: UpdateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    ({ reportId, request }: UpdateParams) => {
      return reportsApi.reportsReportIdSpecialReportsPut(reportId, request).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.SpecialReports);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const update = (params: UpdateParams) => mutate(params);

  return [isLoading, isError, validationError, data, update];
}

export default useUpdateReport;

import { ObjectTypeGroup } from 'common/api/multimap';
import { useTranslation } from 'react-i18next';

function useObjectGroupTranslation(): { t: (group: ObjectTypeGroup) => string } {
  const { t: tg } = useTranslation('admin', { keyPrefix: 'objectTypes.groups' });

  const t = (group: ObjectTypeGroup) => {
    switch (group) {
      case 'Annet':
        return tg('annet');

      case 'Eiendom':
        return tg('eiendom');

      case 'Internal':
        return tg('internal');

      case 'Park':
        return tg('park');

      case 'Vei':
        return tg('vei');

      default:
        throw new Error(`Unknown ObjectTypeGroup ${ObjectTypeGroup}`);
    }
  };

  return { t };
}

export default useObjectGroupTranslation;

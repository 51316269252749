import { ArrowCircleRightRounded, ArrowRightRounded } from '@mui/icons-material';

import styles from './timeline.module.scss';

export const Timeline = () => {
  return (
    <div className="row">
      <div className="col">
        <div className={`${styles['timeline-steps']} aos-init aos-animate`} data-aos="fade-up">
          <div className={`${styles['timeline-step']}`}>
            <div
              className={styles['timeline-content']}
              data-toggle="popover"
              data-trigger="hover"
              data-placement="top"
              title=""
              data-content="And here's some amazing content. It's very engaging. Right?"
              data-original-title="2003"
            >
              <ArrowCircleRightRounded />
              <p className="h6 mt-3 mb-1">Registrer</p>
            </div>
          </div>
          <div className={`${styles['timeline-step']}`}>
            <div className={styles['timeline-content']}>
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                title=""
                data-content="And here's some amazing content. It's very engaging. Right?"
                data-original-title="2004"
              >
                <ArrowCircleRightRounded />
                <p className="h6 mt-3 mb-1">Kartlegg</p>
              </div>
            </div>
          </div>

          <div className={`${styles['timeline-step']}`}>
            <div className={styles['timeline-content']}>
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                title=""
                data-content="And here's some amazing content. It's very engaging. Right?"
                data-original-title="2004"
              >
                <ArrowCircleRightRounded />
                <p className="h6 mt-3 mb-1">Analyser</p>
              </div>
            </div>
          </div>
          <div className={`${styles['timeline-step']} mb-0`}>
            <div
              className={styles['timeline-content']}
              data-toggle="popover"
              data-trigger="hover"
              data-placement="top"
              title=""
              data-content="And here's some amazing content. It's very engaging. Right?"
              data-original-title="2020"
            >
              <ArrowCircleRightRounded />
              <p className="h6 mt-3 mb-1">Presenter</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class LanguageStorageService {
  private preferredLanguageKey = 'preferredLanguage';

  private preferredLanguage: string | undefined;

  constructor() {
    this.preferredLanguage = window.localStorage.getItem(this.preferredLanguageKey) ?? undefined;
  }

  public get() {
    return this.preferredLanguage ?? undefined;
  }

  public set(lang: string | undefined) {
    if (!lang) {
      window.localStorage.removeItem(this.preferredLanguageKey);
    } else {
      window.localStorage.setItem(this.preferredLanguageKey, lang);
    }

    this.preferredLanguage = lang;
  }
}

const ls = new LanguageStorageService();
export { ls as LanguageStorageService };

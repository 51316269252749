import CloseIcon from '@mui/icons-material/Close';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import React from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';

export interface IProps {
  importFile?: File;
  setImportFile: (file?: File) => void;
  onNext: () => void;
}

export const ImportSelectFileStep: React.FC<IProps> = ({ importFile, setImportFile, onNext }) => {
  const isValid = (importFile && importFile.name.endsWith('.xlsx') && importFile.size > 0) ?? false;

  const onFileChanged = (files: FileList | null) => {
    const hasSingleFile = files?.length === 1;
    if (!hasSingleFile) {
      setImportFile(undefined);
      return;
    }

    setImportFile(files[0]);
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Label id="choose-file">Velg en Excel fil</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col>
          {importFile && (
            <InputGroup size="sm">
              <Form.Control type="text" value={importFile.name} readOnly={true} aria-labelledby="choose-file" />
              <Button
                type="button"
                variant="primary"
                size="sm"
                aria-label="Close"
                onClick={() => setImportFile(undefined)}
              >
                <CloseIcon />
              </Button>
            </InputGroup>
          )}
          {!importFile && (
            <Form.Control
              type="file"
              size="sm"
              aria-labelledby="choose-file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFileChanged(e.target.files)}
            />
          )}
        </Col>
        <Col>
          {importFile && (
            <Button
              type="submit"
              variant="primary"
              size="sm"
              aria-label="Import"
              className="text-nowrap"
              disabled={!isValid}
              onClick={() => onNext()}
            >
              <UploadOutlinedIcon /> Import
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

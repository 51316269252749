import React from 'react';

import styles from './TableContainer.module.scss';

interface IProps {
  children?: JSX.Element | JSX.Element[];
}

export const TableContainer: React.FC<IProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

import { AddCircleOutline, ArrowDropDown, SearchOutlined } from '@mui/icons-material';
import { CreateMaintenancePlanRequest, MaintenancePlanResponse, MaintenancePlanType } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetMappingObjectDetail from 'features/user/mapping/hooks/useGetMappingObjectDetail';
import useGetWorkspaceModule from 'features/user/mapping/hooks/useGetWorkspaceModule';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Modal, Row, Stack, Table } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { CreateMaintenancePlanModal } from '../components/CreateMaintenancePlanModal';
import useAddMaintenancePlan from '../hooks/useAddMaintenancePlan';
import useGetLastClosedMappingPeriod from '../hooks/useGetLastClosedPeriod';
import useGetMaintenancePlans from '../hooks/useGetMaintenancePlans';

export const MaintenancePlanCreate = () => {
  const { workspaceModuleId } = useParams();

  const [isLoading, , data] = useGetLastClosedMappingPeriod(workspaceModuleId ?? '');

  const [, , module] = useGetWorkspaceModule(workspaceModuleId ?? '');

  const [, , , createdPlan, add] = useAddMaintenancePlan();

  const [mappingObjectId, setMappingObjectId] = useState<string>('');
  const [, , mappingObjectDetail, fetch] = useGetMappingObjectDetail(mappingObjectId);

  const [filter, setFilter] = useState<string>('');

  const [show, setShow] = useState<boolean>(false);

  const createPlan = (id: string) => {
    setMappingObjectId(id);
    setShow(true);
  };

  return (
    <>
      {data ? (
        <>
          <PageTitle
            title={`Objekter som kan planlegges`}
            backTo={`/maintenance-plan/${workspaceModuleId}/overview`}
          ></PageTitle>
          <h4 className="mt-3">Velg hvilket av de kartlage objektene du ønsker å lage en plan for.</h4>
          {data.mappingObjects && data.mappingObjects.length > 0 ? (
            <TableContainer>
              <Stack direction="horizontal" className="table-filter">
                <InputGroup>
                  <InputGroup.Text>
                    <SearchOutlined fontSize="small" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    id="filter"
                    placeholder={'Søk'}
                    aria-describedby={'Søk'}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </InputGroup>
              </Stack>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th scope="col">Objekt</th>
                    <th scope="col">Samlet</th>
                    <th scope="col">Areal</th>
                    <th scope="col">År</th>
                    {data.mappingObjects[0].valueGroups &&
                      data.mappingObjects[0].valueGroups.map((row, i) => {
                        return (
                          <>
                            <th scope="col">{row.mappingGroupName}</th>
                          </>
                        );
                      })}
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {data.mappingObjects
                    .filter((x) => x.objectName.toLowerCase().includes(filter.toLowerCase()))
                    .map((row, i) => {
                      return (
                        <tr>
                          <td>
                            <Stack direction="vertical">
                              <span>{row.objectName}</span>
                              <small>{row.objectParentName}</small>
                            </Stack>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <ConditionDegreeIcon conditionDegree={row.averageConditionDegree} />
                              <span className="ms-2">{(row.averageConditionDegree != null) ? row.averageConditionDegree.toFixed(1) : "N/A"}</span>
                            </div>
                          </td>
                          <td>{row.areal}</td>
                          <td>{row.year}</td>
                          {row.valueGroups &&
                            row.valueGroups.map((group, index) => {
                              return (
                                <>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <ConditionDegreeIcon conditionDegree={group.averageConditionDegree} />
                                      <span className="ms-2">{(group.averageConditionDegree != null) ? group.averageConditionDegree.toFixed(1) : "N/A"}</span>
                                    </div>
                                  </td>
                                </>
                              );
                            })}
                          <td>
                            <div className="d-flex justify-content-end">
                              <Button
                                variant="tertiary"
                                className="text-nowrap"
                                onClick={() => createPlan(row.mappingObjectId)}
                              >
                                Lag tiltak
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                <>
                  <Col xs={8} className="p-3">
                    <h4>Ingen av objektene er kartlagt på denne perioden</h4>
                    <span>En eller flere objekter må være kartlagt for å kunne lage et tiltak.</span>
                  </Col>
                </>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <PageTitle
            title={`Objekter som kan planlegges`}
            backTo={`/maintenance-plan/${workspaceModuleId}/overview`}
          ></PageTitle>

          <LoadingSpinner isLoading={isLoading} />
          <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
            <>
              <Col xs={8} className="p-3">
                <h4>Du har ingen avsluttede kartlegginger</h4>
                <span>Vennligst gjør en kartlegging på denne modulen for å fortsette videre med planlegging.</span>
              </Col>
            </>
          </Row>
        </>
      )}
      <CreateMaintenancePlanModal
        mappingObjectId={mappingObjectId}
        show={show}
        onHide={() => setShow(false)}
        moduleName={module ? module.systemName : ''}
        workspaceModuleId={workspaceModuleId!}
      />
    </>
  );
};

import { ErrorMessage } from '@hookform/error-message';
import {
  CreateMappingPeriodDescriptionAndStateRequest,
  MappingPeriodResponse,
  MappingState,
} from 'common/api/multimap';
import { asDateTime } from 'common/helpers/dateHelpers';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetAllModuleWeightingVersions from 'features/admin/modules/hooks/useGetAllModuleWeightingVersions';
import { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, useForm } from 'react-hook-form';

import { CreateParams } from '../../hooks/useAddMappingObjectState';
import useAddMappingPeriod from '../../hooks/useAddMappingPeriod';
import useAddMappingPeriodDescriptionAndState from '../../hooks/useAddMappingPeriodDescriptionAndState';
import useGetWorkspaceModule from '../../hooks/useGetWorkspaceModule';

export interface IProps {
  show: boolean;
  onHide: () => void;
  workspaceModuleId: string;
  mappingPeriodResponse: MappingPeriodResponse | undefined;
}

export const EditMappingPeriodModal: React.FC<IProps> = ({
  show,
  onHide,
  workspaceModuleId,
  mappingPeriodResponse,
}) => {
  const [, , , dataState, addState] = useAddMappingPeriodDescriptionAndState();

  const [, , workspaceModule] = useGetWorkspaceModule(workspaceModuleId ? workspaceModuleId : '');

  const [, , weightingVersions] = useGetAllModuleWeightingVersions(workspaceModule?.moduleId ?? '');

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
    formState,
    reset,
  } = useForm({ mode: 'onChange' });

  const editMappingPeriod = (d: FieldValues) => {
    const closePeriodRequest: CreateMappingPeriodDescriptionAndStateRequest = {
      mappingPeriodId: mappingPeriodResponse?.mappingPeriodId,
      yearAndMonth: d.yearAndMonth,
      stateId: mappingPeriodResponse?.mappingStateId,
      periodName: d.periodName,
      moduleWeightingVersionId: d.moduleWeightingVersionId,
      description: d.description,
    };

    addState({ id: mappingPeriodResponse?.mappingPeriodId ?? '', request: closePeriodRequest });
    onHide();
  };

  useEffect(() => {
    if (mappingPeriodResponse) {
      console.log(mappingPeriodResponse.activeMappingPeriod);
      console.log(new Date(mappingPeriodResponse.activeMappingPeriod).toISOString());
      reset({
        periodName: mappingPeriodResponse.mappingPeriodName,
        yearAndMonth: mappingPeriodResponse.activeMappingPeriod.split('T')[0],
        moduleWeightingVersionId: mappingPeriodResponse.moduleWeightingVersionId,
        description: mappingPeriodResponse.mappingPeriodDescription,
      });
    }
  }, [mappingPeriodResponse]);

  return (
    <Modal show={show} onHide={onHide} centered={true} size="lg">
      <Modal.Header closeButton>
        <h4>Rediger kartleggingsperiode</h4>
      </Modal.Header>

      <Form onSubmit={handleSubmit((d) => editMappingPeriod(d))}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Kartleggingsnavn</Form.Label>
            <Form.Control
              placeholder="Navngi denne kartleggingsperioden ..."
              {...register('periodName', { required: 'Du må navngi denne kartleggingsperioden' })}
            ></Form.Control>
            <ErrorMessage errors={errors} name="periodName" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Periode</Form.Label>
            <Form.Control
              type="date"
              {...register('yearAndMonth', { required: 'Du må gi kartleggingen en periode' })}
            ></Form.Control>
            <ErrorMessage errors={errors} name="yearAndMonth" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Velg vektingsversjon</Form.Label>
            <Form.Select {...register('moduleWeightingVersionId', { required: 'Du må velge en vektingsversjon' })}>
              {weightingVersions &&
                weightingVersions.map((row, i) => {
                  return (
                    <option key={i} value={row.moduleWeightingVersionId} selected={row.active}>
                      {row.versionName}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Beskrivelse</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Skriv en kort beskrivelse ..."
              {...register('description')}
            ></Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={onHide}>
            Avbryt
          </Button>
          <Button variant="primary" type="submit" disabled={!formState.isValid}>
            Rediger
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

import { useMsal } from '@azure/msal-react';
import {
  AssignmentTurnedInOutlined,
  CenterFocusStrongOutlined,
  CheckCircleOutlineOutlined,
  EnergySavingsLeafOutlined,
  PlaylistAddCheckOutlined,
  ThumbsUpDownOutlined,
} from '@mui/icons-material';
import macbook1 from 'assets/images/Macbook-Pro-1.png';
import macbook2 from 'assets/images/Macbook-Pro-2.png';
import macbook3 from 'assets/images/Macbook-Pro-3.png';
import macbook from 'assets/images/Macbook-Pro.png';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { Button, Card, Carousel, CarouselItem, Col, Container, Image, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { createLoginRequest } from '../../../auth/authConfig';
import { Timeline } from './components/Timeline';

interface Workspace {
  systemName: string;
  workspaceId: string;
}

export const Landing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();

  const [workspace, setWorkspace] = useLocalStorage<Workspace | undefined>('workspace', undefined);

  const handleLogin = () => {
    setWorkspace(undefined);
    navigate('/home');
    instance.loginRedirect(createLoginRequest()).catch((error) => console.log(error));
  };

  return (
    <Container fluid className={'px-sm-4 px-lg-5'}>
      <Row className="mt-5">
        <Col>
          <h4>Verktøy for strategisk eiendomsforvaltning</h4>
          <h2>Har du en levedyktig eiendomsportefølje?</h2>
          <br></br>
          <p>
            multiMap er et verktøy for kartlegging av porteføljer som gir grunnlag for langsiktig planlegging og
            bærekraftig utvikling av bygg, anlegg og eiendom i et livsløpsperspektiv.
          </p>
          <br></br>
          <Button variant="secondary" className="me-2" onClick={handleLogin}>
            Bestill multiMap
          </Button>
          <Button variant="tertiary" onClick={handleLogin}>
            Logg inn
          </Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KtrgFGQ4CyE?si=QUDcGUSjNWXSeIMk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
      <hr></hr>
      <Row className="mt-5">
        <Col className="d-flex justify-content-center">
          <div className="d-flex flex-column text-center">
            <h3>Fordeler med multiMap</h3>
            <p>
              Kartlegging med multiMap kan benyttes som underlag for budsjettprosesser, eiendomsstrategier og
              utviklingsplaner.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4} className="d-flex justify-content-center">
          <Stack gap={3} direction="horizontal">
            <CheckCircleOutlineOutlined color="success" />
            <h4>Moduler utviklet av fageksperter</h4>
          </Stack>
        </Col>
        <Col md={4} className="d-flex justify-content-center">
          <Stack gap={3} direction="horizontal">
            <CheckCircleOutlineOutlined color="success" />
            <h4>Fra 5-10 minutter per bygg*</h4>
          </Stack>
        </Col>

        <Col md={4} className="d-flex justify-content-center">
          <Stack gap={3} direction="horizontal">
            <CheckCircleOutlineOutlined color="success" />
            <h4>Få umiddelbare rapporter</h4>
          </Stack>
        </Col>

        <Col md={6} className="d-flex justify-content-center">
          <Stack gap={3} direction="horizontal">
            <CheckCircleOutlineOutlined color="success" />
            <h4>Utarbeid tiltaks- og vedlikeholdsplaner</h4>
          </Stack>
        </Col>

        <Col md={6} className="d-flex justify-content-center">
          <Stack gap={3} direction="horizontal">
            <CheckCircleOutlineOutlined color="success" />
            <h4>Spor utvikling over tid</h4>
          </Stack>
        </Col>
      </Row>
      <hr></hr>
      <Row className="mt-5">
        <Col className="d-flex justify-content-center">
          <h3>Slik ser multiMap ut</h3>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center">
        <div className="w-75">
          <Carousel variant="dark" controls={false}>
            <CarouselItem className="text-center">
              <Image src={macbook}></Image>
            </CarouselItem>
            <CarouselItem className="text-center">
              <Image src={macbook1}></Image>
            </CarouselItem>
            <CarouselItem className="text-center">
              <Image src={macbook2}></Image>
            </CarouselItem>
            <CarouselItem className="text-center">
              <Image src={macbook3}></Image>
            </CarouselItem>
          </Carousel>
        </div>
      </Row>
      <hr></hr>
      <Row className="mt-5">
        <Col className="d-flex justify-content-center">
          <div className="d-flex flex-column text-center">
            <h3>Prosess</h3>
            <p>Få bedre oversikt over din portefølje</p>
          </div>
        </Col>
        <Timeline></Timeline>
      </Row>
      <hr></hr>
      <Row className="my-5">
        <Col>
          <h3>Kartleggingsmoduler</h3>
          <Row className="mt-3">
            <Col md={1} className="d-flex align-items-center justify-content-center">
              <AssignmentTurnedInOutlined />
            </Col>
            <Col className="d-flex flex-column">
              <span className="lead fw-bold">Teknisk tilstand</span>
              <span className="lead">
                Modulen brukes til å kartlegge nåværende kvalitet og tilstand til bygningskomponenter.
              </span>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={1} className="d-flex align-items-center justify-content-center">
              <CenterFocusStrongOutlined />
            </Col>
            <Col className="d-flex flex-column">
              <span className="lead fw-bold">Tilpasningsdyktighet</span>
              <span className="lead">
                Modulen benyttes til å kartlegge de strukturelle egenskapene til eiendomsporteføljen som er grunnlag for
                bygningens evne til å tilpasse endrede behov etter ny bruk.
              </span>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={1} className="d-flex align-items-center justify-content-center">
              <ThumbsUpDownOutlined />
            </Col>
            <Col className="d-flex flex-column">
              <span className="lead fw-bold">Funksjonell egnethet</span>
              <span className="lead">
                Modulen benyttes til å kartlegge eiendomsporteføljens egnethet til organisasjonens funksjoner.
              </span>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={1} className="d-flex align-items-center justify-content-center">
              <EnergySavingsLeafOutlined />
            </Col>
            <Col className="d-flex flex-column">
              <span className="lead fw-bold">Klimasårbarhet og naturfarer</span>
              <span className="lead">
                Modulen benyttes til å kartlegge eiendomsporteføljens motstandskraft mot nåværende og fremtidige
                klimaforhold.
              </span>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={1} className="d-flex align-items-center justify-content-center">
              <PlaylistAddCheckOutlined />
            </Col>
            <Col className="d-flex flex-column">
              <span className="lead fw-bold">Øvrige moduler</span>
              <span className="lead">
                multiMap tilbyr en rekke moduler innen kartlegging, analyse og strategisk forvaltning av
                eiendomsporteføljer, uavhengig av kompleksitet.
              </span>
            </Col>
          </Row>
        </Col>
        <Col>
          <Card className="px-2 py-2">
            <Card.Body>
              <span className="fw-bold">Priser fra</span>
              <div className="text-center background-blue10 py-3">
                <h1>9 500,-</h1>
                <span className="lead fw-bold">NOK per år</span>
              </div>
              <p className="my-5">
                multiMap er et web-basert verktøy for kartlegging av porteføljer som gir grunnlag for langsiktig
                planlegging og bærekraftig utvikling av bygningene.
              </p>
              <Stack direction="vertical" gap={3}>
                <Button variant="secondary" onClick={handleLogin}>
                  Bestill multiMap
                </Button>
                <Button variant="tertiary" href="mailto:multimap@multiconsult.no">
                  Ta kontakt med en rådgiver
                </Button>
              </Stack>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

import { organizationsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { OrganizationModuleResponse, OrganizationResponse } from 'common/api/multimap/api';
import { useQuery } from 'react-query';

function useGetOrganizationModules(
  organizationId: string | undefined,
): [
  boolean,
  boolean,
  OrganizationModuleResponse[] | undefined,
  () => Promise<OrganizationModuleResponse[] | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.OrganizationModules],
    () => organizationsApi.getOrganizationModules(organizationId ?? '').then((x) => x.data),
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetOrganizationModules;

import { PageTitle } from 'common/components/page-title/PageTitle';
import { Col, Container, Row } from 'react-bootstrap';

export const LearningMapping = () => {
  return (
    <>
      <PageTitle title="Kartlegging" />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/PuFnDhB9eWk?si=3G0h3bBvpNMFcc5T"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <br></br>
      <br></br>

      <p>Kartleggingsfanen gir oversikt over aktive kartleggingsmoduler og tilgjengelige kartleggingsmoduler.</p>

      <p>
        <b>Aktive kartleggingsmoduler</b> viser hvilke kartleggingsmoduler organisasjonen har bestilt. Her har man
        anledning til å lese mer om hver kartleggingsmodul eller gå direkte til kartleggingsmodulene for å opprette nye
        kartlegginger eller se fullførte kartlegginger. Dersom man har en pågående kartlegging, vil man i
        kartleggingsfanen få en oversikt over fremdriften for kartleggingen. Det vil for pågående kartlegginger også
        være mulig å gå direkte til den aktive kartleggingsperioden for å fortsette kartleggingen.{' '}
      </p>
      <p>
        <b>Tilgjengelige kartleggingsmoduler</b> kartleggingsmoduler viser hvilke kartleggingsmoduler som er
        tilgjengelig for bestilling og gir organisasjonen en enkel mulighet for bestilling av nye kartleggingsmoduler
        ved behov. I tillegg til bestillingsmulighet vil det være mulig å lese mer om de ulike modulene, herunder hva de
        inneholder, hva de gir av verdi og hva som kartlegges. multiMap har et bredt utvalg av kartleggingsmoduler å
        velge blant, og flere nye moduler er under utvikling. I dialog med Multiconsult vil våre erfarne rådgivere
        hjelpe dere med å velge den eller de kartleggingsmodulene som passer best for deres organisasjon og behov. Mest
        populære moduler i multiMap er blant andre:{' '}
        <i>
          Teknisk tilstand, Tilpasningsdyktighet, Funksjonell egnethet, Universell utforming, Park, Brannsikkerhet,
          Energibesparing, Klimaskall og Naturfarer.
        </i>
      </p>
    </>
  );
};

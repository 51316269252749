import { organizationsApi, usersApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { UserResponse } from 'common/api/multimap/api';
import { useQuery } from 'react-query';

function useGetUsers(): [
  boolean,
  boolean,
  UserResponse[] | undefined,
  () => Promise<UserResponse[] | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.Users],
    () => usersApi.usersGet().then((x) => x.data),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetUsers;

import { ObjectPropertyResponse, ObjectTypeDetailResponse, WorkspaceDetailResponse } from 'common/api/multimap';
import { useTranslation } from 'react-i18next';

import { UpsertDetail } from './UpsertDetail';

export interface IProps {
  type: ObjectTypeDetailResponse;
  properties: ObjectPropertyResponse[];
}

export const Create: React.FC<IProps> = ({ type, properties }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes.detail' });

  const detail: WorkspaceDetailResponse = {
    workspaceId: '',
    objectId: '',
    systemName: '',
    systemDescription: '',
    // name: [],
    // description: [],
    objectType: type,
    levels: [],
  };
  return <UpsertDetail mode={'create'} detail={detail} type={type} properties={properties} admin={true} />;
};

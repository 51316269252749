import { Edit, Link, SearchOutlined } from '@mui/icons-material';
import { Pill } from 'common/components/Pill/Pill';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { asDateTime } from 'common/helpers/dateHelpers';
import { PageNavTitles } from 'features/admin/modules/components/PageNavTitles';
import useGetOrganizationUsers from 'features/admin/organizations/hooks/useGetOrganizationUsers';
import { t } from 'i18next';
import { useState } from 'react';
import { Button, Form, InputGroup, Stack, Table } from 'react-bootstrap';
import { useParams } from 'react-router';

export const ActiveUsers = () => {
  const [filter, setFilter] = useState<string>('');

  const { organizationId } = useParams();

  const [isLoading, , data] = useGetOrganizationUsers(organizationId ?? undefined);

  return (
    <>
      <PageNavTitles
        titles={[
          { title: 'Aktive brukere', link: `/admin/organizations/${organizationId}/users/active`, active: true },
          { title: 'Inviterte brukere', link: `/admin/organizations/${organizationId}/users/invited` },
        ]}
        backTo={`/admin/organizations/${organizationId}`}
      ></PageNavTitles>
      <br></br>
      <TableContainer>
        <Stack className="table-filter" direction="horizontal" gap={3}>
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder={'Søk på aktive brukere'}
              aria-describedby={'Søk på aktive brukere'}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </Stack>

        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">Navn</th>
              <th scope="col">Opprettet den</th>
              <th scope="col">Rettighetsnivå</th>
              <th scope="col">Innsyn</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((row, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Stack direction="vertical">
                        <span>{row.firstname + ' ' + row.lastname}</span>
                        <small>{row.email}</small>
                      </Stack>
                    </td>
                    <td>
                      <span>{asDateTime(row.createdOn ? row.createdOn.toString() : '')}</span>
                    </td>
                    <td>
                      <span>
                        {row.isOrganizationAdministrator ? 'Organisasjonsadministrator' : 'Organisasjonsbruker'}
                      </span>
                    </td>
                    <td>
                      <span className="me-3">
                        <Pill variant="success">Alle arbeidsområder til organisasjonen</Pill>
                      </span>
                    </td>
                    <td>
                      {/* <div className="d-flex gap-3 flex-wrap justify-content-end align-items-center">
                        <Button variant="link">Se bruker detaljer</Button>
                      </div>  */}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import React, { forwardRef, useState } from 'react';
// eslint-disable-next-line import/named
import { Button, ButtonProps } from 'react-bootstrap';

export function useExpander<T>(): [T[], (id: T) => void, (id: T) => boolean] {
  const [selected, setSelected] = useState<T[]>([]);

  const select = (id: T) => {
    setSelected((ids) => {
      if (ids.includes(id)) {
        return ids.filter((x) => x !== id);
      }
      return [...ids, id];
    });
  };

  const isExpanded = (id: T) => {
    return selected.includes(id);
  };

  return [selected, select, isExpanded];
}

export interface IProps extends ButtonProps {
  id: string;
  ids: string[];
  onToggle: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Expander = forwardRef<HTMLButtonElement, IProps>(({ id, ids, onToggle, ...props }, ref) => (
  <Button onClick={(e) => onToggle(e)} {...props} ref={ref}>
    {ids.includes(id) && <RemoveOutlinedIcon titleAccess="Less"></RemoveOutlinedIcon>}
    {!ids.includes(id) && <AddOutlinedIcon titleAccess="More"></AddOutlinedIcon>}
  </Button>
));

Expander.displayName = 'Expander';

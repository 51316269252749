import {
  ObjectPropertyResponse,
  ObjectTypeDetailResponse,
  ObjectTypeLevelUpdateRequest,
  ObjectTypeUpdateRequest,
  TextLanguageRequest,
} from 'common/api/multimap';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useGenericForm } from 'common/form/hooks/useGenericForm';
import { notify } from 'common/helpers/toast-notification-helper';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import { Button, Col, InputGroup, Form as RBForm, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { objectTypeGroups } from '../helpers/objectTypeGroups';
import { withLangs } from '../helpers/withLangs';
import useObjectGroupTranslation from '../hooks/useObjectGroupTranslation';
import useUpsertObjectType from '../hooks/useUpsertObjectType';
import { PropertyEditor } from './LevelPropertiesEditor';
import { TreeEditor } from './TreeEditor';
import { EditText } from './components/EditText';

export interface IProps {
  title: string;
  detail: ObjectTypeDetailResponse;
  properties: ObjectPropertyResponse[];
  mode: 'edit' | 'create';
}

export const UpsertDetail: React.FC<IProps> = ({ title, detail, properties, mode }) => {
  const [formData, setFormData] = useState<ObjectTypeDetailResponse>(structuredClone(detail));
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes.detail' });
  const { t: tg } = useObjectGroupTranslation();
  const { t: ts } = useTranslation('shared');

  const [changed, setChanged] = useState(false);
  const [assignedProperties, setAssignedProperties] = useState<Guid[]>([]);
  const [isEditingMainData, setIsEditingMainData] = useState(mode === 'create');
  const [isEditingTree, setIsEditingTree] = useState(false);
  const [isEditingTexts, setIsEditingTexts] = useState(false);
  const [editingPropertyLevel, setEditingPropertyLevel] = useState<number | undefined>(undefined);

  const onUpsertSucceed = () => {
    notify('success', 'Objecttype lagret');
  };

  const [isSaving, , , , validationError, objectTypeDetailResponse, update, reset] = useUpsertObjectType(
    mode,
    onUpsertSucceed,
  );

  const {
    FormContainer,
    FormSelect,
    FormErrors,
    form,
    isLoading: isFormSaving,
  } = useGenericForm<ObjectTypeDetailResponse>({
    onSubmit: async (e) => {
      const request: ObjectTypeUpdateRequest = {
        name: e.name,
        description: e.description,
        group: e.group,
        levels: e.levels.map((level) => {
          return {
            objectTypeId: level.objectTypeId ? level.objectTypeId : undefined,
            name: level.name.map((n) => {
              return {
                language: n.language,
                text: n.text,
              } as TextLanguageRequest;
            }),
            description: level.description.map((n) => {
              return {
                language: n.language,
                text: n.text,
              } as TextLanguageRequest;
            }),
            properties: [...level.properties],
          } as ObjectTypeLevelUpdateRequest;
        }),
      };
      update({ objectTypeId: formData.objectTypeId, request });
      setFormData(e);
    },
    model: formData,
    errorDetails: validationError,
  });

  useEffect(() => {
    if (objectTypeDetailResponse) {
      setFormData(() => structuredClone(objectTypeDetailResponse));
      reset();
      setChanged(false);
      form.methods.reset();
    }
  }, [form.methods, reset, objectTypeDetailResponse]);

  useEffect(() => {
    setChanged((x) => x || form.methods.formState.isDirty);
  }, [form.methods.formState.isDirty]);

  useEffect(() => {
    const x = formData.levels.reduce((prev, curr) => {
      return prev.concat(curr.properties);
    }, [] as Guid[]);
    setAssignedProperties(x);
  }, [formData]);

  const appendLevel = () => {
    const levels = [
      ...(formData.levels || []),
      {
        objectTypeId: '',
        level: formData.levels.length + 1,
        systemName: '',
        systemDescription: '',
        name: [],
        description: [],
        properties: [],
        usedBy: 0,
      },
    ];
    setFormData((x) => {
      return { ...x, levels: levels };
    });
    setEditingPropertyLevel(undefined);
    setChanged(true);
  };

  const removeLastLevel = () => {
    const levels = formData.levels.filter((_, index) => index !== formData.levels.length - 1);
    setFormData((x) => {
      return { ...x, levels: levels };
    });
    setChanged(true);
  };

  const onPropertyCheckedHandler = (propertyId: Guid, checked: boolean) => {
    setFormData((data) => {
      if (editingPropertyLevel === undefined || data.levels[editingPropertyLevel] === undefined) {
        return data;
      }

      const obj: ObjectTypeDetailResponse = structuredClone(data);
      const props = obj.levels[editingPropertyLevel].properties;
      const newProps = checked ? [...props, propertyId] : props.filter((x) => x !== propertyId);
      obj.levels[editingPropertyLevel].properties = newProps;
      return obj;
    });
    setChanged(true);
  };

  const onSelectPropertyLevelHandler = (level: number) => {
    setEditingPropertyLevel(editingPropertyLevel === level ? undefined : level);
  };

  return (
    <>
      <FormContainer form={form} >
        <PageTitle background title={title} backTo={`/admin/object-types`}>
          <Stack direction="horizontal" gap={2}>
            <RBForm.Switch
              inline
              id="enable-editing-main"
              label={t('editMainData')}
              checked={isEditingMainData}
              onChange={(e) => {
                setEditingPropertyLevel(undefined);
                setIsEditingMainData(e.target.checked);
                setIsEditingTree(false);
                setIsEditingTexts(false);
              }}
            />

            <RBForm.Switch
              inline
              id="enable-editing-tree"
              label={t('editTreeStructure')}
              checked={isEditingTree}
              onChange={(e) => {
                setEditingPropertyLevel(undefined);
                setIsEditingMainData(false);
                setIsEditingTree(e.target.checked);
                setIsEditingTexts(false);
              }}
            />

            <RBForm.Switch
              inline
              id="enable-editing-texts"
              label={t('editLabels')}
              checked={isEditingTexts}
              onChange={(e) => {
                setEditingPropertyLevel(undefined);
                setIsEditingMainData(false);
                setIsEditingTree(false);
                setIsEditingTexts(e.target.checked);
              }}
            />

            <Button type="submit" variant="primary" size="sm" disabled={!changed}>
              {ts('actionSave')}
            </Button>

            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                setEditingPropertyLevel(undefined);
                setFormData(() => structuredClone(detail));
                setChanged(false);
              }}
              disabled={!changed}
            >
              {ts('actionCancel')}
            </Button>
          </Stack>
        </PageTitle>

        <Row className="mt-4">
          <Col>
            <FormErrors form={form} />
          </Col>
        </Row>

        <LoadingSpinner isLoading={isFormSaving || isSaving} />

        <Row className="mb-4">
          <Col>
            <small>{t('nameTitle')}</small>
            <div className="d-flex">
              <InputGroup>
                <RBForm.Control
                  type="text"
                  readOnly={!isEditingMainData}
                  defaultValue={withLangs(formData.name).defaultText.text}
                  onChange={(e) => {
                    setFormData((data) => {
                      return { ...data, name: withLangs(data.name).update(e.target.value, 'Norwegian') };
                    });
                    setChanged(true);
                  }}
                />
                {isEditingTexts && (
                  <EditText
                    texts={formData.name}
                    multiline={false}
                    hasData={(e) => {
                      setFormData((data) => {
                        return { ...data, name: e };
                      });
                      setChanged(true);
                    }}
                  ></EditText>
                )}
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col >
            <small>{t('descriptionTitle')}</small>
            <div className="d-flex">
              <InputGroup>
                <RBForm.Control
                  as="textarea"
                  id="description"
                  aria-describedby="description"
                  readOnly={!isEditingMainData}
                  value={withLangs(formData.description).defaultText.text}
                  onChange={(e) => {
                    setFormData((data) => {
                      return { ...data, description: withLangs(data.description).update(e.target.value, 'Norwegian') };
                    });
                    setChanged(true);
                  }}
                />
                {isEditingTexts && (
                  <EditText
                    texts={formData.description}
                    multiline={true}
                    hasData={(e) => {
                      setFormData((data) => {
                        return { ...data, description: e };
                      });
                      setChanged(true);
                    }}
                  ></EditText>
                )}
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col >
            <small>{t('groupTitle')}</small>
            <FormSelect form={form} name="group" disabled={!isEditingMainData}>
              {objectTypeGroups.map((group) => (
                <option key={group} value={group}>
                  {tg(group)}
                </option>
              ))}
            </FormSelect>
          </Col>
        </Row>

        <Row>
          <Col>
            <hr />
          </Col>
        </Row>

        <Row className="my-4">
          <Col xs={4}>
            <TreeEditor
              levels={formData.levels}
              isEditingMainData={isEditingMainData}
              isEditingTree={isEditingTree}
              isEditingTexts={isEditingTexts}
              editingPropertyLevel={editingPropertyLevel}
              created={() => appendLevel()}
              deleted={() => removeLastLevel()}
              editPropertyHandler={onSelectPropertyLevelHandler}
              hasChanged={() => setChanged(true)}
              hasData={(e) => {
                setFormData((data) => {
                  return { ...data, levels: e };
                });
              }}
            ></TreeEditor>
          </Col>
          <Col xs={8}>
            {editingPropertyLevel !== undefined && (
              <PropertyEditor
                disabled={editingPropertyLevel === undefined || isEditingTree}
                excludedIds={properties
                  .filter((x) => x.isAverage || x.isTotal)
                  .map((x) => x.objectPropertyId)
                  .filter((x) => assignedProperties.includes(x))}
                ids={editingPropertyLevel !== undefined ? formData.levels[editingPropertyLevel]?.properties || [] : []}
                properties={properties}
                onChecked={onPropertyCheckedHandler}
              ></PropertyEditor>
            )}
          </Col>
        </Row>
      </FormContainer>
    </>
  );
};

import { ObjectPropertyResponse } from 'common/api/multimap';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import {
  // eslint-disable-next-line import/named
  Control,
  Controller, // eslint-disable-next-line import/named
  DeepRequired, // eslint-disable-next-line import/named
  FieldErrorsImpl,
} from 'react-hook-form';

import { AddressControl } from '../../../components/AddressControl/AddressControl';
import { FormFieldError } from '../../helpers/FormFieldError';
import { IFormInput } from './ObjectModal';

interface IProps {
  control: Control<IFormInput, any>;
  index: number;
  errors: FieldErrorsImpl<DeepRequired<IFormInput>>;
  disabled: boolean;
  labelledById: string;
  prop: ObjectPropertyResponse;
}

export const EditPropertyControl: React.FC<IProps> = ({ control, index, errors, disabled, labelledById, prop }) => {
  return (
    <>
      {prop.datatypeId === 'String' && (
        <>
          <Controller
            control={control}
            name={`properties.${index}.valueString`}
            rules={{
              required: prop.required,
              max: prop.maxValue ?? undefined,
              min: prop.minValue ?? undefined,
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Form.Control
                as={'input'}
                type="text"
                aria-labelledby={labelledById}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                ref={ref}
                isInvalid={!!errors?.properties?.[index]?.valueString}
                isValid={!errors?.properties?.[index]?.valueString}
              ></Form.Control>
            )}
          />
          <FormFieldError
            fieldError={errors?.properties?.[index]?.valueString}
            minValue={prop.minValue}
            maxValue={prop.maxValue}
          />
        </>
      )}

      {prop.datatypeId === 'Integer' && (
        <>
          <Controller
            control={control}
            name={`properties.${index}.valueInteger`}
            rules={{
              required: prop.required,
              max: prop.maxValue ?? undefined,
              min: prop.minValue ?? undefined,
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup>
                <Form.Control
                  as={'input'}
                  type="number"
                  aria-labelledby={labelledById}
                  step={1}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value ?? ''}
                  ref={ref}
                  isInvalid={!!errors?.properties?.[index]?.valueInteger}
                  isValid={!errors?.properties?.[index]?.valueInteger}
                ></Form.Control>

                {prop.suffix && <InputGroup.Text>{prop.suffix}</InputGroup.Text>}

                <FormFieldError
                  fieldError={errors?.properties?.[index]?.valueInteger}
                  minValue={prop.minValue}
                  maxValue={prop.maxValue}
                />
              </InputGroup>
            )}
          />
        </>
      )}

      {prop.datatypeId === 'Float' && (
        <>
          <Controller
            control={control}
            name={`properties.${index}.valueDecimal`}
            rules={{
              required: prop.required,
              max: prop.maxValue ?? undefined,
              min: prop.minValue ?? undefined,
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup>
                <Form.Control
                  as={'input'}
                  type="number"
                  step={0.01}
                  aria-labelledby={labelledById}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value ?? ''}
                  ref={ref}
                  isInvalid={!!errors?.properties?.[index]?.valueDecimal}
                  isValid={!errors?.properties?.[index]?.valueDecimal}
                ></Form.Control>

                {prop.suffix && <InputGroup.Text>{prop.suffix}</InputGroup.Text>}

                <FormFieldError
                  fieldError={errors?.properties?.[index]?.valueDecimal}
                  minValue={prop.minValue}
                  maxValue={prop.maxValue}
                />
              </InputGroup>
            )}
          />
        </>
      )}

      {prop.datatypeId === 'Address' && (
        <>
          <Controller
            control={control}
            name={`properties.${index}.valueString`}
            rules={{
              required: prop.required,
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <AddressControl
                disabled={disabled}
                labelledById={labelledById}
                placeholder="skriv inn en adresse"
                onChange={(e) => onChange(e ? JSON.stringify(e) : null)}
                onBlur={onBlur}
                value={value ? JSON.parse(value) : undefined}
                ref={ref}
                isInvalid={!!errors?.properties?.[index]?.valueString}
                isValid={!errors?.properties?.[index]?.valueString}
              ></AddressControl>
            )}
          />
          <FormFieldError
            fieldError={errors?.properties?.[index]?.valueString}
            minValue={prop.minValue}
            maxValue={prop.maxValue}
          />
        </>
      )}

      {prop.datatypeId === 'OptionSet' && (
        <>
          <Controller
            control={control}
            name={`properties.${index}.valueOptionReferenceId`}
            rules={{
              validate: (value) => (prop.required && !value ? 'Dette feltet er obligatorisk' : undefined),
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Form.Select
                aria-labelledby={labelledById}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                ref={ref}
                isInvalid={!!errors?.properties?.[index]?.valueOptionReferenceId}
                isValid={!errors?.properties?.[index]?.valueOptionReferenceId}
              >
                <option key={''} value={''}>
                  Ingen
                </option>
                {prop.options
                  .sort((v1, v2) => v1.systemName.localeCompare(v2.systemName))
                  .map((o) => (
                    <option key={o.objectPropertyOptionId} value={o.objectPropertyOptionId}>
                      {o.systemName}
                    </option>
                  ))}
              </Form.Select>
            )}
          />
          <FormFieldError
            fieldError={errors?.properties?.[index]?.valueOptionReferenceId}
            minValue={prop.minValue}
            maxValue={prop.maxValue}
          />
        </>
      )}
    </>
  );
};

import { Edit, SearchOutlined } from '@mui/icons-material';
import { CreateOrganizationModuleRequest, ModuleResponse, UpdateOrganizationModuleRequest } from 'common/api/multimap';
import { Pill } from 'common/components/Pill/Pill';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { asDateTime } from 'common/helpers/dateHelpers';
import useGetAllModules from 'features/admin/modules/hooks/useGetAllModules';
import useAddOrganizationModules from 'features/user/account-settings/components/modules/hooks/useCreateOrganizationModule';
import useDeleteOrganizationModule from 'features/user/account-settings/components/modules/hooks/useDeleteOrganizationModule';
import useGetOrganizationModules from 'features/user/account-settings/components/modules/hooks/useGetOrganizationModules';
import useUpdateOrganizationModule from 'features/admin/organizations/hooks/useUpdateOrganizationModule';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Modal, Row, Stack, Table } from 'react-bootstrap';
import { useParams } from 'react-router';

// eslint-disable-next-line import/named
import { FieldValues, useForm } from 'react-hook-form';

export const OrganizationModules = () => {
  const { organizationId } = useParams();

  const [, , data, fetch] = useGetOrganizationModules(organizationId ?? undefined);
  const [, , , result, remove] = useDeleteOrganizationModule();
  const [, , , , update] = useUpdateOrganizationModule();

  const [organizationModuleId, setOrganizationModuleId] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UpdateOrganizationModuleRequest>();

  const updateOrganizationModule = async (d: FieldValues) => {
    update({ id: d.organizationModuleId, request: d });

    setShowEditModal(false);
  };

  return (
    <>
      {data && (
        <>
          <PageTitle title="Abonnement" backTo={`/admin/organizations/${organizationId}`}></PageTitle>
          <h4 className="mt-2">Oversikt</h4>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <Stack direction="vertical">
                    <small className="bold">Status</small>
                    <h5>Aktiv</h5>
                  </Stack>
                </Col>
                <Col>
                  <Stack direction="vertical">
                    <small className="bold">Moduler</small>
                    <h5>{data.length}</h5>
                  </Stack>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <h4>Moduler som organisasjonen abonnerer på</h4>
          {data.length > 0 ? (
            data.map((row, i) => (
              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Navn:</small>
                        <span>{row.moduleName}</span>
                      </Stack>
                    </Col>
                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Objekttype:</small>

                        <Stack direction="horizontal" gap={2}>
                          <Pill variant="primary">{row.rootObjectTypeName}</Pill>
                          <Pill variant="secondary">{row.objectTypeName}</Pill>
                        </Stack>
                      </Stack>
                    </Col>

                    <Col>
                      <Stack direction="vertical">
                        <small className="bold text-nowrap">Maconomy referanse:</small>
                        <span>{row.maconomyId}</span>
                      </Stack>
                    </Col>

                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Faktureringsdato:</small>
                        <Stack direction="horizontal">
                          <span className="text-nowrap">{row.invoiceDate == undefined ? "Ingen fakturingsdato" : asDateTime(row.invoiceDate)}</span>
                        </Stack>
                      </Stack>
                    </Col>
                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Sist fakturert:</small>
                        <span>{row.lastInvoiceDate == undefined ? "Ikke fakturert" : asDateTime(row.lastInvoiceDate)}</span>
                      </Stack>
                    </Col>

                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Pris:</small>
                        <Stack direction="horizontal">
                          <span>{row.price} kr</span>
                        </Stack>
                      </Stack>
                    </Col>

                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Aktivert den:</small>
                        <span>{asDateTime(row.createdOn)}</span>
                      </Stack>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <Button variant="tertiary" className="me-2" onClick={() => {
                        reset(
                          {
                            organizationModuleId: row.organizationModuleId,
                            invoiceDate: row.invoiceDate,
                            price: row.price
                          }
                        );

                        setShowEditModal(true);
                      }}>Endre</Button>
                      <Button
                        variant="warning"
                        onClick={() => {
                          setShowDeleteModal(true);
                          setOrganizationModuleId(row.organizationModuleId);
                        }}
                      >
                        Fjern
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>Ingen abonnementer på organisasjon.</p>
          )}
        </>
      )}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Er du sikker på at du vil slette abonnementet på kunde?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sletter du abonnementet her i multiMap blir det ikke automatisk fjernet fra Maconomy. Om du skal velge å
          fjerne husk og gjøre det samme i Maconomy.
          <br></br>
          <br></br>
          Kundedata blir heller ikke fjernet med å fjerne abonnement, slik at kunde kan abonnere igjen på et senere
          tidspunkt og fortsatt se kartleggingsdataene sine.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={() => setShowDeleteModal(false)}>
            Avbryt
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              remove({ organizationId: organizationId ?? '', organizationModuleId: organizationModuleId });
              setShowDeleteModal(false);
            }}
          >
            Ja, jeg vil slette abonnementet.
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Endre organisasjonsmodul</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit((d) => updateOrganizationModule(d))} id="form">
            <Form.Label>Faktureringsdato:</Form.Label>
            <Form.Control type="date" {...register('invoiceDate')}></Form.Control>
            <br></br>
            <Form.Label>Pris:</Form.Label>
            <Form.Control type="number" {...register('price')}></Form.Control>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={() => setShowEditModal(false)}>
            Avbryt
          </Button>
          <Button variant="primary" type="submit" form="form">Rediger</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

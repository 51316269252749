import { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';

interface IProps {
  onAcceptChange: (agreementAccepted: boolean | undefined) => void;
}

export const OrganizationAgreement: React.FC<IProps> = ({ onAcceptChange }) => {
  const [acceptedAgreement, setAcceptedAgreement] = useState<boolean>(false);

  useEffect(() => {
    onAcceptChange(acceptedAgreement);
  }, [acceptedAgreement, onAcceptChange]);

  return (
    <Container fluid>
      <Row className="d-flex justify-content-center">
        <Col>
          <h3>Abonnementsbetingelser for multiMap</h3>
          <Card className="p-4 overflow-auto" style={{ maxHeight: '70vh' }}>
            <Card.Body>
              <h4>1. Innledning</h4>
              <p>
                «Abonnementsbetingelser for multiMap». I det følgende brukes betegnelsen «Leverandøren» om Multiconsult
                Norge AS.
              </p>

              <h4>2. Omfang og varighet</h4>
              <p>
                Avtalen gjelder abonnement på tilgang til verktøyet multiMap.no som det fremgår av valgte
                produktbeskrivelse. Abonnementet løper i 12 måneder fra inngåelse av avtalen og fornyes årlig inntil
                oppsigelse av avtalen finner sted (jf punkt 10).
              </p>

              <h4>3. Generelle betingelser</h4>
              <p>
                Med mindre annet er avtalt, gjelder i nevnte rekkefølge, følgende avtaledokumenter for abonnement på
                multiMap:
              </p>
              <ol>
                <li>Valgte produktbeskrivelse</li>
                <li>Bestemmelsene i foreliggende ”Abonnementsbetingelser for multiMap.”</li>
              </ol>

              <h4>4. Leveransen</h4>
              <h4>4.1 Ytelse og plikter</h4>
              <p>
                Verktøyet multiMap er et nettbasert verktøy som gir tilgang til ulike produkter som Kunden abonnerer på.
                De produktene som til enhver tid gjelder er beskrevet på <a href="www.multimap.no">www.multimap.no</a>.
              </p>
              <p>
                Kundens tilgang til produkter avhenger av abonnementsløsningen som Kunden har valgt. Hvilke produkter
                som inngår i inneværende abonnement, og til hvilken pris, fremgår av valgte produktbeskrivelse. Kunden
                kan bestille ytterligere produkter etter egne abonnementsavtaler eller enkeltrapporter (engangspris).
              </p>

              <h4>4.2 Brukerveiledning og annen bistand</h4>
              <p>
                Brukerveiledning vil være tilgjengelig på <a href="www.multimap.no">www.multimap.no</a>.
              </p>
              <p>
                Inkludert i abonnement er teknisk støtte i nødvendig utstrekning, opprettelse av abonnement, brukernavn
                og passord. Teknisk utvikling som API grensesnitt og annen ønsket teknisk tilpasning av
                multiMap-verktøyet er ikke inkludert under teknisk støtte.
              </p>
              <p>Faglig support utover dette faktureres etter avtale.</p>
              <p>
                Eventuelt behov for bistand med oppgradering og integrering av Kundens tekniske plattform er ikke en del
                av denne avtalen. For senere teknisk veiledning og andre henvendelser om bruk av verktøyet, kontakt:{' '}
                <a href="mailto:support@multimap.no">support@multimap.no</a>
              </p>

              <h4>5. Vederlag</h4>
              <h4>5.1 Abonnementsavgift</h4>
              <p>Tilgang på produkter og abonnementsavgift er spesifisert i valgte produktbeskrivelse.</p>

              <h4>5.2 Prisstigning</h4>
              <p>
                Med mindre annet er avtalt, prisjusteres abonnementsavgiften hvert år iht KPI (hovedindeksen), første
                gang med utgangspunkt i indeksen for den måned avtalen ble signert.
              </p>

              <h4>6. Betaling</h4>
              <p>
                Avtalens faste vederlag vil være gjenstand for årlig forskuddsvis fakturering. Betalingsfrist er 30
                dager etter fakturadato.
              </p>
              <p>
                Kunden må spesifisere og begrunne eventuelle innsigelser til Leverandørens faktura uten ugrunnet
                opphold. Gjør han ikke det legges fakturaen til grunn for oppgjøret.
              </p>
              <p>
                Ved forsinket betaling regnes renter etter «lov om rente ved forsinket betaling». Leverandøren har rett
                til å holde tilbake tilgang til programvaren ved forsinket betaling.
              </p>
              <p>
                Dersom forfalt uomtvistet vederlag med tillegg av forsinkelsesrenter ikke er betalt innen 30 (tretti)
                kalenderdager fra forfall, kan Leverandøren sende skriftlig varsel til Kunden om at avtalen vil bli
                hevet dersom oppgjør ikke er skjedd innen 30 (tretti) kalenderdager etter at varselet er mottatt. Heving
                kan ikke skje hvis Kunden gjør opp forfalt vederlag med tillegg av forsinkelsesrenter innen fristens
                utløp.
              </p>

              <h4>7. Eiendoms- og bruksrett</h4>
              <p>
                Leverandøren har eiendomsrett til produktene og innholdet, herunder programvaren, metodikken og
                veiledningsmateriell benyttet i multiMap. Leverandøren beholder opphavsretten til tilpasninger som
                utvikles spesielt for Kunden med mindre annet er avtalt i det enkelte tilfellet.
              </p>
              <p>
                Leverandøren har bruksrett på aggregert informasjon av materialet som lagres i multiMaps database. Denne
                bruksretten gjelder kun for bruk av anonymisert informasjon til for eksempel nasjonale rapporter.
              </p>
              <p>
                For øvrig har Kunden eiendomsrett til data som de selv har lagt inn i systemet og beholder disse dataene
                etter eventuell oppsigelse av avtalen. Kunden har kun disposisjonsrett til verktøyet så lenge Kunden er
                abonnent på multiMap.
              </p>

              <h4>8. Lagring og tilgang til data</h4>
              <h4>8.1 Personsensitive data</h4>
              <p>
                Data som samles inn gjennom multiMap inneholder ikke sensitive persondata. Leverandøren tar ikke ansvar
                for tekster som legges inn i fritekstfelter.
              </p>

              <h4>8.2 Lokasjon for lagring av data</h4>
              <p>
                multiMap benytter Microsoft sine skytjenester for lagring av data. Data relatert til eiendommene ligger
                på Azure SQL Servere, og databaser. Alle nøkler mellom applikasjonene og databaser lagres og håndteres i
                "Key Vault". Innlogging håndteres av "Azure Active Directory B2C".
              </p>
              <p>Ingen data eller informasjon går utenfor Norge.</p>

              <h4>8.3 Tilgangsstyring</h4>
              <p>
                Tilgang til data er styrt iht til tilgangshierarki og formålstjenlige aktiviteter knyttet til multiMap.
              </p>

              <h4>8.4 Tredjeparter</h4>
              <p>
                multiMap benytter seg av eksterne konsulenter til utvikling av programvaren. Konsulenter vil få tilgang
                til data innenfor definerte tidsperioder og kun til det formål å utvikle på løsningen.
              </p>

              <h4>9. Feilmelding og -retting</h4>
              <p>
                Kunden plikter å melde ifra uten ugrunnet opphold dersom feil oppdages i systemet til{' '}
                <a href="mailto:multimap@multiconsult.no">multimap@multiconsult.no</a>.
              </p>
              <p>Ved mottak av feilmelding skal Leverandøren følge opp og rette opp feilen så snart som mulig.</p>

              <h4>10. Ansvar og erstatningsbegrensning</h4>
              <p>
                Dersom Kunden mener å ha et krav mot Leverandøren skal dette varsles skriftlig uten ugrunnet opphold
                etter at misligholdet er oppdaget eller burde vært oppdaget. Gjør han ikke det, tapes retten til å
                påberope seg feilen.
              </p>
              <p>
                Leverandøren er ikke ansvarlig for merkostnader eller annet økonomisk tap som oppstår som følge av tap
                av data, eller som følge av feil i tredjepartsleveranser som oppstår etter avtaleinngåelse (eksempelvis
                nedetid fra Microsoft eller liknende).
              </p>
              <p>Leverandøren er heller ikke ansvarlig for indirekte tap.</p>
              <p>
                Samlet erstatning i avtaleperioden er begrenset til et beløp som tilsvarer den årlige
                abonnementsavgiften eksklusiv merverdiavgift.
              </p>

              <h4>11. Oppsigelse av avtalen</h4>
              <p>
                Partene kan si opp avtalen ved skriftlig varsel til den annen part senest 1 måned før dato for fornyelse
                av abonnement. Dato for fornyelse er spesifisert i abonnementsavtalen.
              </p>
              <p>
                Ved oppsigelse løper abonnementet ut daværende abonnementsperiode. Det er ikke anledning til å kreve
                tilbakebetalt deler av abonnementsavgiften ved sluttføring av abonnementet før dette.
              </p>
              <p>Ved oppsigelse av avtalen kan Kunden, ved forespørsel, få utlevert alle sine kartlagte data.</p>

              <h4>12. Avgifter</h4>
              <p>Avtalt abonnementsavgift tillegges merverdiavgift etter de til enhver tid gjeldende satser.</p>

              <h4>13. Lovvalg og verneting</h4>
              <p>
                Avtalen er underlagt norsk rett, og Leverandørens verneting gjelder som verneting for alle tvister som
                springer ut av avtalen.
              </p>
              <hr></hr>
              <Form.Check
                className="mt-4 fw-bold"
                type="checkbox"
                id="repsonsiblePersonConsent"
                name="responsiblePersonConsent"
                key="responsiblePersonConsent"
                form="repsonsiblePersonConsent"
                label="Jeg godtar multiMaps avtalevilkår og betingelser. Jeg har tillatelse til å inngå avtalen på vegne av min arbeidsgiver/mitt
            selskap."
                checked={acceptedAgreement}
                onChange={(e) => setAcceptedAgreement(e.target.checked)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

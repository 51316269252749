import { Language, TextLanguageResponse } from 'common/api/multimap';
import { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { withLangs } from '../../helpers/withLangs';

export interface IProps {
  show: boolean;
  langs: Language[];
  texts: TextLanguageResponse[];
  multiline: boolean;
  onHide: () => void;
  hasData: (data: TextLanguageResponse[]) => void;
}

export const UpdateTextModal: React.FC<IProps> = ({ show, langs, texts, multiline, onHide, hasData }) => {
  const { t: ts } = useTranslation('shared');
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes.detail.updateTextModal' });

  const [data, setData] = useState(texts);
  const [isChanged, setIsChanged] = useState(false);

  return (
    <>
      <Modal show={show} onHide={onHide} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>{t('title')}</Modal.Title>
        </Modal.Header>

        <Container fluid className="p-3">
          <Form>
            {langs.map((lang) => (
              <Row key={lang} className="mb-3">
                <Col xs={3}>
                  <Form.Label className="mt-2">{lang}</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    id="text"
                    aria-describedby="text"
                    as={multiline ? 'textarea' : 'input'}
                    value={withLangs(data).get(lang).text}
                    onChange={(e) => {
                      setData(withLangs(data).update(e.target.value, lang));
                      setIsChanged(true);
                    }}
                  />
                </Col>
              </Row>
            ))}

            <div className="w-100 d-flex justify-content-end">
              <Button
                type="button"
                variant="primary"
                size="sm"
                className="my-3 me-2"
                disabled={!isChanged}
                onClick={() => hasData(data)}
              >
                {ts('actionSave')}
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

import { WorkspaceImportVerifyDetailResponse } from 'common/api/multimap';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import React from 'react';
import { Table } from 'react-bootstrap';

import { ValidationError } from './ValidationError';

type IProps = {
  errors: WorkspaceImportVerifyDetailResponse[];
};

export const ImportErrorsResult: React.FC<IProps> = ({ errors }) => {
  return (
    <>
      <ValidationError message="Noen rader har ugyldige verdier" />

      <TableContainer>
        <Table hover responsive className="caption-top">
          <caption>Ugyldige verdier</caption>
          <thead>
            <tr>
              <th scope="col" className="text-nowrap">
                Rad
              </th>
              <th scope="col" className="text-nowrap">
                Felt
              </th>
              <th scope="col" className="text-nowrap">
                Verdi
              </th>
              <th scope="col" className="text-nowrap">
                Beskrivelse
              </th>
            </tr>
          </thead>
          <tbody>
            {errors
              .sort((v1, v2) => (v1.rowIndex ?? 0) - (v2.rowIndex ?? 0))
              .map((x, i) => {
                return (
                  <tr key={`${x.rowIndex}-${i}`}>
                    <td>{x.rowIndex === null || x.rowIndex === undefined ? '' : x.rowIndex + 2}</td>
                    <td>{x.field}</td>
                    <td>{x.newValue}</td>
                    <td>{x.message}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ObjectTypeGroup } from 'common/api/multimap';
import { TileButton } from 'common/components/TileButton/TileButton';
import { objectTypeGroups } from 'features/admin/object-types/helpers/objectTypeGroups';
import useObjectGroupIcon from 'features/admin/object-types/hooks/useObjectGroupIcon';
import useObjectGroupTranslation from 'features/admin/object-types/hooks/useObjectGroupTranslation';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type IProps = {
  onConfirm: (group: ObjectTypeGroup) => void;
};

export const CreateWizardStep1: React.FC<IProps> = ({ onConfirm }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.create.createWizard.createWizardStep1' });
  const { t: tg } = useObjectGroupTranslation();
  const [iconResolver] = useObjectGroupIcon();

  return (
    <>
      <p className="larger">{t('title')}</p>

      {objectTypeGroups.map((group) => (
        <Row className="mb-3" key={group}>
          <Col sm={12}>
            <TileButton
              key={group}
              title={tg(group)}
              text="?"
              onClick={() => onConfirm(group)}
              leftIcon={iconResolver(group)}
              rightIcon={<ArrowForwardIcon />}
            />
          </Col>
        </Row>
      ))}
    </>
  );
};

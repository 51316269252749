import { AxiosError } from 'axios';
import { mappingSetsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { CreateMappingSetRequest, MappingSetDetailResponse, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

function useAddMappingSet(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  MappingSetDetailResponse | undefined,
  (createMappingSetRequest: CreateMappingSetRequest) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    (mappingSetRequest: CreateMappingSetRequest) => {
      return mappingSetsApi.mappingSetsPost(mappingSetRequest).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.MappingSets);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const add = (request: CreateMappingSetRequest) => mutate(request);

  return [isLoading, isError, validationError, data, add];
}

export default useAddMappingSet;

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExtensionIcon from '@mui/icons-material/Extension';
import GrassIcon from '@mui/icons-material/Grass';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ObjectTypeGroup } from 'common/api/multimap';
import React from 'react';

function useObjectGroupIcon(): [(group: ObjectTypeGroup) => JSX.Element] {
  const objectGroupIcons: Record<ObjectTypeGroup, JSX.Element> = {
    Unknown: <></>,
    Eiendom: <HomeWorkIcon></HomeWorkIcon>,
    Park: <GrassIcon />,
    Vei: <DirectionsCarIcon />,
    Annet: <ExtensionIcon />,
    Internal: <RemoveRedEyeIcon />,
  };

  const resolver = (group: ObjectTypeGroup) => {
    return objectGroupIcons[group];
  };

  return [resolver];
}

export default useObjectGroupIcon;

import { Language, TextLanguageResponse } from 'common/api/multimap';

type IWithLangs = {
  defaultText: TextLanguageResponse;
  update: (text: string, lang: Language) => TextLanguageResponse[];
  get: (lang: Language) => TextLanguageResponse;
};

export const withLangs = (texts: TextLanguageResponse[]): IWithLangs => {
  const w = texts.find((text) => text.language === 'Norwegian') ?? {
    language: 'Norwegian',
    text: '',
  };

  const update = (text: string, lang: Language) => {
    const data = [...texts];
    let idx = data.findIndex((x) => x.language === lang);
    if (idx === -1) {
      idx =
        data.push({
          language: lang,
          text: '',
        }) - 1;
    }
    data[idx].text = text;
    return data;
  };

  const get = (lang: Language) => {
    const idx = texts.find((x) => x.language === lang);

    return (
      idx ?? {
        language: lang,
        text: '',
      }
    );
  };

  return { defaultText: w, update: update, get };
};

import { AxiosError } from 'axios';
import { organizationsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { OrganizationServiceTypeResponse, ValidationProblemDetails } from 'common/api/multimap/api';
import { Guid } from 'common/types/guid.type';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type CreateParams = {
  organizationId: string;
  organizationServiceTypeId: Guid;
};

function useDeleteOrganizationServiceType(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  OrganizationServiceTypeResponse[] | undefined,
  (params: CreateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    async ({ organizationId, organizationServiceTypeId }: CreateParams) => {
      return organizationsApi
        .organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete(
          organizationId,
          organizationServiceTypeId,
        )
        .then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.ServiceTypes);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const remove = (params: CreateParams) => mutate(params);

  return [isLoading, isError, validationError, data, remove];
}

export default useDeleteOrganizationServiceType;

import {
  ObjectPropertyResponse,
  WorkspaceDetailLevelPropertyResponse,
  WorkspaceDetailLevelPropertyUpdateRequest,
} from 'common/api/multimap';

export const toUpdateRequest = (
  properties: ObjectPropertyResponse[],
  value: WorkspaceDetailLevelPropertyResponse,
): WorkspaceDetailLevelPropertyUpdateRequest => {
  const p = properties.find((x) => x.objectPropertyId === value.objectPropertyId);
  if (!p) {
    throw new Error('Unknown property');
  }

  return {
    objectPropertyId: value.objectPropertyId,
    valueString: p.datatypeId === 'Address' || p.datatypeId === 'String' ? value.valueString : undefined,
    valueInteger: p.datatypeId === 'Integer' ? value.valueInteger : undefined,
    valueDecimal: p.datatypeId === 'Float' ? value.valueDecimal : undefined,
    valueOptionReferenceId: p.datatypeId === 'OptionSet' ? value.valueOptionReferenceId : undefined,
  };
};

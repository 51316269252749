import { Form } from 'react-bootstrap';
import {
  // eslint-disable-next-line import/named
  FieldError
} from 'react-hook-form';

export const FormFieldError = ({
  fieldError,
  minValue,
  maxValue,
}: {
  fieldError?: FieldError;
  minValue?: number | null;
  maxValue?: number | null;
}) => {
  if (!fieldError || !fieldError.type) {
    return <></>;
  }

  let msg = '';
  if (fieldError.type === 'required') {
    msg = fieldError.message || 'Dette feltet er obligatorisk';
  } else if (fieldError.type === 'validate') {
    msg = fieldError.message || 'Ugyldig verdi';
  } else if (fieldError.type === 'min' || fieldError.type === 'max') {
    if ((maxValue ?? null) !== null && (minValue ?? null) !== null) {
      msg = fieldError.message || `Gyldig verdi er mellom ${minValue} og ${maxValue}`;
    } else if ((maxValue ?? null) !== null) {
      msg = fieldError.message || `Maksimum gyldige verdien er ${maxValue}`;
    } else if ((minValue ?? null) !== null) {
      msg = fieldError.message || `Minste gyldige verdi er ${minValue}`;
    }
  }
  if (!msg) {
    msg = 'Ugyldig verdi';
  }

  return (
    <>
      <Form.Control.Feedback type="invalid">{msg}</Form.Control.Feedback>
    </>
  );
};

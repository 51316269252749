import { workspacesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { WorkspaceDetailResponse } from 'common/api/multimap/api';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetWorkspaceDetail(
  id: Guid,
): [boolean, boolean, WorkspaceDetailResponse | undefined, () => Promise<WorkspaceDetailResponse | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.Workspaces, id],
    () => workspacesApi.getWorkspaceByIdRoute(id).then((x) => x.data),
    {
      enabled: !!id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetWorkspaceDetail;

import React from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner';
import { Create } from './Create';
//import { Create } from './Create';
import { Upsert } from './Upsert';

export const Detail = () => {
  const { id } = useParams();

  return id ? <Upsert id={id} /> : <Create />;
};

import { ObjectPropertyResponse } from 'common/api/multimap';
import { ErrorPage } from 'common/components/error-page/ErrorPage';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';

import useGetObjectTypeDetail from '../hooks/useGetObjectTypeDetail';
import { UpsertDetail } from './UpsertDetail';

export interface IProps {
  id: Guid;
  properties: ObjectPropertyResponse[];
}

export const Upsert: React.FC<IProps> = ({ id, properties }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes.detail' });

  const [isLoading, , detail] = useGetObjectTypeDetail(id);

  if (!isLoading && !detail) {
    return <ErrorPage reason={404} />;
  }

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />

      {detail && <UpsertDetail mode={'edit'} title={t('editTitle')} detail={detail} properties={properties} />}
    </>
  );
};

import { PageTitle } from 'common/components/page-title/PageTitle';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetLearning from 'features/admin/mapping-sets/hooks/useGetLearning';
import { Button, Modal } from 'react-bootstrap';

interface ReadMore {
  id: string;
  show: boolean;
  onHide: () => void;
}

export const ReadMoreModal: React.FC<ReadMore> = ({ id, show, onHide }) => {
  const [isLoading, , data] = useGetLearning(id);

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  console.log(data);

  return (
    <Modal size="lg" centered show={show} onHide={onHide}>
      {data && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{data.mappingSetName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data.videoPath !== '' && (
              <>
                <iframe
                  width="560"
                  height="315"
                  src={data.videoPath}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <br></br>
                <br></br>
              </>
            )}
            <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="tertiary" onClick={onHide}>
              Lukk
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

import { ObjectPropertyResponse, ObjectTypeDetailResponse } from 'common/api/multimap';
import { useTranslation } from 'react-i18next';

import { UpsertDetail } from './UpsertDetail';

export interface IProps {
  properties: ObjectPropertyResponse[];
}

export const Create: React.FC<IProps> = ({ properties }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes.detail' });

  const detail: ObjectTypeDetailResponse = {
    group: 'Internal',
    systemName: '',
    systemDescription: '',
    name: [],
    description: [],
    objectTypeId: '',
    levels: [],
  };
  return <UpsertDetail mode={'create'} title={t('createTitle')} detail={detail} properties={properties} />;
};

import ErrorIcon from '@mui/icons-material/Error';
import { ValidationProblemDetails } from 'common/api/multimap';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface IProps {
  error: ValidationProblemDetails | undefined;
  handleClose: () => void;
}

export const AccessFailed: React.FC<IProps> = ({ error, handleClose }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'features.user.redeemInvitation.components.accessFailed',
  });
  return (
    <Container fluid className="h-100 d-flex flex-column">
      <Row className="justify-content-center flex-fill">
        <Col sm={12} md={7} className="text-center align-self-center">
          <ErrorIcon className={`mb-4 size-xxl`} />
          <h2 className="m-4">{t('title')}</h2>
          <p className="m-4"> {error?.genericErrors?.[0]} </p>
        </Col>
      </Row>
      <Row className="align-self-end w-100">
        <Col sm={12} className="text-center">
          <Button variant="primary" className="m-4" onClick={handleClose}>
            {t('btnClose')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

import { mappingSetsApi, objectMappingsApi, reportsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  MappingDataReportResponse,
  MappingObjectResponse,
  MappingPeriodResponse,
  MappingSetResponse,
  WorkspaceModuleResponse,
} from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetMappingDataReport(
  id: Guid,
): [boolean, boolean, MappingDataReportResponse | undefined, () => Promise<MappingDataReportResponse | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.Reports, 'mapping-data-report: ' + id],
    () => reportsApi.reportsPeriodIdPeriodMappingDataGet(id).then((x) => x.data),
    {
      enabled: false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetMappingDataReport;

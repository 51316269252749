import { AxiosError } from 'axios';
import { mappingSetsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  CreateLearningRequest,
  CreateMappingSetRequest,
  LearningResponse,
  MappingSetDetailResponse,
  ValidationProblemDetails,
} from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

function useAddLearning(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  LearningResponse | undefined,
  (createLearningRequest: CreateLearningRequest) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    (mappingSetRequest: CreateLearningRequest) => {
      return mappingSetsApi.mappingSetsIdLearningPost('123', mappingSetRequest).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.Learning);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const add = (request: CreateMappingSetRequest) => mutate(request);

  return [isLoading, isError, validationError, data, add];
}

export default useAddLearning;

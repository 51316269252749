import { SearchOutlined } from '@mui/icons-material';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import React, { useState } from 'react';
import { Button, Form, InputGroup, Stack, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LoadingSpinner } from '../components/loading-spinner/LoadingSpinner';
import useGetOrganizations from './hooks/useGetOrganizations';

export const Organizations: React.FC = () => {
  const [isLoading, , data] = useGetOrganizations();

  const { t } = useTranslation('admin', { keyPrefix: 'organizations.organizations' });

  const [filter, setFilter] = useState<string>('');

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <>
      <PageTitle title={t('title')}></PageTitle>
      <TableContainer>
        <Stack direction="horizontal" className="table-filter">
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder={'Søk på organisasjon'}
              aria-describedby={'Søk på organisasjon'}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </Stack>

        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">{t('tableHeaders.name')}</th>
              <th scope="col">{t('tableHeaders.organizationNumber')}</th>
              <th scope="col">{t('tableHeaders.status')}</th>
              <th scope="col">Antall brukere</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .filter((x) => x.name?.toLowerCase().includes(filter.toLowerCase()))
                .sort((a, b) => {
                  const textA = a.name ? a.name.toUpperCase() : '';
                  const textB = b.name ? b.name.toUpperCase() : '';
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                })
                .map((row, i) => {
                  // const orgnr = organizationNumberFormatter(row.orgnr);
                  return (
                    <tr key={i}>
                      <td>
                        <span>{row.name}</span>
                      </td>
                      <td>
                        <span>{row.organizationNumber}</span>
                      </td>
                      <td>
                        <span>Aktiv</span>
                      </td>
                      <td>
                        <span>{row.userCount}</span>
                      </td>
                      <td>
                        <Link className="text-nowrap" to={`/admin/organizations/${row.organizationId}`}>
                          {t('btnDetails')}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};

import { useMsal } from '@azure/msal-react';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { createResetPasswordRequest } from '../../../../../auth/authConfig';

export const Profile = () => {
  const { instance, accounts } = useMsal();
  const { t } = useTranslation();

  const handleResetPassword = () => {
    instance.loginRedirect(createResetPasswordRequest());
  };

  return (
    <>
      <PageTitle title={t('profile-title')} />
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>{t('fullname')}</Form.Label>
          <Form.Control type="text" value={accounts[0].name} disabled />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>{t('email')}</Form.Label>
          <Form.Control type="email" value={(accounts[0]?.idTokenClaims?.email || '') + ''} disabled />
        </Form.Group>
      </Form>
      {/* <SecondaryButton text={t('edit-password')} onClickMethod={handleResetPassword} /> */}
      <Button variant="secondary" onClick={handleResetPassword}>
        {t('edit-password')}
      </Button>
    </>
  );
};

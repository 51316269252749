import React, { forwardRef } from 'react';
// eslint-disable-next-line import/named
import { Badge, BadgeProps } from 'react-bootstrap';

export interface IProps extends BadgeProps {
  // based on: \node_modules\react-bootstrap\esm\types.d.ts
  variant: 'primary' | 'secondary' | 'tertiary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
}

export const Pill = forwardRef<typeof Badge, IProps>(({ variant, ...props }, ref) => (
  <Badge pill bg={variant} {...props} ref={ref} />
));

Pill.displayName = 'Pill';

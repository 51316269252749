import { ErrorMessage } from '@hookform/error-message';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { MappingGroupResponse } from 'common/api/multimap';
import { ErrorPage } from 'common/components/error-page/ErrorPage';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { PageNavTitles } from 'features/admin/modules/components/PageNavTitles';
import { useEffect, useState } from 'react';
import { Accordion, Button, Col, Form, Row, Table } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useGetMappingSetDetail from '../hooks/useGetMappingSet';
import useUpdateMappingSet from '../hooks/useUpdateMappingSet';
import styles from '../mapping-sets.module.scss';
import { Parameters } from './Parameters';

export interface IProps {
  id: Guid;
}

export interface IAlertProps {
  when: boolean;
  message: string;
}

export const Upsert: React.FC<IProps> = ({ id }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'mappingSets.detail' });

  const [isLoading, , data] = useGetMappingSetDetail(id);

  const [, , , , update] = useUpdateMappingSet();

  const [editPrimaryComponents, setEditPrimaryComponents] = useState(false);
  const [editUnderComponents, setEditUnderComponents] = useState(false);

  const [changed, setChanged] = useState(false);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    fields: groups,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'mappingGroups', // unique name for your Field Array
  });

  useEffect(() => {
    if (data) {
      reset({
        mappingSetId: data.mappingSetId,
        systemName: data.systemName,
        mappingGroups: data.mappingGroups?.sort((a, b) => a.sortOrder - b.sortOrder),
      });
    }
  }, [data, reset]);

  const updateMappingSet = async (d: FieldValues) => {
    update({ id: id, request: d });

    setEditPrimaryComponents(false);
    setEditUnderComponents(false);
    setChanged(false);
  };

  if (!isLoading && !data) {
    return <ErrorPage reason={404} />;
  }

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />

      <PageNavTitles
        titles={[
          { title: 'Rediger parametersett', link: `/admin/mapping-sets/${id}/detail`, active: true },
          { title: 'Opplæring', link: `/admin/mapping-sets/${id}/detail/learning` },
        ]}
        backTo={`/admin/mapping-sets`}
      >
        <Form.Switch
          inline
          id="enable-editing-main"
          label={t('editMain')}
          onChange={(e) => {
            setEditPrimaryComponents(e.target.checked);
            if (editUnderComponents) {
              setEditUnderComponents(!e.target.checked);
            }
          }}
          checked={editPrimaryComponents}
        />

        <Form.Switch
          inline
          id="enable-editing-tree"
          label={t('editUnder')}
          onChange={(e) => {
            setEditUnderComponents(e.target.checked);
            if (editPrimaryComponents) {
              setEditPrimaryComponents(!e.target.checked);
            }
          }}
          checked={editUnderComponents}
        />

        <Form.Switch inline id="enable-editing-tree" label={t('editLabels')} onChange={(e) => {}} />

        <Button type="submit" variant="primary" size="sm" className="mx-2" disabled={!changed} form="form">
          {t('saveButton')}
        </Button>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            setChanged(false);
            reset();
            setEditPrimaryComponents(false);
            setEditUnderComponents(false);
          }}
          disabled={!changed}
        >
          {t('cancelButton')}
        </Button>
      </PageNavTitles>
      <br></br>
      <Form
        onSubmit={handleSubmit((d) => updateMappingSet(d))}
        onChange={() => {
          setChanged(true);
        }}
        id="form"
      >
        <Row>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>{t('mappingSetNameLabel')}</Form.Label>
              <Form.Control
                readOnly={!editPrimaryComponents}
                {...register('systemName', { required: 'Du må gi parametersettet et navn.' })}
                isInvalid={!!errors.systemName}
              ></Form.Control>
              <Form.Text className="text-danger">
                <ErrorMessage errors={errors} name="systemName" />
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col xs={12}>
            <h4>{t('mappingGroupsLabel')}</h4>
            {editPrimaryComponents ? (
              <>
                <Row>
                  <Col md={1}>
                    <Form.Label>{t('sortingLabel')}</Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>{t('editMappingGroupLabel')}</Form.Label>
                  </Col>
                </Row>
                {data &&
                  getValues(`mappingGroups`)
                    ?.sort((a: MappingGroupResponse, b: MappingGroupResponse) => {
                      return a.sortOrder - b.sortOrder;
                    })
                    .map((row: MappingGroupResponse, index: number) => (
                      <Row className="mb-2" key={row.mappingGroupId}>
                        <Col md={1}>
                          <Form.Control
                            type="number"
                            {...register(`mappingGroups.${index}.sortOrder`, { required: 'Verdi her' })}
                            isInvalid={!!errors.mappingGroups?.[index]}
                          ></Form.Control>
                          <Form.Text className="text-danger">
                            <ErrorMessage errors={errors} name={`mappingGroups.${index}.sortOrder`} />
                          </Form.Text>
                        </Col>
                        <Col>
                          <Form.Control type="text" {...register(`mappingGroups.${index}.systemName`)}></Form.Control>
                        </Col>
                        <Col md={1}>
                          <Button
                            variant="link"
                            className="btn-sm"
                            onClick={() => {
                              removeGroup(index);
                            }}
                          >
                            <RemoveCircleOutlinedIcon />
                          </Button>
                        </Col>
                      </Row>
                    ))}
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    appendGroup({ sortOrder: groups.length + 1, systemName: '', mappingParameters: [] });
                  }}
                >
                  <AddCircleOutlineOutlinedIcon /> {t('addMappingGroup')}
                </Button>
              </>
            ) : (
              <>
                <Accordion className={styles.accordion} alwaysOpen>
                  {data &&
                    getValues(`mappingGroups`)
                      ?.sort((a: MappingGroupResponse, b: MappingGroupResponse) => {
                        return a.sortOrder - b.sortOrder;
                      })
                      .map((row: MappingGroupResponse, index: number) => (
                        <Accordion.Item key={index} eventKey={`${index}`} className="border-0">
                          <Accordion.Header className={styles['accordion-header']}>
                            <span>{getValues(`mappingGroups.${index}.systemName`)}</span>
                          </Accordion.Header>
                          <Accordion.Body className="px-0 py-3">
                            <Parameters
                              nestIndex={index}
                              control={control}
                              errors={errors}
                              register={register}
                              getValues={getValues}
                              editParameters={editUnderComponents}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                </Accordion>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

import { Select } from 'common/components/form/input/Select';
import { Form as GenericForm } from 'common/form/hooks/useGenericForm';
import { ReactNode } from 'react';
// TODO: Find a workaround for failing export check
// eslint-disable-next-line import/named
import { Controller, Path } from 'react-hook-form';

type FormSelectProps<T> = {
  name: Path<T>;
  height?: string;
  labelText?: string;
  placeholder?: string;
  disabled?: boolean;
  hidden?: boolean;
  form: GenericForm<T>;
  children?: ReactNode[];
};

function FormSelect<T>({ name, labelText, placeholder, disabled, form, hidden, children }: FormSelectProps<T>) {
  const { isLoading, methods, formProps } = form;
  const val: string | number | readonly string[] | undefined = (formProps.model &&
    formProps.model[name.toString() as keyof typeof formProps.model]) as
    | string
    | number
    | readonly string[]
    | undefined;

  const invalidMessages: string[] = [];
  Object.entries(formProps.errorDetails?.errors ?? []).forEach(([key, value]) => {
    if (key.toLocaleLowerCase() === name.toString().toLocaleLowerCase()) {
      value.forEach((err) => {
        if (!err) {
          return;
        }
        invalidMessages.push(err);
      });
    }
  });
  const isInvalid = invalidMessages.length > 0;

  // const validationErrors = formProps.errorDetails?.errors?.[name.toString()] ?? [];
  // const isInvalid = validationErrors?.some((x: string) => x) ?? false;

  return (
    <Controller
      control={methods.control}
      name={name}
      render={({ field: { onChange, onBlur, ref } }) => (
        <Select
          onChange={onChange}
          labelText={labelText}
          ref={ref}
          onBlur={onBlur}
          isInvalid={isInvalid}
          invalidMessages={invalidMessages}
          placeholder={placeholder}
          defaultValue={val}
          disabled={disabled || isLoading}
          name={name}
          hidden={hidden}
        >
          {children}
        </Select>
      )}
    />
  );
}

export default FormSelect;

import { AxiosError } from 'axios';
import { userInvitationsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { CreateUserInvitationRequest, UserInvitationResponse, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

function useCreateInvitation(): [
  boolean,
  boolean,
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  UserInvitationResponse | undefined,
  (request: CreateUserInvitationRequest) => void,
  () => void,
] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const queryClient = useQueryClient();
  const { isLoading, isIdle, isSuccess, isError, data, mutate, reset } = useMutation(
    (request: CreateUserInvitationRequest) => {
      return userInvitationsApi.createInvitation(request).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.Invitations);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const create = (request: CreateUserInvitationRequest) => mutate(request);
  const resetState = () => {
    reset();
    setValidationError(undefined);
  };

  return [isLoading, isIdle, isSuccess, isError, validationError, data, create, resetState];
}
export default useCreateInvitation;

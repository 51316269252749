import { Language } from 'common/api/multimap';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { SupportedLanguage } from './SupportedLanguage';
import adminEN from './en/admin.json';
import navigationEN from './en/navigation.json';
import sharedEN from './en/shared.json';
import translationEN from './en/translation.json';
import adminNB from './nb/admin.json';
import navigationNB from './nb/navigation.json';
import sharedNB from './nb/shared.json';
import translationNB from './nb/translation.json';

export const defaultLanguage: SupportedLanguage = {
  displayName: 'Norsk',
  id: Language.Norwegian,
  shortName: 'nb',
  shortDisplayName: 'no',
};

export const supportedLanguages: SupportedLanguage[] = [
  defaultLanguage,
  {
    displayName: 'English',
    id: Language.English,
    shortName: 'en',
    shortDisplayName: 'en',
  },
];

declare module 'react-i18next' {
  interface CustomTypeOptions {
    resources: {
      admin: typeof adminNB;
      shared: typeof sharedNB;
      translation: typeof translationNB;
      navigation: typeof navigationNB;
    };
  }
}

export const resources = {
  [Language[Language.English]]: {
    admin: adminEN,
    shared: sharedEN,
    translation: translationEN,
    navigation: navigationEN,
  },
  [Language[Language.Norwegian]]: {
    admin: adminNB,
    shared: sharedNB,
    translation: translationNB,
    navigation: navigationNB,
  },
} as const;

i18n.use(initReactI18next).init({
  // debug: Environment.Current !== 'production',
  debug: ENV !== 'production',
  ns: ['admin', 'shared', 'translation', 'navigation'],
  resources,
  fallbackLng: defaultLanguage.id,
});

export default i18n;

import useAddOrganizationServiceType from 'common/api/hooks/useAddOrganizationServiceType';
import useGetOrganizationServiceTypes from 'common/api/hooks/useGetOrganizationServiceTypes';
import { OrganizationServiceTypeResponse, WorkspaceResponse } from 'common/api/multimap';
import { Pill } from 'common/components/Pill/Pill';
import { TileButton } from 'common/components/TileButton/TileButton';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetAllWorkspaceModules from 'features/user/mapping/hooks/useGetAllWorkspaceModules';
import { useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router';

import { displayIcon } from '../../../admin/modules/components/ModuleIcons';

export const MaintenancePlanModules = () => {
  const [workspace] = useLocalStorage<WorkspaceResponse | undefined>('workspace', undefined);

  const [isLoading, , data] = useGetAllWorkspaceModules(workspace?.workspaceId ?? '');
  const [serviceTypeLoading, , serviceTypes] = useGetOrganizationServiceTypes(workspace?.organizationId ?? '');

  const [, , , result, add] = useAddOrganizationServiceType();

  const [showSubscribeModal, setShowSubscribeModal] = useState<boolean>(false);

  const findPlanningServiceType = (services: OrganizationServiceTypeResponse[]) => {
    const notEmpty = services.filter((x) => x.serviceType == 'Planning');

    if (notEmpty.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <PageTitle title="Tiltak- og vedlikeholdsplan" />
      {serviceTypeLoading ? (
        <LoadingSpinner isLoading={serviceTypeLoading} />
      ) : (
        <>
          {serviceTypes && findPlanningServiceType(serviceTypes) ? (
            <>
              <h4>Mine moduler</h4>

              {data && data.length > 0 ? (
                data.map((row, i) => {
                  if (row.hasPreviousMapping) {
                    return (
                      <Card key={i} className="mb-3">
                        <Row className="g-0">
                          <Col xs={1}>
                            <div className="w-100 h-100 bg-primary d-flex justify-content-center align-items-center rounded-start">
                              {displayIcon(row.icon)}
                            </div>
                          </Col>
                          <Col xs={11} className="p-3">
                            <Row>
                              <Col>
                                <h4>{row.systemName}</h4>
                                <span>{row.systemDescription}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex flex-row justify-content-end align-items-end pb-3">
                                <Button
                                  variant="secondary"
                                  onClick={() => navigate(`/maintenance-plan/${row.workspaceModuleId}/overview`)}
                                >
                                  Bruk modul
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    );
                  } else {
                    return (
                      <Row className="g-0 rounded pt-3 mb-3" style={{ border: '1px dashed #ABBED1' }}>
                        <>
                          <Col xs={8} className="p-3">
                            <h4>{row.systemName}</h4>
                          </Col>
                          <Col xs={4} className="p-3 d-flex justify-content-end align-items-end">
                            <Pill variant="danger">Har ikke vært kartlagt tidligere</Pill>
                          </Col>
                        </>
                      </Row>
                    );
                  }
                })
              ) : (
                <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                  <>
                    <Col xs={8} className="p-3">
                      <h4>Du har ingen moduler</h4>
                      <span>Du har ingen kartleggingsmoduler aktive.</span>
                    </Col>
                  </>
                </Row>
              )}
              <LoadingSpinner isLoading={isLoading} />
            </>
          ) : (
            <>
              <p>
                En omfattende plan for tiltak og vedlikehold gir retningslinjer som er avgjørende for organisasjonens
                samlede drift. Den tjener som en veiviser for ressurspersoner som skal definere og rangere prioriteter
                blant tiltakene. I tillegg til å være til nytte for interne aktører, gir denne planen også klarhet for
                andre interessenter angående omfanget av tiltaks- og vedlikeholdsplanen.
              </p>
              <p>
                Normalt sett strekker en slik plan seg over et tiårsperspektiv. For å sikre at den forblir tilpasset
                gjeldende krav og endringer i porteføljen, anbefales det å gjennomgå og oppdatere den årlig. Dette
                bidrar til kontinuerlig oversikt over porteføljen og de tilknyttede kostnadene, og sikrer at planen til
                enhver tid er i samsvar med organisasjonens behov.
              </p>
              <p className="fw-bold">Pris: 49 500,- NOK per år</p>
              <Button variant="secondary" onClick={() => setShowSubscribeModal(true)}>
                Bestill tiltak- og vedlikeholdsplan
              </Button>
            </>
          )}
        </>
      )}
      <Modal show={showSubscribeModal} onHide={() => setShowSubscribeModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vil du abonnere på tiltak- og vedlikeholdsplan?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Modulens faste vederlag vil være gjenstand for årlig forskuddsvis fakturering. Betalingsfrist er 30 dager
          etter fakturadato. Kunden må spesifisere og begrunne eventuelle innsigelser til Leverandørens faktura uten
          ugrunnet opphold. Gjør han ikke det legges fakturaen til grunn for oppgjøret. Ved forsinket betaling regnes
          renter etter «lov om rente ved forsinket betaling». Leverandøren har rett til å holde tilbake tilgang til
          programvaren ved forsinket betaling. Dersom forfalt uomtvistet vederlag med tillegg av forsinkelsesrenter ikke
          er betalt innen 30 (tretti) kalenderdager fra forfall, kan Leverandøren sende skriftlig varsel til Kunden om
          at avtalen vil bli hevet dersom oppgjør ikke er skjedd innen 30 (tretti) kalenderdager etter at varselet er
          mottatt. Heving kan ikke skje hvis Kunden gjør opp forfalt vederlag med tillegg av forsinkelsesrenter innen
          fristens utløp.
          <br></br>
          <br></br>
          Leverandøren har eiendomsrett til produktene og innholdet, herunder programvaren, metodikken og
          veiledningsmateriell benyttet i multiMap. Leverandøren har bruksrett på aggregert informasjon av materialet
          som lagres i multiMaps database. Denne bruksretten gjelder kun for bruk av anonymisert informasjon til for
          eksempel nasjonale rapporter. For øvrig har Kunden eiendomsrett til data som de selv har lagt inn i systemet
          og beholder disse dataene etter eventuell oppsigelse av avtalen. Kunden har kun disposisjonsrett til verktøyet
          så lenge Kunden er abonnent på multiMap.
          <br></br>
          <br></br>
          Partene kan si opp avtalen ved skriftlig varsel til den annen part senest 1 måned før dato for fornyelse av
          abonnement. Dato for fornyelse er spesifisert i abonnementsavtalen. Ved oppsigelse løper abonnementet ut
          daværende abonnementsperiode. Det er ikke anledning til å kreve tilbakebetalt deler av abonnementsavgiften ved
          sluttføring av abonnementet før dette. Ved oppsigelse av avtalen kan Kunden, ved forespørsel, få utlevert alle
          sine kartlagte data.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={() => setShowSubscribeModal(false)}>
            Avbryt
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              add({
                request: {
                  organizationId: workspace?.organizationId ?? '',
                  serviceType: 'Planning',
                },
              });
              setShowSubscribeModal(false);
            }}
          >
            Ja, jeg vil abonnere
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

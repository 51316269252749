import { AxiosError } from 'axios';
import { workspacesApi } from 'common/api/MultimapClients';
import { ValidationProblemDetails, WorkspaceImportVerifyResponse } from 'common/api/multimap/api';
import { Guid } from 'common/types/guid.type';
import { useState } from 'react';
import { useMutation } from 'react-query';

type UpdateRequest = {
  workspaceId: Guid;
  file: File;
};

function useImportVerifyWorkspace(): [
  boolean,
  boolean,
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  WorkspaceImportVerifyResponse | undefined,
  (request: UpdateRequest) => void,
  () => void,
] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const mutation = useMutation(
    (request: UpdateRequest) => {
      return workspacesApi
        .workspacesWorkspaceIdImportVerifyV1Post(request.workspaceId, [request.file])
        .then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const action = (payload: UpdateRequest) => mutation.mutate(payload);

  return [
    mutation.isLoading,
    mutation.isError,
    mutation.isSuccess,
    mutation.isIdle,
    validationError,
    mutation.data,
    action,
    mutation.reset,
  ];
}

export default useImportVerifyWorkspace;

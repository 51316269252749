import { mappingSetsApi, objectMappingsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { MappingPeriodResponse, MappingSetResponse, WorkspaceModuleResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetLastClosedMappingPeriod(
  id: Guid,
): [boolean, boolean, MappingPeriodResponse | undefined, () => Promise<MappingPeriodResponse | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.ObjectMappings, id],
    () => objectMappingsApi.objectMappingsWorkspaceModuleWorkspaceModuleIdLastClosedPeriodGet(id).then((x) => x.data),
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetLastClosedMappingPeriod;

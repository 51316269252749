import { organizationsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { UserResponse } from 'common/api/multimap/api';
import { useQuery } from 'react-query';

function useGetOrganizationUsers(
  organizationId: string | undefined,
): [boolean, boolean, UserResponse[] | undefined, () => Promise<UserResponse[] | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.OrganizationUsers, organizationId],
    () => organizationsApi.getOrganizationUsers(organizationId ?? '').then((x) => x.data),
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetOrganizationUsers;

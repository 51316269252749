import { ObjectPropertyResponse, WorkspaceDetailLevelResponse } from 'common/api/multimap';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  // eslint-disable-next-line import/named
  Control, // eslint-disable-next-line import/named
  DeepRequired, // eslint-disable-next-line import/named
  FieldErrorsImpl,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccordionProperties } from './AccordionProperties';
import { EditPropertyControl } from './EditPropertyControl';
import { IFormInput } from './ObjectModal';

interface IProps {
  disabled: boolean;
  control: Control<IFormInput, any>;
  errors: FieldErrorsImpl<DeepRequired<IFormInput>>;
  object: WorkspaceDetailLevelResponse;
  properties: ObjectPropertyResponse[];
}

export const ObjectModalProperties: React.FC<IProps> = ({ disabled, control, errors, object, properties }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.detail.object' });

  const { fields } = useFieldArray({
    control,
    name: 'properties',
  });

  const props = fields
    .map((field, index) => {
      const prop = properties.find((p) => p.objectPropertyId === field.objectPropertyId);
      return {
        field,
        index,
        property: prop,
      };
    })
    .filter((x) => x.property && x.property?.datatypeId !== 'Unknown')
    .map((x) => {
      return { field: x.field, index: x.index, property: x.property! };
    });

  const requiredCtrls = props.filter((x) => x.property.required);
  const optionalProps = props.filter((x) => !x.property.required);

  return (
    <>
      {requiredCtrls.map((ctrl) => {
        return (
          <Col key={ctrl.field.id} sm={6}>
            <Form.Group className="mb-3">
              <Form.Label id={ctrl.field.id}>* {ctrl.property.systemName}</Form.Label>
              <EditPropertyControl
                labelledById={ctrl.field.id}
                control={control}
                index={ctrl.index}
                errors={errors}
                prop={ctrl.property}
                disabled={disabled}
              />
            </Form.Group>
          </Col>
        );
      })}

      <AccordionProperties title="Valgfri" eventKey="optional">
        <Row>
          {optionalProps.map((ctrl) => {
            return (
              <Col key={ctrl.field.id} sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label id={ctrl.field.id}>{ctrl.property.systemName}</Form.Label>
                  <EditPropertyControl
                    labelledById={ctrl.field.id}
                    control={control}
                    index={ctrl.index}
                    errors={errors}
                    prop={ctrl.property}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
            );
          })}
        </Row>
      </AccordionProperties>
    </>
  );
};

import { East } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetOrganizationModules from 'features/user/account-settings/components/modules/hooks/useGetOrganizationModules';
import useGetWorkspacesOnOrganizationId from 'features/user/account-settings/hooks/useGetWorkspacesOnUserId';
import React, { useState } from 'react';
import { Button, Card, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import useGetOrganizationById from '../hooks/useGetOrganizationById';

// eslint-disable-next-line import/named
import { FieldValues, useForm } from 'react-hook-form';
import { UpdateOrganizationRequest } from 'common/api/multimap';
import useUpdateOrganization from '../hooks/useUpdateOrganization';

const KeyValue = ({
  cols,
  label,
  value,
  tooltip,
}: {
  cols: number;
  label: string;
  value: string | null | undefined;
  tooltip?: string;
}) => {
  return (
    <Col xs={cols}>
      <small>{label}</small>

      {tooltip && <HelpPopover type="help" id={label.replace(' ', '_')} title={label} text={tooltip}></HelpPopover>}

      <h6>{value || '-'}</h6>
    </Col>
  );
};

const CardLink = ({ to, label }: { to: string; label: string }) => {
  return (
    <Link className="text-decoration-none" to={to}>
      <div>
        {label}
        <span>
          <ArrowForwardIcon />
        </span>
      </div>
    </Link>
  );
};

export const OrganizationDetail: React.FC = () => {
  const { organizationId } = useParams();
  const [isLoading, , data] = useGetOrganizationById(organizationId);
  const [, , workspaces, fetch] = useGetWorkspacesOnOrganizationId(organizationId ?? '');
  const [, , modules, modulesFetch] = useGetOrganizationModules(organizationId ?? undefined);
  const { t } = useTranslation('admin', { keyPrefix: 'organizations.organizationDetail' });

  const [, , , , update] = useUpdateOrganization();


  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UpdateOrganizationRequest>();


  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const updateOrganization = async (d: FieldValues) => {
    update({ id: d.organizationId, request: d });

    setShowEditModal(false);
  };

  const navigate = useNavigate();

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <>
      <PageTitle title={'Organisasjonsdetaljer'} backTo="/admin/organizations"></PageTitle>
      <h6 className="mt-4">Detaljer</h6>
      <Card className="mt-4">
        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            <Row>
              <Col>
                <small>Kundenavn</small>
                <h6>{data?.name}</h6>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col>
                <small>Organisasjonsnummer</small>
                <h6>{data?.organizationNumber}</h6>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col>
                <small>Type</small>
                <h6>{data?.organizationType}</h6>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col>
                <small>Kategori</small>
                <h6>Statlig</h6>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col>
                <small>Abonnement</small>
                <h6>Aktiv - {modules && modules.length} moduler</h6>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <Button variant="link" onClick={() => navigate('modules')}>
                  Se detaljer og moduler <East />
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col>
                <small>Brukere</small>
                <h6>{data?.userCount} Bruker(e)</h6>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <Button variant="link" onClick={() => navigate('users/active')}>
                  Se brukere <East />
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col>
                <small>Arbeidsområder</small>
                <h6>{workspaces ? workspaces.length : 0} Arbeidsområder</h6>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <Button variant="link" onClick={() => navigate('workspaces')}>
                  Se alle arbeidsområder
                  <East />
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col>
                <small>Tilleggsfunksjonalitet</small>
                <h6>{data ? data.serviceTypes?.length : 0} Tilleggsfunksjonalitet(er)</h6>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <Button variant="link" onClick={() => navigate('service-types')}>
                  Se tilleggsfunksjonaliteter
                  <East />
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col>
                <small>Faktureringsinformasjon</small>
                <h6>Fakturareferanse: {data?.invoiceReference}</h6>
                <h6>Kontaktperson: {data?.contactPerson}</h6>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <Button variant="link" onClick={() => {
                  reset(
                    {
                      organizationId: data?.organizationId,
                      invoiceReference: data?.invoiceReference,
                      contactPerson: data?.contactPerson
                    }
                  );

                  setShowEditModal(true);
                }}>
                  Endre faktureringsinformasjon
                  <East />
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Card>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Endre organisasjonens fakturainformasjon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit((d) => updateOrganization(d))} id="form">
            <Form.Label>Faktureringsreferanse:</Form.Label>
            <Form.Control type="text" {...register('invoiceReference')}></Form.Control>
            <br></br>
            <Form.Label>Kontaktperson:</Form.Label>
            <Form.Control type="text" {...register('contactPerson')}></Form.Control>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={() => setShowEditModal(false)}>
            Avbryt
          </Button>
          <Button variant="primary" type="submit" form="form">Rediger</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

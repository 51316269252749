import { userInvitationsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { CustomerAccessRequestResponse, UserInvitationResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetInvitationByEmail(): [
  boolean,
  boolean,
  UserInvitationResponse | undefined,
  () => Promise<UserInvitationResponse | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch, isIdle } = useQuery(
    [QueryKeys.Home, 'invitation'],
    () => userInvitationsApi.userInvitationsFindInvitationByUserGet().then((x) => x.data),
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetInvitationByEmail;

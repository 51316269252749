import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

import styles from './signedOut.module.scss';

type IProps = {
  signedOut?: boolean;
};

export const SignedOut = (props: IProps) => {
  const [isSignedOut, setIsSignedOut] = useState<boolean>(props.signedOut ? true : false);

  const redirectToLanding = () => {
    setTimeout(() => {
      setIsSignedOut(true);
    }, 2000);
  };

  useEffect(() => {
    redirectToLanding();
  }, []);

  if (isSignedOut) {
    window.location.href = 'https://multimap.multiconsult.no';
  }

  return (
    <Container fluid className={styles.container}>
      <div className={styles.centeredBox}>
        {/* <h2></h2> */}
        <h4>
          <CheckCircleOutlineIcon fontSize="medium" /> Du er logget ut av multiMap
        </h4>
      </div>
    </Container>
  );
};

import React, { useState } from 'react';

type IAppContext = {
  setAdminState: (value: IAppState) => void;
  state: IAppState;
};

type IAppState = {
  tooltips: {
    activeId?: string;
  };
};

export const AppContext = React.createContext<IAppContext>({
  setAdminState: () => { },
  state: {
    tooltips: {
      activeId: '',
    },
  },
});

export const AdminProvider: React.FC<{ children?: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const [appState, setAppState] = useState<IAppState>({ tooltips: { activeId: undefined } });

  const changeState = (value: IAppState) => {
    setAppState(value);
  };

  return (
    <>
      <AppContext.Provider value={{ state: appState, setAdminState: changeState }}>{children}</AppContext.Provider>
    </>
  );
};

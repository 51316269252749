import { AxiosError } from 'axios';
import { customerAccessRequestApi } from 'common/api/MultimapClients';
import {
  CreateCustomerAccessRequest,
  CustomerAccessRequestResponse,
  ValidationProblemDetails,
} from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation } from 'react-query';

function useCreateAccessRequestApi(): [
  boolean,
  boolean,
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  CustomerAccessRequestResponse | undefined,
  (createCustomerAccessRequest: CreateCustomerAccessRequest) => void,
] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, isSuccess, isIdle, data, mutate } = useMutation(
    (createCustomerAccessRequest: CreateCustomerAccessRequest) => {
      return customerAccessRequestApi.customerAccessRequestsPost(createCustomerAccessRequest).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const create = (createCustomerAccessRequest: CreateCustomerAccessRequest) => mutate(createCustomerAccessRequest);

  return [isLoading, isError, isSuccess, isIdle, validationError, data, create];
}

export default useCreateAccessRequestApi;

import { WorkspaceResponse } from 'common/api/multimap';
import { ContactModal } from 'common/components/Contact/ContactModal';
import { TileButton } from 'common/components/TileButton/TileButton';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router';

import { displayIcon } from '../../../admin/modules/components/ModuleIcons';
import useAddWorkspaceModule from '../hooks/useAddWorkspaceModule';
import useGetAllAvailableModules from '../hooks/useGetAllAvailableModules';
import useGetAllWorkspaceModules from '../hooks/useGetAllWorkspaceModules';
import { GoToActivePeriodButton } from './components/GoToActivePeriodButton';
import { MappingObjectsProgress } from './components/MappingObjectsProgress';
import { ReadMoreModal } from './components/ReadMoreModal';

export const MappingOverview = () => {
  const { profileInfo } = useAuthenticatedUser();

  const [workspace] = useLocalStorage<WorkspaceResponse | undefined>('workspace', undefined);

  const [isLoading, , data, fetch] = useGetAllWorkspaceModules(workspace ? workspace.workspaceId : '');

  const [modulesLoading, , modules] = useGetAllAvailableModules(workspace ? workspace.workspaceId : '');

  const [contactModuleName, setContactModuleName] = useState<string>();

  const [showModal, setShowModal] = useState<boolean>(false);

  const [showSubscribeModal, setShowSubscribeModal] = useState<boolean>(false);

  const [chosenModuleId, setChosenModuleId] = useState<string>('');

  const [, , , result, add] = useAddWorkspaceModule();

  const [mappingSetId, setMappingSetId] = useState<string>('');
  const [showLearningModal, setShowLearningModal] = useState<boolean>(false);

  console.log(profileInfo);

  useEffect(() => {
    fetch();
  }, [result]);

  const navigate = useNavigate();
  return (
    <>
      <PageTitle title="Kartleggingsoversikt" />
      <h4>Mine kartleggingsmoduler</h4>

      {data && data.length > 0 ? (
        data.map((row, i) => {
          return (
            <Card key={i} className="mb-3">
              <Row className="g-0">
                <Col xs={1}>
                  <div className="w-100 h-100 bg-primary d-flex justify-content-center align-items-center rounded-start">
                    {displayIcon(row.icon)}
                  </div>
                </Col>
                <Col xs={11} className="p-3">
                  <Row>
                    <Col>
                      <h4>{row.systemName}</h4>
                      <span>{row.systemDescription}</span>
                    </Col>
                    <Col>
                      <MappingObjectsProgress workspaceModuleId={row.workspaceModuleId} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-row justify-content-end align-items-end pb-3">
                      <Button
                        variant="tertiary"
                        className="me-2"
                        onClick={() => {
                          setMappingSetId(row.mappingSetId);
                          setShowLearningModal(true);
                        }}
                      >
                        Les mer
                      </Button>
                      <GoToActivePeriodButton workspaceModuleId={row.workspaceModuleId} />

                      {(profileInfo?.isOrganizationAdministrator || profileInfo?.isSystemAdministrator) && (
                        <Button
                          variant="secondary"
                          onClick={() => navigate(`/mapping/${row.workspaceModuleId}/periods`)}
                        >
                          Perioder
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          );
        })
      ) : (
        <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
          <>
            <Col xs={8} className="p-3">
              <h4>Du har ingen kartleggingsmoduler</h4>
              <span>Du har ingen kartleggingsmoduler aktive.</span>
            </Col>
          </>
        </Row>
      )}

      <h4>Tilgjengelige kartleggingsmoduler</h4>
      {modules &&
        modules.map((row, i) => {
          return (
            <Card key={i} className="mb-3">
              <Row className="g-0">
                <Col xs={1}>
                  <div className="w-100 h-100 bg-primary d-flex justify-content-center align-items-center rounded-start">
                    {displayIcon(row.icon)}
                  </div>
                </Col>
                <Col xs={11} className="p-3">
                  <Row>
                    <Col>
                      <h4>{row.systemName}</h4>
                      <span>{row.systemDescription}</span>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col className="d-flex flex-column align-items-start pb-3">
                      {row.activeBefore && <span>Denne modulen har vært aktiv før</span>}
                      <span>Pris: {row.modulePrice},-</span>
                    </Col>
                    <Col className="d-flex flex-row justify-content-end align-items-end pb-3">
                      <Button
                        variant="tertiary"
                        className="me-2"
                        onClick={() => {
                          setMappingSetId(row.mappingSetId);
                          setShowLearningModal(true);
                        }}
                      >
                        Les mer
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setChosenModuleId(row.moduleId);
                          setShowSubscribeModal(true);
                        }}
                      >
                        Bestill og aktiver
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          );
        })}
      <ContactModal
        show={showModal}
        onHide={() => setShowModal(false)}
        theme="Bestill modul"
        moduleName={contactModuleName}
      />
      <LoadingSpinner isLoading={isLoading} />
      <ReadMoreModal id={mappingSetId} show={showLearningModal} onHide={() => setShowLearningModal(false)} />
      <Modal show={showSubscribeModal} onHide={() => setShowSubscribeModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vil du abonnere på denne kartleggingsmodulen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Modulens faste vederlag vil være gjenstand for årlig forskuddsvis fakturering. Betalingsfrist er 30 dager
          etter fakturadato. Kunden må spesifisere og begrunne eventuelle innsigelser til Leverandørens faktura uten
          ugrunnet opphold. Gjør han ikke det legges fakturaen til grunn for oppgjøret. Ved forsinket betaling regnes
          renter etter «lov om rente ved forsinket betaling». Leverandøren har rett til å holde tilbake tilgang til
          programvaren ved forsinket betaling. Dersom forfalt uomtvistet vederlag med tillegg av forsinkelsesrenter ikke
          er betalt innen 30 (tretti) kalenderdager fra forfall, kan Leverandøren sende skriftlig varsel til Kunden om
          at avtalen vil bli hevet dersom oppgjør ikke er skjedd innen 30 (tretti) kalenderdager etter at varselet er
          mottatt. Heving kan ikke skje hvis Kunden gjør opp forfalt vederlag med tillegg av forsinkelsesrenter innen
          fristens utløp.
          <br></br>
          <br></br>
          Leverandøren har eiendomsrett til produktene og innholdet, herunder programvaren, metodikken og
          veiledningsmateriell benyttet i multiMap. Leverandøren har bruksrett på aggregert informasjon av materialet
          som lagres i multiMaps database. Denne bruksretten gjelder kun for bruk av anonymisert informasjon til for
          eksempel nasjonale rapporter. For øvrig har Kunden eiendomsrett til data som de selv har lagt inn i systemet
          og beholder disse dataene etter eventuell oppsigelse av avtalen. Kunden har kun disposisjonsrett til verktøyet
          så lenge Kunden er abonnent på multiMap.
          <br></br>
          <br></br>
          Partene kan si opp avtalen ved skriftlig varsel til den annen part senest 1 måned før dato for fornyelse av
          abonnement. Dato for fornyelse er spesifisert i abonnementsavtalen. Ved oppsigelse løper abonnementet ut
          daværende abonnementsperiode. Det er ikke anledning til å kreve tilbakebetalt deler av abonnementsavgiften ved
          sluttføring av abonnementet før dette. Ved oppsigelse av avtalen kan Kunden, ved forespørsel, få utlevert alle
          sine kartlagte data.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={() => setShowSubscribeModal(false)}>
            Avbryt
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              add({
                id: workspace?.workspaceId ?? '',
                request: { moduleId: chosenModuleId, workspaceId: workspace?.workspaceId },
              });
              setShowSubscribeModal(false);
            }}
          >
            Ja, jeg vil abonnere
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { organizationsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { UserInvitationResponse, UserInvitationStatus } from 'common/api/multimap/api';
import { useQuery } from 'react-query';

function useGetInvitations(
  organizationId: string | undefined
): [boolean, boolean, UserInvitationResponse[] | undefined, () => Promise<UserInvitationResponse[] | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.Invitations, organizationId],
    () =>
      organizationsApi
        .getOrganizationInvitations(organizationId ?? '', UserInvitationStatus.Pending)
        .then((x) => x.data),
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetInvitations;

import {
  MappingObjectResponse,
  MappingParameterConditionDegreeValueResponse,
  ModuleParameterDescriptionResponse,
} from 'common/api/multimap';
import { Button, Modal, Stack } from 'react-bootstrap';

export interface IProps {
  show: boolean;
  onHide: () => void;
  data: ModuleParameterDescriptionResponse | undefined;
}

export const MappingObjectPDModal: React.FC<IProps> = ({ show, onHide, data }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      {data && (
        <>
          <Modal.Header closeButton>
            <Stack direction="vertical">
              <span className="larger">Beskrivelse av tilstandsgrad</span>
              <h4>{data.parameterName}</h4>
            </Stack>
          </Modal.Header>
          <Modal.Body>
            <div
              className="mb-4"
              dangerouslySetInnerHTML={{
                __html: data.description ? data.description : 'Det finnes ingen beskrivelse på denne foreløpig',
              }}
            ></div>
            {data.conditionDegrees &&
              data.conditionDegrees
                .sort((a, b) => a.conditionDegree - b.conditionDegree)
                .map((row, i) => {
                  return (
                    <>
                      <h5>TG {row.conditionDegree}</h5>
                      <div
                        key={i}
                        className="background-blue50 p-3 mb-2 rounded border"
                        dangerouslySetInnerHTML={{
                          __html: row.description ? row.description : 'Det finnes ingen beskrivelse på denne foreløpig',
                        }}
                      ></div>
                    </>
                  );
                })}
          </Modal.Body>
        </>
      )}

      <Modal.Footer>
        <Button variant="tertiary" onClick={onHide}>
          Lukk og fortsett med kartleggingen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

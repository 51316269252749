import { PageTitle } from 'common/components/page-title/PageTitle';
import { Col, Container, Row } from 'react-bootstrap';

export const LearningPlanning = () => {
  return (
    <>
      <PageTitle title="Planlegging" />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/d4-sGvpFEvE?si=DJ7mHcqJi9hTXbM6"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <br></br>
      <br></br>
      <p>
        Planleggingsfanen gir deg mulighet til å bruke ferdig kartlagte moduler til å utvikle en tiltak- og
        vedlikeholdsplan basert på kartlagte data. Det gis mulighet for å lage tiltaksplan for samtlige moduler. Tiltak
        opprettes på byggnivå. Det gis mulighet til å inkludere flere komponenter eller parametre for hvert tiltak.
        Dette muliggjør opprettelse av prosjekttiltak for objekter hvor det er hensiktsmessig å samle flere tiltak i ett
        prosjekt.
      </p>
      <p>
        Videre kan man detaljere prosjektet eller tiltaket ved å legge inn følgende informasjon:
        <ul>
          <li>Prosjektnavn og -nummer</li>
          <li>Tiltakstype, konsekvensgrad og prioritet</li>
          <li>Beskrivelse av tiltaket</li>
          <li>Aktuelle komponenter</li>
          <li>Planlagt startår og budsjettert år</li>
          <li>Estimert pris og kalkulert pris</li>
        </ul>
      </p>

      <p>
        Opprettede tiltak samles i en detaljoversikt. Det er mulig å redigere allerede opprettede tiltak samt vise
        avsluttede tiltak.{' '}
      </p>
    </>
  );
};

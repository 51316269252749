import { customerAccessRequestApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { CustomerAccessRequestDetailResponse } from 'common/api/multimap/api';
import { useQuery } from 'react-query';

function useGetCustomeAccessRequestDetails(
  requestId: string,
  isCreditCheckDataReady?: boolean,
): [
    boolean,
    boolean,
    CustomerAccessRequestDetailResponse | undefined,
    () => Promise<CustomerAccessRequestDetailResponse | undefined>,
  ] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.CustomerAccessRequests, isCreditCheckDataReady],
    () => customerAccessRequestApi.getByIdRoute(requestId).then((x) => x.data),
    {
      enabled: !!requestId,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetCustomeAccessRequestDetails;

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {
  ObjectPropertyResponse,
  ObjectTypeDetailResponse,
  ObjectTypeLevelResponse,
  WorkspaceDetailLevelResponse,
  WorkspaceDetailResponse,
  WorkspaceDetailUpdateRequest,
} from 'common/api/multimap';
import { PageActions } from 'common/components/PageActions/PageActions';
import { ErrorPage } from 'common/components/error-page/ErrorPage';
import { ValidationErrors } from 'common/components/form/ValidationErrors';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { upsert } from 'common/helpers/array.helpers';
import { notify } from 'common/helpers/toast-notification-helper';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useBeforeUnload } from 'react-router-dom';

import { toUpdateRequest } from '../helpers/property.helper';
import useUpsertWorkspace from '../hooks/useUpsertWorkspace';
import { ImportExportWizardModal } from '../import-export/ImportExportWizardModal';
import { Guidance } from './Guidance';
import { AddressVerificationWizardModal } from './address-verification/AddressVerificationWizardModal';
import { ObjectModal } from './components/ObjectModal';
import { StartModal, StartModalChoice } from './components/StartModal';
import styles from './detail.module.scss';

type SelectionState = Record<number, Guid | undefined>;

export const ObjectElement = ({
  inactive,
  level,
  data,
  selectObject,
  openDetail,
}: {
  inactive?: boolean;
  level: number;
  data: WorkspaceDetailLevelResponse;
  selectObject: (level: number, objectId: Guid) => void;
  openDetail: (objectId: Guid) => void;
}) => {
  const isDeleted = !!data.deactivatedOn;
  const deletedClass = isDeleted ? 'text-decoration-line-through' : '';

  return (
    <div
      role={'button'}
      tabIndex={0}
      key={data.objectId}
      className={`d-flex align-items-center p-3 ${styles.object} ${inactive ? 'bg-grey-300' : 'active bg-white'}`}
      onClick={() => selectObject(level, data.objectId)}
      onKeyDown={() => selectObject(level, data.objectId)}
    >
      {isDeleted && <RemoveCircleOutlineOutlinedIcon color="error" />}
      <span className={`ms-3 text-truncate ${deletedClass}`}>{data.systemName}</span>
      <Button
        size="sm"
        variant="link"
        aria-label="Open detaljer"
        onClick={(e) => {
          e.stopPropagation();
          openDetail(data.objectId);
        }}
        className="p-0 ms-auto "
      >
        <MoreVertIcon />
      </Button>
    </div>
  );
};

export const Level = ({
  level,
  mode,
  parentId,
  selectedId,
  active,
  detailLevels,
  selectObject,
  openDetail,
  created,
}: {
  level: ObjectTypeLevelResponse;
  mode: 'read' | 'edit' | 'create';
  parentId: Guid | undefined;
  selectedId: Guid | undefined;
  active: boolean;
  detailLevels: WorkspaceDetailLevelResponse[];
  selectObject: (level: number, objectId: Guid) => void;
  openDetail: (objectId: Guid) => void;
  created: () => void;
}) => {
  return (
    <div key={level.objectTypeId} className={`flex-grow-1 me-2`}>
      <div className="d-flex justify-content-between my-2">
        <span>{level.systemName}</span>
        <span className="me-2">{detailLevels.length}</span>
      </div>

      <div className={`d-flex flex-column overflow-auto ${styles['level-container']} border `}>
        {detailLevels
          .filter((_) => active || level.level === 1)
          .filter((x) => x.parentId === parentId || level.level === 1)
          .sort(
            (v1, v2) =>
              (v1.deactivatedOn ? 1 : 0) - (v2.deactivatedOn ? 1 : 0) || v1.systemName.localeCompare(v2.systemName),
          )
          .map((data) => {
            return (
              <ObjectElement
                key={data.objectId}
                inactive={data.objectId !== selectedId && !!selectedId}
                level={level.level}
                data={data}
                selectObject={selectObject}
                openDetail={openDetail}
              />
            );
          })}
      </div>

      {mode === 'create' ||
        (mode === 'edit' && (
          <div className="d-flex mt-2">
            <Button variant="tertiary" className="w-100" disabled={!active && level.level > 1} onClick={created}>
              <AddCircleOutlineOutlinedIcon /> Legg til ny på nivå {level.level}
            </Button>
          </div>
        ))}
    </div>
  );
};

const applyChildrenFor = (
  objects: WorkspaceDetailLevelResponse[],
  objectId: Guid,
  applyPredicate: (value: WorkspaceDetailLevelResponse) => void,
): void => {
  const element = objects.find((x) => x.objectId === objectId);
  if (element) {
    applyPredicate(element);
  }

  const children = objects.filter((x) => x.parentId === objectId);
  for (let i = 0; i < children.length; i++) {
    applyChildrenFor(objects, children[i].objectId, applyPredicate);
  }
};

type IProps = {
  detail: WorkspaceDetailResponse;
  type: ObjectTypeDetailResponse;
  properties: ObjectPropertyResponse[];
  mode: 'edit' | 'create';
  onRefresh?: () => void;
  admin: boolean;
};

export const UpsertDetail: React.FC<IProps> = ({ detail, type, properties, mode, onRefresh, admin }) => {
  const { t: ts } = useTranslation('shared');
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.detail' });

  const navigate = useNavigate();

  const { profileInfo } = useAuthenticatedUser();

  const [formData, setFormData] = useLocalStorage<WorkspaceDetailResponse>(
    'portfolio-workspace',
    structuredClone(detail),
  );
  const [isChanged, setIsChanged] = useLocalStorage<boolean>('portfolio-is-changed', false);
  const [selections, setSelections] = useState<SelectionState>({});
  const [isEditing, setIsEditing] = useState(mode === 'create');

  const [isImportExportWizard, setIsImportExportWizard] = useState(false);
  const [isAddressVerificationWizard, setIsAddressVerificationWizard] = useState(false);

  const [showObjectDetails, setShowObjectDetails] = useState<
    { object: WorkspaceDetailLevelResponse; objectType: ObjectTypeLevelResponse } | undefined
  >(undefined);
  const [createObjectLevel, setCreateObjectLevel] = useState<
    { object: WorkspaceDetailLevelResponse; objectType: ObjectTypeLevelResponse } | undefined
  >(undefined);

  const [showDeleted, setShowDeleted] = useState(false);
  const [showInitialGuidance, setShowInitialGuidance] = useState(false);

  const onUpsertSucceed = () => {
    notify('success', 'Object lagret');
  };

  const [isSaving, , , , validationError, workspaceDetailResponse, , update, reset] = useUpsertWorkspace(
    mode,
    onUpsertSucceed,
  );

  const title = `Portefølje "${formData.systemName}"`;

  const selectObjectHandler = (level: number, objectId: Guid) => {
    console.log(level, objectId);
    if (selections[level]) {
      setSelections((x) => {
        const prev = { ...x };
        const curr: SelectionState = {};
        for (let i = 1; i < level; i++) {
          curr[i] = prev[i];
        }
        if (prev[level] === objectId) {
          curr[level] = undefined;
        } else {
          curr[level] = objectId;
        }
        return curr;
      });
    } else {
      setSelections((x) => {
        const prev = { ...x };
        prev[level] = objectId;
        return prev;
      });
    }
  };

  const openDetailHandler = (objectId: Guid) => {
    console.log(objectId);

    const object = formData.levels.find((x) => x.objectId === objectId);
    const objectType = type.levels.find((x) => x.level === formData.levels.find((y) => y.objectId === objectId)?.level);
    if (!object || !objectType) {
      return;
    }

    setShowObjectDetails({ object, objectType });
  };

  const hideObjectDetailHandler = () => {
    console.log('hide', showObjectDetails);
    setShowObjectDetails(undefined);
  };

  const onStartHandler = () => {
    console.log('start');
    setShowInitialGuidance(true);
  };

  const createdHandler = (level: number) => {
    console.log(level);
    const object: WorkspaceDetailLevelResponse = {
      level: level,
      // as it'a a tree relationship that could be completely build only in the frontend, and we need a key to connect objects, we need to create a Guid.
      objectId: crypto.randomUUID(),
      properties: [],
      systemDescription: '',
      systemName: '',
      parentId: level === 1 ? detail.objectId : selections[level - 1],
    };
    const objectType = type.levels.find((x) => x.level === level);
    if (!objectType) {
      return;
    }

    setIsEditing(true);
    setCreateObjectLevel({ object, objectType });
  };

  const onCreateConfirmHandler = (object: WorkspaceDetailLevelResponse) => {
    setIsChanged(true);

    const data: WorkspaceDetailResponse = structuredClone(formData);
    data.levels.push(object);

    setFormData(data);
    setCreateObjectLevel(undefined);
  };

  const onEditConfirmHandler = (object: WorkspaceDetailLevelResponse) => {
    setIsChanged(true);

    const data: WorkspaceDetailResponse = structuredClone(formData);
    upsert(
      data.levels,
      (_) => object,
      (x) => x.objectId === object.objectId,
    );

    setFormData(data);
    setShowObjectDetails(undefined);
  };

  const onDeleteConfirmHandler = (object: WorkspaceDetailLevelResponse) => {
    setIsChanged(true);

    const data: WorkspaceDetailResponse = structuredClone(formData);
    applyChildrenFor(data.levels, object.objectId, (x) => {
      if (x.deactivatedOn) {
        return;
      }
      x.deactivatedOn = new Date().toISOString();
    });

    setFormData(data);
    setShowObjectDetails(undefined);
  };

  const onSaveHandler = () => {
    const request: WorkspaceDetailUpdateRequest = {
      systemName: formData.systemName,
      systemDescription: formData.systemDescription,
      levels: formData.levels.map((level) => {
        return {
          objectId: level.objectId,
          parentId: level.parentId,
          level: level.level,
          systemName: level.systemName,
          systemDescription: level.systemDescription,
          isDeleted: !!level.deactivatedOn,
          properties: level.properties.map((prop) => toUpdateRequest(properties, prop)),
        };
      }),
    };
    update({ workspaceId: formData.workspaceId, request });
    setIsChanged(false);
  };

  const hideCreateObjectLevelHandler = () => {
    setCreateObjectLevel(undefined);
  };

  const onInitialChoiceHandler = (value: StartModalChoice) => {
    setShowInitialGuidance(false);
    if (value === 'manual') {
      createdHandler(1);
    }
    if (value === 'upload') {
      setIsImportExportWizard(true);
    }
  };

  const importExportWizardHandler = async () => {
    console.log('import/export');
    setIsImportExportWizard(true);
  };

  const importExportWizardCancelHandler = () => {
    console.log('import cancelled');
    setIsImportExportWizard(false);
  };

  const importExportWizardCompletedHandler = async (refreshRequired: boolean) => {
    console.log('import/export completed');
    setIsImportExportWizard(false);
    if (refreshRequired && onRefresh) {
      onRefresh();
    }
  };

  const addressVerificationWizardHandler = async () => {
    setIsAddressVerificationWizard(true);
  };

  const addressVerificationWizardCancelHandler = () => {
    setIsAddressVerificationWizard(false);
  };

  useEffect(() => {
    if (!type || !formData) {
      return;
    }

    for (const level of type.levels) {
      const v = formData.levels
        .filter((x) => showDeleted || (!showDeleted && !x.deactivatedOn))
        .filter((x) => x.level === level.level);
      if (v.length === 1 && selections[level.level] !== v[0].objectId) {
        selectObjectHandler(level.level, v[0].objectId);
      }
    }
  }, [formData, type, selectObjectHandler, selections, showDeleted]);

  useEffect(() => {
    const data = workspaceDetailResponse || detail;
    if (!data) {
      return;
    }

    if (!isChanged) {
      setFormData(data);
      setIsChanged(false);
      return;
    }
  }, [workspaceDetailResponse, detail, isChanged]);

  useEffect(() => {
    if (!workspaceDetailResponse || mode !== 'create') {
      return;
    }
    const to = `/admin/workspaces/${workspaceDetailResponse.workspaceId}/types/${workspaceDetailResponse.objectType.objectTypeId}/detail`;
    navigate(to);
  }, [mode, navigate, workspaceDetailResponse]);

  if (type && formData && type.objectTypeId !== formData.objectType.objectTypeId) {
    return <ErrorPage reason={404} />;
  }

  return (
    <>
      {!!formData && !!type && (
        <>
          <Guidance
            detail={formData}
            objectTypes={type.levels}
            onCreate={(level) => createdHandler(level)}
            onStart={onStartHandler}
          />

          {showInitialGuidance && (
            <StartModal
              objectType={type.levels.find((l) => l.level === 1)?.systemName || ''}
              onChoice={onInitialChoiceHandler}
              onHide={() => setShowInitialGuidance(false)}
            />
          )}
        </>
      )}

      <Stack direction="vertical" gap={4}>
        <PageTitle title={title} backTo={admin ? `/admin/workspaces` : undefined}>
          <Button
            variant="tertiary"
            size="sm"
            onClick={() => {
              setFormData(() => structuredClone(detail));
              setIsChanged(false);
            }}
            disabled={!isChanged}
          >
            {ts('actionCancel')}
          </Button>

          <Button
            type="button"
            variant="secondary"
            size="sm"
            className="mx-2"
            disabled={!isChanged}
            onClick={onSaveHandler}
          >
            {ts('actionSave')}
          </Button>
        </PageTitle>

        <PageActions
          left={
            <>
              <Form.Switch
                inline
                id="show-deleted"
                label={'Vise også fjernet'}
                checked={showDeleted}
                onChange={(e) => {
                  setShowDeleted(e.target.checked);
                }}
              />

              {profileInfo?.isOrganizationAdministrator && (
                <Form.Switch
                  inline
                  id="enable-editing"
                  label={'Rediger portefølje'}
                  checked={isEditing}
                  onChange={(e) => {
                    setIsEditing(e.target.checked);
                  }}
                />
              )}
            </>
          }
          right={
            <>
              <Button variant="tertiary" onClick={importExportWizardHandler}>
                <CloudSyncOutlinedIcon /> Importer/Eksporter porteføljeobjekter
              </Button>

              <Button variant="tertiary" onClick={addressVerificationWizardHandler}>
                <FactCheckOutlinedIcon /> Verifisere porteføljeadresser
              </Button>
            </>
          }
        />
      </Stack>

      {validationError && <ValidationErrors errors={validationError} />}

      <LoadingSpinner isLoading={isSaving} />

      {isImportExportWizard && (
        <ImportExportWizardModal
          show={isImportExportWizard}
          workspaceId={formData.workspaceId}
          properties={properties}
          onCancel={importExportWizardCancelHandler}
          onCompleted={importExportWizardCompletedHandler}
        />
      )}

      {isAddressVerificationWizard && (
        <AddressVerificationWizardModal
          show={isAddressVerificationWizard}
          propertyDefinitions={properties}
          includeDeleted={showDeleted}
          levels={formData.levels}
          onCancel={addressVerificationWizardCancelHandler}
        />
      )}

      <div className="d-flex my-3">
        {type?.levels.map((level) => {
          const parentId = selections[level.level - 1];
          const active = !!parentId || level.level === 1;

          return (
            <Level
              key={level.objectTypeId}
              mode={isEditing ? 'edit' : 'read'}
              level={level}
              parentId={parentId}
              selectedId={selections[level.level]}
              active={active}
              detailLevels={
                formData?.levels
                  ?.filter((x) => showDeleted || (!showDeleted && !x.deactivatedOn))
                  ?.filter((x) => x.level == level.level) ?? []
              }
              selectObject={selectObjectHandler}
              openDetail={openDetailHandler}
              created={() => createdHandler(level.level)}
            />
          );
        })}
      </div>

      {showObjectDetails !== undefined && (
        <ObjectModal
          show={showObjectDetails !== undefined}
          mode={isEditing ? 'edit' : 'read'}
          object={showObjectDetails.object}
          objectType={showObjectDetails.objectType}
          objects={formData?.levels || []}
          properties={properties || []}
          showDeleted={showDeleted}
          onHide={hideObjectDetailHandler}
          onConfirm={onEditConfirmHandler}
          onDelete={() => onDeleteConfirmHandler(showObjectDetails.object)}
        />
      )}

      {createObjectLevel !== undefined && (
        <ObjectModal
          show={createObjectLevel !== undefined}
          mode={'create'}
          object={createObjectLevel.object}
          objectType={createObjectLevel.objectType}
          objects={formData.levels || []}
          properties={properties || []}
          showDeleted={showDeleted}
          onHide={hideCreateObjectLevelHandler}
          onConfirm={onCreateConfirmHandler}
        />
      )}
    </>
  );
};

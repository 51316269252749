import { useMsal } from '@azure/msal-react';
import {
  AnalyticsOutlined,
  AssessmentOutlined,
  AssignmentOutlined,
  AssignmentTurnedInOutlined,
  ListAltOutlined,
  ListOutlined,
  ViewKanbanOutlined,
  WorkspacesOutlined,
} from '@mui/icons-material';
import { ListItemIcon } from '@mui/material';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import useGetAllLearningActive from 'features/admin/mapping-sets/hooks/useGetAllLearningActive';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { adminRouteGroups } from 'router/RouteConfig';
import { RouteItem } from 'router/RouteItem';

import styles from './sidebar.module.scss';

export const Sidebar = () => {
  const { accounts } = useMsal();
  const { t } = useTranslation('navigation');
  const { pathname } = useLocation();
  const { hasAccess } = useAuthenticatedUser();
  const isVisible = (routeItem: RouteItem) => !routeItem.hidden && hasAccess(routeItem.requiredPermission);

  const [isLoading, , data] = useGetAllLearningActive();

  return (
    <>
      <aside className="ps-2 bg-light h-100">
        <Nav className={`col-md-12 d-none d-md-block pb-4 pt-5 ps-md-1 ps-lg-4`}>
          <Nav.Item className="pb-3">
            <Nav.Link disabled>
              <h3 className="text-break">Opplæring</h3>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item key={`Group1`} className="pb-1">
            <Nav.Link key={`General`} className="pb-0" disabled>
              Utforsk multiMap
            </Nav.Link>
            <Nav.Link
              key={`Overview`}
              as={Link}
              className={pathname === `/learning/overview` ? styles.active : ''}
              to={'overview'}
            >
              <ViewKanbanOutlined /> Oversikt
            </Nav.Link>
            <Nav.Link
              key={`Overview`}
              as={Link}
              className={pathname === `/learning/portfolio` ? styles.active : ''}
              to={'portfolio'}
            >
              <WorkspacesOutlined /> Portefølje
            </Nav.Link>
            <Nav.Link
              key={`Overview`}
              as={Link}
              className={pathname === `/learning/mapping` ? styles.active : ''}
              to={'mapping'}
            >
              <AssignmentTurnedInOutlined /> Kartlegging
            </Nav.Link>
            <Nav.Link
              key={`Overview`}
              as={Link}
              className={pathname === `/learning/reports` ? styles.active : ''}
              to={'reports'}
            >
              <AnalyticsOutlined /> Rapporter
            </Nav.Link>
            <Nav.Link
              key={`Overview`}
              as={Link}
              className={pathname === `/learning/planning` ? styles.active : ''}
              to={'planning'}
            >
              <AssignmentOutlined /> Planlegging
            </Nav.Link>
          </Nav.Item>
          <Nav.Item key={'Group2'} className="pt-3">
            <Nav.Link key={`Title`} className="pb-0" disabled>
              Moduler
            </Nav.Link>
            {data &&
              data.map((x) => {
                return (
                  <Nav.Link
                    key={x.mappingSetId}
                    as={Link}
                    className={pathname === `/learning/module/:${x.mappingSetId}` ? styles.active : ''}
                    to={`module/${x.mappingSetId}`}
                  >
                    <ListOutlined />
                    {x.mappingSetName}
                  </Nav.Link>
                );
              })}
          </Nav.Item>
        </Nav>
      </aside>
    </>
  );
};

import { modulesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { ModuleMappingGroupDescriptionResponse, ModuleParameterDescriptionResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetModuleConditionDescriptions(
  id: Guid,
  parameterId: Guid,
): [
  boolean,
  boolean,
  ModuleParameterDescriptionResponse | undefined,
  () => Promise<ModuleParameterDescriptionResponse | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.ModuleConditionDescriptions, 'parameter'],
    () => modulesApi.modulesIdParameterParameterIdConditionDescriptionsGet(id, parameterId).then((x) => x.data),
    {
      enabled: !!id && !!parameterId,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetModuleConditionDescriptions;

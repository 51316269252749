import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Button, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type IProps = {
  onConfirm: () => void;
};

export const CreateWizardStep4: React.FC<IProps> = ({ onConfirm }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.create.createWizard.createWizardStep4' });

  return (
    <>
      <Stack direction="vertical" className="justify-content-center align-items-center" gap={5}>
        <CheckCircleOutlineOutlinedIcon color="success" className="size-xl" />

        <div className="text-center">
          <h4>{t('title')}</h4>

          <p>{t('message')}</p>
        </div>

        <Button variant="primary" onClick={() => onConfirm()}>
          {t('next')}
        </Button>
      </Stack>
    </>
  );
};

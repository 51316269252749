import { useIsAuthenticated } from '@azure/msal-react';
import { userInfoApi } from 'common/api/MultimapClients';
import { Language, UpdatePreferredLanguageRequest } from 'common/api/multimap';
import i18n, { defaultLanguage, supportedLanguages } from 'locales/LanguageConfig';
import { LanguageStorageService } from 'locales/LanguageStorageService';
import { SupportedLanguage } from 'locales/SupportedLanguage';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

export default function useLocalization(): [SupportedLanguage, (lang: Language) => void] {
  const { mutate } = useMutation((updatePreferredLanguageRequest?: UpdatePreferredLanguageRequest) => {
    return userInfoApi.setPreferredLanguage(updatePreferredLanguageRequest).then((x) => x.data);
  });
  const getSupportedLanguageById = (lang: Language) => supportedLanguages.find((x) => x.id === lang) ?? defaultLanguage;
  const getSupportedLanguageByShortName = (lang: string) =>
    supportedLanguages.find((x) => x.shortName === lang) ?? defaultLanguage;

  const savedlanguage = getSupportedLanguageByShortName(LanguageStorageService.get() ?? defaultLanguage.shortName);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(savedlanguage.id);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const supportedLang = getSupportedLanguageById(currentLanguage);
    i18n.changeLanguage(supportedLang.id);
    LanguageStorageService.set(supportedLang.shortName);
  }, [currentLanguage]);

  const setLanguage = (lang: Language) => {
    if (lang === currentLanguage) return;

    setCurrentLanguage(lang);
    if (isAuthenticated) mutate({ preferredLanguage: lang });
  };

  return [getSupportedLanguageById(currentLanguage), setLanguage];
}

import { mappingSetsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { MappingSetDetailResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetMappingSetDetail(
  id: Guid,
): [boolean, boolean, MappingSetDetailResponse | undefined, () => Promise<MappingSetDetailResponse | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.MappingSets, id],
    () => mappingSetsApi.getMappingSetByIdRoute(id).then((x) => x.data),
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetMappingSetDetail;

import GroupIcon from '@mui/icons-material/Group';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { useEffect } from 'react';
import { Anchor, Col, Container, Row } from 'react-bootstrap';

export interface IProps {
  currentStep: number;
}

export const OrganizationCreated: React.FC<IProps> = ({ currentStep }) => {
  const refresh = () => {
    setTimeout(() => {
      location.reload();
    }, 3000);
  };

  useEffect(() => {
    console.log(currentStep);
    if (currentStep == 5) {
      refresh();
    }
  }, [currentStep]);
  return (
    <Container fluid>
      <Row className="d-flex justify-content-center">
        <Col sm={12} md={7} lg={5} xl={4} xxl={3} className="text-center">
          <GroupIcon fontSize="large" />

          <h2>Takk for at du har valgt multiMap</h2>
          <p>
            Du kan snart invitere andre brukere fra din organisasjon til multiMap. Vi vil nå gjennomføre en godkjenning
            av din registrering. Du vil motta en e-post så fort det blir klart.
          </p>
          <p className="mt-3">
            <span>
              <HelpIcon />{' '}
            </span>
            <small>
              Har du spørsmål eller lurer på noe er det bare å ta{' '}
              <Anchor href="https://www.multiconsult.no">kontakt</Anchor>
            </small>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

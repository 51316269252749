import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { ObjectTypeLevelResponse, WorkspaceDetailResponse } from 'common/api/multimap';
import { Infobox } from 'common/components/Infobox/Infobox';
import { firstElementIn } from 'common/helpers/array.helpers';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

type Props = {
  detail: WorkspaceDetailResponse;
  objectTypes: ObjectTypeLevelResponse[];
  firstCreated?: boolean;
  onStart: () => void;
  onCreate: (level: number) => void;
};

export const Guidance: React.FC<Props> = ({
  detail,

  objectTypes,
  firstCreated,
  onStart,
  onCreate,
}) => {
  const [showGuidance, setShowGuidance] = useLocalStorage('admin.workspace.portfolio.guidance', { guidance: true });
  const [hasInfobox, setHasInfobox] = useState(showGuidance.guidance);

  const emptyLevels = objectTypes
    .filter((t) => {
      const r = detail.levels.filter((l) => l.level === t.level).length === 0;
      return r;
    })
    .filter((t, idx) => idx === 0 || detail.levels.filter((l) => l.level === idx - 1).length === 1)
    .map((x) => x.level)
    .sort();
  const level = firstElementIn(emptyLevels);
  if (!level) {
    return <></>;
  }

  const hideGuidanceHandler = () => {
    setShowGuidance((x) => {
      x.guidance = false;
      return x;
    });
    setHasInfobox(false);
  };

  const onClickHandler = () => {
    if (detail.levels.length === 0) {
      onStart();
    } else {
      onCreate(level);
    }
  };

  let title = level === 1 ? 'Opprett din portefølje' : 'Ønsker du å koble til objekter på det neste nivået?';
  let text =
    level === 1
      ? 'Vennligst importer dine objekter via import eller legg dem til manuel'
      : 'Du kan velge å bygge ut din portefølje på det overordnet nivået eller legge til objekter på det neste nivået i hierarkien ...';
  const actionText =
    level === 1 ? 'Sett i gang med registreringen av dine porteføljeobjekter' : 'Legg til objekt på det neste nivået';

  if (firstCreated) {
    title = 'Du har lagt til ditt første objekt!';
    text =
      'Du kan velge å bygge ut din portefølje på det overordnet nivået eller legge til objekter knyttet til det første.';
    // actionText = 'Legg til objekt på det neste nivået';
  }

  return (
    <Infobox
      show={hasInfobox}
      variant="light"
      title={title}
      text={text}
      icon={<LightbulbIcon color="primary" />}
      actionText={actionText}
      actionIcon={<ArrowForwardIcon />}
      onClick={onClickHandler}
    >
      <div>
        <Button variant="link" className="ms-auto align-self-center p-0" onClick={hideGuidanceHandler}>
          <div className="me-2">Ikke vis igjen</div>
        </Button>
      </div>
    </Infobox>
  );
};

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { ReportOverviewResponse } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import { Button, Card, Col, ProgressBar, Row, Stack } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router';

export interface IProps {
  report: ReportOverviewResponse;
  loading: boolean;
}

export interface DataSet {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderWidth: number;
}
export interface ChartData {
  labels: string[];
  datasets: DataSet[];
}

export const ReportCardOverview: React.FC<IProps> = ({ report, loading }) => {
  const navigate = useNavigate();

  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
      },
    },
  };

  const [chartData, setChartData] = useState({
    labels: [''],
    datasets: [{ label: '', data: [0], backgroundColor: ['#14BE14'] }],
  });

  const roundDegree = (value: number) => {
    if (value < 0.75) {
      return "0";
    } else if (value >= 0.75 && value < 1.5) {
      return "1";
    } else if (value >= 1.5 && value < 2.25) {
      return "2";
    } else {
      return "3";
    }
  }

  useEffect(() => {
    if (report) {
      let TG0 = 0;
      let TG1 = 0;
      let TG2 = 0;
      let TG3 = 0;

      report.reportObjects.map((row, i) => {
        switch (roundDegree(row.averageWTCD)) {
          case '0':
            TG0 += row.areal;
            break;
          case '1':
            TG1 += row.areal;
            break;
          case '2':
            TG2 += row.areal;
            break;
          case '3':
            TG3 += row.areal;
            break;
          default:
            TG3 += row.areal;
        }
      });

      setChartData({
        labels: ['TG 0', 'TG 1', 'TG 2', 'TG 3'],
        datasets: [
          {
            label: `Tilstandsgrad fordelt på areal`,
            data: [TG0, TG1, TG2, TG3],
            backgroundColor: ['#14BE14', '#B8E926', '#FAAB35', '#FF0000'],
          },
        ],
      });
    }
  }, [report]);

  return (
    <>
      <LoadingSpinner isLoading={loading} />

      {report && (
        <Card className="p-3">
          <Row>
            <Col>
              <h4>{report.moduleName}</h4>
            </Col>
          </Row>
          <Row>
            <Col xs={8} className="d-flex border-end flex-column">
              <h5 className="text-nowrap">{chartData.datasets[0].label}</h5>
              <div>
                <Doughnut options={options} data={chartData} />
              </div>
            </Col>
            <Col xs={4}>
              <h5>Nøkkeltall</h5>
              <small>Samlet tilstandsgrad</small>
              <h4 className="border-bottom pb-2">
                {report.collectedCD.toFixed(2)} <ConditionDegreeIcon conditionDegree={report.collectedCD} />
              </h4>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

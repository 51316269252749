import { ObjectPropertyResponse, WorkspaceImportVerifyRowResponse } from 'common/api/multimap';
import { Pill } from 'common/components/Pill/Pill';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { Guid } from 'common/types/guid.type';
import { OSMNominatim } from 'features/admin/hooks/useOpenStreetMapNominatim';
import React from 'react';
import { Badge, Stack, Table } from 'react-bootstrap';

import { Expander, useExpander } from '../../../../common/components/Expander/Expander';

type IProps = {
  rows: WorkspaceImportVerifyRowResponse[];
  properties: ObjectPropertyResponse[];
};

export const ImportRowsResult: React.FC<IProps> = ({ rows, properties }) => {
  const [expandedPathIds, togglePathId, isPathExpanded] = useExpander<Guid>();
  const [expandedDetailIds, toggleDetailId, isDetailExpanded] = useExpander<Guid>();

  const asTreeParts = (tree: WorkspaceImportVerifyRowResponse[], row: WorkspaceImportVerifyRowResponse): string[] => {
    let current: WorkspaceImportVerifyRowResponse | undefined = row;
    const parts = [];
    while (current !== undefined) {
      parts.push(current.objectName);
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      current = tree.find((x) => x.objectId === current?.parentObjectId);
    }
    return parts;
  };

  return (
    <>
      <TableContainer>
        <Table hover responsive className="caption-top">
          <caption>Rader som vil bli importert</caption>
          <thead className="table-light">
            <tr>
              <th scope="col" className="text-nowrap">
                Nivå
              </th>
              <th scope="col" className="text-nowrap">
                Foreldreobjekt
              </th>
              <th scope="col" className="text-nowrap">
                Navn
              </th>
              <th scope="col" className="text-nowrap">
                Objekttype
              </th>
              <th scope="col" className="text-nowrap">
                Beskrivelse
              </th>
              <th scope="col">Egenskaper</th>
            </tr>
          </thead>
          <tbody>
            {rows
              .sort(
                (v1, v2) =>
                  v1.level - v2.level ||
                  (v1.parentObjectName ?? '').localeCompare(v2.parentObjectName ?? '') ||
                  v1.objectName.localeCompare(v2.objectName),
              )
              .map((row) => {
                return (
                  <React.Fragment key={row.objectId}>
                    <tr>
                      <td className="text-center">
                        <span>{row.level}</span>
                      </td>
                      <td>
                        {(true || row.properties.length > 0) && (
                          <Stack direction="horizontal" gap={3}>
                            <span>{row.parentObjectName}</span>
                            {row.parentObjectName && (
                              <div className="ms-auto">
                                <Expander
                                  variant='link-tertiary'
                                  size="sm"
                                  className='py-0'
                                  ids={expandedPathIds}
                                  id={row.objectId}
                                  onToggle={() => togglePathId(row.objectId)}
                                />
                              </div>
                            )}
                          </Stack>
                        )}
                        {isPathExpanded(row.objectId) && (
                          <ul>
                            {asTreeParts(rows, row)
                              .reverse()
                              .map((x) => (
                                <li key={x}>{x}</li>
                              ))}
                          </ul>
                        )}
                      </td>
                      <td>
                        <span>{row.objectName}</span>
                      </td>
                      <td>
                        <span>{row.objectTypeName}</span>
                      </td>
                      <td>
                        <span>{row.objectDescription}</span>
                      </td>
                      <td>
                        {(true || row.properties.length > 0) && (
                          <Stack direction="horizontal" gap={3}>
                            <Pill
                              variant="secondary"
                              className={`ms-auto ${row.properties.length > 0 ? 'visible' : 'invisible'}`}
                            >
                              {row.properties.length}
                            </Pill>

                            <Expander
                              variant='link-tertiary'
                              size="sm"
                              className='py-0'
                              ids={expandedDetailIds}
                              id={row.objectId}
                              onToggle={() => toggleDetailId(row.objectId)}
                            />
                          </Stack>
                        )}
                      </td>
                    </tr>
                    {isDetailExpanded(row.objectId) && (
                      <tr className="border-0">
                        <td className="border-0"></td>
                        <td colSpan={8} className="p-0 border-0">
                          <Table hover responsive className="m-0">
                            <thead>
                              <tr>
                                <th scope="col" className="text-nowrap">
                                  Egenskap
                                </th>
                                <th scope="col" className="text-nowrap">
                                  Verdi
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr key={`detail-${row.objectId}-${row.objectId}`}>
                                <td>
                                  <span>* Object ID</span>
                                </td>
                                <td>
                                  <span>{row.objectId}</span>
                                </td>
                              </tr>
                              <tr key={`detail-${row.objectId}-${row.parentObjectId}`}>
                                <td>
                                  <span>Foreldreobjekt ID</span>
                                </td>
                                <td>
                                  <span>{row.parentObjectId}</span>
                                </td>
                              </tr>
                              {row.properties
                                .map((x) => {
                                  return {
                                    property: properties.find((p) => p.objectPropertyId == x.objectPropertyId),
                                    value: x,
                                  };
                                })
                                .sort(
                                  (v1, v2) =>
                                    (v1.property?.required ? -1 : 0) - (v2.property?.required ? -1 : 0) ||
                                    (v1.property?.systemName ?? '').localeCompare(v2.property?.systemName ?? ''),
                                )
                                .map((prop) => {
                                  return (
                                    <tr key={`detail-${row.objectId}-${prop.property?.objectPropertyId}`}>
                                      <td>
                                        <span>
                                          {prop.property?.required && <span>*</span>} {prop.property?.systemName}
                                        </span>
                                      </td>
                                      <td>
                                        <span>
                                          {prop.property?.datatypeId === 'Address' && !!prop.value.valueString && (
                                            <>
                                              {/* {prop.value.valueString} */}
                                              {(JSON.parse(prop.value.valueString) as OSMNominatim).display_name}
                                            </>
                                          )}
                                          {prop.property?.datatypeId !== 'Address' && (
                                            <>
                                              {prop.value.valueString ||
                                                prop.value.valueDecimal ||
                                                prop.value.valueInteger ||
                                                prop.property?.options.find(
                                                  (o) => o.objectPropertyOptionId == prop.value.valueOptionReferenceId,
                                                )?.systemName}
                                            </>
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};

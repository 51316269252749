import { PageTitle } from 'common/components/page-title/PageTitle';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetLearning from 'features/admin/mapping-sets/hooks/useGetLearning';
import { Button, Modal } from 'react-bootstrap';

interface ReadMore {
  show: boolean;
  onHide: () => void;
  title: string;
  text: string;

}

export const ReadMoreModal: React.FC<ReadMore> = ({ show, onHide, title, text }) => {

  return (
    <Modal size="lg" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="tertiary" onClick={onHide}>
          Lukk
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

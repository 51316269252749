import useDeleteOrganizationServiceType from 'common/api/hooks/useDeleteOrganizationServiceType';
import useGetOrganizationServiceTypes from 'common/api/hooks/useGetOrganizationServiceTypes';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useState } from 'react';
import { Button, Card, Col, Modal, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router';

export const OrganizationServiceTypes = () => {
  const { organizationId } = useParams();

  const [, , data, fetch] = useGetOrganizationServiceTypes(organizationId ?? '');

  const [, , , result, remove] = useDeleteOrganizationServiceType();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [organizationServiceTypeId, setOrganizationServiceTypeId] = useState<string>('');

  return (
    <>
      {data && (
        <>
          <PageTitle title="Tilleggsfunksjonalitet" backTo={`/admin/organizations/${organizationId}`}></PageTitle>
          <h4 className="mt-2">Oversikt</h4>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <Stack direction="vertical">
                    <small className="bold">Status</small>
                    <h5>Aktiv</h5>
                  </Stack>
                </Col>
                <Col>
                  <Stack direction="vertical">
                    <small className="bold">Tilleggsfunksjonalitet</small>
                    <h5>{data.length}</h5>
                  </Stack>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <h4>Tilleggsfunksjonalitet som organisasjonen abonnerer på:</h4>
          {data.length > 0 ? (
            data.map((row, i) => (
              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Navn:</small>
                        <span>{row.serviceType == 'Planning' ? 'Planlegging' : ''}</span>
                      </Stack>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <Button
                        variant="warning"
                        onClick={() => {
                          setShowDeleteModal(true);
                          setOrganizationServiceTypeId(row.organizationServiceTypeId);
                        }}
                      >
                        Fjern
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>Organisasjonen har ikke abonnert på noe tilleggsfunksjonaliteter.</p>
          )}
        </>
      )}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Er du sikker på at du vil slette abonnementet på kunde?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sletter du abonnementet her i multiMap blir det ikke automatisk fjernet fra Maconomy. Om du skal velge å
          fjerne husk og gjøre det samme i Maconomy.
          <br></br>
          <br></br>
          Kundedata blir heller ikke fjernet med å fjerne abonnement, slik at kunde kan abonnere igjen på et senere
          tidspunkt og fortsatt se kartleggingsdataene sine.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={() => setShowDeleteModal(false)}>
            Avbryt
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              remove({ organizationId: organizationId ?? '', organizationServiceTypeId: organizationServiceTypeId });
              setShowDeleteModal(false);
            }}
          >
            Ja, jeg vil slette abonnementet.
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { ErrorMessage } from '@hookform/error-message';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { MappingParameterResponse } from 'common/api/multimap';
import { BoolIcon } from 'common/components/BoolIcon/BoolIcon';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

/* eslint-disable import/named */
import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface IProps {
  nestIndex: number;
  control: Control<FieldValues, any>;
  errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
  register: UseFormRegister<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  editParameters: boolean;
}

export const Parameters: React.FC<IProps> = ({ nestIndex, control, errors, register, getValues, editParameters }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'mappingSets.detail' });

  const {
    fields: parameters,
    append,
    remove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `mappingGroups[${nestIndex}].mappingParameters`, // unique name for your Field Array
  });

  return (
    <>
      {editParameters ? (
        <>
          {parameters.length !== 0 && (
            <Row className="mb-3">
              <Col md={2}>{t('sortingLabel')}</Col>
              <Col>{t('editMappingParameterLabel')}</Col>
              <Col md={2} className="d-flex justify-content-center align-items-center text-nowrap">
                {t('lowerLevelDeviationLabel')}{' '}
                <HelpPopover title="info" text="info" type={'help'} id={'para' + nestIndex} />
              </Col>
              <Col md={1}></Col>
            </Row>
          )}

          {parameters &&
            getValues(`mappingGroups[${nestIndex}].mappingParameters`)
              .sort((a: MappingParameterResponse, b: MappingParameterResponse) => {
                return a.sortOrder - b.sortOrder;
              })
              .map((parameter: MappingParameterResponse, index: number) => {
                return (
                  <Row key={parameter.mappingParameterId} className="mb-2">
                    <Col md={2}>
                      <Form.Control
                        type="number"
                        {...register(`mappingGroups[${nestIndex}].mappingParameters.${index}.sortOrder`, {
                          required: 'Angi verdi her',
                        })}
                        isInvalid={!!errors.mappingGroups?.[nestIndex]?.mappingParameters?.[index]}
                      ></Form.Control>
                      <Form.Text className="text-danger">
                        <ErrorMessage
                          errors={errors}
                          name={`mappingGroups[${nestIndex}].mappingParameters.${index}.sortOrder`}
                        />
                      </Form.Text>
                    </Col>

                    <Col>
                      <Form.Control
                        type="text"
                        {...register(`mappingGroups[${nestIndex}].mappingParameters.${index}.systemName`)}
                      ></Form.Control>
                    </Col>

                    <Col md={2} className="d-flex justify-content-center align-items-center border-end">
                      <Form.Check
                        {...register(`mappingGroups[${nestIndex}].mappingParameters.${index}.lowerLevelDeviation`)}
                      ></Form.Check>
                    </Col>
                    <Col md={1} className="d-flex justify-content-center align-items-center">
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <RemoveCircleOutlinedIcon />
                      </Button>
                    </Col>
                  </Row>
                );
              })}

          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              append({ sortOrder: parameters.length + 1, systemName: '', mappingParameters: [] });
            }}
          >
            <AddCircleOutlineOutlinedIcon /> {t('addMappingParameter')}
          </Button>
        </>
      ) : (
        <TableContainer>
          <Table hover responsive>
            <thead>
              {parameters.length !== 0 && (
                <tr>
                  <th scope="col" className="col-md-1">
                    {t('sortingLabel')}
                  </th>
                  <th scope="col">{t('mappingParameterLabel')}</th>
                  <th scope="col" className="col-md-3 text-nowrap">
                    {t('lowerLevelDeviationLabel')}
                  </th>
                </tr>
              )}
            </thead>
            <tbody>
              {parameters &&
                getValues(`mappingGroups[${nestIndex}].mappingParameters`)
                  ?.sort((a: MappingParameterResponse, b: MappingParameterResponse) => {
                    return a.sortOrder - b.sortOrder;
                  })
                  .map((parameter: MappingParameterResponse, index: number) => {
                    return (
                      <tr key={parameter.mappingParameterId}>
                        <td className="text-end col-md-1">
                          <span>{getValues(`mappingGroups[${nestIndex}].mappingParameters.${index}.sortOrder`)}</span>
                        </td>
                        <td className="col-md-10">
                          <span>{getValues(`mappingGroups[${nestIndex}].mappingParameters.${index}.systemName`)}</span>
                        </td>
                        <td className="text-center col-md-3">
                          <span>
                            {
                              <BoolIcon
                                value={getValues(
                                  `mappingGroups[${nestIndex}].mappingParameters.${index}.lowerLevelDeviation`,
                                )}
                              />
                            }
                          </span>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

import { customerAccessRequestApi, homeApi, modulesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { CustomerAccessRequestResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetRequestOnUserId(
  userId: Guid | undefined,
): [
  boolean,
  boolean,
  CustomerAccessRequestResponse | undefined,
  () => Promise<CustomerAccessRequestResponse | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch, isIdle } = useQuery(
    [QueryKeys.Home, 'maintenance plans: ' + userId],
    () => customerAccessRequestApi.getByUserIdRoute(userId ?? '').then((x) => x.data),
    {
      enabled: !!userId,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetRequestOnUserId;

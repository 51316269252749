import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { ObjectTypeLevelResponse } from 'common/api/multimap';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { Button, InputGroup, Form as RBForm } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { withLangs } from '../helpers/withLangs';
import { EditText } from './components/EditText';

export interface IProps {
  label: string;
  data: ObjectTypeLevelResponse;
  isNew: boolean;
  canEditData: boolean;
  canEditTexts: boolean;
  isEditingProperties: boolean;

  editPropertyClick?: () => void;
  hasChanged: () => void;
  hasData: (data: ObjectTypeLevelResponse) => void;
}

export const LevelEditor: React.FC<IProps> = ({
  label,
  data,

  isNew,
  canEditData,
  canEditTexts,
  isEditingProperties,

  editPropertyClick: editPropertyClick,
  hasChanged,
  hasData,
}) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes.detail.levelEditor' });

  const header = (
    <div className="d-flex justify-content-between">
      <small> {label} </small>
      {!isNew && (
        <small>
          {t('objects', { qty: data.usedBy })}
          <HelpPopover
            id={`used-by-objects-${data.level}`}
            type="info"
            title={t('objectsTooltipTitle')}
            svgProps={{ fontSize: 'small' }}
            text={t('objectsTooltipText')}
          ></HelpPopover>
        </small>
      )}
      <small>{t('properties', { qty: data.properties?.length ?? 0 })}</small>
    </div>
  );

  const desc = data.systemDescription || t('descriptionHint');

  return (
    <div className="d-flex flex-column">
      {header}

      <div className="d-flex flex-column">
        <div className="d-flex mb-2">
          <InputGroup>
            <RBForm.Control
              type="text"
              id="name"
              aria-describedby="name"
              readOnly={!canEditData}
              defaultValue={withLangs(data.name).defaultText.text}
              onChange={(e) => {
                hasData({ ...data, name: withLangs(data.name).update(e.target.value, 'Norwegian') });
                hasChanged();
              }}
            />

            {canEditTexts && (
              <EditText
                texts={data.name}
                multiline={false}
                hasData={(e) => {
                  hasData({ ...data, name: e });
                  hasChanged();
                }}
              ></EditText>
            )}
          </InputGroup>
        </div>

        <div className="d-flex mb-2">
          <InputGroup className="flex-nowrap justify-content-between">
            <span className="text-truncate inline-input-sm" title={desc}>
              {desc || '---'}
            </span>
            {canEditTexts && (
              <EditText
                texts={data.description}
                multiline={true}
                hasData={(e) => {
                  hasData({ ...data, description: e });
                  hasChanged();
                }}
              ></EditText>
            )}
          </InputGroup>
        </div>

        {canEditData && (
          <div className="d-flex">
            <Button
              size="sm"
              variant={isEditingProperties ? 'primary' : 'secondary'}
              active={false}
              onClick={editPropertyClick}
            >
              <AddCircleOutlineOutlinedIcon /> {t('propertiesEdit')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

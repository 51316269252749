import { PageTitle } from 'common/components/page-title/PageTitle';
import { Col, Container, Row } from 'react-bootstrap';

export const LearningOverview = () => {
  return (
    <>
      <PageTitle title="Oversikt" />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/PWV9Mz95xmE?si=ZBaR5RKZESeQ4J3-"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <br></br>
      <br></br>
      <p>
        Oversiktsfanen viser et overordnet sammendrag av portefølje, kartlegginger, rapporter og planer. Etter hvert som
        organisasjonen registrerer informasjon i multiMap, vil oversiktsfanen oppdateres. Oversiktfanen gir dermed en
        oppdatert status over bruken av verktøyet.
      </p>

      <p>
        Oversiktsfanen er delt opp i fire områder:
        <ul>
          <li>
            <b>Portefølje</b> viser informasjon om registrert bygningsportefølje. Her ser man registrerte objekter på de
            ulike nivåene i objekthierarkiet, totalt registrert bruttoareal og gjennomsnittlig byggeår for porteføljen.
          </li>
          <li>
            <b>Alle kartlegginger</b> viser alle kartleggingsmoduler som har blitt bestilt av organisasjonen. Her ser
            man pågående, avsluttede og ikke påbegynte kartlegginger.
          </li>
          <li>
            <b>Rapporter</b> vises en oppsummering av rapporter for fullførte kartlegginger. Her får man informasjon om
            tilstandsgrad fordelt på bygg, samlet tilstandsgrad for porteføljen og andel av porteføljen som er kartlagt.{' '}
          </li>
          <li>
            <b>Vedlikeholdsplan</b> viser en oppsummering av antall tiltak i vedlikeholdsplanen og tilhørende estimert
            og kalkulert kostnad for å gjennomføre tiltakene.
          </li>
        </ul>
      </p>
      <p>
        Oversiktfanen inneholder snarveier til de fire ovennevnte områdene. Ved å følge snarveiene kommer man direkte
        til hovedsidene for de respektive områdene.{' '}
      </p>
    </>
  );
};

import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/ErrorOutline';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { CreateCustomerAccessRequest } from 'common/api/multimap';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useEffect } from 'react';
import { Anchor, Button, Col, Container, Row } from 'react-bootstrap';

import useCreateAccessRequestApi from '../../hooks/useCreateAccessRequestApi';

export interface IProps {
  request?: CreateCustomerAccessRequest;
  onSuccess: () => void;
  onError: () => void;
  onCloseRequest: () => void;
  isActive: boolean;
}

export const RequestCustomerAccess: React.FC<IProps> = ({ request, onSuccess, onError, onCloseRequest, isActive }) => {
  const [isLoading, isError, isSuccess, isIdle, validationError, data, create] = useCreateAccessRequestApi();

  useEffect(() => {
    if (!isActive || !isIdle || isSuccess || isError || isLoading) return;

    if (request) create(request);
  }, [isActive, request, isError, create, isLoading, isSuccess, isIdle]);

  useEffect(() => {
    if (isSuccess && isActive) {
      setTimeout(() => {
        onSuccess();
      }, 3000);
    }
  }, [isSuccess, isActive, onSuccess]);

  useEffect(() => {
    if (isError) onError();
  }, [isError, onError]);

  return (
    <Container fluid>
      <Row className="d-flex justify-content-center">
        <Col className="text-center">
          <LoadingSpinner isLoading={isLoading} />

          {isError && (
            <div>
              <WarningIcon className="size-xl mb-4" />
              <h2>Noe gikk galt...</h2>
              <p>{validationError?.genericErrors?.[0]}</p>
              <Button variant="primary" onClick={onCloseRequest}>
                Lukk
              </Button>
              <p className="mt-3">
                <span>
                  <HelpIcon className="size-sm" />{' '}
                </span>
                <small>
                  Har du spørsmål eller lurer på noe er det bare å ta{' '}
                  <Anchor href="https://www.multiconsult.no">kontakt</Anchor>
                </small>
              </p>
            </div>
          )}
          {isSuccess && (
            <div>
              <CheckIcon className="size-xl mb-4" />
              <h2>Success</h2>
              <p>Du blir snart videresendt til neste steg</p>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

import { AxiosError } from 'axios';
import { maintenancePlansApi, modulesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { ValidationProblemDetails } from 'common/api/multimap/api';
import { Guid } from 'common/types/guid.type';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

type UpdateRequest = {
  workspaceModuleId: Guid;
  file: File;
};

function useImportMaintenancePlans(): [
  boolean,
  boolean,
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  boolean | undefined,
  (request: UpdateRequest) => void,
  () => void,
] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (request: UpdateRequest) => {
      return maintenancePlansApi
        .maintenancePlansWorkspaceModuleIdImportPost(request.workspaceModuleId, [request.file])
        .then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.MaintenancePlans);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const action = (payload: UpdateRequest) => mutation.mutate(payload);

  return [
    mutation.isLoading,
    mutation.isError,
    mutation.isSuccess,
    mutation.isIdle,
    validationError,
    mutation.data,
    action,
    mutation.reset,
  ];
}

export default useImportMaintenancePlans;

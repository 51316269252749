import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  background?: boolean;
  title: React.ReactNode;
  backTo?: string;
  children?: JSX.Element | JSX.Element[];
};

export const PageTitle: React.FC<Props> = ({ background, title, backTo, children }) => {
  const { t } = useTranslation('shared');

  return (
    <div className={`${background ? 'background-blue50 p-3' : ''}`}>
      {backTo ? (
        <>
          <div className="mb-3">
            <Link to={backTo} className="link-primary text-capitalize mb-3">
              <ArrowBackIcon fontSize="medium" /> {t('back')}
            </Link>
          </div>

          <div className="d-flex justify-content-between">
            <h3 className="mr-auto">{title}</h3>
            {children && <div>{children}</div>}
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-between">
          <h2 className="mb-5">{title}</h2>
          {children && <div>{children}</div>}
        </div>
      )}
    </div>
  );
};

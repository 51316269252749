import TranslateIcon from '@mui/icons-material/Translate';
import { TextLanguageResponse } from 'common/api/multimap';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { defaultLanguages } from '../../helpers/defaultLanguages';
import { UpdateTextModal } from './UpdateTextModal';

export interface IProps {
  texts: TextLanguageResponse[];
  multiline: boolean;
  hasData: (data: TextLanguageResponse[]) => void;
}

export const EditText: React.FC<IProps> = ({ texts, multiline, hasData }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <UpdateTextModal
        show={isEditing}
        langs={defaultLanguages}
        texts={texts}
        multiline={multiline}
        onHide={() => setIsEditing(false)}
        hasData={(e) => {
          hasData(e);
          setIsEditing(false);
        }}
      ></UpdateTextModal>
      <Button className="border-0 shadow-none" size="sm" variant="primary" onClick={() => setIsEditing(true)}>
        <TranslateIcon />
      </Button>
    </>
  );
};

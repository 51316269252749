import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { ProgressBar } from 'react-bootstrap';

import useGetActiveMappingPeriod from '../../hooks/useGetActiveMappingPeriod';

export interface IProps {
  workspaceModuleId: string;
}
export const MappingObjectsProgress: React.FC<IProps> = ({ workspaceModuleId }) => {
  const [loading, , data] = useGetActiveMappingPeriod(workspaceModuleId);

  return (
    <>
      <LoadingSpinner isLoading={loading} />
      {data && (
        <ProgressBar>
          <ProgressBar
            striped
            animated
            variant="success"
            now={data.mappingObjectsAmountDone * 100}
            key={1}
            label={data.mappingObjectsAmountDone?.toString()}
          />
          <ProgressBar
            striped
            animated
            variant="secondary"
            now={data.mappingObjectsAmountStarted * 100}
            key={2}
            label={data.mappingObjectsAmountStarted?.toString()}
          />
          <ProgressBar
            striped
            animated
            variant="light"
            now={data.mappingObjectsAmountNotStarted * 100}
            key={3}
            label={data.mappingObjectsAmountNotStarted?.toString()}
            style={{ color: 'black' }}
          />
        </ProgressBar>
      )}
    </>
  );
};

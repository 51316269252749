import { SearchOutlined } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { WorkspaceResponse } from 'common/api/multimap';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { asDateTime } from 'common/helpers/dateHelpers';
import { Guid } from 'common/types/guid.type';
import { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Stack, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { LoadingSpinner } from '../components/loading-spinner/LoadingSpinner';
import useGetWorkspaces from './hooks/useGetWorkspaces';

export const Workspaces = () => {
  const navigate = useNavigate();

  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.workspaces' });
  const [isLoading, , data] = useGetWorkspaces();
  const [items, setItems] = useState<WorkspaceResponse[]>([]);
  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    const x = [...(data ?? [])].sort((v1, v2) => v1.systemName.localeCompare(v2.systemName));
    setItems(x || []);
  }, [data]);

  const onActiveChanged = (workspaceId: Guid, active: boolean) => {
    setItems((x) => {
      const workspaces = [...x];
      const workspace = workspaces.find((q) => q.workspaceId === workspaceId);
      if (!workspace) {
        return x;
      }
      workspace.organizationId = active ? '' : null;
      return workspaces;
    });
  };

  const pageTitle = (
    <PageTitle title={t('title')}>
      <Button variant="tertiary" size="sm" onClick={() => navigate(`/admin/workspaces/detail`)}>
        <AddCircleOutlineOutlinedIcon /> {t('actionCreate')}
      </Button>
    </PageTitle>
  );

  return (
    <>
      {pageTitle}

      <LoadingSpinner isLoading={isLoading} />

      <TableContainer>
        <Stack direction="horizontal" className="table-filter">
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder="Søk på arbeidsområder"
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </Stack>
        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">{t('table.activity')}</th>
              <th scope="col">{t('table.name')}</th>
              <th scope="col">{t('table.type')}</th>
              <th scope="col" className="text-nowrap">
                {t('table.createdAt')}
              </th>
              <th scope="col" className="text-nowrap">
                {t('table.lastModifiedBy')}
              </th>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {items
              .filter((x) => x.systemName.toLowerCase().includes(filter.toLowerCase()))
              .map((row) => {
                return (
                  <tr key={row.workspaceId}>
                    <td>
                      <Form.Switch
                        className="d-flex justify-content-center"
                        id="isActive"
                        aria-label="aktivitet"
                        checked={row.organizationId !== null}
                        onChange={(e) => {
                          onActiveChanged(row.workspaceId, e.target.checked);
                        }}
                      />
                    </td>
                    <td>
                      <span>{row.systemName}</span>
                    </td>
                    <td>
                      <span>{row.objectType.systemName}</span>
                    </td>
                    <td className="text-end">
                      <span>{(row.createdOn && asDateTime(row.createdOn)) || ''}</span>
                    </td>
                    <td>
                      <span>{/* TODO: */}</span>
                    </td>
                    <td>
                      <Link
                        className="text-nowrap"
                        to={`/admin/workspaces/${row.workspaceId}/types/${row.objectType.objectTypeId}/detail`}
                      >
                        {t('table.actionDetail')}
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};

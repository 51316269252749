import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { ReportOverviewResponse } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { arrayRange } from 'common/helpers/array.helpers';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetReportOverview from 'features/user/home/hooks/useGetReportOverview';
import { useEffect, useState } from 'react';
import { Button, Card, Col, ProgressBar, Row, Stack, Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router';

export interface IProps {
  reportOverview: ReportOverviewResponse | undefined;
  isLoading: boolean;
}
export interface DataSet {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderWidth: number;
}
export interface ChartData {
  labels: string[];
  datasets: DataSet[];
}

export const ReportCardBarArealYearBasisReport: React.FC<IProps> = ({ reportOverview, isLoading }) => {
  const navigate = useNavigate();

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
      },
    },
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
    maintainAspectRatio: false,
  };

  const [chartData, setChartData] = useState({
    labels: [''],
    datasets: [{ label: '', data: [0], backgroundColor: ['#14BE14'] }],
  });

  useEffect(() => {
    if (reportOverview) {
      let labels: string[] = [];

      reportOverview.reportObjects.map((row, i) => {
        const year = Math.floor(row.year / 10) * 10;

        if (!labels.includes(year.toString())) {
          labels.push(year.toString());
        }
      });

      labels = labels.sort((a, b) => {
        return parseInt(a) - parseInt(b);
      });

      const color = (value: number) => {
        if (value < 0.75) {
          return '#14BE14';
        } else if (value >= 0.75 && value < 1.5) {
          return '#B8E926';
        } else if (value >= 1.5 && value < 2.25) {
          return '#FAAB35';
        } else {
          return '#FF0000';
        }
      };

      const TG0values = labels.map(() => 0);
      const TG1values = labels.map(() => 0);
      const TG2values = labels.map(() => 0);
      const TG3values = labels.map(() => 0);

      reportOverview.reportObjects.map((row, i) => {
        const year = Math.floor(row.year / 10) * 10;
        const index = labels.findIndex((x) => x == year.toString());

        if (row.children.length > 0) {
          row.children.map((child, j) => {
            if (child.averageWTCD < 0.75) {
              TG0values[index] += child.areal;
            } else if (child.averageWTCD < 1.5) {
              TG1values[index] += child.areal;
            } else if (child.averageWTCD < 2.25) {
              TG2values[index] += child.areal;
            } else {
              TG3values[index] += child.areal;
            }
          })
        } else {
          if (row.averageWTCD < 0.75) {
            TG0values[index] += row.areal;
          } else if (row.averageWTCD < 1.5) {
            TG1values[index] += row.areal;
          } else if (row.averageWTCD < 2.25) {
            TG2values[index] += row.areal;
          } else {
            TG3values[index] += row.areal;
          }
        }
      });

      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'TG 0',
            data: TG0values,
            backgroundColor: [color(0)],
          },
          {
            label: 'TG 1',
            data: TG1values,
            backgroundColor: [color(1)],
          },
          {
            label: 'TG 2',
            data: TG2values,
            backgroundColor: [color(2)],
          },
          {
            label: 'TG 3',
            data: TG3values,
            backgroundColor: [color(3)],
          },
        ],
      });
    }
  }, [reportOverview]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {reportOverview && (
        <>
          <div className="d-flex justify-content-between">
            <h4>Samlet areal og {reportOverview.moduleName.toLowerCase()} etter byggperiode</h4>
            <HelpPopover
              id="stacked-barchart"
              type="info"
              title="Stacked bar chart"
              text="Grafen viser mengden som er blitt kartlagt, oppdelt etter oppføringsperiode og avrundet tilstand. Dette gir en visuell representasjon av hvordan mengden er fordelt mellom de ulike oppføringsperiodene og tilstandene."
            />
          </div>
          <Card className="p-3 mb-3">
            <Row>
              <Col>
                <div style={{ maxHeight: '500px', minHeight: '500px' }} className="pb-3">
                  <h4>Oversikt</h4>
                  <Bar options={options} data={chartData} />
                </div>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

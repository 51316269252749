import { ArrowDropDown } from '@mui/icons-material';
import { CreateMaintenancePlanRequest, MaintenancePlanType } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { arrayRange } from 'common/helpers/array.helpers';
import useGetMappingObjectDetail from 'features/user/mapping/hooks/useGetMappingObjectDetail';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import useAddMaintenancePlan from '../hooks/useAddMaintenancePlan';

export interface IProps {
  show: boolean;
  onHide: () => void;
  mappingObjectId: string;
  moduleName: string;
  workspaceModuleId: string;
}

export const CreateMaintenancePlanModal: React.FC<IProps> = ({
  show,
  onHide,
  mappingObjectId,
  moduleName,
  workspaceModuleId,
}) => {
  const [, , mappingObjectDetail, fetch] = useGetMappingObjectDetail(mappingObjectId);

  const [, , , createdPlan, add] = useAddMaintenancePlan();

  const [chooseAll, setChooseAll] = useState<boolean>(false);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<CreateMaintenancePlanRequest>();

  const {
    fields: values,
    append: appendValue,
    remove: removeValue,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'maintenancePlanParameterValueBases', // unique name for your Field Array
  });

  const {
    fields: measures,
    append: appendMeasure,
    remove: removeMeasure,
  } = useFieldArray({
    control,

    name: 'maintenancePlanMeasures',
  });

  useEffect(() => {
    if (mappingObjectId) {
      fetch();
      if (measures.length <= 0)
        appendMeasure({
          startYear: new Date().getFullYear(),
          budgetYear: new Date().getFullYear(),
          priceEstimate: 0,
          priceCalculate: 0,
        });
    }
  }, [mappingObjectId]);

  const navigate = useNavigate();

  useEffect(() => {
    if (createdPlan) {
      navigate(`/maintenance-plan/${workspaceModuleId}/overview`);
    }
  }, [createdPlan]);

  const addMaintenancePlan = (d: CreateMaintenancePlanRequest) => {
    d.mappingObjectId = mappingObjectId;
    add({ request: d });
  };

  useEffect(() => {
    if (mappingObjectDetail) {
      if (chooseAll) {
        mappingObjectDetail?.valueGroups?.map((x, i) => {
          x.cdValues?.map((cd, index) => {
            appendValue({ mappingParameterValueId: cd.mappingParameterValueId });
          });
        });
      } else {
        reset({ maintenancePlanParameterValueBases: [], maintenancePlanMeasures: measures });
      }
    }
  }, [chooseAll]);

  const checkErrors = () => {
    if (errors.maintenancePlanName || errors.maintenancePlanNumber || errors.priority) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <Modal.Header closeButton>Opprett tiltak - {moduleName}</Modal.Header>
      <Form onSubmit={handleSubmit((d) => addMaintenancePlan(d))}>
        {mappingObjectDetail && (
          <Modal.Body>
            <>
              <Row className="border-bottom mb-3">
                <Col>
                  <h5 className="mb-3">Plan detaljer</h5>
                  <Row className="mb-3">
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>Parent</Form.Label>
                        <Form.Control value={mappingObjectDetail.objectParentName} readOnly></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={8}>
                      <Form.Group>
                        <Form.Label>Child</Form.Label>
                        <Form.Control value={mappingObjectDetail.objectName} readOnly></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={8}>
                      <Form.Group>
                        <Form.Label>Prosjektnavn</Form.Label>
                        {errors.maintenancePlanName && <span className="ps-2 text-danger">*</span>}
                        <Form.Control
                          placeholder="Oppgi et prosjektnavn"
                          {...register('maintenancePlanName', { required: true })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>Prosjektnummer</Form.Label>
                        {errors.maintenancePlanNumber && <span className="ps-2 text-danger">*</span>}
                        <Form.Control
                          placeholder="Eksempel: 123"
                          type="number"
                          {...register('maintenancePlanNumber', { required: true })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>Tiltakstype</Form.Label>
                        <Form.Select {...register('maintenancePlanTypeId', { required: true })}>
                          <option value={MaintenancePlanType.Sikkerhet} selected>
                            {MaintenancePlanType.Sikkerhet}
                          </option>
                          <option value={MaintenancePlanType.Konomi}>{MaintenancePlanType.Konomi}</option>
                          <option value={MaintenancePlanType.Estetikk}>{MaintenancePlanType.Estetikk}</option>
                          <option value={MaintenancePlanType.Milj}>{MaintenancePlanType.Milj}</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>Konsekvensgrad</Form.Label>
                        <Form.Select {...register('consequenceDegree', { required: true })}>
                          <option value={0} selected>
                            0
                          </option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>Prioritet</Form.Label>
                        {errors.priority && <span className="ps-2 text-danger">*</span>}
                        <Form.Control
                          placeholder="Eksempel: 1"
                          type="number"
                          {...register('priority', { required: true })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group>
                        <Form.Label>Beskrivelse</Form.Label>
                        <Form.Control
                          placeholder="Oppgi en beskrivelse"
                          as="textarea"
                          {...register('maintenancePlanDescription')}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {checkErrors() && (
                    <Row>
                      <Col>
                        <p className="text-danger">Ikke alle felter er fylt ut, feltene er markert med "*"</p>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col>
                  <h5 className="mb-3">Dagens status og parameter</h5>
                  <Row className="mb-3">
                    <Col>
                      <Form.Check
                        label="Velg alle parametere"
                        checked={chooseAll}
                        onChange={() => {
                          setChooseAll(!chooseAll);
                        }}
                      ></Form.Check>
                    </Col>
                    <Col className="d-flex justify-content-end"></Col>
                  </Row>
                  <Row style={{ height: '500px', overflow: 'scroll' }}>
                    <Col xs={12}>
                      <>
                        {mappingObjectDetail.valueGroups &&
                          mappingObjectDetail.valueGroups
                            .sort((a, b) => {
                              return a.sortOrder - b.sortOrder;
                            })
                            .map((row, i) => (
                              <>
                                {row.cdValues &&
                                  row.cdValues
                                    .sort((a, b) => {
                                      return a.sortOrder - b.sortOrder;
                                    })
                                    .map((cd, index) => (
                                      <Card className="p-3 mb-3">
                                        <Row>
                                          <Col>
                                            <Form.Check
                                              value={cd.mappingParameterValueId}
                                              label={cd.mappingParameterName}
                                              checked={values.some(
                                                (x) => x.mappingParameterValueId == cd.mappingParameterValueId,
                                              )}
                                              onChange={() => {
                                                if (
                                                  values.some(
                                                    (x) => x.mappingParameterValueId == cd.mappingParameterValueId,
                                                  )
                                                ) {
                                                  const planIndex = values.findIndex(
                                                    (x) => x.mappingParameterValueId == cd.mappingParameterValueId,
                                                  );
                                                  removeValue(planIndex);
                                                } else {
                                                  appendValue({
                                                    mappingParameterValueId: cd.mappingParameterValueId,
                                                  });
                                                }
                                                console.log(values);
                                              }}
                                            ></Form.Check>
                                          </Col>
                                          {cd.childValues && cd.childValues.length > 0 ? (
                                            cd.childValues.map((child, indexChild) => (
                                              <Row className="border-bottom py-2">
                                                <Col>
                                                  <small>{child.mappingParameterName}</small>
                                                </Col>
                                                <Col className="d-flex justify-content-end">
                                                  <ConditionDegreeIcon conditionDegree={parseInt(child.value!)} />
                                                  <small className="ms-1">
                                                    {parseInt(child.value!) != 99
                                                      ? parseInt(child.value!).toFixed(1)
                                                      : 'N/A'}
                                                  </small>
                                                </Col>
                                              </Row>
                                            ))
                                          ) : (
                                            <Col className="d-flex justify-content-end">
                                              <div className="d-flex align-items-center">
                                                <ConditionDegreeIcon conditionDegree={parseInt(cd.value!)} />
                                                <small className="ms-1">
                                                  {' '}
                                                  {parseInt(cd.value!) != 99 ? parseInt(cd.value!).toFixed(1) : 'N/A'}
                                                </small>
                                              </div>
                                            </Col>
                                          )}
                                        </Row>
                                      </Card>
                                    ))}
                              </>
                            ))}
                      </>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <h5>Oppstart og kostnad</h5>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      appendMeasure({
                        startYear: new Date().getFullYear(),
                        budgetYear: new Date().getFullYear(),
                        priceEstimate: 0,
                        priceCalculate: 0,
                      });
                      console.log('test');
                    }}
                  >
                    Legg til ny oppstart og kostnads post
                  </Button>
                </Col>
              </Row>
              {measures.map((measure, i) => (
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Planlagt startår</Form.Label>

                      <Form.Select {...register(`maintenancePlanMeasures.${i}.startYear`)}>
                        {arrayRange(2000, 2100, 1).map((year, yearIndex) => (
                          <option value={year}>{year}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Budsjettert år</Form.Label>
                      <Form.Select {...register(`maintenancePlanMeasures.${i}.budgetYear`)}>
                        {arrayRange(2000, 2100, 1).map((year, yearIndex) => (
                          <option value={year}>{year}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Estimert pris</Form.Label>
                      <Form.Control
                        type="number"
                        {...register(`maintenancePlanMeasures.${i}.priceEstimate`)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Kalkulert pris</Form.Label>
                      <Form.Control
                        type="number"
                        {...register(`maintenancePlanMeasures.${i}.priceCalculate`)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              ))}
            </>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button
            variant="tertiary"
            onClick={() => {
              reset();
              onHide();
            }}
          >
            Avbryt
          </Button>
          <Button variant="secondary" type="submit">
            Opprett
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export interface IProps {
  reason: 404 | 500;
}

export const ErrorPage = ({ reason }: IProps) => {
  switch (reason) {
    case 404: {
      return <p className="text-danger">Page not found</p>;
    }

    default: {
      return <p className="text-danger">An error occurred</p>;
    }
  }
};

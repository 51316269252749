import { AxiosError } from 'axios';
import { modulesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { UpdateModuleMappingGroupDescriptionRequest, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type UpdateParams = {
  id: string;
  request: UpdateModuleMappingGroupDescriptionRequest[];
};

function useUpdateModuleConditionDescriptions(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  UpdateModuleMappingGroupDescriptionRequest[] | undefined,
  (params: UpdateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    ({ id, request }: UpdateParams) => {
      return modulesApi.modulesIdConditionDescriptionsPut(id, request).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.ModuleConditionDescriptions);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const update = (params: UpdateParams) => mutate(params);

  return [isLoading, isError, validationError, data, update];
}

export default useUpdateModuleConditionDescriptions;

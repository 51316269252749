import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ListIcon from '@mui/icons-material/List';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { BoolIcon } from 'common/components/BoolIcon/BoolIcon';
import { Expander, useExpander } from 'common/components/Expander/Expander';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { Infobox } from 'common/components/Infobox/Infobox';
import { PageActions } from 'common/components/PageActions/PageActions';
import { Pill } from 'common/components/Pill/Pill';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { TileButton } from 'common/components/TileButton/TileButton';
import { ErrorPage } from 'common/components/error-page/ErrorPage';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { asDateTime } from 'common/helpers/dateHelpers';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { Button, Col, Container, Form, InputGroup, Row, Stack, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import styles from './kitchenSink.module.scss';

export const DesignKitchenSink = () => {
  const [expandedDetailIds, toggleDetailId, isDetailExpanded] = useExpander<string>();

  return (
    <>
      <div className="d-flex flex-column host">
        <h2>Template | Custom bootstrap</h2>

        <h3 className={styles.divider}>"Spacing"</h3>
        <div>
          <Stack direction="horizontal" gap={3} className="flex-wrap">
            <div className={`${styles['example-square']} bg-info ms-0`}>0 - 0px</div>
            <div className={`${styles['example-square']} bg-info ms-1`}>1 - 4px</div>
            <div className={`${styles['example-square']} bg-info ms-2`}>2 - 8px</div>
            <div className={`${styles['example-square']} bg-info ms-3`}>3 - 16px</div>
            <div className={`${styles['example-square']} bg-info ms-4`}>4 - 24px</div>
            <div className={`${styles['example-square']} bg-info ms-5`}>5 - 48px</div>
          </Stack>
        </div>

        <h3 className={styles.divider}>Colors</h3>
        <div>
          <Stack direction="horizontal" gap={3} className="flex-wrap">
            <span>Error</span>
            <div className={`${styles['example-circle']} bg-error`}></div>

            <span>Warning</span>
            <div className={`${styles['example-circle']} bg-warning`}></div>

            <span>Success</span>
            <div className={`${styles['example-circle']} bg-success`}></div>

            <span>Information</span>
            <div className={`${styles['example-circle']} bg-info`}></div>

            <span>Loading</span>
            <div className={`${styles['example-circle']} bg-loading`}></div>
          </Stack>

          <Stack direction="horizontal" gap={3} className="flex-wrap">
            <span>Primary</span>
            <div className={`${styles['example-circle']} bg-primary`}></div>

            <span>secondary</span>
            <div className={`${styles['example-circle']} bg-secondary`}></div>

            <span>tertiary</span>
            <div className={`${styles['example-circle']} bg-tertiary`}></div>

            <span>success</span>
            <div className={`${styles['example-circle']} bg-success`}></div>

            <span>info</span>
            <div className={`${styles['example-circle']} bg-info`}></div>

            <span>warning</span>
            <div className={`${styles['example-circle']} bg-warning`}></div>

            <span>danger</span>
            <div className={`${styles['example-circle']} bg-danger`}></div>

            <span>light</span>
            <div className={`${styles['example-circle']} bg-light`}></div>

            <span>dark</span>
            <div className={`${styles['example-circle']} bg-dark`}></div>
          </Stack>
        </div>

        <h3 className={styles.divider}>Text</h3>
        <div>
          <small>
            OBS. The specified size is generated at 1200px (breakpoint-xl). For example H1 has 64px only at {'>'}=
            1200px screen width, and at 1083px H1 has 60.48px.
          </small>
          <Stack>
            <h1>Headline 1</h1>
            <h2>Headline 2</h2>
            <h3>Headline 3</h3>
            <h4>Headline 4</h4>
            <h5>Headline 5</h5>
            <h6>Headline 6</h6>
          </Stack>

          <Stack>
            <p className="h1">Display 1</p>
            <p className="h2">Display 2</p>
            <p className="h3">Display 3</p>
            <p className="h4">Display 4</p>
            <p className="h5">Display 5</p>
            <p className="h6">Display 6</p>
          </Stack>

          <Stack>
            <p className="fs-1">fs-1</p>
            <p className="fs-2">fs-2</p>
            <p className="fs-3">fs-3</p>
            <p className="fs-4">fs-4</p>
            <p className="fs-5">fs-5</p>
            <p className="fs-6">fs-6</p>
          </Stack>

          <Stack>
            <span className="lead">Lead</span>
            <span className="lead fw-bold">Lead bold</span>

            <span>A -span- with default size</span>
            <span className="larger">A -span- with larger size</span>
            <span className="fw-bold">A -span- with bold</span>
          </Stack>

          <Stack>
            <p>A -P- with default size</p>
            <p className="larger">A -P- with larger size</p>
          </Stack>

          <small>A -small-</small>
          <p className="smaller">A -P- with `smaller` size</p>

          <div>A datetime: {asDateTime('2022-08-23T09:18:58.0760737')}</div>
        </div>

        <h3 className={styles.divider}>Badges</h3>
        <div>
          <small>Pills</small>
          <Stack direction="horizontal" className="flex-wrap">
            <Pill variant="primary">primary</Pill>
            <Pill variant="secondary">secondary</Pill>
            <Pill variant="tertiary">tertiary</Pill>
            <Pill variant="success">success</Pill>
            <Pill variant="danger">Danger</Pill>
            <Pill variant="warning">warning</Pill>
            <Pill variant="info">info</Pill>
            <Pill variant="light">light</Pill>
            <Pill variant="dark">dark</Pill>
          </Stack>
        </div>

        <h3 className={styles.divider}>Button</h3>
        {/* Outline are not used */}
        <div>
          <small>sizes</small>
          <Stack direction="horizontal" className="flex-wrap">
            <Button
              title="Smallest button possible"
              variant="primary"
              size="sm"
              className="text-nowrap"
              onClick={(e) => console.log(e)}
            >
              x <AddCircleOutlineOutlinedIcon />
            </Button>
            <Button
              title="Button w/ right icon"
              variant="primary"
              className="text-nowrap"
              onClick={(e) => console.log(e)}
            >
              Label | size default | icon small 20px <AddCircleOutlineOutlinedIcon />
            </Button>
            <Button
              title="Button w/ right icon"
              variant="primary"
              size="lg"
              className="text-nowrap"
              onClick={(e) => console.log(e)}
            >
              Label | size lg | icon medium 24px <AddCircleOutlineOutlinedIcon />
            </Button>

            <Button variant="link" size="sm" className="text-nowrap" onClick={(e) => console.log(e)}>
              Link | size sm
            </Button>
            <Button variant="link" className="text-nowrap" onClick={(e) => console.log(e)}>
              Link | size default <AddCircleOutlineOutlinedIcon />
            </Button>
            <Button variant="link" size="lg" className="text-nowrap" onClick={(e) => console.log(e)}>
              Link | size lg <AddCircleOutlineOutlinedIcon />
            </Button>
          </Stack>

          <small>icon to the left</small>
          <Stack direction="horizontal" className="flex-wrap">
            <Button
              title="Smallest button possible"
              variant="primary"
              size="sm"
              className="text-nowrap"
              onClick={(e) => console.log(e)}
            >
              <AddCircleOutlineOutlinedIcon /> x
            </Button>
            <Button
              title="Button w/ right icon"
              variant="primary"
              className="text-nowrap"
              onClick={(e) => console.log(e)}
            >
              <AddCircleOutlineOutlinedIcon /> Label | size default | icon small 20px
            </Button>
            <Button
              title="Button w/ right icon"
              variant="primary"
              size="lg"
              className="text-nowrap"
              onClick={(e) => console.log(e)}
            >
              <AddCircleOutlineOutlinedIcon /> Label | size lg | icon medium 24px
            </Button>

            <Button variant="link" className="text-nowrap" onClick={(e) => console.log(e)}>
              <AddCircleOutlineOutlinedIcon /> Link | size default
            </Button>
            <Button variant="link" size="lg" className="text-nowrap" onClick={(e) => console.log(e)}>
              <AddCircleOutlineOutlinedIcon /> Link | size lg
            </Button>
          </Stack>

          <small>primary</small>
          <Stack direction="horizontal" className="text-wrap">
            <Button variant="primary" size="sm" className="text-nowrap" onClick={(e) => console.log(e)}>
              primary sm
            </Button>
            <Button variant="link-primary" size="sm" className="text-nowrap" onClick={(e) => console.log(e)}>
              link-primary sm
            </Button>
            <Button variant="primary" size="sm" className="text-nowrap" disabled={true} onClick={(e) => console.log(e)}>
              primary sm | disabled
            </Button>

            <Button variant="primary" size="lg" className="text-nowrap" onClick={(e) => console.log(e)}>
              primary lg
            </Button>
            <Button variant="link-primary" size="lg" className="text-nowrap" onClick={(e) => console.log(e)}>
              link-primary lg
            </Button>
            <Button variant="primary" size="lg" className="text-nowrap" disabled={true} onClick={(e) => console.log(e)}>
              primary lg | disabled
            </Button>

            <Button variant="primary" className="text-nowrap" onClick={(e) => console.log(e)}>
              primary default
            </Button>
            <Button variant="link-primary" className="text-nowrap" onClick={(e) => console.log(e)}>
              link-primary default
            </Button>
            <Button variant="primary" className="text-nowrap" disabled={true} onClick={(e) => console.log(e)}>
              primary default | disabled
            </Button>
          </Stack>

          <small>secondary</small>
          <Stack direction="horizontal" className="flex-wrap">
            <Button variant="secondary" size="sm" className="text-nowrap" onClick={(e) => console.log(e)}>
              secondary sm
            </Button>
            <Button variant="link-secondary" size="sm" className="text-nowrap" onClick={(e) => console.log(e)}>
              link-secondary sm
            </Button>
            <Button
              variant="secondary"
              size="sm"
              className="text-nowrap"
              disabled={true}
              onClick={(e) => console.log(e)}
            >
              secondary sm | disabled
            </Button>

            <Button variant="secondary" size="lg" className="text-nowrap" onClick={(e) => console.log(e)}>
              secondary lg
            </Button>
            <Button variant="link-secondary" size="lg" className="text-nowrap" onClick={(e) => console.log(e)}>
              link-secondary lg
            </Button>
            <Button
              variant="secondary"
              size="lg"
              className="text-nowrap"
              disabled={true}
              onClick={(e) => console.log(e)}
            >
              secondary lg | disabled
            </Button>

            <Button variant="secondary" className="text-nowrap" onClick={(e) => console.log(e)}>
              secondary default
            </Button>
            <Button variant="link-secondary" className="text-nowrap" onClick={(e) => console.log(e)}>
              link-secondary default
            </Button>
            <Button variant="secondary" className="text-nowrap" disabled={true} onClick={(e) => console.log(e)}>
              secondary default | disabled
            </Button>
          </Stack>

          <small>tertiary</small>
          <Stack direction="horizontal" className="flex-wrap">
            <Button variant="tertiary" size="sm" onClick={(e) => console.log(e)}>
              tertiary sm
            </Button>
            <Button variant="link-tertiary" size="sm" onClick={(e) => console.log(e)}>
              link-tertiary sm
            </Button>
            <Button variant="tertiary" size="sm" disabled={true} onClick={(e) => console.log(e)}>
              tertiary sm | disabled
            </Button>

            <Button variant="tertiary" size="lg" onClick={(e) => console.log(e)}>
              tertiary lg
            </Button>
            <Button variant="link-tertiary" size="lg" onClick={(e) => console.log(e)}>
              link-tertiary lg
            </Button>
            <Button variant="tertiary" size="lg" disabled={true} onClick={(e) => console.log(e)}>
              tertiary lg | disabled
            </Button>

            <Button variant="tertiary" onClick={(e) => console.log(e)}>
              tertiary default
            </Button>
            <Button variant="link-tertiary" onClick={(e) => console.log(e)}>
              link-tertiary default
            </Button>
            <Button variant="tertiary" disabled={true} onClick={(e) => console.log(e)}>
              tertiary default | disabled
            </Button>
          </Stack>
        </div>

        <h3 className={styles.divider}>Form Control text</h3>
        <div>
          <Stack direction="vertical" gap={3}>
            <Stack direction="horizontal" gap={3}>
              <Form.Control
                size="sm"
                type="text"
                placeholder="input-group small"
                title="filter"
                onChange={(e) => console.log(e)}
              />
              <Form.Control
                type="text"
                placeholder="input-group small"
                title="filter"
                onChange={(e) => console.log(e)}
              />
              <Form.Control
                size="lg"
                type="text"
                placeholder="input-group small"
                title="filter"
                onChange={(e) => console.log(e)}
              />
            </Stack>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Control
                  isInvalid={true}
                  size="sm"
                  type="text"
                  placeholder="input-group small"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
                <Form.Control.Feedback type="invalid">err</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Control
                  isInvalid={true}
                  type="text"
                  placeholder="input-group small"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
                <Form.Control.Feedback type="invalid">err</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Control
                  isInvalid={true}
                  size="lg"
                  type="text"
                  placeholder="input-group small"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
                <Form.Control.Feedback type="invalid">err</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Control
                  isValid={true}
                  size="sm"
                  type="text"
                  placeholder="input-group small"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Control
                  isValid={true}
                  type="text"
                  placeholder="input-group small"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Control
                  isValid={true}
                  size="lg"
                  type="text"
                  placeholder="input-group small"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>
            </Row>

            <Stack direction="horizontal" gap={3}>
              <InputGroup size="sm">
                <InputGroup.Text>
                  <SearchOutlinedIcon />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="input-group small"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>
                  <SearchOutlinedIcon />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="input-group default"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
              </InputGroup>

              <InputGroup size="lg">
                <InputGroup.Text>
                  <SearchOutlinedIcon />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="input-group large"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
              </InputGroup>
            </Stack>

            <Stack direction="horizontal" gap={3}>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="input-group small"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
                <InputGroup.Text>Text</InputGroup.Text>
              </InputGroup>

              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="input-group default"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
                <InputGroup.Text>Text</InputGroup.Text>
              </InputGroup>

              <InputGroup size="lg">
                <Form.Control
                  type="text"
                  placeholder="input-group large"
                  title="filter"
                  onChange={(e) => console.log(e)}
                />
                <InputGroup.Text>Text</InputGroup.Text>
              </InputGroup>
            </Stack>
          </Stack>
        </div>

        <h3 className={styles.divider}>Form Select</h3>
        <div>
          <Stack direction="vertical" gap={3}>
            <Stack direction="horizontal" gap={3}>
              <Form.Select size="sm" aria-label="select example" onChange={() => { }}>
                <option>Option 0</option>
                <option value="1">Option 1</option>
              </Form.Select>

              <Form.Select aria-label="select example" onChange={() => { }}>
                <option>Option 0</option>
                <option value="1">Option 1</option>
              </Form.Select>

              <Form.Select size="lg" aria-label="select example" onChange={() => { }}>
                <option>Option 0</option>
                <option value="1">Option 1</option>
              </Form.Select>
            </Stack>

            <Row className="mb-3">
              <Form.Group as={Col} md={3}>
                <Form.Select isInvalid={true} aria-label="select example" onChange={() => { }}>
                  <option>Option 0</option>
                  <option value="1">Option 1</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">err</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Select isValid={true} aria-label="select example" onChange={() => { }}>
                  <option>Option 0</option>
                  <option value="1">Option 1</option>
                </Form.Select>
              </Form.Group>
            </Row>
          </Stack>
        </div>

        <h3 className={styles.divider}>Form Checkbox, Switch and Radio</h3>
        <div>
          <Stack direction="vertical" gap={3}>
            <div>
              <Form.Check type={'checkbox'} id="ck-1" label={`label 1`} />

              <Form.Check type={'switch'} id="ck-2.1" label={`label 2.1`} />

              <Form.Check type={'switch'} id="ck-2.2" defaultChecked={true} label={`label 2.2`} />

              <Form.Check inline type={'checkbox'} id="ck-3.1" label={`label 3.1`} />
              <Form.Check inline defaultChecked={true} type={'checkbox'} id="ck-3.2" label={`label 3.2`} />

              <Form.Check type={'radio'} id="ck-4" name="4" label={`label 4`} />
              <Form.Check inline type={'radio'} id="ck-5.1" name="5" label={`label 5.1`} />
              <Form.Check inline defaultChecked={true} type={'radio'} id="ck-5.2" name="5" label={`label 5.2`} />
            </div>

            <Row>
              <Form.Group as={Col} md={3}>
                <Form.Check
                  type={'checkbox'}
                  id="ck-err-1"
                  label={`label err.1`}
                  isInvalid={true}
                  feedback="err"
                  feedbackType="invalid"
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Check
                  type={'checkbox'}
                  id="ck-ok-2"
                  label={`label ok`}
                  isValid={true}
                  feedback="ok"
                  feedbackType="valid"
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Check type={'checkbox'} id="ck-ok-3" label={`label`} isValid={true} />
              </Form.Group>
            </Row>
          </Stack>
        </div>

        <h3 className={styles.divider}>
          Material <u>SVG</u> with{' '}
          <a href="https://mui.com/material-ui/material-icons/" target="_new">
            2000 icons
          </a>{' '}
          (<a href="https://mui.com/material-ui/icons/">styling</a>)
        </h3>
        <div>
          <Stack direction="horizontal" gap={3} className="flex-wrap">
            <div>
              default size (default color) <ListIcon />
            </div>
            <div>
              small (default color) <ListIcon fontSize="small" />
            </div>
            <div>
              medium (default color) <ListIcon fontSize="medium" />
            </div>
            <div>
              large (default color) <ListIcon fontSize="large" />
            </div>
            <div>
              custom size 64 (default color) <ListIcon sx={{ fontSize: 64 }} />
            </div>
          </Stack>

          <Stack direction="horizontal" gap={3} className="flex-wrap">
            <div>
              large (color primary) <ListIcon fontSize="large" color="primary" />
            </div>
            <div>
              large (color secondary) <ListIcon fontSize="large" color="secondary" />
            </div>
            <div>
              large (color success) <ListIcon fontSize="large" color="success" />
            </div>
            <div>
              large (color action) <ListIcon fontSize="large" color="action" />
            </div>
            <div>
              large (color disabled) <ListIcon fontSize="large" color="disabled" />
            </div>
            <div>
              large (color error) <ListIcon fontSize="large" color="error" />
            </div>
            <div>
              large (color warning) <ListIcon fontSize="large" color="warning" />
            </div>
            <div>
              large (color info) <ListIcon fontSize="large" color="info" />
            </div>
          </Stack>
        </div>

        <h3 className={styles.divider}>Table</h3>
        <div>
          <TableContainer>
            <Stack direction="horizontal" gap={3} className="table-filter">
              <InputGroup>
                <InputGroup.Text>
                  <SearchOutlinedIcon fontSize="small" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="placeholder" title="filter" onChange={(e) => console.log(e)} />
              </InputGroup>

              <Button variant="tertiary" size="sm">
                Filter
              </Button>
              <Button variant="tertiary" size="sm" className="text-nowrap">
                Action x
              </Button>
            </Stack>

            <Table hover responsive>
              <thead>
                <tr>
                  <th scope="col">header col 1</th>
                  <th scope="col">header col 2</th>
                  <th scope="col">header col 3</th>
                  <th scope="col">header col 4</th>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>cell 1-1</span>
                  </td>
                  <td>
                    <span>cell 1-2</span>
                  </td>
                  <td>
                    <span>cell 1-3</span>
                  </td>
                  <td>
                    <Pill variant="success">Aktiv</Pill>
                  </td>
                  <td>
                    <Button variant="link" size="sm">
                      <span>Se detaljer</span>
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>cell 2-1</span>
                  </td>
                  <td>
                    <span>cell 2-2</span>
                  </td>
                  <td>
                    <span>cell 2-3</span>
                  </td>
                  <td>
                    <Pill variant="secondary">Inaktiv</Pill>
                  </td>
                  <td>
                    <Link to={`/`}>Se detaljer</Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>cell 3-1</span>
                  </td>
                  <td>
                    <span>cell 3-2</span>
                  </td>
                  <td>
                    <span>cell 3-3</span>
                  </td>
                  <td>
                    <Pill variant="danger">Deaktivert</Pill>
                  </td>
                  <td>cell 3-5</td>
                </tr>
              </tbody>
            </Table>
          </TableContainer>
        </div>

        <h3 className={styles.divider}>Grid</h3>
        <div className={styles.fullscreen}>
          <Container>
            <Row>
              <Col className="border">Cell 1-1</Col>
              <Col className="border">Cell 1-2</Col>
              <Col className="border">Cell 1-3</Col>
            </Row>
            <Row>
              <Col className="border">Cell 2-1</Col>
              <Col className="border">Cell 2-2</Col>
              <Col className="border">Cell 2-3</Col>
            </Row>
          </Container>
        </div>

        <hr />
        <h2>Custom components</h2>

        <h3 className={styles.divider}>BoolIcon</h3>
        <Stack direction="horizontal" gap={3} className="flex-wrap">
          <BoolIcon value={true}></BoolIcon>
          <BoolIcon value={false}></BoolIcon>
          Custom color
          <BoolIcon value={false} svgProps={{ color: 'error' }}></BoolIcon>
        </Stack>

        <h3 className={styles.divider}>ErrorPage</h3>
        <Stack direction="vertical" gap={3} className="flex-wrap">
          <ErrorPage reason={500} />
          <ErrorPage reason={404} />
        </Stack>

        <h3 className={styles.divider}>Expander</h3>
        <Stack direction="horizontal" gap={3} className="flex-wrap">
          <Expander ids={expandedDetailIds} id={'1'} onToggle={() => toggleDetailId('1')} />
          {isDetailExpanded('1') && <p>Expanded content</p>}
        </Stack>
      </div>

      <h3 className={styles.divider}>HelpPopover</h3>
      <Stack direction="horizontal" gap={3} className="flex-wrap">
        type `help` <HelpPopover type="help" id={'help-1'} title={'title'} text={'tooltip'}></HelpPopover>
        type `info` <HelpPopover type="info" id={'info-1'} title={'title'} text={'tooltip'}></HelpPopover>
      </Stack>

      <h3 className={styles.divider}>Infobox</h3>
      <Stack direction="vertical" gap={3} className="flex-wrap">
        <Infobox
          show={true}
          variant="light"
          title="Opprett din portefølje"
          text="Vennligst importer dine objekter via import eller legg dem til manuel."
          icon={<LightbulbIcon color="primary" />}
          actionText="Sett i gang med registreringen av dine porteføljeobjekter"
          actionIcon={<ArrowForwardIcon />}
          onClick={() => console.log('click')}
        />
      </Stack>

      <h3 className={styles.divider}>PageAction</h3>
      <Stack direction="vertical" gap={3} className="flex-wrap">
        <h4 className={styles.divider}>Left</h4>
        <PageActions
          left={
            <>
              <Form.Switch inline label="Label" onChange={(e) => console.log(e)} />
              <Form.Switch inline label="Label" onChange={(e) => console.log(e)} />
            </>
          }
        ></PageActions>

        <h4 className={styles.divider}>Right</h4>
        <PageActions
          right={
            <>
              <Button variant="tertiary" onClick={() => console.log('x')}>
                action
              </Button>
              <Button variant="tertiary" onClick={() => console.log('x')}>
                action
              </Button>
            </>
          }
        ></PageActions>

        <h4 className={styles.divider}>Left + Right</h4>
        <PageActions
          left={
            <>
              <Form.Switch inline label="Label" onChange={(e) => console.log(e)} />
              <Form.Switch inline label="Label" onChange={(e) => console.log(e)} />
            </>
          }
          right={
            <>
              <Button variant="tertiary" onClick={() => console.log('x')}>
                action
              </Button>
              <Button variant="tertiary" onClick={() => console.log('x')}>
                action
              </Button>
            </>
          }
        ></PageActions>

        <h4 className={styles.divider}>Title + action</h4>
        <PageTitle title={'title'}>
          <Button variant="secondary" size="sm" onClick={() => console.log('x')}>
            <AddCircleOutlineOutlinedIcon /> action
          </Button>
        </PageTitle>

        <h4 className={styles.divider}>Title + back</h4>
        <div>
          <PageTitle title={'title'} backTo={`./`}></PageTitle>
        </div>

        <h4 className={styles.divider}>Title + back + action</h4>
        <div>
          <PageTitle title={'title'} backTo={`./`}>
            <Button variant="secondary" size="sm" className="mx-2" onClick={(e) => console.log(e)}>
              secondary sm
            </Button>
            <Button variant="primary" size="sm" onClick={(e) => console.log(e)}>
              primary sm
            </Button>
          </PageTitle>
        </div>
      </Stack>

      <h3 className={styles.divider}>PageTitle</h3>
      <Stack direction="vertical" gap={3} className="flex-wrap">
        <h4 className={styles.divider}>Title</h4>
        <PageTitle title={'title'}></PageTitle>

        <h4 className={styles.divider}>Title + action</h4>
        <PageTitle title={'title'}>
          <Button variant="secondary" size="sm" onClick={() => console.log('x')}>
            <AddCircleOutlineOutlinedIcon /> action
          </Button>
        </PageTitle>

        <h4 className={styles.divider}>Title + back</h4>
        <div>
          <PageTitle title={'title'} backTo={`./`}></PageTitle>
        </div>

        <h4 className={styles.divider}>Title + back + action</h4>
        <div>
          <PageTitle title={'title'} backTo={`./`}>
            <Button variant="secondary" size="sm" className="mx-2" onClick={(e) => console.log(e)}>
              secondary sm
            </Button>
            <Button variant="primary" size="sm" onClick={(e) => console.log(e)}>
              primary sm
            </Button>
          </PageTitle>
        </div>
      </Stack>

      <h3 className={styles.divider}>LoadingSpinner</h3>
      <Stack direction="horizontal" gap={3} className="flex-wrap">
        <LoadingSpinner isLoading={true} />
        <LoadingSpinner isLoading={true} size={'sm'} />
      </Stack>

      <h3 className={styles.divider}>TileButton</h3>
      <Stack direction="vertical" gap={3} className="flex-wrap">
        <TileButton
          title="Title"
          text="horizontal tile button with optional left and right icons"
          onClick={() => console.log('click')}
          leftIcon={<DownloadOutlinedIcon fontSize="large" />}
          rightIcon={<ArrowForwardIcon />}
        />

        <TileButton
          disabled
          title="Disabled"
          text="Disabled"
          onClick={() => console.log('click')}
          leftIcon={<DownloadOutlinedIcon fontSize="large" />}
          rightIcon={<ArrowForwardIcon />}
        />

        <TileButton
          title="Only title"
          onClick={() => console.log('click')}
          leftIcon={<DownloadOutlinedIcon />}
          rightIcon={<ArrowForwardIcon />}
        />

        <TileButton
          title="Only title | no left icon"
          onClick={() => console.log('click')}
          rightIcon={<ArrowForwardIcon />}
        />

        <TileButton
          title="Only title | right icon as button"
          onClick={() => console.log('click')}
          rightIcon={
            <Button variant="tertiary" size="sm" onClick={(e) => console.log(e)}>
              Bytt objecttype
            </Button>
          }
        />

        <TileButton
          title="Expander"
          text={isDetailExpanded('1') ? 'Expanded content' : undefined}
          onClick={() => console.log('click')}
          leftIcon={
            <Expander
              size="sm"
              className="p-1"
              variant="link-tertiary"
              ids={expandedDetailIds}
              id={'1'}
              onToggle={(e) => {
                toggleDetailId('1');
                e.stopPropagation();
              }}
            />
          }
          rightIcon={
            <Button
              variant="tertiary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                console.log(e);
              }}
            >
              Bytt objecttype
            </Button>
          }
        />
      </Stack>
    </>
  );
};

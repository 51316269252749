import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './passwordReset.module.scss';

export const PasswordReset = () => {
  const { t } = useTranslation();
  const { isPasswordResetFlow } = useAuthenticatedUser();

  if (isPasswordResetFlow)
    return (
      <Container fluid className={styles.container}>
        <h2>{t('password-reset-title')}</h2>
        <p className="lead">{t('password-reset-ingress')}</p>
      </Container>
    );

  return (
    <Container fluid className={styles.container}>
      {' '}
      <LoadingSpinner isLoading={true} />
    </Container>
  );
};

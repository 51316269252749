import { ArrowForward, ArrowRight, Edit, KeyboardArrowRight, SearchOutlined, SwapVert } from '@mui/icons-material';
import Lightbulb from '@mui/icons-material/Lightbulb';
import { MappingState } from 'common/api/multimap';
import { Infobox } from 'common/components/Infobox/Infobox';
import { Pill } from 'common/components/Pill/Pill';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, ProgressBar, Row, Stack, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';

import useGetCheckActivePeriod from '../hooks/useGetCheckActivePeriod';
import useGetMappingPeriodObjects from '../hooks/useGetMappingPeriodObjects';
import useUpdateMappingPeriods from '../hooks/useUpdateMappingPeriod';
import useUpdateMappingPeriod from '../hooks/useUpdateMappingPeriod';

export const MappingStatus = (status: string) => {
  switch (status) {
    case 'Open':
      return <Pill variant="light">Ikke kartlagt</Pill>;

    case 'InProgress':
      return <Pill variant="warning">Påbegynt kartlegging</Pill>;

    case 'Closed':
      return <Pill variant="success">Ferdig kartlagt</Pill>;
  }
};

export const MappingObjects = () => {
  const { profileInfo } = useAuthenticatedUser();

  const { workspaceModuleId, periodId } = useParams();

  const [isLoading, , data] = useGetMappingPeriodObjects(periodId ? periodId : '');

  const [isLoadingCheck, , checkData] = useGetCheckActivePeriod(periodId ? periodId : '');

  const [, , , , update] = useUpdateMappingPeriod();

  const [parentFilter, setParentFilter] = useLocalStorage('parentFilter', '');
  const [mainFilter, setMainFilter] = useLocalStorage('mainFilter', '');
  const [statusFilter, setStatusFilter] = useLocalStorage('statusFilter', '');

  const [parentSort, setParentSort] = useLocalStorage<boolean | undefined>('parentSort', undefined);
  const [mainSort, setMainSort] = useLocalStorage<boolean | undefined>('mainSort', undefined);

  const [showModal, setShowModal] = useState<boolean>(false);

  const sortParent = () => {
    if (parentSort) {
      setParentSort(false);
    } else {
      setParentSort(true);
    }

    setMainSort(undefined);
  };

  const sortMain = () => {
    if (mainSort) {
      setMainSort(false);
    } else {
      setMainSort(true);
    }
    setParentSort(undefined);
  };

  const navigate = useNavigate();

  const isOrgOrMulti = profileInfo?.isOrganizationAdministrator || profileInfo?.isSystemAdministrator;
  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {data && (
        <>
          <PageTitle
            title={data.mappingPeriodName}
            backTo={isOrgOrMulti ? `/mapping/${workspaceModuleId}/periods` : `/mapping`}
          ></PageTitle>
          <br></br>
          {!isLoading && checkData != undefined && checkData.length > 0 && (
            <Infobox
              show={true}
              variant="light"
              title={'Endringer i portefølje'}
              icon={<Lightbulb color="primary" />}
              text={
                'Det har vært noen endringer i objektregisteret siden kartleggingen ble aktivert. Ønsker du at disse endringene skal gjelde for denne kartleggingen også?'
              }
              actionText={'Ja, gjør endringer i kartleggingen'}
              actionIcon={<ArrowForward />}
              onClick={() => setShowModal(true)}
            ></Infobox>
          )}
          <Row>
            <Col>
              <ProgressBar>
                <ProgressBar
                  striped
                  animated
                  variant="success"
                  now={data.mappingObjectsAmountDone * 100}
                  label={data.mappingObjectsAmountDone?.toString()}
                  key={1}
                />

                <ProgressBar
                  striped
                  animated
                  variant="secondary"
                  now={data.mappingObjectsAmountStarted * 100}
                  key={2}
                  label={data.mappingObjectsAmountStarted?.toString()}
                />
                <ProgressBar
                  striped
                  animated
                  variant="light"
                  now={data.mappingObjectsAmountNotStarted * 100}
                  key={3}
                  label={data.mappingObjectsAmountNotStarted?.toString()}
                  style={{ color: 'black' }}
                />
              </ProgressBar>
            </Col>
          </Row>
          <br></br>
          <h4>Objekter fra portefølje klare for kartlegging</h4>
          <TableContainer>
            <Row className="table-filter">
              <Col>
                <Form.Group>
                  <Form.Label>
                    Søk på <span className="text-lowercase">{data.objectTypeParentName}</span>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <SearchOutlined />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Alle lokasjoner"
                      onChange={(e) => setParentFilter(e.target.value)}
                      value={parentFilter}
                    ></Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Søk på <span className="text-lowercase">{data.objectTypeName}</span>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <SearchOutlined />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Alle bygg"
                      onChange={(e) => setMainFilter(e.target.value)}
                      value={mainFilter}
                    ></Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Velg status</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <SearchOutlined />
                    </InputGroup.Text>
                    <Form.Select onChange={(e) => setStatusFilter(e.currentTarget.value)} value={statusFilter}>
                      <option value={''} defaultChecked>
                        Alle
                      </option>
                      <option value={MappingState.Open}>Ikke kartlagt</option>
                      <option value={MappingState.InProgress}>Påbegynt kartlegging</option>
                      <option value={MappingState.Closed}>Ferdig kartlagt</option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-end align-items-end">
                <Button
                  variant="tertiary"
                  className="text-nowrap"
                  onClick={() => {
                    setParentFilter('');
                    setMainFilter('');
                    setStatusFilter('');
                  }}
                >
                  Nullstill filter
                </Button>
              </Col>
            </Row>
            <Table hover responsive>
              <thead>
                <tr>
                  <th scope="col">
                    {data.objectTypeParentName}{' '}
                    <Button variant="link" onClick={() => sortParent()}>
                      <SwapVert />
                    </Button>
                  </th>
                  <th scope="col">
                    {data.objectTypeName}{' '}
                    <Button variant="link" onClick={() => sortMain()}>
                      <SwapVert />
                    </Button>
                  </th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data.mappingObjects &&
                  data.mappingObjects
                    .filter(
                      (x) =>
                        x.objectParentName.toLowerCase().includes(parentFilter.toLowerCase()) &&
                        x.objectName.toLowerCase().includes(mainFilter.toLowerCase()) &&
                        x.status.toLowerCase().includes(statusFilter.toLowerCase()),
                    )
                    .sort((a, b) => {
                      if (mainSort != undefined) {
                        if (mainSort) {
                          const textA = a.objectName.toUpperCase();
                          const textB = b.objectName.toUpperCase();
                          return textA < textB ? -1 : textA > textB ? 1 : 0;
                        } else {
                          const textA = a.objectName.toUpperCase();
                          const textB = b.objectName.toUpperCase();
                          return textB < textA ? -1 : textB > textA ? 1 : 0;
                        }
                      } else if (parentSort != undefined) {
                        if (parentSort) {
                          const textA = a.objectParentName.toUpperCase();
                          const textB = b.objectParentName.toUpperCase();
                          return textA < textB ? -1 : textA > textB ? 1 : 0;
                        } else {
                          const textA = a.objectParentName.toUpperCase();
                          const textB = b.objectParentName.toUpperCase();
                          return textB < textA ? -1 : textB > textA ? 1 : 0;
                        }
                      } else {
                        const textA = a.status.toUpperCase();
                        const textB = b.status.toUpperCase();
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                      }
                    })
                    .map((row, i) => {
                      return (
                        <tr>
                          <td>
                            <span>{row.objectParentName}</span>
                          </td>
                          <td>
                            <span>{row.objectName}</span>
                          </td>
                          <td>
                            <span>{MappingStatus(row.status)}</span>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end">
                              <Button
                                variant="tertiary"
                                size="sm"
                                onClick={() =>
                                  navigate(
                                    `/mapping/${data.workspaceModuleId}/periods/${data.mappingPeriodId}/objects/${row.mappingObjectId}`,
                                  )
                                }
                              >
                                {row.status == 'Closed' && 'Se kartlegging'}
                                {row.status == 'Open' && 'Start kartlegging'}
                                {row.status == 'InProgress' && 'Fortsett kartlegging'}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </TableContainer>
        </>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Endringer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Objekter opprettet</h5>
          <ul>
            {checkData &&
              checkData
                .filter((x) => x.added)
                .map((y) => (
                  <li>
                    {y.systemName} ({y.objectTypeName})
                  </li>
                ))}
          </ul>
          <br></br>
          <h5>Objekter slettet</h5>
          <ul>
            {checkData &&
              checkData
                .filter((x) => x.removed)
                .map((y) => (
                  <li>
                    {y.systemName} ({y.objectTypeName})
                  </li>
                ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={() => setShowModal(false)}>
            Avbryt
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              update({ periodId: periodId ? periodId : '', request: checkData! });
              setShowModal(false);
            }}
          >
            Gjør endringer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

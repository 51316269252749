import { PublicClientApplication } from '@azure/msal-browser';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import 'locales/LanguageConfig';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App';
import { msalConfig } from './auth/authConfig';
import './index.scss';
import reportWebVitals from './reportWebVitals';

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error) => {
        const e = error as AxiosError;
        const errorCode = e.response?.status ?? 0;
        return count < 3 && (errorCode === StatusCodes.TOO_MANY_REQUESTS || (errorCode >= 500 && errorCode <= 599));
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App pca={msalInstance} />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ObjectPropertyResponse } from 'common/api/multimap';
import { BoolIcon } from 'common/components/BoolIcon/BoolIcon';
import { Pill } from 'common/components/Pill/Pill';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { asDateTime } from 'common/helpers/dateHelpers';
import { useState } from 'react';
import { Badge, Button, Form, InputGroup, Stack, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from '../components/loading-spinner/LoadingSpinner';
import { AddPropertyModal } from './components/AddObjectPropertyModal';
import { UpdateObjectPropertyModal } from './components/UpdateObjectPropertyModal';
import useGetAllObjectProperties from './hooks/useGetAllObjectProperties';

export const Properties = () => {
  const [isLoading, , data] = useGetAllObjectProperties();

  const { t } = useTranslation('admin', { keyPrefix: 'objectProperties' });

  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const [filter, setFilter] = useState<string>('');

  const [objectProperty, setObjectProperty] = useState<ObjectPropertyResponse>();

  const showUpdate = (o: ObjectPropertyResponse) => {
    setObjectProperty(o);
    setShowUpdateModal(true);
  };

  const sortTableByDate = (a: ObjectPropertyResponse, b: ObjectPropertyResponse) => {
    if (a.createdOn == undefined) {
      return 1;
    }

    if (b.createdOn == undefined) {
      return -1;
    }
    const a1 = new Date(a.createdOn).getTime();
    const b1 = new Date(b.createdOn).getTime();

    return b1 - a1;
  };

  return (
    <>
      <PageTitle title={t('title')}>
        <Button variant="tertiary" size="sm" onClick={() => setShowAddModal(true)}>
          <AddCircleOutlineOutlinedIcon /> {t('actionCreate')}
        </Button>
      </PageTitle>

      <TableContainer>
        <Stack direction="horizontal" className="table-filter">
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlinedIcon fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder="Søk etter en egenskap..."
              aria-describedby="Søk etter en egenskap..."
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </Stack>

        <LoadingSpinner isLoading={isLoading} />

        <br></br>
        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">{t('table.name')}</th>
              <th scope="col">Oppsett</th>
              <th scope="col" className="text-nowrap">
                Tilpassede beregninger
              </th>
              <th scope="col">{t('table.required')}</th>
              <th scope="col" className="text-nowrap">
                {t('table.inUse')}
              </th>
              <th scope="col" className="text-nowrap">
                {t('table.createdOn')}
              </th>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .filter((x) => x.systemName.toLowerCase().includes(filter.toLowerCase()))
                .sort((a, b) => {
                  return sortTableByDate(a, b);
                })
                .map((row, i) => {
                  // const orgnr = organizationNumberFormatter(row.orgnr);
                  return (
                    <tr key={i}>
                      <td>
                        <span>{row.systemName}</span>
                      </td>
                      <td className="text-nowrap">
                        <Stack direction="horizontal" gap={2}>
                          <Pill variant="success">{row.datatypeId}</Pill>
                          {row.minValue !== null && <Pill variant="success">{`Min: ${row.minValue}`}</Pill>}
                          {row.maxValue !== null && <Pill variant="success">{`Max: ${row.maxValue}`}</Pill>}
                        </Stack>
                      </td>
                      <td className="text-nowrap">
                        <Stack direction="horizontal" gap={2}>
                          {row.isAverage && <Pill variant="success">{'Snitt'}</Pill>}
                          {row.isTotal && <Pill variant="success">{'Total'}</Pill>}
                          {row.weightedAveragePropertyId && row.weightedAverageProperty && (
                            <>
                              <Pill variant="success">{'Vekted'}</Pill>
                              <Pill variant="success">{row.weightedAverageProperty.systemName}</Pill>
                            </>
                          )}
                        </Stack>
                      </td>
                      <td className="text-center">
                        <span>{BoolIcon({ value: row.required })}</span>
                      </td>
                      <td className="text-end">
                        <span>{row.usedBy}</span>
                      </td>
                      <td>
                        <span>{(row.createdOn && asDateTime(row.createdOn)) || ''}</span>
                      </td>
                      <td>
                        <Button variant="link" size="sm" onClick={() => showUpdate(row)}>
                          <MoreVertIcon />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </TableContainer>

      <UpdateObjectPropertyModal
        show={showUpdateModal}
        onHide={() => setShowUpdateModal(false)}
        objectProperty={objectProperty}
        objectProperties={data}
      ></UpdateObjectPropertyModal>
      <AddPropertyModal show={showAddModal} onHide={() => setShowAddModal(false)}></AddPropertyModal>
    </>
  );
};

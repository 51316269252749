import globalAxios from 'axios';
import { RegisterError } from 'common/helpers/error-handling/ErrorHandling';
import { LanguageStorageService } from 'locales/LanguageStorageService';

import { msalInstance } from '../..';
import { createLoginRequest } from '../../auth/authConfig';
import {
  Configuration,
  CustomerAccessRequestsApi,
  HomeApi,
  MaintenancePlansApi,
  MappingSetsApi,
  ModulesApi,
  ObjectMappingsApi,
  ObjectPropertiesApi,
  ObjectTypesApi,
  OrganizationsApi,
  PlanningApi,
  ProblemDetails,
  ReportsApi,
  UserInfoApi,
  UserInvitationsApi,
  UsersApi,
  WorkspacesApi,
} from './multimap';

// Disabled as this method is passed to be consumed by Axios generated client
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getAccessToken(name?: string | undefined, scopes?: string[] | undefined): Promise<string> {
  const loginRequest = createLoginRequest();
  const result = await msalInstance.acquireTokenSilent({
    scopes: loginRequest.scopes,
    //TODO: setActiveAccount should have been called by this point, we should investigate why it wasn't
    account: msalInstance.getAllAccounts()[0],
  });
  return `Bearer ${result.accessToken}`;
}

const configuration = new Configuration({
  basePath: MULTIMAP_API,
  apiKey: async () => getAccessToken(),
});

globalAxios.interceptors.request.use((config) => {
  const lang = LanguageStorageService.get();
  if (lang && config.headers) config.headers['Accept-Language'] = lang as string;
  return config;
});

//Response interceptor - show notifications for any error that isn't 422, we handle those in the UI (validation error)
globalAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 422) return Promise.reject(error);

    const problemDetails = error.response?.data as ProblemDetails;
    if (problemDetails) RegisterError(`${problemDetails.status} - ${problemDetails.title}`);
    else RegisterError('Something went wrong');

    console.log('Something went wrong');
    return Promise.reject(error);
  },
);

const userInfoApi = new UserInfoApi(configuration);
const organizationsApi = new OrganizationsApi(configuration);
const customerAccessRequestApi = new CustomerAccessRequestsApi(configuration);
const objectPropertiesApi = new ObjectPropertiesApi(configuration);
const objectTypesApi = new ObjectTypesApi(configuration);
const usersApi = new UsersApi(configuration);
const userInvitationsApi = new UserInvitationsApi(configuration);
const mappingSetsApi = new MappingSetsApi(configuration);
const workspacesApi = new WorkspacesApi(configuration);
const modulesApi = new ModulesApi(configuration);
const objectMappingsApi = new ObjectMappingsApi(configuration);
const reportsApi = new ReportsApi(configuration);
const maintenancePlansApi = new MaintenancePlansApi(configuration);
const homeApi = new HomeApi(configuration);
const planningApi = new PlanningApi(configuration);

export {
  customerAccessRequestApi,
  homeApi,
  maintenancePlansApi,
  mappingSetsApi,
  modulesApi,
  objectMappingsApi,
  objectPropertiesApi,
  objectTypesApi,
  organizationsApi,
  planningApi,
  reportsApi,
  userInfoApi,
  userInvitationsApi,
  usersApi,
  workspacesApi,
};

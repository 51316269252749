import { mappingSetsApi, objectMappingsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  MappingObjectResponse,
  MappingParameterConditionDegreeValueResponse,
  MappingPeriodResponse,
  MappingSetResponse,
  WorkspaceModuleResponse,
} from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetPeriodObjectValueComments(
  periodId: Guid,
  objectId: Guid,
  parameterId: Guid,
): [
  boolean,
  boolean,
  MappingParameterConditionDegreeValueResponse[] | undefined,
  () => Promise<MappingParameterConditionDegreeValueResponse[] | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.ObjectMappings, 'comments'],
    () =>
      objectMappingsApi
        .objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet(periodId, objectId, parameterId)
        .then((x) => x.data),
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetPeriodObjectValueComments;

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useContext } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

interface IProps {
  eventKey: string;
  children?: JSX.Element | JSX.Element[];
  callback?: (eventKey: string) => void;
}

/** As the accordion is inside a Fieldset (to use the disabled functionality for the whole form)
 * when the fieldset is disabled, also the accordion-toggle-button is disabled.
 * I haven't found a way to customize only Accordion.Button, so I needed to replace the whole Accordion as in the docs:
 * https://react-bootstrap.github.io/components/accordion/#custom-toggle-with-expansion-awareness
 */
export const FieldsetAccordion: React.FC<IProps> = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  const btn = isCurrentEventKey ? (
    <span role="button" tabIndex={0} onClick={decoratedOnClick} onKeyDown={decoratedOnClick}>
      <ExpandLessIcon></ExpandLessIcon>
    </span>
  ) : (
    <span role="button" tabIndex={0} onClick={decoratedOnClick} onKeyDown={decoratedOnClick}>
      <ExpandMoreIcon></ExpandMoreIcon>
    </span>
  );
  return (
    <div
      role="button"
      className="d-flex justify-content-between align-items-center"
      tabIndex={0}
      onClick={decoratedOnClick}
      onKeyDown={decoratedOnClick}
    >
      {children} {btn}
    </div>
  );
};

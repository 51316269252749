import HelpIcon from '@mui/icons-material/HelpOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import { AppContext } from 'app/AdminContext';
import { useContext } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import styles from './helpPopover.module.scss';

type Props = {
  type: 'help' | 'info';
  id: string;
  title: string;
  text: string;
  svgProps?: React.ComponentPropsWithRef<typeof SvgIcon>;
};

export const HelpPopover = ({ type, id, title, text, svgProps }: Props) => {
  const domId = id.replace(' ', '_');
  const adminContext = useContext(AppContext);
  const isVisible = domId === adminContext.state.tooltips.activeId;

  const visibleHandler = (e: React.MouseEvent | React.KeyboardEvent, visible: boolean) => {
    e.stopPropagation();
    e.preventDefault();

    const activeId = visible && adminContext.state.tooltips.activeId !== domId ? domId : undefined;
    adminContext.setAdminState({ ...adminContext.state, tooltips: { activeId } });

    if (window.scrollY + window.innerHeight == window.document.body.scrollHeight) {
      window.scrollTo(window.scrollX, window.scrollY - 1);
    } else {
      window.scrollTo(window.scrollX, window.scrollY + 1);
    }
  };

  const popover = (
    <Popover
      id={`popover-${domId}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Popover.Header as="h3">
        <span className="d-flex justify-content-between">
          {title}
          <span role="button" tabIndex={0}>
            <HighlightOffIcon fontSize="small" className="mx-2 white" onClick={(e) => visibleHandler(e, false)} />
          </span>
        </span>
      </Popover.Header>
      <Popover.Body>{text}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose placement="auto" overlay={popover} show={isVisible}
      delay={{ show: 250, hide: 400 }}>
      <span
        role="button"
        tabIndex={0}
        className={styles.container}
        onClick={(e) => visibleHandler(e, !isVisible)}
        onKeyDown={(e) => visibleHandler(e, !isVisible)}
      >
        {type === 'help' && <HelpIcon {...svgProps} className={`mx-2 ${isVisible ? 'active' : ''} `} />}
        {type === 'info' && <InfoIcon {...svgProps} className={`mx-2 ${isVisible ? 'active' : ''} `} />}
      </span>
    </OverlayTrigger>
  );
};

import { ObjectPropertyResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ImportExecuteStep } from './ImportExecuteStep';
import { ImportSelectFileStep } from './ImportSelectFileStep';
import { ImportVerifyFileStep } from './ImportVerifyFileStep';

type ImportStep = 'file' | 'verify-file' | 'execute' | 'done';

export interface IProps {
  workspaceId: Guid;
  properties: ObjectPropertyResponse[];
  onCompleted: () => void;
}

export const ImportModal: React.FC<IProps> = ({ workspaceId, properties, onCompleted }) => {
  const [step, setStep] = useState<ImportStep>('file');
  const [importFile, setImportFile] = useState<File | undefined>(undefined);

  useEffect(() => {
    if (step === 'done') {
      onCompleted();
    }
  }, [onCompleted, step]);

  return (
    <>
      {step === 'file' && (
        <ImportSelectFileStep
          importFile={importFile}
          setImportFile={setImportFile}
          onNext={() => setStep('verify-file')}
        />
      )}

      {step === 'verify-file' && !!importFile && (
        <ImportVerifyFileStep
          workspaceId={workspaceId}
          properties={properties}
          file={importFile}
          onNext={() => setStep('execute')}
          onPrevious={() => setStep('file')}
        />
      )}

      {step === 'execute' && !!importFile && (
        <ImportExecuteStep
          workspaceId={workspaceId}
          file={importFile}
          onNext={() => setStep('done')}
          onCancel={() => setStep('file')}
        />
      )}
    </>
  );
};

import React from 'react';

import styles from './TileButton.module.scss';

interface IProp {
  variant?: string;
  title?: string;
  text?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
}

export const TileButton: React.FC<IProp> = ({ variant, title, text, leftIcon, rightIcon, disabled, onClick }) => {
  const classVariant = variant ? `bg-${variant}` : '';
  const classDisabled = disabled ? `disabled` : '';
  return (
    <div
      role="button"
      aria-disabled={disabled ?? false}
      tabIndex={onClick && !disabled ? 0 : -1}
      className={`${styles.container} ${classVariant} ${classDisabled} d-flex align-items-center p-3`}
      onClick={() => onClick && onClick()}
      onKeyDown={() => onClick && onClick()}
    >
      <div className="d-flex align-self-center">{leftIcon}</div>
      <div className="ms-3">
        {title && <h5 className="mb-0">{title}</h5>}
        {text && <span>{text}</span>}
      </div>
      {rightIcon && <div className="d-flex ms-auto align-self-center">{rightIcon}</div>}
    </div>
  );
};

import { homeApi, modulesApi, reportsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { ModuleResponse, ObjectsOverviewResponse, ReportOverviewResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetBasisReport(
  periodId: Guid,
  mappingObjectIds: Guid[],
): [boolean, boolean, ReportOverviewResponse | undefined, () => Promise<ReportOverviewResponse | undefined>] {
  const { isLoading, isFetching, isError, data, refetch, isIdle } = useQuery(
    [QueryKeys.Reports, periodId],
    () => reportsApi.reportsPeriodIdPeriodBasisReportGet(periodId, mappingObjectIds).then((x) => x.data),
    {
      enabled: !!periodId,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetBasisReport;

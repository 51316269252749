import { ArrowRightOutlined } from '@mui/icons-material';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { Col, Container, Row } from 'react-bootstrap';

export const LearningPortfolio = () => {
  return (
    <>
      <PageTitle title="Portefølje" />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/YkuZXqaCpEk?si=2jYAJbkEbLD12bw4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <br></br>
      <br></br>
      <p>
        Porteføljefanen benyttes for å administrere organisasjonens bygningsportefølje. Førstegangsregistrering av
        bygningsporteføljen gjøres enkelt ved Excel-import. For allerede registrerte bygningsporteføljer gir
        porteføljefanen informasjon om porteføljen samt anledning til å gjøre endringer i denne ved behov.{' '}
      </p>

      <p>
        Porteføljen har en hierarkisk oppbygning med fire nivåer. De hierarkiske nivåene vil avhenge av hvordan
        organisasjonens portefølje ser ut. Eksempler på hierarkiske oppbygninger av porteføljer:
        <ul>
          <li>
            Eksempel offentlig kunde: Fylke <ArrowRightOutlined /> Kommune <ArrowRightOutlined /> Lokasjon{' '}
            <ArrowRightOutlined /> Bygg
          </li>
          <li>
            Eksempel privat kunde: Selskap <ArrowRightOutlined /> Lokasjon <ArrowRightOutlined /> Bygg{' '}
            <ArrowRightOutlined /> Etasje
          </li>
        </ul>
        De hierarkiske nivåene kan skreddersys enhver portefølje.
      </p>

      <p>
        Porteføljefanen viser informasjon om alle registrerte objekter på en oversiktlig og strukturert måte. Det gis
        anledning til å legge til objekter på alle nivåer samt mulighet for redigering, sletting og reaktivering av
        objekter. Slettede objekter kan vises for bedre oversikt og kontroll. Registrert portefølje kan enkelt
        eksporteres til Excel.{' '}
      </p>
    </>
  );
};

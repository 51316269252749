import { Spinner } from 'react-bootstrap';
import styles from './loading-spinner.module.scss';

export interface IProps {
  isLoading: boolean;
  size?: 'sm';
}

export const LoadingSpinner = ({ isLoading, size }: IProps) => {
  return isLoading ? (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" style={{ color: styles.colorLoading }} size={size} />
    </div>
  ) : null;
};

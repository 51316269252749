import { ErrorMessage } from '@hookform/error-message';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { MappingModel, ObjectPropertyDatatype } from 'common/api/multimap';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { ErrorPage } from 'common/components/error-page/ErrorPage';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetAllMappingSets from 'features/admin/mapping-sets/hooks/useGetAllMappingSets';
import useGetObjectPropertiesByObjectTypeId from 'features/admin/object-properties/hooks/useGetObjectPropertiesByObjectTypeId';
import useGetObjectTypeDetail from 'features/admin/object-types/hooks/useGetObjectTypeDetail';
import useGetObjectTypes from 'features/admin/object-types/hooks/useGetObjectTypes';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { displayIcon, getListOfIcons } from '../components/ModuleIcons';
import { PageNavTitles } from '../components/PageNavTitles';
import useGetAllModuleWeightingVersions from '../hooks/useGetAllModuleWeightingVersions';
import useGetModule from '../hooks/useGetModule';
import useUpdateModule from '../hooks/useUpdateModule';

export interface IProps {
  id: Guid;
}

export interface IAlertProps {
  when: boolean;
  message: string;
}

export const Upsert: React.FC<IProps> = ({ id }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'mappingSets.detail' });

  const navigate = useNavigate();

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [isLoading, , data] = useGetModule(id);

  const [mappingSetsLoading, , mappingSets] = useGetAllMappingSets();

  const [weightingLoading, , weightings] = useGetAllModuleWeightingVersions(id);

  const [objectTypesLoading, , objectTypes] = useGetObjectTypes();

  const [objectTypeId, setObjectTypeId] = useState('');

  const [objectPropertiesLoading, , properties, fetchPropertiesObjectType] =
    useGetObjectPropertiesByObjectTypeId(objectTypeId);

  const [rootId, setRootId] = useState('');

  const [objectTypeDetailLoading, , objectTypeDetail, fetchObjectTypeDetail] = useGetObjectTypeDetail(rootId);

  const [edit, setEdit] = useState<boolean>(false);

  const [changed, setChanged] = useState<boolean>(false);

  const [, , , , update] = useUpdateModule();

  const [optionSet, setOptionSet] = useState<boolean>(false);

  const [radioCustomValues, setRadioCustomValues] = useState<boolean>(false);

  const [chosenIcon, setChosenIcon] = useState<string>('');

  const [reactIcon, setReactIcon] = useState<JSX.Element>();

  useEffect(() => {
    if (data) {
      reset({
        moduleId: data.moduleId,
        systemName: data.systemName,
        systemDescription: data.systemDescription,
        objectTypeId: data.objectType.objectTypeId,
        mappingSetId: data.mappingSet.mappingSetId,
        objectPropertyId: data.objectProperty && data.objectProperty.objectPropertyId,
        mappingModelId: data.mappingModelId,
        mapLowValue: data.mapLowValue,
        mapHighValue: data.mapHighValue,
        usePercentValues: data.usePercentValues,
        rootId: data.objectType.root.objectTypeId,
        useObjectPropertyOptionSet: data.useObjectPropertyOptionSet,
        icon: data.icon,
        maconomyId: data.maconomyId,
        modulePrice: data.modulePrice,
      });

      console.log(data);

      setRootId(data.objectType.root.objectTypeId);
      setObjectTypeId(data.objectType.objectTypeId);
      setOptionSet(data.useObjectPropertyOptionSet);
      setChosenIcon(data.icon);
      setReactIcon(() => displayIcon(data.icon));
    }
  }, [data, reset]);

  useEffect(() => {
    if (rootId) {
      fetchObjectTypeDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootId]);

  useEffect(() => {
    if (chosenIcon) {
      setReactIcon(displayIcon(chosenIcon));
    }
  }, [chosenIcon]);

  useEffect(() => {
    if (objectTypeId) {
      fetchPropertiesObjectType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectTypeId]);

  if (!isLoading && !data) {
    return <ErrorPage reason={404} />;
  }

  const updateModule = async (d: FieldValues) => {
    if (d.useObjectPropertyOptionSet == false) {
      d.objectPropertyId = null;
    }

    update({ id: id, request: d });

    setEdit(false);
    setChanged(false);
  };

  const NoWeightings = () => {
    if (weightings) {
      if (weightings.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <PageNavTitles
        titles={[
          { title: 'Modul Oppsett', link: `/admin/modules/${id}/detail`, active: true },
          { title: 'Vekting', link: `/admin/modules/${id}/detail/weighting` },
          { title: 'Hjelpematrise', link: `/admin/modules/${id}/detail/condition-descriptions` },
        ]}
        backTo={`/admin/modules`}
      >
        <Form.Switch
          inline
          id="enable-editing-tree"
          label={'Rediger grunndata'}
          onChange={(e) => {
            setEdit(e.currentTarget.checked);
          }}
          checked={edit}
        />

        <Button
          variant="tertiary"
          size="sm"
          onClick={() => {
            reset();
            setChanged(false);
            setEdit(false);
          }}
          disabled={!changed}
        >
          Avbryt
        </Button>

        <Button type="submit" variant="primary" size="sm" className="mx-2" form="form" disabled={!changed}>
          Lagre
        </Button>
      </PageNavTitles>
      <br></br>
      <Form
        onSubmit={handleSubmit((d) => updateModule(d))}
        onChange={() => {
          setChanged(true);
        }}
        id="form"
      >
        <Row>
          <Col>
            <h4>Grunninformasjon</h4>
            <hr></hr>
            <Form.Group>
              <Form.Label>Navngi modul</Form.Label>
              <Form.Control
                {...register('systemName', { required: 'Du må navngi modulen' })}
                readOnly={!edit}
                isInvalid={!!errors.systemName}
              ></Form.Control>
              <Form.Text className="text-danger">
                <ErrorMessage errors={errors} name="systemName" />
              </Form.Text>
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Beskriv modul</Form.Label>
              <Form.Control as="textarea" {...register('systemDescription')} readOnly={!edit}></Form.Control>
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Maconomy ID</Form.Label>
              <Form.Control {...register('maconomyId')} readOnly={!edit} />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Modul pris:</Form.Label>
              <Form.Control {...register('modulePrice')} type="number" readOnly={!edit} />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Ikon for modul</Form.Label>
              <Form.Select
                {...register('icon', {
                  onChange: (event) => {
                    setChosenIcon(event.currentTarget.value);
                  },
                })}
                disabled={!edit}
              >
                <option value={undefined} disabled>
                  Velg ikon
                </option>
                {getListOfIcons().map((icon, i) => (
                  <option value={icon} key={i}>
                    {icon}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {chosenIcon && (
              <div className="background-blue50 p-3 my-2">
                <h5>Forhåndsvisning av ikon valgt for denne modulen</h5>
                {reactIcon}
              </div>
            )}
            <br></br>
            <br></br>
            <h4>Modul oppsett</h4>
            <hr></hr>
            <Form.Group>
              <Form.Label>Hva skal kartlegges?</Form.Label>
              {mappingSets ? (
                <Form.Select
                  {...register('mappingSetId', { required: 'Du må velge parametersett' })}
                  disabled={!edit || NoWeightings()}
                  defaultValue={undefined}
                  isInvalid={!!errors.mappingSetId}
                >
                  <option value={undefined} disabled>
                    Velg parametersett
                  </option>
                  {mappingSets.map((row, index) => (
                    <option key={index} value={row.mappingSetId}>
                      {row.systemName}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <LoadingSpinner isLoading={mappingSetsLoading} />
              )}
              <Form.Text className="text-danger">
                <ErrorMessage errors={errors} name="mappingSetId" />
              </Form.Text>
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Hvilke objekter skal kartlegges?</Form.Label>
              {objectTypes ? (
                <Form.Select
                  {...register('rootId', {
                    required: 'Du må velge objekttype',
                    onChange: (event) => {
                      setRootId(event.currentTarget.value);
                    },
                  })}
                  disabled={!edit || NoWeightings()}
                  isInvalid={!!errors.rootId}
                >
                  {objectTypes.map((row, index) => (
                    <option key={index} value={row.objectTypeId}>
                      {row.systemName}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <LoadingSpinner isLoading={objectTypesLoading} />
              )}
              <Form.Text className="text-danger">
                <ErrorMessage errors={errors} name="rootId" />
              </Form.Text>
            </Form.Group>
            <br></br>
            {objectTypeDetail ? (
              <Form.Group>
                <Form.Label>Hvilket nivå skal kartlegges?</Form.Label>
                <Form.Select
                  {...register('objectTypeId', {
                    required: 'Du må velge nivå',
                    onChange: (event) => {
                      setObjectTypeId(event.currentTarget.value);
                    },
                  })}
                  disabled={!edit || NoWeightings()}
                >
                  {objectTypeDetail.levels &&
                    objectTypeDetail.levels
                      .sort((a, b) => {
                        return a.level - b.level;
                      })
                      .map((row, index) => (
                        <option key={index} value={row.objectTypeId}>
                          {row.systemName}
                        </option>
                      ))}
                </Form.Select>
              </Form.Group>
            ) : (
              <LoadingSpinner isLoading={objectTypeDetailLoading} />
            )}

            <Form.Text className="text-danger">
              <ErrorMessage errors={errors} name="objectTypeId" />
            </Form.Text>

            <Form.Group className="background-blue50 p-3 mt-3 rounded">
              <Form.Label>Ønsker du at det skal være mulig å bryte ned på underliggende nivåer?</Form.Label>
              <Row>
                <Col md={1}>
                  <Form.Check
                    label="Ja"
                    type="radio"
                    value={MappingModel.MapToObjectChildren}
                    {...register('mappingModelId')}
                    disabled={!edit}
                    isInvalid={!!errors.mappingModelId}
                  />
                </Col>
                <Col md={1}>
                  <Form.Check
                    label="Nei"
                    type="radio"
                    value={MappingModel.MapToObject}
                    {...register('mappingModelId')}
                    disabled={!edit}
                    isInvalid={!!errors.mappingModelId}
                  />
                </Col>
              </Row>
              <Form.Text className="text-danger">
                <ErrorMessage errors={errors} name="mappingModelId" />
              </Form.Text>
            </Form.Group>
            <br></br>
            <br></br>
            <h4>Tilstandsgrader</h4>
            <hr></hr>
            <Form.Group>
              <Form.Label>Hvordan skal verdien beregnes?</Form.Label>
              <Form.Check label="Radio" type="radio" checked disabled={!edit || NoWeightings()} />
            </Form.Group>
            <br></br>
            <Form.Group>
              <Form.Label>Hvor mange tilstandsgrader skal benyttes?</Form.Label>
              <div className="d-flex align-items-center">
                <span className="me-2">Fra</span>
                <Col md={2}>
                  <Form.Select {...register('mapLowValue')} className="w-75 me-3" disabled={!edit || NoWeightings()}>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </Form.Select>
                </Col>
                <span className="me-2">Til</span>
                <Col md={2}>
                  <Form.Select {...register('mapHighValue')} className="w-75 me-3" disabled={!edit || NoWeightings()}>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </Form.Select>
                </Col>
                <Col md={3} className="ms-5">
                  {/* <Form.Check
                    label="Angi som prosentverdier"
                    {...register('usePercentValues')}
                    disabled={!edit || NoWeightings()}
                  /> */}
                </Col>
              </div>
            </Form.Group>
            <br></br>
            <br></br>
            <h4>Velg vektingsmetode for denne modulen</h4>
            <hr></hr>
            <Form.Group>
              <Form.Check
                label="En enkelt vekting uavhenging av objektenes egenskaper"
                type="radio"
                checked={!optionSet}
                disabled={!edit || NoWeightings()}
                onChange={() => {
                  setValue('useObjectPropertyOptionSet', false);
                  setOptionSet(false);
                }}
                className="mb-2"
              />
              <Form.Check
                label="Flere vektinger avhengig av en av objektenes egenskaper"
                type="radio"
                checked={optionSet}
                disabled={!edit || NoWeightings()}
                onChange={() => {
                  setValue('useObjectPropertyOptionSet', true);
                  setOptionSet(true);
                }}
              />
            </Form.Group>
            <br></br>
            {optionSet && (
              <Form.Group>
                <Form.Label>Velg egenskap for beregning av kost</Form.Label>
                {objectTypeDetail && properties ? (
                  <Form.Select
                    {...register('objectPropertyId', { required: 'Du må velge en egenskap for beregning av kost' })}
                    disabled={!edit || NoWeightings()}
                    isInvalid={!!errors.objectPropertyId}
                  >
                    <option disabled>Velg egenskap...</option>
                    {properties &&
                      properties
                        .filter((x) => x.datatypeId === ObjectPropertyDatatype.OptionSet)
                        .map((row, index) => (
                          <option key={index} value={row.objectPropertyId}>
                            {row.systemName}
                          </option>
                        ))}
                  </Form.Select>
                ) : (
                  <LoadingSpinner isLoading={objectTypeDetailLoading && objectPropertiesLoading} />
                )}
                <Form.Text className="text-danger">
                  <ErrorMessage errors={errors} name="objectPropertyId" />
                </Form.Text>
              </Form.Group>
            )}
            <br></br>
          </Col>
        </Row>
      </Form>
    </>
  );
};

import { objectTypesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { ObjectTypeResponse } from 'common/api/multimap/api';
import { useQuery } from 'react-query';

function useGetObjectTypes(): [
  boolean,
  boolean,
  ObjectTypeResponse[] | undefined,
  () => Promise<ObjectTypeResponse[] | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.ObjectTypes],
    () => objectTypesApi.objectTypesGet().then((x) => x.data),
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetObjectTypes;

import { BasicInformationResponse, CreateCustomerAccessRequest, OrganizationType } from 'common/api/multimap';
import { useGenericForm } from 'common/form/hooks/useGenericForm';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useOrganizationInfoSearch from '../../hooks/useOrganizationInfoSearch';

export interface IProps {
  onSearchResultChange: (orgFound: boolean, customerAccessRequest?: CreateCustomerAccessRequest) => void;
}

export const OrganizationRegistration: React.FC<IProps> = ({ onSearchResultChange }) => {
  const [formState, setFormState] = useState<BasicInformationResponse | undefined>();
  const [organizationType, setOrganizationType] = useState<OrganizationType>(OrganizationType.Unknown);
  const [orgFound, setOrgFound] = useState<boolean | undefined>();
  const [isLoading, , , , validationError, organizationInfo, search] = useOrganizationInfoSearch();
  const { t } = useTranslation('translation', {
    keyPrefix: 'features.user.onboarding.components.organizationRegistration',
  });

  const {
    FormContainer,
    FormInput,
    FormErrors,
    form,
    isLoading: isFormLoading,
  } = useGenericForm<BasicInformationResponse>({
    onSubmit: async (e) => {
      search(e.organizationNumber ?? undefined);
      setFormState(e);
    },
    model: formState,
    errorDetails: validationError,
  });

  useEffect(() => {
    setFormState((x) => (organizationInfo ? organizationInfo : { organizationNumber: x?.organizationNumber }));
    setOrganizationType(OrganizationType.Unknown);
    setOrgFound(formState?.organizationNumber ? !!organizationInfo : undefined);
  }, [formState?.organizationNumber, organizationInfo]);

  useEffect(() => {
    const req: CreateCustomerAccessRequest = {
      organizationNumber: organizationInfo?.organizationNumber as string,
      address1: organizationInfo?.address,
      city: organizationInfo?.city,
      country: organizationInfo?.country,
      name: organizationInfo?.name,
      organizationType: organizationType,
      zipCode: organizationInfo?.zipCode,
    };

    onSearchResultChange(!!orgFound, orgFound ? req : undefined);
  }, [onSearchResultChange, organizationType, orgFound, organizationInfo]);

  const showLoading = isLoading || isFormLoading;

  const titleText = orgFound ? t('title.found') : t('title.initial');
  const instructionText = orgFound ? t('content.found') : t('content.initial');

  return (
    <Container fluid>
      <FormContainer form={form}>
        <Row className="d-flex justify-content-center">
          <Col sm={12} md={7} lg={5} xl={4} xxl={4}>
            {showLoading ? (
              <LoadingSpinner isLoading={true} />
            ) : (
              <Card className={`p-4`}>
                <h3>{titleText}</h3>
                <p className="pb-4">{instructionText}</p>
                {!validationError && orgFound === false && <p> {t('content.noResult')} </p>}
                <FormErrors form={form} />
                <FormInput form={form} name="organizationNumber" labelText={t('form.organizationNumber')} />
                <FormInput form={form} name="name" labelText={t('form.name')} readonly hidden={!orgFound} />
                <FormInput form={form} name="address" labelText={t('form.address')} readonly hidden={!orgFound} />
                <Row>
                  <Col sm={6}>
                    <FormInput form={form} name="zipCode" labelText={t('form.zipCode')} readonly hidden={!orgFound} />
                  </Col>
                  <Col sm={6}>
                    <FormInput form={form} name="city" labelText={t('form.city')} readonly hidden={!orgFound} />
                  </Col>
                </Row>
                <FormInput form={form} name="country" labelText={t('form.country')} readonly hidden={!orgFound} />
                {/* TODO: Refactor this to a reusable component */}
                <FormGroup hidden={!orgFound}>
                  <Form.Check
                    inline
                    label="Privat sektor"
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    onChange={(e) =>
                      setOrganizationType(OrganizationType[e.target.value as keyof typeof OrganizationType])
                    }
                    value={OrganizationType.Private}
                  />
                  <Form.Check
                    inline
                    label="Offentlig sektor"
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    onChange={(e) =>
                      setOrganizationType(OrganizationType[e.target.value as keyof typeof OrganizationType])
                    }
                    value={OrganizationType.Public}
                  />
                </FormGroup>
                <Row>
                  <Col sm={12}>
                    <Button type="submit" size="sm" variant="secondary" className="mt-4 w-100">
                      {orgFound ? t('btnSearch.withResult') : t('btnSearch.noResult')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      </FormContainer>
    </Container>
  );
};

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { ReportOverviewResponse, WorkspaceResponse } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetObjectsOverview from 'features/user/home/hooks/useGetObjectsOverview';
import useGetReportOverview from 'features/user/home/hooks/useGetReportOverview';
import { useEffect, useState } from 'react';
import { Button, Card, Col, ProgressBar, Row, Stack } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router';
import { asNumberSeperator } from 'common/helpers/numberHelpers';

export interface IProps {
  reportOverview: ReportOverviewResponse | undefined;
  isLoading: boolean;
}

export interface DataSet {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderWidth: number;
}
export interface ChartData {
  labels: string[];
  datasets: DataSet[];
}

export const ReportCardPieBasisReport: React.FC<IProps> = ({ reportOverview, isLoading }) => {
  const navigate = useNavigate();

  const [workspace] = useLocalStorage<WorkspaceResponse | undefined>('workspace', undefined);

  const [, , objectsOverview] = useGetObjectsOverview(workspace?.workspaceId ?? '');

  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
      },
    },
    maintainAspectRatio: false,
  };

  const [areal, setAreal] = useState<number>(0);
  const [chartData, setChartData] = useState({
    labels: [''],
    datasets: [{ label: '', data: [0], backgroundColor: ['#14BE14'] }],
  });

  const roundDegree = (value: number) => {
    if (value < 0.75) {
      return "0";
    } else if (value < 1.5) {
      return "1";
    } else if (value < 2.25) {
      return "2";
    } else {
      return "3";
    }
  }

  useEffect(() => {
    if (reportOverview) {

      setAreal(0);

      let TG0 = 0;
      let TG1 = 0;
      let TG2 = 0;
      let TG3 = 0;

      let thisAreal = 0;
      reportOverview.reportObjects.map((row, i) => {
        if (row.children.length > 0) {
          row.children.map((child, j) => {
            thisAreal = thisAreal + child.areal;
            switch (roundDegree(child.averageWTCD)) {
              case '0':
                TG0 += child.areal;
                break;
              case '1':
                TG1 += child.areal;
                break;
              case '2':
                TG2 += child.areal;
                break;
              case '3':
                TG3 += child.areal;
                break;
              default:
                TG3 += child.areal;
            }
          })
        } else {
          thisAreal = thisAreal + row.areal;
          switch (roundDegree(row.averageWTCD)) {
            case '0':
              TG0 += row.areal;
              break;
            case '1':
              TG1 += row.areal;
              break;
            case '2':
              TG2 += row.areal;
              break;
            case '3':
              TG3 += row.areal;
              break;
            default:
              TG3 += row.areal;
          }
        }
      });

      setAreal(thisAreal);

      setChartData({
        labels: ['TG 0', 'TG 1', 'TG 2', 'TG 3'],
        datasets: [
          {
            label: `Tilstandsgrad fordelt på areal`,
            data: [TG0, TG1, TG2, TG3],
            backgroundColor: ['#14BE14', '#B8E926', '#FAAB35', '#FF0000'],
          },
        ],
      });
    }
  }, [reportOverview]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {reportOverview && (
        <>
          <div className="d-flex justify-content-between">
            <h4>{chartData.datasets[0].label}</h4>
            <HelpPopover
              id="pie"
              type="info"
              title="Tilstandsgrad fordelt på areal"
              text="Denne grafen viser fordelingen av mengden som er blitt kartlagt, rundet av til nærmeste hele tilstandsgrad. Den gir en visuell representasjon av fordelingen av dataene og viser hvordan mengden er fordelt mellom de ulike tilstandsgradene."
            />
          </div>
          <Card className="p-3">
            <Row>
              <Col md={8} sm={12} className="d-flex border-end flex-column">
                <div style={{ maxHeight: '500px', minHeight: '500px' }} className="pb-3">
                  <h4>Oversikt</h4>
                  <Doughnut options={options} data={chartData} />
                </div>
              </Col>
              <Col md={4} sm={12}>
                <h5>Nøkkeltall</h5>
                <small>Samlet tilstandsgrad</small>
                <h4 className="border-bottom pb-2">
                  {reportOverview.collectedCD.toFixed(2)}{' '}
                  <ConditionDegreeIcon conditionDegree={reportOverview.collectedCD} />
                </h4>
                <small>Kartlagt bruttoareal</small>
                <h4 className="border-bottom pb-2">
                  {asNumberSeperator(areal)} m2
                </h4>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

import React, { ReactNode } from 'react';
// eslint-disable-next-line import/named
import { Form, FormSelectProps } from 'react-bootstrap';

export interface SelectProps extends FormSelectProps {
  name: string;
  labelText?: string;
  invalidMessages?: string[];
  children?: ReactNode[];
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ name, labelText, invalidMessages, children, ...rest }: SelectProps, ref) => {
    return (
      <Form.Group controlId={rest.id} hidden={rest.hidden}>
        {labelText && <Form.Label>{labelText}</Form.Label>}

        <Form.Select name={name} {...rest} ref={ref}>
          {children}
        </Form.Select>

        {invalidMessages?.some((x) => x) && (
          <Form.Control.Feedback type="invalid">{invalidMessages[0]}</Form.Control.Feedback>
        )}
      </Form.Group>
    );
  },
);

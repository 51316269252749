import { HelpPopover } from 'common/components/HelpPopover/HelpPopover';

export interface IProps {
  label: string;
  field: string;
  tooltip?: string;
}

export const LabelField: React.FC<IProps> = ({ label, field, tooltip }) => {
  return (
    <div>
      <small>{label}</small>
      <div className="d-flex align-items-center">
        <h6 className="m-0">{field}</h6>

        {tooltip && <HelpPopover type="help" id={label} title={label} text={tooltip}></HelpPopover>}
      </div>
    </div>
  );
};

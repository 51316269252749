const QueryKeys = {
  CustomerAccessRequests: 'CustomerAccessRequests',
  Invitations: 'Invitations',
  MappingSets: 'MappingSets',
  Modules: 'Modules',
  ModuleVersionWeightings: 'ModuleVersionWeightings',
  ModuleWeightingVersions: 'ModuleWeightingVersions',
  ModuleConditionDescriptions: 'ModuleConditionDescriptions',
  ObjectProperties: 'ObjectProperties',
  ObjectTypes: 'ObjectTypes',
  OpenStreetMapNominatim: 'OpenStreetMapNominatim',
  OrganizationSearch: 'OrganizationSearch',
  OrganizationUsers: 'OrganizationUsers',
  Organizations: 'Organizations',
  UserInfo: 'UserInfo',
  Workspaces: 'Workspaces',
  ObjectMappings: 'ObjectMappings',
  MappingPeriods: 'MappingPeriods',
  Reports: 'Report',
  OrganizationModules: 'OrganizationModules',
  MaintenancePlans: 'MaintenancePlans',
  Home: 'Home',
  ServiceTypes: 'ServiceTypes',
  Learning: 'Learning',
  SpecialReports: 'SpecialReports',
  Users: 'Users',
  PlanningProjects: 'PlanningProjects',
};

export default QueryKeys;

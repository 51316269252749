import { ReportResponse, CreateReportRequest, OrganizationReportResponse, CreateOrganizationReportRequest } from "common/api/multimap";
import { displayIcon, getListOfIcons } from "features/admin/modules/components/ModuleIcons";
import { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import React from "react";
import { AddCircleOutline } from "@mui/icons-material";
import useGetOrganizationReports from "../hooks/useGetOrganizationReports";
import useGetAllReports from "../hooks/useGetAllReports";
import useCreateOrUpdateOrganizationReportsOnOrganizationId from "../hooks/useCreateOrUpdateOrganizationReportsOnOrganizationId";

export interface IProps {
  show: boolean;
  onHide: () => void;
  organizationId: string;
}

export interface OrganizationReports {
  items: CreateOrganizationReportRequest[];
}

export const OrganizationReportModal: React.FC<IProps> = ({ show, onHide, organizationId }) => {

  const {
    control,
    register,
    handleSubmit,
    reset,
  } = useForm<OrganizationReports>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const [isLoading, , organizationReports] = useGetOrganizationReports(organizationId);
  const [, , , , add] = useCreateOrUpdateOrganizationReportsOnOrganizationId();
  const [, , reports] = useGetAllReports();

  useEffect(() => {
    if (organizationReports) {
      reset({
        items: organizationReports
      })
    }
  }, [organizationReports])

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Organisasjonens rapporter</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit((d) => {
        add({ organizationId: organizationId, request: d.items });
        onHide();
      })}>
        <Modal.Body>
          {fields.map((row, i) => (
            <>
              <Row>
                <Col xs={8}>
                  <Form.Group className="mb-3">
                    <Form.Label>Rapport</Form.Label>
                    <Form.Select disabled={row.organizationReportId != "00000000-0000-0000-0000-000000000000"} {...register(`items.${i}.reportId`)}
                    >
                      {reports && reports.map((report, index) => (
                        <option value={report.reportId} key={index}>{report.systemName}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                {/* <Col xs={4} className="d-flex justify-content-end align-items-start">
                  <Button variant="secondary" size="sm" onClick={() => remove(i)}>
                    Fjern organisasjonsrapport
                  </Button>
                </Col> */}
              </Row>
              <Row className="my-2">
                <Form.Group>
                  <Form.Label>Tittel</Form.Label>
                  <Form.Control {...register(`items.${i}.title`)}></Form.Control>
                </Form.Group>
              </Row>
              <Row className="my-2">
                <Form.Group>
                  <Form.Label>Beskrivelse</Form.Label>
                  <Form.Control as="textarea" {...register(`items.${i}.description`)}></Form.Control>
                </Form.Group>
              </Row>
              <Row className="my-2">
                <Form.Group>
                  <Form.Label>Link</Form.Label>
                  <Form.Control type="text" {...register(`items.${i}.link`)} ></Form.Control>
                </Form.Group>
              </Row>
              <Row className="my-2">
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label={"Skal denne rapporten vises til organisasjonen?"}
                    className="mb-3" {...register(`items.${i}.active`)}
                  ></Form.Check>
                </Form.Group>
              </Row>
              <hr></hr>
            </>
          ))}
          <Button variant="primary" onClick={() => append(
            {
              organizationId: organizationId,
              organizationReportId: "00000000-0000-0000-0000-000000000000",
              reportId: "",
            }
          )}><AddCircleOutline /> Legg til ny rapport</Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={onHide}>Avbryt</Button>
          <Button variant="primary" type="submit">Lagre</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
import { AxiosError } from 'axios';
import { objectPropertiesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { ObjectPropertyResponse, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type UpdateParams = {
  id: string;
};

function useRemoveObjectProperty(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  ObjectPropertyResponse | undefined,
  (params: UpdateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    ({ id }: UpdateParams) => {
      return objectPropertiesApi.objectPropertiesIdDelete(id).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.ObjectProperties);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const remove = (params: UpdateParams) => mutate(params);

  return [isLoading, isError, validationError, data, remove];
}

export default useRemoveObjectProperty;

import { workspacesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { WorkspaceResponse } from 'common/api/multimap/api';
import { useQuery } from 'react-query';

function useWorkspaces(): [
  boolean,
  boolean,
  WorkspaceResponse[] | undefined,
  () => Promise<WorkspaceResponse[] | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.Workspaces],
    () => workspacesApi.workspacesGet().then((x) => x.data),
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useWorkspaces;

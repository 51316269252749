import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetAllObjectProperties from 'features/admin/object-properties/hooks/useGetAllObjectProperties';
import { useParams } from 'react-router-dom';

import { Create } from './Create';
import { Upsert } from './Upsert';

export const Detail = () => {
  const { id } = useParams();
  const [isLoading, , properties] = useGetAllObjectProperties();

  if (isLoading) {
    return <LoadingSpinner isLoading={true} />;
  }

  return id ? <Upsert id={id} properties={properties ?? []} /> : <Create properties={properties ?? []} />;
};

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { ObjectPropertyResponse, WorkspaceDetailLevelResponse } from 'common/api/multimap';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { upsert } from 'common/helpers/array.helpers';
import { useDebounce } from 'common/hooks/useDebounce';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useOpenStreetMapNominatim, {
  OSMDefaultConfig,
  OSMNominatim,
} from 'features/admin/hooks/useOpenStreetMapNominatim';
import React, { useEffect, useState } from 'react';
import { Button, Container, Modal, Stack, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ValidationError } from '../../import-export/ValidationError';

type AddressValidationRow = {
  value: string;
  valid?: boolean;
  isSearching?: boolean;
  suggestions?: OSMNominatim[];
};

type AddressData = {
  objectId: Guid;
  objectPropertyId: Guid;
  address: OSMNominatim;
};

export interface IProps {
  show: boolean;
  propertyDefinitions: ObjectPropertyResponse[];
  includeDeleted: boolean;
  levels: WorkspaceDetailLevelResponse[];
  onCancel: () => void;
}

const AsOSMNominatim = (payload: string): OSMNominatim | undefined =>
  payload ? (JSON.parse(payload) as OSMNominatim) : undefined;

export const AddressVerificationWizardModal: React.FC<IProps> = ({
  show,
  propertyDefinitions,
  includeDeleted,
  levels,
  onCancel,
}) => {
  const { t: ts } = useTranslation('shared');
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.detail.addressVerificationWizardModal' });

  const [isRunning, setIsRunning] = useState(false);

  const [queueItems, setQueueItems] = useState<string[]>([]);
  const [rows, setRows] = useState<AddressValidationRow[]>([]);
  const [addressQuery, setAddressQuery] = useState('');
  const [osmQuery, setOsmQuery] = useState<string | undefined>(undefined);

  // OBS: do not reduce to less than 1 second
  // https://operations.osmfoundation.org/policies/nominatim/
  // No heavy uses (an absolute maximum of 1 request per second).
  const debunceMs = 1000 * 1;
  const debouncedSearchAddress = useDebounce<string | undefined>(addressQuery, debunceMs);

  const [isSearching, , results] = useOpenStreetMapNominatim(osmQuery ?? '', OSMDefaultConfig);

  const onCancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    const addressPropertyIds = propertyDefinitions
      .filter((x) => x.datatypeId === 'Address')
      .map((x) => x.objectPropertyId);
    const result = levels
      ?.filter((x) => includeDeleted || (!includeDeleted && !x.deactivatedOn))
      .map((x) => {
        return {
          objectId: x.objectId,
          properties: x.properties
            .filter((p) => addressPropertyIds.includes(p.objectPropertyId))
            .filter((p) => !!p.valueString)
            .map((p) => ({ objectPropertyId: p.objectPropertyId, address: AsOSMNominatim(p.valueString ?? '') }))
            .filter((p) => !!p.address)
            .map((p) => ({ objectPropertyId: p.objectPropertyId, address: p.address as OSMNominatim }))
            .filter((p) => !p.address.osm_id),
        };
      })
      .filter(({ properties }) => properties.length > 0)
      .flatMap((x) =>
        x.properties.map(
          (p) => ({ objectId: x.objectId, objectPropertyId: p.objectPropertyId, address: p.address } as AddressData),
        ),
      )
      .sort((v1, v2) => v1.address.display_name.localeCompare(v2.address.display_name));

    const uniqueDisplayNames = [...new Set(result.map((x) => x.address.display_name))];
    const items = uniqueDisplayNames.map((x) => ({
      value: x,
      isSearching: false,
      suggestions: [],
      valid: undefined,
    }));
    setRows(items);
  }, [propertyDefinitions, levels, includeDeleted]);

  useEffect(() => {
    if (debouncedSearchAddress && debouncedSearchAddress.length > 5) {
      setOsmQuery(debouncedSearchAddress);
    }
  }, [debouncedSearchAddress]);

  useEffect(() => {
    if (!osmQuery) {
      return;
    }

    setRows((prev) => {
      upsert(
        prev,
        (x) => Object.assign(x ?? {}, { value: osmQuery, isSearching: isSearching }),
        (x) => x.value === osmQuery,
      );
      return prev;
    });
  }, [osmQuery, isSearching]);

  useEffect(() => {
    if (!osmQuery) {
      return;
    }

    const data = (results ?? [])
      .filter((x) => x)
      .map((x) => x as OSMNominatim)
      .sort((v1, v2) => v1.importance - v2.importance);

    setRows((prev) => {
      const isValid = data.some((x) => x.display_name === osmQuery);
      const suggestions = isValid ? [] : data;
      upsert(
        prev,
        (x) => Object.assign(x ?? {}, { value: osmQuery, valid: isValid, suggestions }),
        (x) => x.value === osmQuery,
      );
      return prev;
    });

    setQueueItems((prev) => {
      return prev.filter((x) => x !== osmQuery);
    });
  }, [results, osmQuery]);

  useEffect(() => {
    if (queueItems.length > 0) {
      setAddressQuery(queueItems[0]);
    }
  }, [queueItems]);

  useEffect(() => {
    if (queueItems.length > 0) {
      return;
    }

    setIsRunning(false);
  }, [queueItems]);

  useEffect(() => {
    if (!isRunning) {
      return;
    }
    setQueueItems(rows.map((x) => x.value));
  }, [isRunning, rows]);

  return (
    <>
      <Modal
        show={show}
        onHide={onCancelHandler}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="xl"
        contentClassName="fit-screen"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('title')}</Modal.Title>
        </Modal.Header>

        <Container fluid className="p-3">
          <LoadingSpinner isLoading={queueItems.length > 0} />

          {rows.some((x) => !x.isSearching && x.valid === false) && <ValidationError message={t('invalidAddresses')} />}

          <Stack>
            <Button
              type="submit"
              variant="primary"
              size="sm"
              aria-label="Start"
              className="text-nowrap ms-auto"
              disabled={isRunning}
              onClick={() => setIsRunning(true)}
            >
              {t('start')}
            </Button>
          </Stack>

          {rows.length === 0 && <p>{t('table.noAddresses')}</p>}

          {rows.length > 0 && (
            <TableContainer>
              <Table hover responsive className="caption-top">
                <caption>{t('table.caption')}</caption>
                <thead className="table-light">
                  <tr>
                    <th scope="col" className="text-nowrap">
                      {t('table.isValid')}
                    </th>
                    <th scope="col" className="text-nowrap">
                      {t('table.address')}
                    </th>
                    <th scope="col" className="text-nowrap">
                      {t('table.addressSuggestions')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows
                    .sort((v1, v2) => v1.value.localeCompare(v2.value))
                    .map((row) => {
                      const suggestions = [...new Set((row.suggestions ?? []).map((x) => x.display_name))];
                      return (
                        <React.Fragment key={row.value}>
                          <tr>
                            <td className="text-center">
                              <Stack direction="horizontal" className="d-flex justify-content-center">
                                {row.isSearching && <LoadingSpinner isLoading={true} size="sm" />}
                                {!row.isSearching && (
                                  <>
                                    {row.valid === true && <CheckCircleOutlinedIcon color="success" fontSize="small" />}
                                    {row.valid === false && <CancelOutlinedIcon color="error" fontSize="small" />}
                                  </>
                                )}
                              </Stack>
                            </td>
                            <td>
                              <span>{row.value}</span>
                            </td>
                            <td>
                              {suggestions.length > 0 && (
                                <>
                                  <Stack direction="horizontal" gap={3}>
                                    <a
                                      href={`https://www.openstreetmap.org/search?query=${encodeURIComponent(row.value)}`}
                                      className="ms-auto"
                                    >
                                      openstreetmap.org
                                    </a>
                                  </Stack>
                                  <ul>
                                    {suggestions.map((x) => (
                                      <li key={x}>{x}</li>
                                    ))}
                                  </ul>
                                </>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </Table>
            </TableContainer>
          )}
        </Container>
      </Modal>
    </>
  );
};

import { SearchOutlined } from '@mui/icons-material';
import useGetOrganizationServiceTypes from 'common/api/hooks/useGetOrganizationServiceTypes';
import { CreateOrganizationModuleRequest, ModuleResponse } from 'common/api/multimap';
import { Pill } from 'common/components/Pill/Pill';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { asDateTime } from 'common/helpers/dateHelpers';
import useGetAllModules from 'features/admin/modules/hooks/useGetAllModules';
import useAddOrganizationModules from 'features/user/account-settings/components/modules/hooks/useCreateOrganizationModule';
import useGetOrganizationModules from 'features/user/account-settings/components/modules/hooks/useGetOrganizationModules';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Modal, Row, Stack, Table } from 'react-bootstrap';
import { useParams } from 'react-router';

export const ServiceTypes = () => {
  const { profileInfo } = useAuthenticatedUser();

  const [, , data, fetch] = useGetOrganizationServiceTypes(profileInfo?.organization?.organizationId ?? '');

  const [, , modules] = useGetAllModules();

  const [show, setShow] = useState<boolean>(false);

  const [filter, setFilter] = useState<string>('');

  const [requests, setRequests] = useState<CreateOrganizationModuleRequest[]>([]);

  return (
    <>
      {data && (
        <>
          <PageTitle title="Tilleggsfunksjonalitet" backTo={`/account-settings`}></PageTitle>
          <h4 className="mt-2">Oversikt</h4>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <Stack direction="vertical">
                    <small className="bold">Status</small>
                    <h5>Aktiv</h5>
                  </Stack>
                </Col>
                <Col>
                  <Stack direction="vertical">
                    <small className="bold">Tilleggsfunksjonalitet</small>
                    <h5>{data.length}</h5>
                  </Stack>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <h4>Tilleggsfunksjonalitet som organisasjonen abonnerer på:</h4>
          {data.length > 0 ? (
            data.map((row, i) => (
              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Stack direction="vertical">
                        <small className="bold">Navn:</small>
                        <span>{row.serviceType == 'Planning' ? 'Planlegging' : ''}</span>
                      </Stack>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>Organisasjonen har ikke abonnert på noe tilleggsfunksjonaliteter.</p>
          )}
        </>
      )}
    </>
  );
};

import { useLocalStorage } from 'common/hooks/useLocalStorage';

import useGetPlanningProjects from '../hooks/useGetPlanningProjects';

interface Workspace {
  systemName: string;
  workspaceId: string;
}

export const PlanningOverview = () => {
  const [workspace] = useLocalStorage<Workspace | undefined>('workspace', undefined);
  const [, , data] = useGetPlanningProjects(workspace?.workspaceId ?? '');

  return <div>Hei</div>;
};

import { ArrowBack, ArrowForward, ContentPasteOutlined, History } from '@mui/icons-material';
import {
  MappingParameterConditionDegreeValueResponse,
  MappingParameterCustomValueRequest,
  MappingParameterCustomValueResponse,
  MappingParameterPercentValuesResponse,
} from 'common/api/multimap';
import { Expander, useExpander } from 'common/components/Expander/Expander';
import { TileButton } from 'common/components/TileButton/TileButton';
import { asDateTime } from 'common/helpers/dateHelpers';
import useGetClosedMappingPeriods from 'features/user/mapping/hooks/useGetClosedMappingPeriods';
import useGetPeriodObjectValueComments from 'features/user/mapping/hooks/useGetPeriodObjectValueComments';
import { useEffect, useState } from 'react';
import { Button, Form, FormCheck, Modal, Stack } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, UseFormGetValues, UseFormRegister } from 'react-hook-form';
import { useParams } from 'react-router';

export interface IProps {
  show: boolean;
  onHide: () => void;
  data: MappingParameterConditionDegreeValueResponse | undefined;
  register: UseFormRegister<FieldValues>;
  value: string;
  objectToMapId: string;
}

export interface IMappingObjectCommentProps {
  action1: () => void;
  action2?: () => void;
  data: MappingParameterConditionDegreeValueResponse | undefined;
  register: UseFormRegister<FieldValues>;
  value: string;
  objectToMapId: string;
}

const sortTableByDate = (
  a: MappingParameterConditionDegreeValueResponse,
  b: MappingParameterConditionDegreeValueResponse,
) => {
  if (a.createdOn == undefined) {
    return 1;
  }

  if (b.createdOn == undefined) {
    return -1;
  }
  const a1 = new Date(a.createdOn).getTime();
  const b1 = new Date(b.createdOn).getTime();

  return b1 - a1;
};

export const MappingObjectCommentOverview: React.FC<IMappingObjectCommentProps> = ({ action1, action2, data }) => {
  return (
    <>
      <Modal.Header closeButton>
        <h4 className="m-0">{data?.mappingParameterName}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <TileButton
            leftIcon={<ContentPasteOutlined />}
            rightIcon={<ArrowForward />}
            title="Legg til informasjon"
            text="Her kan du legge til informasjon om dette parametere for denne kartleggingen. Informasjon vil bli tilgjengelige for nye kartleggingsperioder."
            onClick={action1}
          />
        </div>
        <TileButton
          leftIcon={<History />}
          rightIcon={<ArrowForward />}
          title="Se historisk informasjon"
          text="Ønsker du å se tidligere tilstander og kommentarer så kan du benytte deg av denne tidsmaskinen."
          onClick={action2}
        />
      </Modal.Body>
    </>
  );
};

export const MappingObjectAddComment: React.FC<IMappingObjectCommentProps> = ({
  action1,
  action2,
  data,
  register,
  value,
}) => {
  return (
    <>
      <Modal.Header closeButton>
        <Button variant="link" onClick={action1}>
          <ArrowBack />
        </Button>
        <h4 className="m-0">Legg til informasjon - {data?.mappingParameterName}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between w-100 background-blue50 mb-3 p-3">
          <Stack direction="horizontal" gap={5}>
            <small className="fw-bold">Opgitt tilstand</small>
            <Stack direction="horizontal" gap={3}>
              <span>{data?.value ? data.value : 'NULL'}</span>
              <Form.Check type="radio" checked readOnly />
            </Stack>
          </Stack>
          <Button variant="tertiary" onClick={action2}>
            Bytt tilstand
          </Button>
        </div>
        <Form.Group>
          <Form.Label>Kommentar</Form.Label>
          <Form.Control as={'textarea'} placeholder="Skriv en kort kommentar ..." {...register(value)}></Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={action2}>
          Legg til kommentar
        </Button>
      </Modal.Footer>
    </>
  );
};

export const MappingObjectComments: React.FC<IMappingObjectCommentProps> = ({ action1, data, objectToMapId }) => {
  const { periodId, workspaceModuleId } = useParams();

  const [isLoadingClosed, , dataClosed] = useGetClosedMappingPeriods(workspaceModuleId ? workspaceModuleId : '');

  const [period, setPeriod] = useState<string>(periodId ? periodId : '');
  const object = objectToMapId ? objectToMapId : '';
  const parameter = data?.mappingParameterId ? data.mappingParameterId : '';

  const [, , commentData, fetch] = useGetPeriodObjectValueComments(period, object, parameter);
  const [expandedDetailIds, toggleDetailId, isDetailExpanded] = useExpander<string>();

  useEffect(() => {
    fetch();
  }, [period]);
  return (
    <>
      <Modal.Header closeButton>
        <Button variant="link" onClick={action1}>
          <ArrowBack />
        </Button>
        <h4 className="m-0">Historisk informasjon - {data?.mappingParameterName}</h4>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Velg periode</Form.Label>
          <Form.Select
            onChange={(e) => {
              setPeriod(e.currentTarget.value);
            }}
          >
            {dataClosed?.map((x, i) => (
              <option value={x.mappingPeriodId}>{x.mappingPeriodName}</option>
            ))}
          </Form.Select>
        </Form.Group>
        {commentData &&
          commentData
            .sort((a, b) => {
              return sortTableByDate(a, b);
            })
            .map((row, i) => {
              return (
                <div
                  className={`mb-3 p-4 border rounded ${
                    isDetailExpanded(row.mappingParameterValueId) ? 'background-blue10' : ''
                  }`}
                >
                  <Stack direction="horizontal" gap={3}>
                    <Expander
                      variant="link"
                      ids={expandedDetailIds}
                      id={'1'}
                      onToggle={() => toggleDetailId(row.mappingParameterValueId)}
                      className="p-0"
                    />
                    <h6 className="m-0">{asDateTime(row.createdOn)}</h6>
                  </Stack>
                  {isDetailExpanded(row.mappingParameterValueId) && (
                    <Stack direction="vertical" gap={3} className="mt-4">
                      <div className="d-flex justify-content-between">
                        <small className="fw-bold">Tilstand</small>
                        <Stack direction="horizontal" gap={3}>
                          <span className="larger">{row.value ? row.value : 'NULL'}</span>
                          <Form.Check type="radio" checked readOnly />
                        </Stack>
                      </div>
                      <hr></hr>
                      <div className="d-flex justify-content-between">
                        <small className="fw-bold">Kartlagt av</small>
                        <span className="larger">{row.commenter ? row.commenter : 'Ingen foreløpig'}</span>
                      </div>
                      <hr></hr>
                      <div className="d-flex justify-content-between">
                        <small className="fw-bold me-5">Kommentar</small>
                        <span className="larger">{row.comment ? row.comment : 'Ingen kommentar gitt'}</span>
                      </div>
                    </Stack>
                  )}
                </div>
              );
            })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="tertiary">Lukk historisk informasjon</Button>
      </Modal.Footer>
    </>
  );
};

export const MappingObjectValueCommentModal: React.FC<IProps> = ({
  show,
  onHide,
  data,
  register,
  value,
  objectToMapId,
}) => {
  const [step, setStep] = useState<number>(0);
  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      {step == 0 && (
        <MappingObjectCommentOverview
          action1={() => setStep(1)}
          action2={() => setStep(2)}
          data={data}
          register={register}
          value={value}
          objectToMapId={objectToMapId}
        />
      )}
      {step == 1 && (
        <MappingObjectAddComment
          action1={() => setStep(0)}
          action2={onHide}
          data={data}
          register={register}
          value={value}
          objectToMapId={objectToMapId}
        />
      )}
      {step == 2 && (
        <MappingObjectComments
          action1={() => setStep(0)}
          data={data}
          register={register}
          value={value}
          objectToMapId={objectToMapId}
        />
      )}
    </Modal>
  );
};

import { objectPropertiesApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { ObjectPropertyResponse } from 'common/api/multimap';
import { useQuery } from 'react-query';

function useGetAllObjectProperties(): [
  boolean,
  boolean,
  ObjectPropertyResponse[] | undefined,
  () => Promise<ObjectPropertyResponse[] | undefined>,
] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.ObjectProperties],
    () => objectPropertiesApi.objectPropertiesGet().then((x) => x.data),
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetAllObjectProperties;

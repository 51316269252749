import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { asDateTime } from 'common/helpers/dateHelpers';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageTitle } from '../../../common/components/page-title/PageTitle';
import { LoadingSpinner } from '../components/loading-spinner/LoadingSpinner';
import useGetCustomerAccessRequests from './hooks/useGetCustomerAccessRequests';

export const Requests = () => {
  const [isLoading, , data] = useGetCustomerAccessRequests();
  const { t } = useTranslation('admin', { keyPrefix: 'requests' });

  return (
    <>
      <PageTitle title={t('title')}></PageTitle>

      <h6 className="mb-4">{t('subtitle')}</h6>

      <LoadingSpinner isLoading={isLoading} />

      <TableContainer>
        <>
          <Table hover responsive>
            <thead>
              <tr>
                <th scope="col">{t('table.date')}</th>
                <th scope="col">{t('table.customerName')}</th>
                <th scope="col">{t('table.organizationNumber')}</th>
                <th scope="col">{t('table.createdBy')}</th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((row, i) => {
                  // const orgnr = organizationNumberFormatter(row.orgnr);
                  return (
                    <tr key={i}>
                      <td>
                        <span>{(row.createdOn && asDateTime(row.createdOn)) || ''}</span>
                      </td>
                      <td>
                        <span>{row.name}</span>
                      </td>
                      <td>
                        <span>{row.organizationNumber}</span>
                      </td>
                      <td>
                        <span>{row.createdByUserName}</span>
                      </td>
                      <td>
                        <Link className="text-nowrap" to={`/admin/requests/${row.customerAccessRequestId}/detail`}>
                          {t('table.actionDetail')}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>

          {(data?.length ?? 0) === 0 && <span>{t('table.noResults')}</span>}
        </>
      </TableContainer>
    </>
  );
};

import { PageTitle } from 'common/components/page-title/PageTitle';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetLearning from 'features/admin/mapping-sets/hooks/useGetLearning';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';

export const LearningModule: React.FC = () => {
  const { id } = useParams();
  const [isLoading, , data] = useGetLearning(id);

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  return (
    <>
      {data && (
        <>
          <PageTitle title={data.mappingSetName} />
          {data.videoPath !== '' && (
            <>
              <iframe
                width="560"
                height="315"
                src={data.videoPath}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <br></br>
              <br></br>
            </>
          )}
          <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
        </>
      )}
    </>
  );
};

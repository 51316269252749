import { AxiosError } from 'axios';
import { organizationsApi } from 'common/api/MultimapClients';
import { OrganizationInfoResponse, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation } from 'react-query';

function useOrganizationInfoSearch(): [
  boolean,
  boolean,
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  OrganizationInfoResponse | undefined,
  (orgNr: string | undefined) => void,
] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, isSuccess, isIdle, data, mutate } = useMutation(
    (orgNr: string | undefined) => {
      return organizationsApi.searchInfo({ organizationNumber: orgNr }).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const search = (orgNr: string | undefined) => mutate(orgNr);

  return [isLoading, isError, isSuccess, isIdle, validationError, data, search];
}

export default useOrganizationInfoSearch;

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ObjectTypeLevelResponse } from 'common/api/multimap';
import { upsert } from 'common/helpers/array.helpers';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { UserConfirmationModal } from '../../../../common/modals/UserConfirmationModal';
import { LevelEditor } from './LevelEditor';
import { LabelField } from './components/LabelField';

export interface IProps {
  levels: ObjectTypeLevelResponse[];
  isEditingMainData: boolean;
  isEditingTree: boolean;
  isEditingTexts: boolean;
  editingPropertyLevel: number | undefined;

  created: () => void;
  deleted: () => void;
  editPropertyHandler: (level: number) => void;
  hasChanged: () => void;
  hasData: (levels: ObjectTypeLevelResponse[]) => void;
}

export const TreeEditor: React.FC<IProps> = ({
  levels,
  isEditingMainData,
  isEditingTree,
  isEditingTexts,
  editingPropertyLevel,
  created,
  deleted,
  editPropertyHandler,
  hasChanged,
  hasData,
}) => {
  const { t } = useTranslation('admin', { keyPrefix: 'objectTypes.detail.treeEditor' });

  const [showDeleteLevelModal, setShowDeleteLevelModal] = useState(false);

  return (
    <>
      <Row className="mb-4">
        <Col sm={5}>
          <LabelField label={t('hierarchyTitle')} field={t('hierarchy')} />
        </Col>
      </Row>
      {levels.map((level, index) => {
        const last = index === levels.length - 1;

        return (
          <Row key={index}>
            <Col>
              <LevelEditor
                label={t('levelTitle', { level: index + 1 })}
                data={level}
                isNew={!levels.length && isEditingTree}
                canEditData={isEditingMainData}
                canEditTexts={isEditingTexts}
                isEditingProperties={editingPropertyLevel === index}
                editPropertyClick={() => {
                  editPropertyHandler(index);
                }}
                hasChanged={hasChanged}
                hasData={(e) => {
                  upsert(
                    levels,
                    (_) => e,
                    (v) => v.level === e.level,
                  );
                  hasData(levels);
                }}
              ></LevelEditor>

              {isEditingTree && last && (
                <div className="d-flex">
                  <Button variant="secondary" size="sm" className="w-100" onClick={() => setShowDeleteLevelModal(true)}>
                    <DeleteOutlineIcon /> {t('deleteLevelButton')}
                  </Button>
                  <UserConfirmationModal
                    show={showDeleteLevelModal}
                    title={t('deleteLevelModalTitle')}
                    requireExtraConfirmation={true}
                    onCancel={setShowDeleteLevelModal}
                    onConfirm={deleted}
                  >
                    <span>{t('deleteLevelModalConfirmation')}</span>
                  </UserConfirmationModal>
                </div>
              )}

              {(!last || isEditingMainData) && <hr />}
            </Col>
          </Row>
        );
      })}
      {levels.length === 0 && !isEditingTree && <p>{t('noLevels')}</p>}

      {isEditingMainData && (
        <>
          <small>{t('levelTitle', { level: levels.length + 1 })}</small>
          <div className="d-flex">
            <Button size="sm" variant="secondary" className="w-100" onClick={created}>
              <AddCircleOutlineOutlinedIcon /> {t('createLevelButton')}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

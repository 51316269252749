import { OrganizationMessageRequest } from 'common/api/multimap';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import useGetAllModules from 'features/admin/modules/hooks/useGetAllModules';
import { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Modal } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, useForm } from 'react-hook-form';

import useAddOrganizationMessageMessage from './hooks/useAddOrganizationMessage';
import useAddOrganizationMessage from './hooks/useAddOrganizationMessage';

type IProps = {
  show: boolean;
  onHide: () => void;
  theme: 'Generelt' | 'Bestill modul' | 'Bestill rapport' | 'Bestill workshop/konsulent' | 'Rapporter feil';
  moduleName?: string;
};

type SubjectProps = {
  thisTheme: string;
};
export const ContactModal: React.FC<IProps> = ({ show, theme, onHide, moduleName }) => {
  const [modulesLoading, , modules] = useGetAllModules();

  const { profileInfo } = useAuthenticatedUser();

  const [, , , add] = useAddOrganizationMessage();

  const { control, register, getValues, handleSubmit, setValue, reset, watch } = useForm();

  useEffect(() => {
    reset({
      title: theme,
      subject: moduleName,
    });
  }, [moduleName]);

  const sendMessage = (d: FieldValues) => {
    const request: OrganizationMessageRequest = {
      organizationId: profileInfo?.organization?.organizationId ?? '',
      subject: d.subject,
      title: d.title,
      comment: d.comment,
      userEmail: profileInfo?.email,
      userName: profileInfo?.displayName
    };

    add({ id: profileInfo?.organization?.organizationId ?? '', request: request });

    onHide();
  };

  const Subject: React.FC<SubjectProps> = ({ thisTheme }) => {
    if (thisTheme == 'Bestill modul') {
      return (
        <Form.Group>
          <Form.Label>Modul</Form.Label>
          <Form.Select {...register('subject')}>
            {modules && modules.map((row, i) => <option value={row.systemName}>{row.systemName}</option>)}
          </Form.Select>
        </Form.Group>
      );
    } else if (thisTheme == 'Bestill rapport') {
      return (
        <Form.Group>
          <Form.Label>Rapport</Form.Label>
          <Form.Select {...register('subject')}>
            <option value="Tilstandsanalyse">Tilstandsanalyse</option>
            <option value="Rapport 2">Rapport 2</option>
          </Form.Select>
        </Form.Group>
      );
    } else {
      return (
        <Form.Group>
          <Form.Label>Tittel på henvendelse</Form.Label>
          <Form.Control placeholder="Skriv en tittel her ... " {...register('subject')}></Form.Control>
        </Form.Group>
      );
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <h4>Kontakt</h4>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Hva gjelder henvendelsen?</Form.Label>
          <Form.Select {...register('title')}>
            <option value="Generelt">Generelt</option>
            <option value="Bestill modul">Bestill modul</option>
            <option value="Bestill rapport">Bestill rapport</option>
            <option value="Bestill workshop/konsulent">Bestill workshop/konsulent</option>
            <option value="Rapporter feil">Rapporter feil</option>
          </Form.Select>
        </Form.Group>
        <div className="mb-3">
          <Subject thisTheme={watch('title')} />
        </div>
        <Form.Group>
          <Form.Label>Kommentar</Form.Label>
          <Form.Control
            as={'textarea'}
            placeholder="Har du en beskjed eller kommentar til oss ..."
            {...register('comment')}
          ></Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="tertiary" onClick={onHide}>
          Avbryt
        </Button>
        <Button variant="primary" onClick={handleSubmit((d) => sendMessage(d))}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

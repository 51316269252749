import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Title {
  title: string;
  link: string;
  active?: boolean;
}
type Props = {
  background?: boolean;
  titles: Title[];
  backTo?: string;
  children?: JSX.Element | JSX.Element[];
};

export const PageNavTitles: React.FC<Props> = ({ background, titles, backTo, children }) => {
  const { t } = useTranslation('shared');

  return (
    <div className={`${background ? 'background-blue50 p-3' : ''}`}>
      {backTo ? (
        <>
          <div className="mb-3">
            <Link to={backTo} className="link-primary text-capitalize mb-3">
              <ArrowBackIcon fontSize="medium" /> {t('back')}
            </Link>
          </div>

          <div className="d-flex justify-content-between">
            <div className="d-flex flex-row">
              {titles.map((x, i) => (
                <Link to={x.link} key={i} className={!x.active ? 'color-blue300' : ''}>
                  <h3 className={`mr-auto me-3 ${!x.active ? 'color-blue300' : ''}`}>{x.title}</h3>
                </Link>
              ))}
            </div>
            {children && <div>{children}</div>}
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-row mb-5">
            {titles.map((x, i) => (
              <Link to={x.link} key={i} className={!x.active ? 'text-decoration-none' : ''}>
                <h2 className={`mr-auto me-3 ${!x.active ? 'color-blue300' : ''}`}>{x.title}</h2>
              </Link>
            ))}
          </div>
          {children && <div>{children}</div>}
        </div>
      )}
    </div>
  );
};

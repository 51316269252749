import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { WorkspaceCreateRequest } from 'common/api/multimap';
import { TileButton } from 'common/components/TileButton/TileButton';
import { Guid } from 'common/types/guid.type';
import useObjectGroupTranslation from 'features/admin/object-types/hooks/useObjectGroupTranslation';
import { useEffect } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFieldError } from '../helpers/FormFieldError';
import useUpsertWorkspace from '../hooks/useUpsertWorkspace';
import { NewWorkspaceInfo } from './CreateWizard';

type ResultPayload = {
  systemName: string;
  systemDescription: string;
  workspaceId: Guid;
  organizationId?: Guid;
};

type IProps = {
  model: NewWorkspaceInfo;
  onConfirm: (payload: ResultPayload) => void;
  onBack: () => void;
  organizationId?: Guid;
};

interface IFormInput {
  systemName: string;
  systemDescription: string;
}

export const CreateWizardStep3: React.FC<IProps> = ({ model, onConfirm, onBack, organizationId }) => {
  const { t: ts } = useTranslation('shared');
  const { t } = useTranslation('admin', { keyPrefix: 'workspaces.create.createWizard.createWizardStep3' });
  const { t: tg } = useObjectGroupTranslation();

  const [isSaving, , , , validationError, workspaceDetail, saveAction, , resetMutation] = useUpsertWorkspace(
    'create',
    () => {},
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInput>({
    defaultValues: {
      systemName: model.systemName ?? '',
      systemDescription: model.systemDescription ?? '',
    },
    mode: 'onBlur',
  });

  const onCreate = async (data: IFormInput) => {
    if (!model.objectType || !data.systemName) {
      return;
    }

    const request: WorkspaceCreateRequest = {
      objectTypeId: model.objectType?.objectTypeId,
      systemName: data.systemName,
      systemDescription: data.systemDescription,
      organizationId: organizationId,
    };
    await saveAction({ request });
  };

  // const onSubmit = async (data: IFormInput) => {
  //   onConfirm({ systemName: data.systemName, systemDescription: data.systemDescription });
  // };

  useEffect(() => {
    if (workspaceDetail) {
      onConfirm({
        systemName: workspaceDetail.systemName,
        systemDescription: workspaceDetail.systemDescription,
        workspaceId: workspaceDetail.workspaceId,
        organizationId: organizationId,
      });
    }
  }, [onConfirm, workspaceDetail]);

  return (
    <>
      <Form onSubmit={handleSubmit(onCreate)}>
        <Row className="mb-3">
          <Col sm={12}>
            <TileButton
              variant="light"
              title={model.objectType?.systemName}
              leftIcon={<CheckOutlinedIcon fontSize="large" />}
              //onClick={() => console.log('click')}
              rightIcon={
                <Button
                  variant="tertiary"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    onBack();
                  }}
                >
                  Bytt objecttype
                </Button>
              }
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t('name')}</Form.Label>
              <Controller
                control={control}
                name="systemName"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Form.Control
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    isInvalid={!!errors.systemName}
                    isValid={!errors.systemName && isDirty}
                  ></Form.Control>
                )}
              />
              <FormFieldError fieldError={errors.systemName} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t('description')}</Form.Label>
              <Controller
                control={control}
                name="systemDescription"
                rules={{ required: false }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Form.Control
                    as={'textarea'}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    isInvalid={!!errors.systemDescription}
                    isValid={!errors.systemDescription}
                  ></Form.Control>
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Stack direction="horizontal" gap={2}>
          <Button type="submit" variant="primary" size="sm" className="ms-auto" disabled={!isValid}>
            {t('next')}
          </Button>
        </Stack>
      </Form>
    </>
  );
};

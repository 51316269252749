import {
  ArrowForward,
  Check,
  Close,
  CloudUploadOutlined,
  ContentPasteOutlined,
  DescriptionOutlined,
  FileDownload,
  ImportExport,
  InsertDriveFileOutlined,
  UploadFileOutlined,
  UploadOutlined,
} from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { modulesApi } from 'common/api/MultimapClients';
//ModuleIconLibrary
import {
  ModuleWeightingFactorResponse,
  ModuleWeightingGroupResponse,
  ModuleWeightingOptionResponse,
} from 'common/api/multimap';
import { Infobox } from 'common/components/Infobox/Infobox';
import { TileButton } from 'common/components/TileButton/TileButton';
import { downloadFile } from 'common/helpers/http-header.helper';
import { notify } from 'common/helpers/toast-notification-helper';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { PageNavTitles } from '../components/PageNavTitles';
import useAddModuleWeightingVersion from '../hooks/useAddModuleWeightingVersion';
import useGetAllModuleWeightingVersions from '../hooks/useGetAllModuleWeightingVersions';
import useGetModule from '../hooks/useGetModule';
import useGetModuleVersionWeightings from '../hooks/useGetModuleVersionWeightings';
import useImportWeightingVersion from '../hooks/useImportWeightingVersion';
import useUpdateModuleWeightingVersion from '../hooks/useUpdateModuleVersionWeightingVersion';

export interface TotalWeighting {
  groupId: Guid;
  totalWeighting: number;
}

type ImportStep = 'import-export' | 'export' | 'import' | 'import-loading';

export const Weighting: React.FC = () => {
  const { id } = useParams();

  const moduleId = id ? id : '';

  const [moduleLoading, , moduleData] = useGetModule(moduleId);

  const [isLoading, , data, fetchVersions] = useGetAllModuleWeightingVersions(moduleId);

  const [weightingVersionId, setWeightingVersionId] = useState<string>('');

  const [isOptionSet, setIsOptionSet] = useState<boolean>(moduleData ? moduleData.useObjectPropertyOptionSet : false);

  const [weightingLoading, , weightingData, fetch] = useGetModuleVersionWeightings(moduleId, weightingVersionId);

  const [optionFilter, setOptionFilter] = useState<string>(weightingData ? weightingData.options[0].optionName : '');

  const [, , , weightingResponse, add] = useAddModuleWeightingVersion();

  const [, , , , update] = useUpdateModuleWeightingVersion();

  //Export Import Functions
  const [isSaving, , , , validationError, importExecuteResponse, saveAction] = useImportWeightingVersion();

  const [step, setStep] = useState<ImportStep>('import-export');

  const [showExportImport, setShowExportImport] = useState<boolean>(false);

  const downloadWeightingExport = async (empty: boolean) => {
    try {
      const response = await modulesApi.modulesIdWeightingVersionsWeightingVersionIdExportGet(
        moduleId,
        weightingVersionId,
        empty,
        {
          responseType: 'blob',
        },
      );
      downloadFile(response);

      notify('success', 'Eksport fil lagret');
    } finally {
      console.log('DONE');
    }
  };

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const { fields: options } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'options', // unique name for your Field Array
  });

  const [showNewVersion, setShowNewVersion] = useState<boolean>(false);

  const [edit, setEdit] = useState<boolean>(false);

  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.length !== 0) {
      if (weightingResponse) {
        setWeightingVersionId(weightingResponse.moduleWeightingVersionId);
      } else {
        const weightingVersion = data.find((x) => x.active);

        if (weightingVersion) {
          setWeightingVersionId(weightingVersion.moduleWeightingVersionId);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (weightingResponse) {
      fetchVersions();
      setWeightingVersionId(weightingResponse.moduleWeightingVersionId);
      reset();
    }
  }, [weightingResponse]);

  useEffect(() => {
    if (weightingVersionId) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightingVersionId, importExecuteResponse]);

  useEffect(() => {
    if (weightingData) {
      reset({
        moduleWeightingVersionId: weightingData.moduleWeightingVersionId,
        versionName: weightingData.versionName,
        active: weightingData.active,
        options: weightingData.options,
      });
    }
  }, [weightingData, reset]);

  const addWeightingVersion = (d: FieldValues) => {
    d.moduleId = moduleId;
    add({ id: moduleId, request: d });
    setShowNewVersion(false);
  };

  const updateWeightingVersion = (d: FieldValues) => {
    d.moduleId = moduleId;
    update({ id: moduleId, weightingVersionId: d.moduleWeightingVersionId, request: d });
    setEdit(false);
    setChanged(false);
  };

  const [importFile, setImportFile] = useState<File>();

  const isValid = (importFile && importFile.name.endsWith('.xlsx') && importFile.size > 0) ?? false;

  const onFileChanged = (files: FileList | null) => {
    const hasSingleFile = files?.length === 1;
    if (!hasSingleFile) {
      setImportFile(undefined);
      return;
    }

    setImportFile(files[0]);
  };

  return (
    <>
      <PageNavTitles
        titles={[
          { title: 'Modul Oppsett', link: `/admin/modules/${id}/detail` },
          { title: 'Vekting', link: `/admin/modules/${id}/detail/weighting`, active: true },
          { title: 'Hjelpematrise', link: `/admin/modules/${id}/detail/condition-descriptions` },
        ]}
        backTo={`/admin/modules/${id}/detail`}
      >
        {data && data.length > 0 ? (
          <Form.Switch
            inline
            id="enable-editing-tree"
            label={'Rediger grunndata'}
            onChange={(e) => {
              setEdit(e.currentTarget.checked);
            }}
            checked={edit}
          />
        ) : (
          <></>
        )}

        <>
          {data && data.length <= 0 && (
            <Button variant="tertiary" size="sm" className="mx-2" onClick={() => setShowNewVersion(true)}>
              Ny versjon <AddCircleOutlineIcon />
            </Button>
          )}
        </>

        {data && data.length > 0 ? (
          <>
            <Button
              variant="tertiary"
              size="sm"
              onClick={() => {
                reset();
                setChanged(false);
                setEdit(false);
              }}
              disabled={!changed}
            >
              Avbryt
            </Button>

            <Button type="submit" variant="primary" size="sm" className="mx-2" form="form" disabled={!changed}>
              Lagre
            </Button>
          </>
        ) : (
          <></>
        )}
      </PageNavTitles>

      {data && data.length > 0 ? (
        <>
          <Row className="background-blue50 mt-5">
            <Col>
              <Form.Select
                value={weightingVersionId}
                onChange={(event) => {
                  setWeightingVersionId(event.currentTarget.value);
                }}
                disabled={changed}
                className="my-3"
              >
                <>
                  {data.map((row, i) => {
                    return (
                      <option key={i} value={row.moduleWeightingVersionId}>
                        {row.versionName}
                      </option>
                    );
                  })}
                </>
              </Form.Select>
            </Col>

            <Col md={4} className="d-flex align-items-center justify-content-end ">
              <Button variant="tertiary" size="sm" className="mx-2 text-nowrap" onClick={() => setShowNewVersion(true)}>
                <CloudUploadOutlined className="me-1" />
                Opprett ny versjon
              </Button>
              <Button
                variant="tertiary"
                size="sm"
                className="mx-2 text-nowrap"
                onClick={() => setShowExportImport(true)}
              >
                <ImportExport className="me-1" />
                Import/Eksport
              </Button>
            </Col>
          </Row>
          <Form
            onSubmit={handleSubmit((d) => updateWeightingVersion(d))}
            onChange={() => {
              setChanged(true);
            }}
            id="form"
          >
            <Row>
              <Col className="d-flex justify-content-between mt-3">
                <Form.Group>
                  <Form.Check
                    label="Denne matrisen skal være gjeldende for nye kartlegginger"
                    {...register('active')}
                  ></Form.Check>
                </Form.Group>
              </Col>
            </Row>
            <Container fluid className="mt-3">
              {weightingData &&
                getValues('options')?.map((row: ModuleWeightingOptionResponse, rowIndex: number) => {
                  return (
                    <Row key={rowIndex} className="bg-white rounded-2 py-3 my-3">
                      {row.optionName !== 'Default' && (
                        <>
                          <h3>{row.optionName}</h3>
                          <hr></hr>
                        </>
                      )}
                      <div className="d-flex justify-content-between">
                        <span>Parameter</span>
                        <span>Vekting</span>
                      </div>
                      {row.groups &&
                        row.groups
                          .sort((a: ModuleWeightingGroupResponse, b: ModuleWeightingGroupResponse) => {
                            return a.sortOrder - b.sortOrder;
                          })
                          .map((group, groupIndex) => {
                            let totalWeighting = 0;
                            watch(`options[${rowIndex}].groups[${groupIndex}].factors`).forEach(
                              (x: { weighting: number }) => (totalWeighting += Number(x.weighting)),
                            );
                            return (
                              <Col md={12} className="mb-4" key={groupIndex}>
                                <div className="d-flex justify-content-between">
                                  <h4>{group.mappingGroupName}</h4>
                                  <h4>{totalWeighting.toFixed(2)}</h4>
                                </div>
                                <hr></hr>
                                {group.factors &&
                                  group.factors
                                    .sort((a: ModuleWeightingFactorResponse, b: ModuleWeightingFactorResponse) => {
                                      return a.sortOrder - b.sortOrder;
                                    })
                                    .map((factor, factorIndex) => {
                                      const factorWeighting: number = watch(
                                        `options[${rowIndex}].groups[${groupIndex}].factors.${factorIndex}.weighting`,
                                      );

                                      let weightingPercentage = (factorWeighting / totalWeighting) * 100;
                                      if (Math.round(weightingPercentage) !== weightingPercentage) {
                                        weightingPercentage = Number(weightingPercentage.toFixed(1));
                                      }
                                      return (
                                        <div className="d-flex justify-content-between my-2" key={factorIndex}>
                                          <span>{factor.parameterName}</span>
                                          <div className="d-flex justify-content-end align-items-center">
                                            <Form.Control
                                              type="number"
                                              step={0.01}
                                              className="w-50"
                                              {...register(
                                                `options[${rowIndex}].groups[${groupIndex}].factors.${factorIndex}.weighting`,
                                                {
                                                  required: 'Oppgi verdi',
                                                },
                                              )}
                                              readOnly={!edit}
                                              isInvalid={
                                                !!errors.options?.[rowIndex]?.groups?.[groupIndex]?.factors?.[
                                                  factorIndex
                                                ]?.weighting
                                              }
                                            ></Form.Control>
                                            <div className="d-flex justify-content-end" style={{ width: '75px' }}>
                                              {isNaN(weightingPercentage) ? (
                                                <span>0%</span>
                                              ) : (
                                                <span>{weightingPercentage}%</span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                              </Col>
                            );
                          })}
                    </Row>
                  );
                })}
            </Container>
          </Form>
        </>
      ) : (
        <div className="mt-3">
          <Infobox
            show={true}
            variant="light"
            title={''}
            text={
              'Det er ingen vektingsversjon som er lagd ennå for denne modulen. Vennligst trykk på knappen "Ny versjon", for å opprette vektingsversjon'
            }
            actionText={''}
            onClick={() => console.log()}
          ></Infobox>
        </div>
      )}
      <Modal show={showNewVersion} onHide={() => setShowNewVersion(false)} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>Ny versjon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit((d) => addWeightingVersion(d))}>
            <Form.Group>
              <Form.Label>Navngi denne versjonen</Form.Label>
              <Form.Control {...register('versionName')}></Form.Control>
            </Form.Group>
            <Button type="submit" variant="primary" size="sm" className="my-2">
              Opprett ny versjon
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showExportImport} onHide={() => setShowExportImport(false)} centered={true} size="lg">
        {step == 'import-export' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Import og eksport</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <TileButton
                  leftIcon={<FileDownload fontSize="large" />}
                  rightIcon={<ArrowForward fontSize="large" />}
                  title="Last ned Excel basert på modul oppsett"
                  text="Du får en excel fil med parametere basert på hvordan modulen er satt opp. Finnes det eksisterende verdier for vekting så eksporteres disse også."
                  onClick={() => setStep('export')}
                />
              </div>
              <div className="mb-3">
                <TileButton
                  leftIcon={<UploadFileOutlined fontSize="large" />}
                  rightIcon={<ArrowForward fontSize="large" />}
                  title="Importer data fra Excel"
                  text="Dersom du har en fil med samme oppbygning som hvordan modulen er satt opp kan du enkelte importere verdier for vekting."
                  onClick={() => setStep('import')}
                />
              </div>
            </Modal.Body>
          </>
        )}
        {step == 'export' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Hvilket dokument type ønsker du å laste ned?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <TileButton
                  leftIcon={<DescriptionOutlined fontSize="large" />}
                  title="Med data"
                  text="Dokument basert på modul oppsett med oppgitte vektings verdier"
                  onClick={() => downloadWeightingExport(false)}
                />
              </div>
              <div className="mb-3">
                <TileButton
                  leftIcon={<InsertDriveFileOutlined fontSize="large" />}
                  title="Uten data"
                  text="Dersom du ønsker en helt tom mal basert på modul oppsett"
                  onClick={() => downloadWeightingExport(true)}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="tertiary" onClick={() => setStep('import-export')}>
                Tilbake
              </Button>
            </Modal.Footer>
          </>
        )}
        {step == 'import' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Import av vektingsmatrise</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Row>
                  <Col>
                    <Form.Label id="choose-file">Velg en excel fil å importere</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {importFile && (
                      <InputGroup size="sm">
                        <Form.Control
                          type="text"
                          value={importFile.name}
                          readOnly={true}
                          aria-labelledby="choose-file"
                        />
                        <Button
                          type="button"
                          variant="primary"
                          size="sm"
                          aria-label="Close"
                          onClick={() => setImportFile(undefined)}
                        >
                          <Close />
                        </Button>
                      </InputGroup>
                    )}
                    {!importFile && (
                      <Form.Control
                        type="file"
                        size="sm"
                        aria-labelledby="choose-file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFileChanged(e.target.files)}
                      />
                    )}
                  </Col>
                  <Col>
                    {importFile && (
                      <Button
                        type="submit"
                        variant="primary"
                        size="sm"
                        aria-label="Import"
                        className="text-nowrap"
                        disabled={!isValid}
                        onClick={() => {
                          setStep('import-loading');
                          saveAction({ id: moduleId, weightingVersionId: weightingVersionId, file: importFile });
                        }}
                      >
                        Fullfør import
                      </Button>
                    )}
                  </Col>
                </Row>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="tertiary" onClick={() => setStep('import-export')}>
                Tilbake
              </Button>
            </Modal.Footer>
          </>
        )}
        {step == 'import-loading' && (
          <>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {isSaving ? (
                <>
                  <h4>Oppdaterer vektingsmatrise</h4>
                  <LoadingSpinner isLoading={isSaving} />
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <Check color="success" />
                  <h4>Vekingsmatrise oppdatert</h4>
                  <p>
                    Import av vektingsmatrise var vellykket. Du kan nå se de nye verdien koblet til modul oppsettet.
                  </p>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setShowExportImport(false);
                  setStep('import-export');
                }}
              >
                Gå til vektingsmatrise
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

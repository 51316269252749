import { AxiosError } from 'axios';
import { customerAccessRequestApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  CustomerAccessRequestResponse,
  CustomerAccessRequestStatus,
  ValidationProblemDetails,
} from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type UpdateParams = {
  status: CustomerAccessRequestStatus;
  id: string;
};

function useGetCustomerAccessRequests(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  CustomerAccessRequestResponse | undefined,
  (params: UpdateParams) => void,
] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const queryClient = useQueryClient();
  const { isLoading, isError, data, mutate } = useMutation(
    ({ status, id }: UpdateParams) => {
      return customerAccessRequestApi.customerAccessRequestsIdPut(id, { status }).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        queryClient.invalidateQueries(QueryKeys.CustomerAccessRequests);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const update = (params: UpdateParams) => mutate(params);

  return [isLoading, isError, validationError, data, update];
}
export default useGetCustomerAccessRequests;

import { AxiosError } from 'axios';
import { userInvitationsApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { UserInvitationResponse, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

function useRedeemInvitation(): [
  boolean,
  boolean,
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  UserInvitationResponse | undefined,
  (id: string) => void,
] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();
  //a workaround for a weird edge-case caused by strict-mode
  const [loading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<UserInvitationResponse | undefined>();

  const queryClient = useQueryClient();
  const { isIdle, isSuccess, isError, mutate } = useMutation(
    (id: string) => {
      return userInvitationsApi.redeemInvitation(id).then((x) => x.data);
    },
    {
      mutationKey: QueryKeys.Invitations,
      onSuccess: (x) => {
        setValidationError(undefined);
        setData(x);
        queryClient.invalidateQueries(QueryKeys.Invitations);
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        setData(undefined);
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
      onMutate: () => {
        setIsLoading(true);
        setData(undefined);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );

  const redeem = (id: string) => mutate(id);

  return [loading, isIdle, isSuccess, isError, validationError, data, redeem];
}
export default useRedeemInvitation;

import { SearchOutlined } from '@mui/icons-material';
import { PageNavTitles } from 'features/admin/modules/components/PageNavTitles';
import useGetWorkspaces from 'features/admin/workspaces/hooks/useGetWorkspaces';
import { useState } from 'react';
import { Button, Form, InputGroup, Modal, Stack, Table } from 'react-bootstrap';

import useGetOrganizations from '../../hooks/useGetOrganizations';
import useUpdateWorkspaceOrganizationId from '../hooks/useUpdateWorkspaceOrganizationId';
import useCreateOrganizationWorkspace from '../hooks/useCreateOrganizationWorkspace';

interface IProps {
  show: boolean;
  onHide: () => void;
  organizationId: string;
}

export const RegisterWorkspaceModal: React.FC<IProps> = ({ show, onHide, organizationId }) => {
  const [isLoading, , data] = useGetWorkspaces();
  const [, , , , update] = useUpdateWorkspaceOrganizationId();
  const [, , , , add] = useCreateOrganizationWorkspace();

  const [filter, setFilter] = useState<string>('');

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Registrer arbeidsområde</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack direction="vertical" gap={3}>
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder="Søk på arbeidsområde"
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </Stack>
        <div className="overflow-scroll mt-3" style={{ maxHeight: '500px' }}>
          <Table hover responsive>
            <thead>
              <tr>
                <th scope="col">Arbeidsområde</th>
                <th scope="col">Objekttype</th>
                <th scope="col">Tilhørende organisasjon</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data
                  .filter((x) => x.systemName.toLowerCase().includes(filter.toLowerCase()))
                  .sort((a, b) => {
                    if (a.systemName < b.systemName) {
                      return -1;
                    }
                    if (a.systemName > b.systemName) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.systemName}</td>
                        <td>{row.objectType.systemName}</td>
                        <td>{row.organizationName !== '' ? row.organizationName : 'Har ingen organisasjon'}</td>
                        <td className="d-flex justify-content-end">
                          <Button
                            variant="tertiary"
                            size="sm"
                            className="text-nowrap me-2"
                            onClick={() => {
                              if (
                                confirm(
                                  'Om dette arbeidsområde har kunde vil nåværende kunde miste tilgang på arbeidsområdet. Er du sikker?',
                                )
                              ) {
                                update({ id: row.workspaceId, organizationId: organizationId });
                                onHide();
                              }
                            }}
                          >
                            Registrer som eier
                          </Button>
                          <Button variant="tertiary" size="sm" className="text-nowrap" onClick={() => {
                            add({ id: organizationId, request: { organizationId: organizationId, workspaceId: row.workspaceId } });
                            onHide();
                          }}>Gi innsyn</Button>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

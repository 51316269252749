import { AxiosError } from 'axios';
import { objectTypesApi } from 'common/api/MultimapClients';
import {
  ObjectTypeCreateRequest,
  ObjectTypeDetailResponse,
  ObjectTypeUpdateRequest,
  ValidationProblemDetails,
} from 'common/api/multimap/api';
import { Guid } from 'common/types/guid.type';
import { useState } from 'react';
import { useMutation } from 'react-query';

type CreateRequest = {
  request: ObjectTypeCreateRequest;
};

type UpdateRequest = {
  objectTypeId: Guid;
  request: ObjectTypeUpdateRequest;
};

type UpdateRequestAction = (payload: UpdateRequest) => void;
type CreateRequestAction = (payload: CreateRequest) => void;

function useUpsertObjectType(
  mode: 'create' | 'edit',
  onSuccess: () => void,
): [
    boolean,
    boolean,
    boolean,
    boolean,
    ValidationProblemDetails | undefined,
    ObjectTypeDetailResponse | undefined,
    UpdateRequestAction | CreateRequestAction,
    () => void,
  ] {
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const createMutation = useMutation(
    (payload: CreateRequest) => {
      return objectTypesApi.objectTypesPost(payload.request).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        onSuccess();
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const updateMutation = useMutation(
    (payload: UpdateRequest) => {
      return objectTypesApi.objectTypesIdPut(payload.objectTypeId, payload.request).then((x) => x.data);
    },
    {
      onSuccess: () => {
        setValidationError(undefined);
        onSuccess();
      },
      onError: (err) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }
      },
    },
  );

  const create = (payload: CreateRequest) => createMutation.mutate(payload);
  const update = (payload: UpdateRequest) => updateMutation.mutate(payload);
  const sss = () => { };

  return mode === 'create'
    ? [
      createMutation.isLoading,
      createMutation.isError,
      createMutation.isSuccess,
      createMutation.isIdle,
      validationError,
      createMutation.data,
      create,
      createMutation.reset,
    ]
    : [
      updateMutation.isLoading,
      updateMutation.isError,
      updateMutation.isSuccess,
      updateMutation.isIdle,
      validationError,
      updateMutation.data,
      update,
      updateMutation.reset,
    ];
}

export default useUpsertObjectType;

import { ErrorMessage } from '@hookform/error-message';
import { AssignmentOutlined, AssignmentTurnedInOutlined, MoreVert } from '@mui/icons-material';
import {
  CreateMappingPeriodDescriptionAndStateRequest,
  MappingPeriodResponse,
  MappingState,
} from 'common/api/multimap';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { asDateTime } from 'common/helpers/dateHelpers';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetAllModuleWeightingVersions from 'features/admin/modules/hooks/useGetAllModuleWeightingVersions';
import { useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, useForm } from 'react-hook-form';
import { Outlet, useNavigate, useParams } from 'react-router';

import useAddMappingPeriod from '../hooks/useAddMappingPeriod';
import useAddMappingPeriodDescriptionAndState from '../hooks/useAddMappingPeriodDescriptionAndState';
import useGetActiveMappingPeriod from '../hooks/useGetActiveMappingPeriod';
import useGetClosedAndInactiveMappingPeriods from '../hooks/useGetClosedAndInactiveMappingPeriods';
import useGetClosedMappingPeriods from '../hooks/useGetClosedMappingPeriods';
import useGetWorkspaceModule from '../hooks/useGetWorkspaceModule';
import { AddMappingPeriodModal } from './components/AddMappingPeriodModal';
import { EditMappingPeriodModal } from './components/EditMappingPeriodModal';

export const MappingPeriods = () => {
  const [show, setShow] = useState(false);

  const [editShow, setEditShow] = useState(false);
  const [mappingPeriodResponse, setMappingPeriodResponse] = useState<MappingPeriodResponse | undefined>(undefined);

  const { workspaceModuleId } = useParams();

  const [isLoading, , data] = useGetActiveMappingPeriod(workspaceModuleId ? workspaceModuleId : '');
  const [isLoadingClosedAndInactive, , dataClosedAndInactive] = useGetClosedAndInactiveMappingPeriods(
    workspaceModuleId ? workspaceModuleId : '',
  );

  const [showInactive, setShowInactive] = useState(false);

  const [, , workspaceModule] = useGetWorkspaceModule(workspaceModuleId ? workspaceModuleId : '');

  const [, , weightingVersions] = useGetAllModuleWeightingVersions(workspaceModule?.moduleId ?? '');

  const [, , , dataState, addState] = useAddMappingPeriodDescriptionAndState();
  const [addLoading, , , dataPeriod, addPeriod] = useAddMappingPeriod();

  const closeMappingPeriod = (period: MappingPeriodResponse) => {
    const closePeriodRequest: CreateMappingPeriodDescriptionAndStateRequest = {
      mappingPeriodId: period.mappingPeriodId,
      yearAndMonth: period.activeMappingPeriod,
      stateId: MappingState.Closed,
      periodName: period.mappingPeriodName,
      moduleWeightingVersionId: period.moduleWeightingVersionId,
      description: period.mappingPeriodDescription,
    };

    addState({ id: period.mappingPeriodId, request: closePeriodRequest });
  };

  const deactivateMappingPeriod = (period: MappingPeriodResponse) => {
    const periodRequest: CreateMappingPeriodDescriptionAndStateRequest = {
      mappingPeriodId: period.mappingPeriodId,
      yearAndMonth: period.activeMappingPeriod,
      stateId: MappingState.InActive,
      periodName: period.mappingPeriodName,
      moduleWeightingVersionId: period.moduleWeightingVersionId,
      description: period.mappingPeriodDescription,
    };

    addState({ id: period.mappingPeriodId, request: periodRequest });
  };

  const openEditModal = (periodResponse: MappingPeriodResponse) => {
    setMappingPeriodResponse(periodResponse);
    setEditShow(true);
  };

  const navigate = useNavigate();
  return (
    <>
      <PageTitle title="Forvaltning av kartlagte perioder" backTo="/mapping" />
      <br></br>
      {isLoading && <LoadingSpinner isLoading={isLoading && isLoadingClosedAndInactive} />}
      <h4>Aktiv kartleggingsperiode</h4>
      {/* If no active mapping periods */}
      <AddMappingPeriodModal show={show} onHide={() => setShow(false)} workspaceModuleId={workspaceModuleId ?? ''} />
      {data ? (
        <Card className="mb-5">
          <Row className="p-3">
            <Col xs={8} className="d-flex flex-row align-items-center">
              <Button variant="link" onClick={() => openEditModal(data)} className="p-0 pb-1">
                <MoreVert />
              </Button>
              <h4 className="m-0">{data.mappingPeriodName}</h4>
            </Col>
            <Col xs={4}>
              <div>
                <ProgressBar>
                  <ProgressBar
                    striped
                    animated
                    variant="success"
                    now={data.mappingObjectsAmountDone * 100}
                    key={1}
                    label={data.mappingObjectsAmountDone?.toString()}
                  />
                  <ProgressBar
                    striped
                    animated
                    variant="secondary"
                    now={data.mappingObjectsAmountStarted * 100}
                    key={2}
                    label={data.mappingObjectsAmountStarted?.toString()}
                  />
                  <ProgressBar
                    striped
                    animated
                    variant="light"
                    now={data.mappingObjectsAmountNotStarted * 100}
                    key={3}
                    label={data.mappingObjectsAmountNotStarted?.toString()}
                    style={{ color: 'black' }}
                  />
                </ProgressBar>
              </div>
            </Col>
          </Row>
          <Row className="p-3">
            <Col>
              <span>{data.mappingPeriodDescription}</span>
            </Col>
          </Row>
          <Row className="p-3">
            <Col xs={2} className="d-flex flex-row">
              <div className="d-flex flex-column me-3">
                <small>SIST ENDRET</small>
                <span>{asDateTime(data.lastEdited)}</span>
              </div>

              <div className="d-flex flex-column">
                <small>PERIODE</small>
                <span>{data.formattedActiveMappingPeriod}</span>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                variant="tertiary"
                className="me-3"
                onClick={() => navigate(`/mapping/${data.workspaceModuleId}/periods/${data.mappingPeriodId}/objects`)}
              >
                Se kartlegging
              </Button>
              <Button variant="secondary" onClick={() => closeMappingPeriod(data)}>
                Avslutt kartleggingsperiode <AssignmentTurnedInOutlined />
              </Button>
            </Col>
          </Row>
        </Card>
      ) : (
        <>
          <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
            {!isLoading && (
              <>
                <Col xs={8} className="p-3">
                  <h4>Start ny kartleggingsperiode</h4>
                  <span>
                    Du har ingen aktive kartleggingsperioder, vennligst opprett en ny dersom du ønsker å kunne kartlegge
                    din portefølje.
                  </span>
                </Col>
                <Col xs={4} className="d-flex justify-content-end align-items-end p-3">
                  <Button variant="tertiary" onClick={() => setShow(true)}>
                    Opprett ny kartleggingsperiode <AssignmentOutlined />
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </>
      )}

      <div className="d-flex justify-content-between">
        <h4>Avsluttede kartleggingsperioder</h4>
        <div className="d-flex flex-row">
          <Form.Switch checked={showInactive} onChange={(e) => setShowInactive(e.currentTarget.checked)}></Form.Switch>
          <span>Vis inaktive kartleggingsperioder</span>
        </div>
      </div>

      {isLoadingClosedAndInactive ? (
        <LoadingSpinner isLoading={isLoadingClosedAndInactive} />
      ) : (
        <>
          {dataClosedAndInactive && (
            <>
              {dataClosedAndInactive.filter((x) =>
                showInactive
                  ? x.mappingStateId == MappingState.Closed || x.mappingStateId == MappingState.InActive
                  : x.mappingStateId == MappingState.Closed,
              ).length > 0 ? (
                <>
                  {dataClosedAndInactive
                    .filter((x) =>
                      showInactive
                        ? x.mappingStateId == MappingState.Closed || x.mappingStateId == MappingState.InActive
                        : x.mappingStateId == MappingState.Closed,
                    )
                    .map((row, i) => {
                      return (
                        <Card
                          key={i}
                          className={`mb-3 ${row.mappingStateId == MappingState.InActive ? 'disabled' : ''}`}
                        >
                          <Row className="p-3">
                            <Col xs={8} className="d-flex flex-row align-items-center">
                              <Button variant="link" onClick={() => openEditModal(row)} className="p-0 pb-1">
                                <MoreVert />
                              </Button>
                              <h4 className="m-0">{row.mappingPeriodName}</h4>
                            </Col>
                            <Col xs={4}>
                              <div>
                                <ProgressBar>
                                  <ProgressBar
                                    striped
                                    animated
                                    variant="success"
                                    now={row.mappingObjectsAmountDone * 100}
                                    key={1}
                                    label={row.mappingObjectsAmountDone?.toString()}
                                  />

                                  <ProgressBar
                                    striped
                                    animated
                                    variant="secondary"
                                    now={row.mappingObjectsAmountStarted * 100}
                                    key={2}
                                    label={row.mappingObjectsAmountStarted?.toString()}
                                  />
                                  <ProgressBar
                                    striped
                                    animated
                                    variant="light"
                                    now={row.mappingObjectsAmountNotStarted * 100}
                                    key={3}
                                    label={row.mappingObjectsAmountNotStarted?.toString()}
                                    style={{ color: 'black' }}
                                  />
                                </ProgressBar>
                              </div>
                            </Col>
                          </Row>
                          <Row className="p-3">
                            <Col>
                              <span>{row.mappingPeriodDescription}</span>
                            </Col>
                          </Row>
                          <Row className="p-3">
                            <Col xs={2} className="d-flex flex-row">
                              <div className="d-flex flex-column me-3">
                                <small>SIST ENDRET</small>
                                <span>{asDateTime(row.lastEdited)}</span>
                              </div>

                              <div className="d-flex flex-column">
                                <small>PERIODE</small>
                                <span>{row.formattedActiveMappingPeriod}</span>
                              </div>
                            </Col>
                            <Col className="d-flex justify-content-end">
                              {row.mappingStateId == MappingState.Closed && (
                                <Button
                                  variant="tertiary"
                                  onClick={() =>
                                    navigate(`/mapping/${row.workspaceModuleId}/periods/${row.mappingPeriodId}/objects`)
                                  }
                                  className="me-3"
                                >
                                  Rediger
                                </Button>
                              )}
                              {row.mappingStateId == MappingState.Closed ? (
                                <Button variant="tertiary" onClick={() => deactivateMappingPeriod(row)}>
                                  Gjør inaktiv
                                </Button>
                              ) : (
                                <Button
                                  variant="tertiary"
                                  onClick={() => closeMappingPeriod(row)}
                                  disabled={false}
                                  className="pe-auto text-primary"
                                >
                                  Gjør aktiv
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Card>
                      );
                    })}
                </>
              ) : (
                <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                  <Col xs={8} className="p-3">
                    <h4>Ingen avsluttede kartlegginger funnet</h4>
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}

      <EditMappingPeriodModal
        show={editShow}
        onHide={() => setEditShow(false)}
        workspaceModuleId={workspaceModuleId ?? ''}
        mappingPeriodResponse={mappingPeriodResponse}
      />
    </>
  );
};

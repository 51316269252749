import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import InfoIcon from '@mui/icons-material/Info';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { TileButton } from 'common/components/TileButton/TileButton';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import useGetInvitations from 'features/admin/organizations/hooks/useGetInvitations';
import useGetOrganizationById from 'features/admin/organizations/hooks/useGetOrganizationById';
import useGetWorkspacesOnOrganizationId from 'features/user/account-settings/hooks/useGetWorkspacesOnUserId';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface IProps {
  nextStep: () => void;
}

export const OnboardingSplash: React.FC<IProps> = ({ nextStep }) => {
  const { profileInfo } = useAuthenticatedUser();

  const [isLoading, , data] = useGetOrganizationById(profileInfo?.organization?.organizationId ?? undefined);

  const [, , workspaces, fetch] = useGetWorkspacesOnOrganizationId(profileInfo?.organization?.organizationId ?? '');

  const [, , invitations, getInvites] = useGetInvitations(profileInfo?.organization?.organizationId ?? undefined);

  const organizationExist = data != undefined;

  const workspacesExist = workspaces && workspaces.length > 0;

  const usersExist = (data && data.userCount! > 1) || (invitations && invitations.length > 0);

  const navigate = useNavigate();

  const { t } = useTranslation('translation', {
    keyPrefix: 'features.user.onboarding.components.onboardingSplash',
  });

  return (
    <Container fluid>
      <Row className="d-flex pb-5">
        <Col sm={12}>
          <h2>{t('title')}</h2>
          <p>{t('subject')}</p>
        </Col>
      </Row>
      <Row className="d-flex">
        <Col sm={4} className="mb-3">
          <Card
            role="button"
            aria-disabled={organizationExist ?? false}
            className={`${organizationExist && 'disabled'}`}
            tabIndex={!organizationExist ? 0 : -1}
            onClick={!organizationExist ? nextStep : () => {}}
          >
            <Card.Body>
              <span>
                <InfoIcon className="align-bottom" /> {!organizationExist ? t('started') : 'Ferdig opprettet'}
              </span>
              <div className="w-100 py-5 text-center">
                <GroupsIcon fontSize="large" />
                <h4>Opprett nytt kundeforhold</h4>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4} className="mb-3">
          <Card
            role="button"
            aria-disabled={(workspacesExist || !organizationExist) ?? false}
            className={`${(workspacesExist || !organizationExist) && 'disabled'}`}
            tabIndex={!workspacesExist && organizationExist ? 0 : -1}
            onClick={!workspacesExist && organizationExist ? () => navigate('/account-settings/workspaces') : () => {}}
          >
            <Card.Body>
              <span>
                <InfoIcon className="align-bottom" /> {!workspacesExist ? 'Ikke startet' : 'Ferdig opprettet'}
              </span>

              <div className="py-5 text-center">
                <AddCircleOutlineOutlinedIcon fontSize="large" />
                <h4>{t('createWorkstation')}</h4>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4} className="mb-3">
          <Card
            role="button"
            aria-disabled={(usersExist || !organizationExist) ?? false}
            className={`${(usersExist || !organizationExist) && 'disabled'}`}
            tabIndex={!usersExist && organizationExist ? 0 : -1}
            onClick={!usersExist && organizationExist ? () => navigate('/account-settings/users/invited') : () => {}}
          >
            <Card.Body>
              <span>
                <InfoIcon className="align-bottom" /> Ikke ferdig
              </span>
              <div className="py-5 text-center">
                <PersonAddAltIcon fontSize="large" />
                <h4>{t('inviteUsers')}</h4>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

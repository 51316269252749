import { useMsal } from '@azure/msal-react';
import { ArrowForward, Lightbulb, LightbulbOutlined } from '@mui/icons-material';
import hurtigruteImage from 'assets/images/hurtigrute.png';
import { CustomerAccessRequestStatus, WorkspaceResponse } from 'common/api/multimap';
import { Infobox } from 'common/components/Infobox/Infobox';
import { Pill } from 'common/components/Pill/Pill';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { asDateTime } from 'common/helpers/dateHelpers';
import { asNumberSeperator } from 'common/helpers/numberHelpers';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetInvitations from 'features/admin/organizations/hooks/useGetInvitations';
import useGetOrganizationById from 'features/admin/organizations/hooks/useGetOrganizationById';
import { WizardShell } from 'features/user/onboarding/components/wizard-shell';
import useGetBasisReportAllModules from 'features/user/report/report-types/detail/BasisReport/hooks/useGetBasisReportAllModules';
import { useEffect, useState } from 'react';
import { Button, Card, Carousel, Col, Container, Image, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useGetWorkspacesOnOrganizationId from '../account-settings/hooks/useGetWorkspacesOnUserId';
import useGetAllWorkspaceModules from '../mapping/hooks/useGetAllWorkspaceModules';
import { MappingObjectsProgress } from '../mapping/mapping-overview/components/MappingObjectsProgress';
import { ReportCardOverview } from './components/ReportCardOverview';
import useGetMaintenancePlansOverview from './hooks/useGetMaintenancePlansOverview';
import useGetObjectsOverview from './hooks/useGetObjectsOverview';
import useGetRequestOnUserId from './hooks/useGetRequestOnUserId';
import useGetInvitationByEmail from './hooks/useGetInvitationByEmail';
import useManualRedeemInvitation from './hooks/useManualRedeemInvitation';

export const Home = () => {
  const [showWizard, setShowWizard] = useState<boolean>(false);

  const { accounts } = useMsal();

  const { t } = useTranslation();

  const [name, setName] = useState<string>('');

  const { profileInfo } = useAuthenticatedUser();

  const [isLoading, , data] = useGetOrganizationById(profileInfo?.organization?.organizationId ?? undefined);

  const [isLoadingRequest, , request] = useGetRequestOnUserId(profileInfo?.userId ?? undefined);

  const [isLoadingInvitation, , invitation] = useGetInvitationByEmail();

  const [workspacesLoading, , workspaces, fetch] = useGetWorkspacesOnOrganizationId(
    profileInfo?.organization?.organizationId ?? '',
  );

  const [invitationsLoading, , invitations, getInvites] = useGetInvitations(
    profileInfo?.organization?.organizationId ?? undefined,
  );

  const [isLoadingRedeem, isIdle, isSuccess, isError, validationError, dataRedeem, redeem] = useManualRedeemInvitation();

  const [workspace] = useLocalStorage<WorkspaceResponse | undefined>('workspace', undefined);

  const [isLoadingReportModules, , reports] = useGetBasisReportAllModules(workspace?.workspaceId ?? undefined);

  const requestStatus = request && request.status;

  const organizationExist = data != undefined;

  const [modulesLoading, , workspaceModules, fetchModules] = useGetAllWorkspaceModules(
    workspace ? workspace.workspaceId : '',
  );

  const workspacesExist = workspaces && workspaces.length > 0;

  const usersExist = (data && data.userCount! > 1) || (invitations && invitations.length > 0);

  //Home info
  const [, , objectsOverview] = useGetObjectsOverview(workspace?.workspaceId ?? '');
  const [, , maintenancePlans] = useGetMaintenancePlansOverview(workspace?.workspaceId ?? '');

  const navigate = useNavigate();

  useEffect(() => {
    setName(accounts[0] && accounts[0].idTokenClaims?.given_name + '');
  }, [accounts]);

  useEffect(() => {
    if (dataRedeem) {
      window.location.reload();
    }
  }, [dataRedeem])

  useEffect(() => {
    document.title = "Oversikt"
  }, [])

  const InfoBoxInfo = () => {
    if (profileInfo?.organization == null) {
      if (requestStatus == CustomerAccessRequestStatus.Pending) {
        return (
          <Infobox
            show={true}
            variant="light"
            title={'Venter på godkjenning'}
            text={'Din organisasjon venter på godkjenning av multiMap administrator.'}
            icon={<LightbulbOutlined color="secondary" />}
            actionText={''}
            actionIcon={<></>}
            onClick={() => { }}
          ></Infobox>
        );
      } else if (requestStatus == CustomerAccessRequestStatus.Denied) {
        return (
          <Infobox
            show={true}
            variant="light"
            title={'Din forespørsel har blitt avist'}
            text={'For mer info, ta kontakt via kontaktskjema.'}
            icon={<LightbulbOutlined color="secondary" />}
            actionText={''}
            actionIcon={<></>}
            onClick={() => { }}
          ></Infobox>
        );
      }
    }

    if (!organizationExist) {
      return (
        <Infobox
          show={true}
          variant="light"
          title={'Onboardingen er ikke ferdig'}
          text={'Du har enda ikke opprettet organisasjon. Fortsett videre med onboarding.'}
          icon={<LightbulbOutlined color="secondary" />}
          actionText={'Gå til onboarding'}
          actionIcon={<ArrowForward />}
          onClick={() => setShowWizard(true)}
        ></Infobox>
      );
    } else if (!workspacesExist) {
      return (
        <Infobox
          show={true}
          variant="light"
          title={'Onboardingen er ikke ferdig'}
          text={'Du har enda ikke opprettet arbeidsområde. Fortsett videre med onboarding.'}
          icon={<LightbulbOutlined color="secondary" />}
          actionText={'Gå til onboarding'}
          actionIcon={<ArrowForward />}
          onClick={() => setShowWizard(true)}
        ></Infobox>
      );
    } else if (!usersExist) {
      return (
        <Infobox
          show={true}
          variant="light"
          title={'Onboardingen er ikke ferdig'}
          text={'Du har enda ikke invitert brukere til din organisasjon. Fortsett videre med onboarding.'}
          icon={<LightbulbOutlined color="secondary" />}
          actionText={'Gå til onboarding'}
          actionIcon={<ArrowForward />}
          onClick={() => setShowWizard(true)}
        ></Infobox>
      );
    }

    return <></>;
  };

  const OnboardingText = () => {

    if (!workspacesExist && requestStatus == CustomerAccessRequestStatus.Approved) {
      return (
        <>
          <PageTitle title={`Velkommen til multiMap, ${profileInfo?.displayName}`}></PageTitle>
          <p>
            Det første du må gjøre som ny kunde i multiMap er å opprette et arbeidsområde med en objektportefølje. Du
            kan ha flere arbeidsområder med tilhørende porteføljer eller delporteføljer, for eksempel kan en kunde ha
            ett arbeidsområde for sine formålsbygg og ett arbeidsområde for sine boliger eller parker. Objekttyper kan
            for eksempel være eiendommer (kontorer, skoler/universiteter, sykehjem, sykehus ol.), parker, veier, eller
            andre typer objekter som er av ulike typer. Ettersom kartleggingsmodulene i multiMap er tilpasset ulike
            typer objekter kan man kun ha en type porteføljeobjekter per arbeidsområde. For eksempel er modulene for
            kartlegging av teknisk tilstand ulike for formålsbygg, boliger, parker og veier – disse må derfor ha ulike
            arbeidsområder.
          </p>
        </>
      );
    } else if (profileInfo?.organization == null && invitation != null) {
      return (
        <>
          <PageTitle title={`Du har fått en invitasjon fra organisasjonen din.`}></PageTitle>
          <p>Din forespørsel har blitt godkjent av din organisasjon. Trykk på knappen nedenfor for å knytte deg til organisasjonen.</p>
          <Button variant="primary" onClick={() => {
            redeem();
          }}>Knytt deg til organisasjonen</Button>
        </>
      );
    } else if (!workspacesExist && requestStatus != CustomerAccessRequestStatus.Pending) {
      return (
        <>
          <PageTitle title={`Velkommen til multiMap, ${profileInfo?.displayName}`}></PageTitle>
          <p>Hei {profileInfo?.displayName},</p>
          <p>Du har nå opprettet en bruker i multiMap.</p>
          <p>
            Dersom du ønsker å opprette et nytt kundeforhold, kan du trykke på «Gå til onboarding» i feltet over.
            <br></br>
            Dersom du ønsker å knytte deg til et eksisterende kundeforhold, vennligst ta kontakt med multiMap-ansvarlig
            i din organisasjon, slik at vedkommende kan invitere deg.
          </p>
          <p></p>
          <p>
            Lurer du på noe er det bare å ta kontakt med{' '}
            <a href="mailto:multimap@multiconsult.no">multimap@multiconsult.no</a>
          </p>
        </>
      );
    } else if (requestStatus == CustomerAccessRequestStatus.Pending) {
      return (
        <>
          <PageTitle title={`Velkommen til multiMap, ${profileInfo?.displayName}`}></PageTitle>
          <p>
            Din forespørsel er nå sendt til multiconsult som snarlig vil behandle denne. Du vil få en mail når denne er
            ferdig behandlet og kan deretter fortsette din bruk av verktøyet.
          </p>
        </>
      );
    } else if (requestStatus == CustomerAccessRequestStatus.Denied) {
      return (
        <>
          <PageTitle title={`Din forespørsel har blitt avisst, ${profileInfo?.displayName}`}></PageTitle>
          <p>For mer info, ta kontakt på kontaktskjema.</p>
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      <Container fluid className="px-sm-4 px-lg-5 mt-4">
        <Row>
          <Col>
            <InfoBoxInfo />
            <LoadingSpinner isLoading={workspacesLoading || invitationsLoading || isLoading} />
            {workspace ? (
              <>
                <PageTitle title="Oversikt"></PageTitle>
                {objectsOverview && objectsOverview ? (
                  <Card className="p-3 mb-5">
                    <Row>
                      {objectsOverview.firstObjectName && (
                        <Col>
                          <h4>{objectsOverview.firstObjectName}</h4>
                          <h3>{objectsOverview.firstObjectAmount}</h3>
                        </Col>
                      )}

                      {objectsOverview.secondObjectName && (
                        <Col>
                          <h4>{objectsOverview.secondObjectName}</h4>
                          <h3>{objectsOverview.secondObjectAmount}</h3>
                        </Col>
                      )}

                      {objectsOverview.thirdObjectName && (
                        <Col>
                          <h4>{objectsOverview.thirdObjectName}</h4>
                          <h3>{objectsOverview.thirdObjectAmount}</h3>
                        </Col>
                      )}

                      <Col>
                        <h4>Bruttoareal</h4>
                        <h3>{asNumberSeperator(objectsOverview.areal)} m2</h3>
                      </Col>
                      {objectsOverview.year > 0 && (
                        <Col>
                          <h4 className="text-nowrap">Gjennomsnittlig byggår</h4>
                          <h3>{objectsOverview.year.toFixed(0)}</h3>
                        </Col>
                      )}
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button variant="tertiary" onClick={() => navigate('/object-registry')}>
                          Til portefølje
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ) : (
                  <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                    <>
                      <Col xs={8} className="p-3">
                        <h4>Din portefølje er tom</h4>
                        <span>
                          For å kunne kartlegge eller benytte deg av andre sider i multiMap må du første registrere dine
                          objekter. Dette kan du gjøre enten via import eller ved å legge til enheter manuelt.
                        </span>
                      </Col>
                      <Col xs={4} className="p-3 d-flex justify-content-end align-items-end">
                        <Button variant="tertiary" onClick={() => navigate('/object-registry')}>
                          Start veileder
                        </Button>
                      </Col>
                    </>
                  </Row>
                )}
                <div className="mb-5">
                  <Row className="mb-2">
                    <Col>
                      <h4>Alle kartlegginger</h4>
                    </Col>
                    {workspaceModules && workspaceModules.length > 0 && (
                      <Col className="d-flex justify-content-end">
                        <Button variant="tertiary" onClick={() => navigate('/mapping')}>
                          Til kartlegginger
                        </Button>
                      </Col>
                    )}
                  </Row>
                  {workspaceModules && workspaceModules.length > 0 ? (
                    <>
                      {workspaceModules.map((row, i) => {
                        if (row.lastMappingDate != undefined) {
                          return (
                            <Card className="p-3 mb-3">
                              <Row>
                                <Col xs={3}>
                                  <h4>{row.systemName}</h4>
                                </Col>
                                <Col xs={6}>
                                  <MappingObjectsProgress workspaceModuleId={row.workspaceModuleId} />
                                </Col>
                                {row.hasPreviousMapping && (
                                  <Col className="ms-5">
                                    <div className="d-flex flex-column">
                                      <small className="text-nowrap">FORRIGE KARTLEGGING</small>
                                      <span>{asDateTime(row.lastMappingDate)}</span>
                                    </div>
                                  </Col>
                                )}
                                <Col className="d-flex align-items-end flex-column">
                                  <small>PERIODE</small>
                                  <span>{row.lastMappingPeriod}</span>
                                </Col>
                              </Row>
                            </Card>
                          );
                        } else {
                          return (
                            <Row className="g-0 rounded pt-3 mb-3" style={{ border: '1px dashed #ABBED1' }}>
                              <>
                                <Col xs={8} className="p-3">
                                  <h4>{row.systemName}</h4>
                                </Col>
                                <Col xs={4} className="p-3 d-flex justify-content-end align-items-end">
                                  <Pill variant="danger">Har ikke vært kartlagt tidligere</Pill>
                                </Col>
                              </>
                            </Row>
                          );
                        }
                      })}
                    </>
                  ) : (
                    <>
                      <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                        <>
                          <Col xs={8} className="p-3">
                            <h4>Ingen abonnement på modul funnet</h4>
                            <span>
                              Du har ingen aktive kartleggings moduler, vennligst gå til kartleggingssiden og se alle
                              tilgjengelig moduler.
                            </span>
                          </Col>
                          <Col xs={4} className="p-3 d-flex justify-content-end align-items-end">
                            <Button variant="tertiary" onClick={() => navigate('/mapping')}>
                              Til kartlegging
                            </Button>
                          </Col>
                        </>
                      </Row>
                    </>
                  )}
                </div>
                <Row className="mb-1">
                  <Col>
                    <h4>Rapporter</h4>
                  </Col>
                  {workspaceModules && workspaceModules.length > 0 && (
                    <Col className="d-flex justify-content-end">
                      <Button variant="tertiary" onClick={() => navigate('/report')}>
                        Til rapporter
                      </Button>
                    </Col>
                  )}
                </Row>
                {isLoadingReportModules ? (
                  <LoadingSpinner isLoading={isLoadingReportModules} />
                ) : (
                  <>
                    {workspaceModules &&
                      workspaceModules.length > 0 &&
                      workspaceModules.some((x) => x.lastMappingDate != undefined) &&
                      reports &&
                      reports.length > 0 ? (
                      <Row className="mb-5">
                        {reports &&
                          reports.map((row, i) => {
                            return (
                              <Col md={4} className="mb-3">
                                <ReportCardOverview report={row} loading={isLoadingReportModules} />
                              </Col>
                            );
                          })}
                      </Row>
                    ) : (
                      <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                        <Col xs={8} className="p-3">
                          <h4>Ingen ferdigstilt kartlegging</h4>
                          <span>
                            Du har ingen ferdigstilte kartleggingsperioder, vennligst opprett og fullfør en kartlegging
                            for å kunne se rapporter.
                          </span>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                <Row className="mb-2">
                  <Col>
                    <h4>Vedlikeholdsplan</h4>
                  </Col>
                  {maintenancePlans && maintenancePlans.length > 0 && (
                    <Col className="d-flex justify-content-end">
                      <Button variant="tertiary" onClick={() => navigate('/maintenance-plan')}>
                        Til tiltak- og vedlikeholdsplan
                      </Button>
                    </Col>
                  )}
                </Row>
                {maintenancePlans && maintenancePlans.length > 0 ? (
                  <Card className="p-3 mb-3">
                    <Row>
                      <Col>
                        <Stack direction="vertical" className="me-3">
                          <small>Tiltak registrert</small>
                          <h5>{maintenancePlans.length}</h5>
                        </Stack>
                      </Col>
                      <Col>
                        <Stack direction="vertical">
                          <small>Total estimert kostnad</small>
                          <h5>
                            {asNumberSeperator(maintenancePlans.reduce((a, b) => a + b.totalPriceEstimate, 0))} kr
                          </h5>
                        </Stack>
                      </Col>
                      <Col>
                        <Stack direction="vertical">
                          <small>Total kalkulert kostnad</small>
                          <h5>
                            {asNumberSeperator(maintenancePlans.reduce((a, b) => a + b.totalPriceCalculate, 0))} kr
                          </h5>
                        </Stack>
                      </Col>
                    </Row>
                  </Card>
                ) : (
                  <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
                    <Col xs={8} className="p-3">
                      <h4>Ingen tiltak etablert</h4>
                      <span>Du har ikke tilgang på modulen eller har ikke etablert tiltak.</span>
                    </Col>
                    <Col xs={4} className="p-3 d-flex justify-content-end align-items-end">
                      <Button variant="tertiary" onClick={() => navigate('/maintenance-plan')}>
                        Til tiltak- og vedlikeholdsplan
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                {workspacesLoading ||
                  isLoading ||
                  isLoadingRequest ||
                  invitationsLoading ||
                  profileInfo == undefined ? (
                  <LoadingSpinner isLoading={workspacesLoading} />
                ) : (
                  <>
                    {(profileInfo?.isSystemAdministrator || workspacesExist) && (
                      <PageTitle title="Ingen arbeidsområde valgt, velg i arbeidsområde velger."></PageTitle>
                    )}

                    {!profileInfo?.isSystemAdministrator && <OnboardingText />}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <WizardShell show={showWizard} onHide={() => setShowWizard(false)} />
    </>
  );
};

import { Alert, Button } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { Variant } from 'react-bootstrap/esm/types';

type Props = {
  show: boolean;
  variant: Variant;
  title: string;
  text: string;
  icon?: JSX.Element;
  actionText: string;
  actionIcon?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
  onClick: () => void;
};

export const Infobox: React.FC<Props> = ({
  show,
  variant,
  title,
  text,
  icon,
  actionText,
  actionIcon,
  children,
  onClick,
}) => {
  return (
    <Alert variant={variant ?? 'light'} show={show} className="shadow">
      <div className="d-flex">
        {icon}
        <div className="ms-3">
          <h5>{title}</h5>
          <span>{text}</span>
          {children}
        </div>
        <Button variant="link" className="d-flex ms-auto align-self-center p-0" onClick={onClick}>
          <div className="me-2 text-end">{actionText}</div>
          {actionIcon}
        </Button>
      </div>
    </Alert>
  );
};

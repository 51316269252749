import { PageTitle } from 'common/components/page-title/PageTitle';
import { Col, Container, Row } from 'react-bootstrap';

export const LearningReports = () => {
  return (
    <>
      <PageTitle title="Rapporter" />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/0lEhC_pkQCw?si=RLQefuTOszBRtY1z"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <br></br>
      <br></br>
      <p>
        Rapportfanen viser aktive kartleggingsmoduler og gir mulighet for å gå videre til rapportgenerering for
        fullførte kartleggingsmoduler. Ved å velge «Se tilgjengelige rapporter» vil man få anledning til å generere to
        ulike rapporter for fullførte kartlegginger: Kartlagt rådata og basisrapport.
      </p>

      <p>
        <b>Kartlagt rådata</b> viser kartlagte tilstandsgrader for alle komponenter for alle kartlagte bygg og kan
        eksporteres til Excel.
      </p>

      <p>
        <b>Basisrapport</b> gir en overordnet oppsummering av resultatene fra kartleggingen i form av grafer og
        nøkkeltall.
      </p>

      <p>
        Utover dette standardsortimentet kan erfarne fagpersoner fra Multiconsult utvikle detaljerte rapporter med
        utdypende, pedagogiske analyser på bestilling. Eksempler på dette kan være interaktive Dashbord i Power BI,
        topprapporter for presentasjon av resultater og tekstbaserte rapporter. Dette gir mulighet for ytterligere
        tilpasning basert på organisasjonens unike behov og portefølje. Ønsker du å høre mer om mulighetene for
        tilpassede rapporter, kan du ta kontakt med våre fageksperter på{' '}
        <a href="mailto:multimap@multiconsult.no">multimap@multiconsult.no.</a>
      </p>
    </>
  );
};

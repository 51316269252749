import { ObjectPropertyResponse } from 'common/api/multimap';
import { ValidationErrors } from 'common/components/form/ValidationErrors';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';

import useImportVerifyWorkspace from '../hooks/useImportVerifyWorkspace';
import { ImportErrorsResult } from './ImportErrorsResult';
import { ImportRowsResult } from './ImportRowsResult';
import { ValidationError } from './ValidationError';

export interface IProps {
  workspaceId: Guid;
  properties: ObjectPropertyResponse[];
  file: File;
  onNext: () => void;
  onPrevious: () => void;
}

export const ImportVerifyFileStep: React.FC<IProps> = ({ workspaceId, properties, file, onNext, onPrevious }) => {
  const [isSaving, isError, , , validationError, importVerifyResponse, saveAction] = useImportVerifyWorkspace();

  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    saveAction({ workspaceId, file });
  }, [workspaceId, file]);

  useEffect(() => {
    setIsInvalid(
      isError || !!validationError || (importVerifyResponse !== undefined && importVerifyResponse.errors.length > 0),
    );
  }, [isError, importVerifyResponse, validationError]);

  return (
    <>
      <LoadingSpinner isLoading={isSaving} />

      {validationError && <ValidationErrors errors={validationError} />}

      {isError && <ValidationError message="En feil oppstod" />}

      <Row>
        <Col className="">
          <Stack direction="horizontal">
            {isInvalid && (
              <Button
                type="submit"
                variant="primary"
                size="sm"
                className="text-nowrap ms-auto"
                onClick={() => onPrevious()}
              >
                Tilbake
              </Button>
            )}

            {!isInvalid && (
              <Button
                type="submit"
                variant="primary"
                size="sm"
                className="text-nowrap ms-auto"
                onClick={() => onNext()}
              >
                Bekreft
              </Button>
            )}
          </Stack>
        </Col>
      </Row>

      <Row>
        <Col>
          {isInvalid && importVerifyResponse !== undefined && importVerifyResponse.errors.length > 0 && (
            <ImportErrorsResult errors={importVerifyResponse.errors} />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {!isInvalid && importVerifyResponse !== undefined && importVerifyResponse.rows.length > 0 && (
            <ImportRowsResult rows={importVerifyResponse.rows} properties={properties} />
          )}
        </Col>
      </Row>
    </>
  );
};

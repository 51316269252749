import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router';

export const Planning = () => {
  useEffect(() => {
    document.title = 'Vedlikeholdsplan';
  }, []);
  return (
    <>
      <Container fluid className="px-sm-4 px-lg-5 mt-4">
        <Outlet />
      </Container>
    </>
  );
};
